import React from 'react'
import { Text, Box, BoxProps } from '@butcherbox/freezer'

const TotalLineItem = ({
  title,
  titleAddon,
  ...props
}: {
  title: string
  titleAddon: string
  props?: BoxProps
}) => {
  return (
    <tbody>
      <Box component="tr" {...props}>
        <Box component="th" paddingBottom={16} scope="row" textAlign="left">
          <Text component="h3" variant="Subhead1">
            {title}
          </Text>
        </Box>
        <Box component="td" paddingBottom={16} textAlign="right">
          <Text component="div" variant="H3Bold">
            {titleAddon}
          </Text>
        </Box>
      </Box>
    </tbody>
  )
}

export default TotalLineItem
