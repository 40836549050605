import { useFeature } from '@optimizely/react-sdk'
import React from 'react'
import {
  addEventToSegmentQueue,
  trackOptimizelyFeatureSeen,
} from '~/analytics/events'
import useGetOptimizelyConfig from './useGetOptimizelyConfig'

type useFeatureParameters = Parameters<typeof useFeature>

export default function useOptimizelyFeature(
  ...parameters: useFeatureParameters
) {
  const featureValues = useFeature(...parameters)
  const [eventDispatched, setEventDispatched] = React.useState(false)
  const { featuresMap } = useGetOptimizelyConfig()

  React.useEffect(() => {
    if (!featuresMap || !!eventDispatched) return

    const [isEnabled] = featureValues
    const [featureKey] = parameters
    const featureId = featuresMap[featureKey]?.id

    if (!featureId || !isEnabled) return

    if (Boolean(window.analytics)) {
      trackOptimizelyFeatureSeen(featureId)
    } else {
      addEventToSegmentQueue({
        eventType: 'Optimizely Feature Seen',
        payload: { feature_id: featureId },
      })
    }

    setEventDispatched(true)
  }, [featuresMap, featureValues, parameters, eventDispatched])

  return featureValues
}
