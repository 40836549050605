import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk'
import Cookies from 'js-cookie'
import React, { PropsWithChildren } from 'react'
import { trackError } from '~/analytics/errors'
import useAnonymousId from '~/hooks/useAnonymousId'

// Optimizely log levels - can't import from library because babel can't compile a different const enum in same file
// https://docs.developers.optimizely.com/full-stack/v2.1/docs/configure-the-logger#section-log-levels
const LOG_LEVEL = {
  NOTSET: 0,
  DEBUG: 1, // Most verbose
  INFO: 2,
  WARNING: 3,
  ERROR: 4, // Only reports critical errors
}

export const TEST_USER_COOKIE = 'bb_jamstack_uat'
const optimizelyLogLevel =
  process.env.NODE_ENV !== 'production' ? LOG_LEVEL.WARNING : LOG_LEVEL.ERROR

interface OptimizelyUserDefinition {
  id: string
  attributes: {
    is_browser_test: boolean
    is_test_user: boolean
  }
}

export default function LocalOptimizelyProvider(props: PropsWithChildren<any>) {
  // To prevent rendering child components before the Optimizely SDK is ready
  const [shouldRender, setShouldRender] = React.useState(false)

  const { anonymousId } = useAnonymousId()
  /**
   * pro-tip: use `?optimizely_log=info` on any page URL to get detailed debugging output
   * in the browser console!
   */
  const optimizelyRef = React.useRef(
    createInstance({
      // https://docs.developers.optimizely.com/full-stack/docs/initialize-sdk-react#section-notes
      datafileOptions: {
        autoUpdate: true,
        updateInterval: 150000, // 150 seconds
      },

      sdkKey: process.env.OPTIMIZELY_SDK_KEY,
      logLevel: optimizelyLogLevel,
    })
  )

  // the Promise resolve handler from userRef is stored here so it can be invoked out of band by
  // the mount effect below
  const callbackRef = React.useRef<Function>(null)

  const userRef = React.useRef(
    process.env.NODE_ENV === 'test'
      ? null
      : new Promise<OptimizelyUserDefinition>((resolve) => {
          callbackRef.current = resolve
        })
  )

  React.useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current({
        /**
         * We want to always use the anonymousId and not the real userId
         * because if a user becomes identified in the same session that
         * they were previously anonymous, this will cause them to be rebucketed
         * and throw off experimental data.
         */
        id: anonymousId,
        attributes: {
          is_browser_test: !!window.Cypress,
          is_test_user: Cookies.get(TEST_USER_COOKIE) === 'yes',
        },
      } as OptimizelyUserDefinition)
    }
  }, [anonymousId])

  // Fail-safe to render child components if datafile can't be downloaded quickly
  const timeout = setTimeout(() => {
    setShouldRender(true)
    trackError((scope) => {
      scope.capture(new Error('Issue downloading Optimizely datafile'))
    })
  }, 5000)

  // Render child components when the Optimizely SDK is ready
  optimizelyRef.current.onReady().then(() => {
    clearTimeout(timeout)
    setShouldRender(true)
  })

  return (
    <OptimizelyProvider
      optimizely={optimizelyRef.current}
      user={userRef.current}
      {...props}
    >
      {shouldRender ? props.children : null}
    </OptimizelyProvider>
  )
}
