import { Flex } from '@chakra-ui/core'
import { rem, styled } from 'design'

export const ModalDialog = styled(Flex)`
  background: ${(p) => p.theme.colors.bb.ivory};
  flex-direction: column;
  height: 100%;
  max-height: inherit;
  max-width: 100%;
  overflow: hidden;
  width: ${rem(593)};
  ${(p) => p.theme.media.tablet} {
    width: ${(p) =>
      typeof p.width === 'object' && p.width.hasOwnProperty('tablet')
        ? p.width['tablet']
        : rem(656)};
  }
`
