export function smoothScrollTo(
  selector: string | Element,
  yOffset = 0,
  overrideScroll = false
): Promise<void> {
  return new Promise((resolve) => {
    const el =
      selector instanceof Element ? selector : document.querySelector(selector)

    if (el) {
      const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset

      if (
        overrideScroll ||
        y <= window.pageYOffset ||
        y >= window.pageYOffset + window.innerHeight
      ) {
        // only scroll if the desired offset isn't visible already
        window.scrollTo({ top: y, behavior: 'smooth' })

        /**
         * scrollTo does not expose a callback or promise to hook into, so
         * picking an arbitrary amount of time that should be sufficient to
         * faciliate the scroll.
         */
        window.setTimeout(resolve, 1000)
      }
    } else {
      resolve()
    }
  })
}
