import { useQuery } from 'react-query'
import { CACHE_KEY_BOX_TYPES } from '~/bb-api/constants'
import { GET_BOX_TYPES } from '~/bb-api/endpoints'
import { BoxTypeDefinition } from '~/bb-api/schemata'
import axios from '~/utils/axios'

export default function useBoxTypes() {
  return useQuery(
    CACHE_KEY_BOX_TYPES,
    () =>
      axios
        .get(GET_BOX_TYPES)
        .then((response) => response.data.data as BoxTypeDefinition[]),
    {
      staleTime: Infinity, //does not expire
    }
  )
}
