export const CTA_UNAUTHENTICATED_DEFAULT = 'Choose your plan'

export const GIFT_BOXES_PATH_STOREFRONT =
  process.env.NODE_ENV === 'production'
    ? 'https://storefront.butcherbox.com/gifts'
    : 'https://storefront.staging.bboxtools.net/gifts'

export const GIFT_BOXES_PATH_SHOP =
  process.env.NODE_ENV === 'production'
    ? 'https://shop.butcherbox.com'
    : 'https://shop.staging.bboxtools.net'

export const STOREFRONT_DISCOUNT_CODE = 'SAVE20'

/**
 * URL for an API endpoint that begins a Shopify Storefront shopping session. Provide
 * query parameters to this URL to customize its behavior.
 *
 * See repo: butcherbox/storefront:src/routes/storefront/shop.server.ts
 */
export const STOREFRONT_SESSION_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://storefront.butcherbox.com/storefront/shop'
    : 'https://storefront.staging.bboxtools.net/storefront/shop'

export const SHOP_SESSION_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://shop.butcherbox.com/resource/redirect'
    : 'https://shop.staging.bboxtools.net/resource/redirect'
