import { OverlayProvider } from '@butcherbox/freezer'
import { Flex } from '@chakra-ui/core'
import { LocationProvider } from '@reach/router'
import theme, { CSSReset, Global, rem, ThemeProvider } from 'design'
import { ModalProvider } from 'design/components/Modal/Modal'
import {
  AbrilFatfaceFontLoader,
  LatoFontLoader,
} from 'design/components/Typography/Typography'
import { LayoutProvider } from 'design/contexts/layout'
import { ToastProvider } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { LiveAnnouncer } from 'react-aria-live'
import { Helmet } from 'react-helmet'
import { trackError } from '~/analytics/errors'
import { BannerProvider } from '~/components/Banner'
import { SubscriptionProviderRemote } from '~/context/subscriptionRemote'
import { UserProvider } from '~/context/user'
import ErrorBoundary from '~/sections/ErrorBoundary'
import globalStyles from '~/styles/global'
import { legacyBrowserRedirect } from './scripts'

const SimulationLauncher = React.lazy(
  () =>
    import(/* webpackChunkName: "simulations" */ '~/components/DevSimulations')
)
const ExperimentController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "experiment_controller" */ '~/components/DevExperimentController/DevExperimentController'
    )
)

interface BaseLayoutState {
  caughtError: Error | null
  mounted: boolean
}

export default class BaseLayout extends React.Component<{}, BaseLayoutState> {
  state: BaseLayoutState = {
    caughtError: null,
    mounted: false,
  }

  componentDidMount() {
    this.setState({ mounted: true })
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ caughtError: error })

    trackError((scope) => {
      scope.setContext('errorInfo', (errorInfo as unknown) as any)
      scope.capture(error)
    })
  }

  render() {
    return (
      <LocationProvider>
        <UserProvider>
          <SubscriptionProviderRemote>
            <ThemeProvider theme={theme}>
              <LayoutProvider>
                <LiveAnnouncer>
                  <BannerProvider>
                    <ToastProvider>
                      <ModalProvider>
                        <OverlayProvider>
                          <CSSReset />
                          <Global styles={globalStyles} />
                          <AbrilFatfaceFontLoader />
                          <LatoFontLoader />

                          <Helmet>
                            <link
                              href="https://use.typekit.net/fhp2qhk.css"
                              rel="stylesheet"
                            />

                            {
                              /* NODE_ENV === 'test' is used by storybook and jest */
                              process.env.NODE_ENV !== 'test'
                                ? /* react-helmet can't use fragments */ [
                                    legacyBrowserRedirect,
                                  ]
                                : null
                            }
                          </Helmet>

                          {this.state.caughtError !== null ? (
                            <ErrorBoundary error={this.state.caughtError} />
                          ) : (
                            this.props.children
                          )}

                          {process.env.NODE_ENV !== 'test' &&
                            this.state.mounted &&
                            window.location.hostname !==
                              'www.butcherbox.com' && (
                              <React.Suspense fallback={null}>
                                <Flex
                                  alignItems="center"
                                  bottom={0}
                                  justifyContent="center"
                                  left={0}
                                  pos="fixed"
                                  style={{ gap: rem(10) }}
                                  w="100%"
                                  zIndex={theme.zIndices.tooltip}
                                >
                                  <SimulationLauncher />
                                  <ExperimentController />
                                </Flex>
                              </React.Suspense>
                            )}
                        </OverlayProvider>
                      </ModalProvider>
                    </ToastProvider>
                  </BannerProvider>
                </LiveAnnouncer>
              </LayoutProvider>
            </ThemeProvider>
          </SubscriptionProviderRemote>
        </UserProvider>
      </LocationProvider>
    )
  }
}
