import type { BoxType } from '~/bb-api/schemata'

export type CuratedMeatGroup = ExtractStrict<
  BoxType,
  'all_beef' | 'beef_and_chicken' | 'beef_and_pork' | 'beef_chicken_pork'
>

export type CuratedMeatTypes = {
  meatGroup: CuratedMeatGroup
  title: string
}

export interface CuratedMeatTypeSelectionFrameUIProps {
  curatedMeatTypes: CuratedMeatTypes[] | Omit<CuratedMeatTypes[], 'all_beef'>
  isSubmitting: boolean
  buttonText: string
}

export const curatedMeatGroupsArray: CuratedMeatGroup[] = [
  'all_beef',
  'beef_and_chicken',
  'beef_and_pork',
  'beef_chicken_pork',
]
export const curatedMeatGroupSet = new Set<BoxType>(curatedMeatGroupsArray)
