import { Box, Modal } from '@butcherbox/freezer'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React, { useContext } from 'react'
import CalendarBillDate from '~/components/CalendarBillDate'
import { useCustomDateRanges } from '~/components/CalendarBillDate/hooks/useCustomDateRanges'
import { useEditBoxDateCalendar } from '~/components/CalendarBillDate/hooks/useEditBoxDateCalendar'

type EditBoxDateModalProps = {
  handleModalClose: () => void
}
const successMessage = 'Successfully updated bill date'
const errorMessage =
  'There was a problem updating your bill date. Please try again or contact customer service for help.'

export default function EditBillDateModal({
  handleModalClose,
}: EditBoxDateModalProps) {
  const calendar = useEditBoxDateCalendar()
  const customDates = useCustomDateRanges(calendar)
  const showToast = useContext(ToastContext)

  const onError = () => {
    handleModalClose()
    showToast('error', { children: errorMessage })
  }

  const onSuccess = () => {
    handleModalClose()
    showToast('success', { children: successMessage })
  }

  return (
    <Box width={'50%'}>
      <Modal
        data-what="edit-bill-date-modal"
        onClose={handleModalClose}
        size="fit"
        title="Confirm Next Bill Date"
      >
        <CalendarBillDate
          customDates={customDates}
          headerLabel=""
          onError={onError}
          onSuccess={onSuccess}
          showHeader
        />
      </Modal>
    </Box>
  )
}
