import React from 'react'
import {
  Box,
  Lockup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalProps,
  OverlayContainer,
  Text,
} from '@butcherbox/freezer'
import { ModalIngredientProps } from './ModalIngredient.types'
import useIngredients from '~/hooks/useIngredients'
import { Product } from '~/bb-api/schemata'

const IngredientModalType: ModalProps = {
  title: 'Cut Information',
  size: 'm',
}

function renderModalIngredients(
  ingredients: Array<Pick<Product, 'description' | 'ingredients'>> | null
) {
  return (
    <>
      {ingredients.map((ingredient, idx) => {
        return (
          <Box
            borderBottom={idx !== ingredients.length - 1 ? 'silt' : undefined}
            key={idx}
            paddingBottom={idx !== ingredients.length - 1 ? 16 : undefined}
            paddingTop={idx === 0 ? undefined : 16}
          >
            <Lockup textAlign="left">
              <Text variant="BodyH2Bold">{ingredient.description}</Text>

              <Text variant="Body1Regular">
                <Text component="span" display="inline" variant="Body1Bold">
                  Ingredients:
                </Text>
                &nbsp;{ingredient.ingredients}
              </Text>
            </Lockup>
          </Box>
        )
      })}
    </>
  )
}

export function ModalIngredient({
  Button,
  product,
  onClose,
  variant = 'deal',
}: ModalIngredientProps) {
  const getIngredients = useIngredients()
  const ingredients = getIngredients(product)
  switch (variant) {
    case 'deal':
      return (
        <OverlayContainer>
          <Modal
            isDismissable
            isOpen
            {...IngredientModalType}
            onClose={onClose}
          >
            <ModalBody variant="default">
              {renderModalIngredients(ingredients)}
            </ModalBody>
            <ModalFooter>
              <Box>{Button}</Box>
            </ModalFooter>
          </Modal>
        </OverlayContainer>
      )
    case 'addon':
    case 'custom':
      return (
        <OverlayContainer>
          <Modal {...IngredientModalType} onClose={onClose}>
            <ModalBody variant="default">
              <Lockup textAlign="left">
                <Text variant="BodyH2Bold">{product.description}</Text>
                {product.fullDescription ? (
                  <Text variant="Body1Regular">{product.fullDescription}</Text>
                ) : null}
                {product.packSize ? (
                  <Text variant="Body1Regular">
                    <strong>Pack Size:</strong>&nbsp;{product.packSize}
                  </Text>
                ) : null}
                {ingredients?.length > 0 ? (
                  <Text variant="Body1Regular">
                    <Text component="span" display="inline" variant="Body1Bold">
                      Ingredients:
                    </Text>
                    &nbsp;{ingredients[0].ingredients}
                  </Text>
                ) : undefined}
              </Lockup>
            </ModalBody>
            <ModalFooter>
              <Box>{Button}</Box>
            </ModalFooter>
          </Modal>
        </OverlayContainer>
      )
  }
}
export default ModalIngredient
