import React from 'react'
import { LinkInternal } from 'design/components/Typography/Typography'
import { Box, Text, VisuallyHidden, rem } from '@butcherbox/freezer'
import { IPanelTableRow } from './PanelTableRow.types'

export function PanelTableRow({
  id = '',
  panelPaddingBottom = rem(6),
  panelPaddingTop = rem(6),
  content,
  contentAddon,
  changeLinkUrl,
  ...props
}: IPanelTableRow) {
  const cellStyle = {
    paddingBottom: panelPaddingBottom,
    paddingTop: panelPaddingTop,
  }
  return (
    <Box component="tbody">
      <Box component="tr" {...props}>
        <Box
          component="th"
          scope="row"
          style={{ ...cellStyle, textAlign: 'left' }}
        >
          <Text component="h3" id={id} variant="Subhead1">
            {content}
          </Text>
          {changeLinkUrl && (
            <LinkInternal href={changeLinkUrl} ml={rem(5)}>
              Change&nbsp;
              <VisuallyHidden component="span">{content}</VisuallyHidden>
            </LinkInternal>
          )}
        </Box>
        <Box component="td" style={{ ...cellStyle }}>
          <Box textAlign={'right'}>
            <Text component="div" variant="H3Bold">
              {contentAddon}
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
