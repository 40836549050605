import dayjs, { Dayjs } from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { trackError } from '~/analytics/errors'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

/**
 * In both of these functions we are cloning the dayjs
 * parameters so that we can be assured that the objects
 * we're using are properly extending the functions we need
 */

dayjs.extend(isSameOrBefore)
dayjs.extend(timezone)
dayjs.extend(utc)

export const YYYY_MM_DD = 'YYYY-MM-DD'

export const convertRangeToArrayOfDays = (
  start: Dayjs | string,
  end: Dayjs | string
): Dayjs[] => {
  const safeStart = dayjs(start)
  const safeEnd = dayjs(end)

  if (safeStart > safeEnd) {
    const error = `Expected start date ${safeStart.toString()} to be before end date ${safeEnd.toString()}.`
    if (process.env.NODE_ENV !== 'production') {
      throw new Error(error)
    } else {
      trackError((scope) => {
        scope.capture(new Error(error))
      })
    }

    return []
  }

  const days: Dayjs[] = []
  let ptr = safeStart

  while (ptr.isSameOrBefore(safeEnd, 'd')) {
    // retain time of day for end
    if (ptr.isSame(safeEnd, 'd')) days.push(safeEnd)
    else days.push(ptr)

    ptr = ptr.add(1, 'd')
  }

  return days
}

export const isCalendarDayInvalid = (
  calendarDate: Dayjs,
  invalidDates: Dayjs[],
  lastValidDate: Dayjs = dayjs().add(6, 'month')
): boolean => {
  // we must create the dayjs instance in *this* file for isSameOrBefore to work.
  const safeCalendarDate = dayjs(calendarDate).format(YYYY_MM_DD)

  return (
    dayjs(safeCalendarDate).isSameOrBefore(
      dayjs.tz(dayjs(), 'America/New_York').format(YYYY_MM_DD),
      'day'
    ) ||
    invalidDates.some((day) => day.isSame(dayjs(calendarDate), 'd')) ||
    dayjs(safeCalendarDate).isAfter(lastValidDate.format(YYYY_MM_DD), 'day')
  )
}

/**
 * @param billingDay - a date in the format YYYY-MM-DD
 * @returns boolean based on if the date passed in matches todays date in the America/New_York timezone
 */
export const returnIsTodayBillingDay = (billingDay: string): boolean => {
  if (!billingDay) return false
  const today = dayjs.tz(dayjs(), 'America/New_York').format(YYYY_MM_DD)
  return dayjs(billingDay).isSame(today, 'day')
}
