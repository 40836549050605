import useUpcomingOrder from './useUpcomingOrder'

export function useMemberDealSavingReceipt() {
  const { data: upcomingOrder } = useUpcomingOrder()
  let slashThroughPrice = 0
  let isSavingReceipt = false
  let totalSlashThroughPriceSave = 0
  if (upcomingOrder !== undefined) {
    upcomingOrder?.invoiceItems.forEach((invoiceItem) => {
      slashThroughPrice +=
        invoiceItem.markupPrice !== '' &&
        invoiceItem.markupPrice !== '0.00' &&
        parseFloat(invoiceItem.markupPrice) * 100 > invoiceItem.price
          ? parseFloat(invoiceItem.markupPrice) * invoiceItem.quantity * 100
          : invoiceItem.amount //Calculating total Slash Through Price
    })
    isSavingReceipt = slashThroughPrice > upcomingOrder?.invoiceItemsPrice

    totalSlashThroughPriceSave =
      slashThroughPrice - upcomingOrder?.invoiceItemsPrice
  }

  return {
    slashThroughPrice,
    isSavingReceipt,
    totalSlashThroughPriceSave,
  }
}
export default useMemberDealSavingReceipt
