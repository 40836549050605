import React from 'react'
import * as Styles from './Panel.css'
import { Box, Text } from '@butcherbox/freezer'

export default function Panel({ children, ...props }) {
  return (
    <Box className={Styles.Panel} {...props}>
      {children}
    </Box>
  )
}

export const PanelHeadline = (props) => (
  <Text component="h2" marginBottom={4} variant="Subhead1" {...props} />
)

export const PanelSubheadline = (props) => (
  <Text
    component="h3"
    display="inlineBlock"
    variant="Body1Regular"
    {...props}
  />
)
