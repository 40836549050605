import React from 'react'

/**
 * KlaviyoPushPayload defines the two argument shapes that the Klaviyo pixel expects
 * from the frontend.
 *
 * See info on identify payloads: https://help.klaviyo.com/hc/en-us/articles/115000751052-Track-and-Identify-API-Reference#identifying-users--javascript-5
 * See info on track payloads: https://help.klaviyo.com/hc/en-us/articles/115000751052-Track-and-Identify-API-Reference#tracking-events--javascript-9
 */
type KlaviyoPushPayload =
  | ['identify', Record<string, any>]
  | ['track', string, Record<string, any>]

declare global {
  /**
   * This pixel is injected by GTM
   */
  interface KlaviyoPixel {
    push: (payload: KlaviyoPushPayload) => void
  }

  interface Window {
    _learnq?: KlaviyoPixel
  }
}

// IE redirect to browser upgrade page, documentMode is only implemented in IE8-11 (not Edge)
export const legacyBrowserRedirect = (
  <script key="legacy-redirect">
    {`;'documentMode' in document && window.location.pathname.indexOf('/legacy-browser') !== 0 && (window.location.href = '/legacy-browser');`}
  </script>
)
