import { Box } from '@chakra-ui/core'
import { styled } from 'design'
import { rem } from 'design/utils'
import React from 'react'
import { LiveMessage } from 'react-aria-live'
import { TEST_ID } from '~/constants/cypress'
import type { IToast } from './Toast.types'
import { Text, theme } from '@butcherbox/freezer'

/*
This is just the presentational component for the toast UI element.
To use, import the useToast hook from Chakra UI and save the returned function
to a variable at the top level of the component you want to call a toast
notification from.

See an example of how to trigger a toast in the accompanying stories file or
follow the instructions for using a custom toast element here:
https://chakra-ui.com/toast#custom-component
*/

export default function Toast({
  children,
  renderIcon,
  status = 'success',
  duration = 5000,
  ...props
}: IToast) {
  return (
    <TransitionBox
      data-what="toast"
      duration={duration}
      status={status}
      // @ts-ignore
      {...props}
    >
      {renderIcon && renderIcon()}
      <LiveMessage aria-live="assertive" message={children} />
      <Text
        color={'white'}
        data-cy={TEST_ID.TOAST}
        data-cy-toast-status={status}
        variant={'H4Bold'}
      >
        {children}
      </Text>
    </TransitionBox>
  )
}

const getToastBackgroundColor = (status: IToast['status']) => {
  switch (status) {
    case 'success':
      return theme.color.success
    case 'error':
      return theme.color.error
    case 'warning':
      return theme.color.warning
    case 'info':
      return theme.color.information
    case 'custom':
      return theme.color.slate
  }
}

const TransitionBox = styled(Box)<{
  duration: number
  status: IToast['status']
}>`
  animation: 0.5s ease 0s 1 fadeIn,
    0.5s ease-out calc(${(p) => p.duration}ms - 0.5s) 1 fadeout;
  animation-fill-mode: forwards;
  background-color: ${(p) => getToastBackgroundColor(p.status)};
  align-items: center;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: ${rem(12)};
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: ${(p) => p.theme.zIndices.docked};

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media (prefers-reduced-motion) {
    animation: unset;
  }
`
