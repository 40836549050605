import { Box, VisuallyHidden } from '@chakra-ui/core'
import { rem, shortId } from 'design'
import { CardLandscapeWide } from 'design/components/Card/Card'
import { CardHTMLImage } from 'design/components/CardImage/CardImage'
import { Body, H4Tight } from 'design/components/Typography/Typography'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { formatPriceFromCents } from '~/utils'
import { CardImageStatus, ModalTrigger, QuantityBadge } from '.'
import CutInfoModal from '../CutInfoModal/CutInfoModal'
import type { ListingCardProps } from './ListCard.types'

export function AddonLandscapeCard({
  Decoration,
  renderButton,
  product,
  quantity,
  ...props
}: ListingCardProps) {
  const [modalOpen, setModalOpen] = React.useState(false)
  const ariaLabel = React.useMemo(() => shortId(), [])

  return (
    <CardLandscapeWide
      aria-describedby={ariaLabel}
      Button={renderButton({ withinInfoModal: false })}
      Content={
        <Box textAlign="left">
          <H4Tight as="h2" id={ariaLabel}>
            {product.description}
          </H4Tight>
          <Body as="h3" color="bb.stone" d="flex" whiteSpace="nowrap">
            <VisuallyHidden>
              Each unit of {product.description} contains&nbsp;
            </VisuallyHidden>
            <Box
              as="span"
              flexShrink={1}
              overflow="hidden"
              style={{
                textOverflow: 'ellipsis',
              }}
              title={product.packSize}
            >
              {product.packSize}
            </Box>
            ,&nbsp;
            <VisuallyHidden>Price:&nbsp;</VisuallyHidden>
            {formatPriceFromCents(product.price)}
          </Body>
        </Box>
      }
      contentProps={{ px: 0, pl: rem(AddonLandscapeCard.PADDING_LEFT), py: 0 }}
      data-attr-sku={product.sku}
      data-cy={TEST_ID.CARD_ADDON}
      data-cy-current-quantity={quantity}
      data-cy-sku={product.sku}
      Decoration={Decoration}
      h={rem(136)}
      Image={
        <>
          <CardHTMLImage height="100%" href={product.image} width={rem(152)} />

          <ModalTrigger
            onClick={() => setModalOpen(!modalOpen)}
            product={product}
          />

          <QuantityBadge corner="left" product={product} quantity={quantity} />

          <CardImageStatus
            isUnavailable={product.isUnavailable}
            quantity={quantity}
          />

          {modalOpen && (
            <CutInfoModal
              onClose={() => setModalOpen(false)}
              product={product}
              quantity={quantity}
              renderButton={() => renderButton({ withinInfoModal: true })}
              showImage={true}
              variant="addon"
            />
          )}
        </>
      }
      {...props}
    />
  )
}

AddonLandscapeCard.PADDING_LEFT = 8

export default AddonLandscapeCard
