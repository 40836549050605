import type { FluidObject } from 'gatsby-image'
import type { CMS } from '~/cms/types'
import type { ImageSetBreakpoints } from '~/components/ImageSet/ImageSet.types'

const LAYOUT_WIDTH = 1440

function vwToPx(vw: string) {
  const percentage = +vw.replace('vw', '')
  return `${(LAYOUT_WIDTH * percentage) / 100}px`
}

/**
 * Breakpoints are used within the fluidImage's media property to determine
 * when an image should be displayed. They are also referenced in sizes when making
 * adaptations to an image's layout size at different breakpoints
 */

export const DefaultBreakpoints: ImageSetBreakpoints = {
  desktop: '(min-width: 1025px)',
  mobileUp: '(min-width: 500px)',
  tablet: '(min-width: 500px) and (max-width: 1024px)',
  mobile: '(max-width: 499px)',
}

/**
 * ImageSetSizes is the second parameter to our hook,
 * this allows the user to provide a % of the screen that
 * the image will occupy at that breakpoint
 */
export type ImageSetSizes = {
  m?: 1 | 0.5 | 0.25
  t?: 1 | 0.5 | 0.25
  d?: 1 | 0.5 | 0.25
}

/**
 * Size Map is a lookup based on the provided imageSetSize value
 * this translates the % into a viewport width value for the
 * sizes property on our fluid objects.
 */
export const SizeMap = {
  1: '100vw',
  0.5: '50vw',
  0.25: '25vw',
}

/**
 * useCMSImage set will consume an ImageSet object from the contentful graphql
 * query, and return an array of fluid objects to be used in a image or background
 * image components
 * @param {CMS.ImageSet} ImageSet - An ImageSet object returned from contentful
 * @param {ImageSetSize} sizes - An object that defines what % of the screen an image will occupy at different breakpoints
 * @returns {array} - The sources array to be supplied to the image component
 */
export default function useCmsImageSet(
  ImageSet: CMS.ImageSet | CMS.AtomImageSet,
  sizes: ImageSetSizes = {},
  fullWidth: boolean = false,
  breakpoints: ImageSetBreakpoints = {}
): FluidObject[] {
  // merge incoming breakpoints
  const Breakpoints = {
    ...DefaultBreakpoints,
    ...breakpoints,
  }

  const sources: FluidObject[] = []

  // AtomImageSet operates under an entirely different pattern and premise, we escape early here
  // the allows us to keep with the rules of hooks so that this hook is still invoked.
  if (ImageSet.__typename !== 'ContentfulImageSet') return sources

  // The order of the fluid objects in the array is important,
  // the first fluid object that's media query matches, will be used
  if (ImageSet.mobileImage) {
    const mobileSize = sizes.m ? SizeMap[sizes.m] : '100vw'
    sources.push({
      ...ImageSet.mobileImage.fluid,
      // The Media key defines at what breakpoint this set of images should be used.
      media: Breakpoints.mobile,
      // If no width was provided, we assume 100% otherwise use the provided %
      sizes: mobileSize,
    })
  }

  if (ImageSet.tabletImage) {
    // tablet images could also be used at mobile if mobile isn't provided
    let tabletSize = sizes.t ? SizeMap[sizes.t] : '100vw'

    if (!ImageSet.mobileImage && sizes.m) {
      tabletSize = `${Breakpoints.mobile} ${SizeMap[sizes.m]}, ${tabletSize}`
    }

    sources.push({
      ...ImageSet.tabletImage.fluid,
      media: Breakpoints.tablet,
      sizes: tabletSize,
    })
  }

  if (ImageSet.desktopImage) {
    // If a new VW is provided, use that otherwise 100vw;
    const desktopSize = sizes.d ? SizeMap[sizes.d] : '100vw'

    // If an image isn't full width, then we want to constrain the
    // sizes to the LAYOUT_WIDTH,
    // For FUll width 100vw is appropriate, but for larger monitors where the
    // content is contained w/i the grid, we should use that as the upper boundary
    let desktopSizes = ''
    if (!fullWidth) {
      desktopSizes = `(max-width: ${LAYOUT_WIDTH}px) ${desktopSize}, ${vwToPx(
        desktopSize
      )}`
    }

    // If there is no tablet image provided, but tablet has a provided size,
    // We want to update the sizes to reflect that request in the desktop
    // image's size attribute.
    if (!ImageSet.tabletImage && sizes.t) {
      desktopSizes = `${Breakpoints.tablet} ${
        SizeMap[sizes.t]
      }, ${desktopSizes}`
    }
    sources.push({
      ...ImageSet.desktopImage.fluid,
      // If no tablet is provided, we want to use the desktop image from mobile up
      media: ImageSet.tabletImage ? Breakpoints.desktop : Breakpoints.mobileUp,
      sizes: desktopSizes,
    })
  }
  return sources
}
