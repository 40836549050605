import zendesk from './scripts/onInitialClientRender/zendesk'
import { executeScript } from './gatsby-on-initial-client-render'
import { getUtmParams } from '~/analytics/events'

const sendPageView = (location) => {
  let title = document.title.slice(0, document.title.indexOf('|')).trim()
  if (location.pathname === '/') title = 'Homepage'
  /**
   * pushState does not automatically trigger `popstate`, which is an important
   * event listener to be able to hook into SPA navigation
   */
  window.dispatchEvent(new Event('popstate'))
  const params = getUtmParams(location)

  // segment
  window.analytics?.page(title, {
    origin: 'netlify',
    path: location.pathname,
    url: location.href,

    // used by Heap to get around ITP limitations
    utmCampaign: params.utm_campaign || null,
    utmContent: params.utm_content || null,
    utmMedium: params.utm_medium || null,
    utmSource: params.utm_source || null,
    utmTerm: params.utm_term || null,
    impId: params.impId || null,
    irclickid: params.irclickid || null,
  })

  // klaviyo
  /**
   * Note: We are tracking Page Viewed events directly into Klaviyo to avoid exposing
   * ourselves to the race condition in email updates which occurs in the Klaviyo
   * Segment Event Forwarder. For more information on this race condition, consult
   * the following doc:
   *
   * https://nbox-341.atlassian.net/wiki/spaces/BBWEBB/pages/1937932350/Spike+Segment+Klaviyo+Identity+Resolution+--+First+Iteration+Wrap-up+Documentation#TLDR
   *
   * Klaviyo documentation for the track event:
   *
   * https://help.klaviyo.com/hc/en-us/articles/115000751052-Track-and-Identify-API-Reference#tracking-events--javascript-9
   */

  window._learnq = window._learnq || []
  window._learnq?.push([
    'track',
    'Page Viewed',
    {
      path: location.pathname,
      url: location.href,
    },
  ])
}

export const onRouteUpdate = ({ location }) => {
  // If ZenDesk wasn't initialized previously attempt to execute it now.
  if (!window.zE) {
    executeScript(zendesk)
  }

  // adapted from https://www.darklaunch.com/javascript-detect-changes-to-document-title.html
  const target = document.querySelector('title')

  if (target) {
    let waitingForTitleUpdate = true

    const observer = new MutationObserver(function (mutations) {
      mutations.forEach(function () {
        waitingForTitleUpdate = false
      })
    })

    observer.observe(target, { childList: true })

    /**
     * Wait for a maximum of 200ms to catch the updated document title after the route change,
     * otherwise just fire the pageview event.
     */

    let iterationsRemaining = 20
    const handle = setInterval(() => {
      if (!waitingForTitleUpdate || iterationsRemaining === 0) {
        sendPageView(location)
        clearInterval(handle)
        observer.disconnect()
      }

      iterationsRemaining--
    }, 10)
  }

  return null
}
