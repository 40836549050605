import { BoxType } from '~/bb-api/schemata'
import { MaxSkuQuantities } from '~/components/BaseCustomize/BaseCustomize.types'
import { curatedMeatGroupSet } from '~/routes/CheckoutFlow/types'

export enum Frames {
  BoxTypeSelectionFrame = '/plans-and-addons/type',
  BoxSizeFrequencyFrame = '/plans-and-addons/size',
  CustomizeBoxFrame = '/plans-and-addons/customize',
  PaymentFrame = '/plans-and-addons/checkout',
  CuratedMeatTypeSelectionFrame = '/plans-and-addons/curated',
  DealsFrame = '/plans-and-addons/deals',
}

export enum JOIN_381_VARIANTS {
  CONTROL = 'control',
  DEALS_ONLY = 'deals_only',
  DEALS_BEFORE_ADDONS = 'deals_before_addons',
}

export const getSubsequentFrame = (
  completedFrame: keyof typeof Frames,
  boxType: BoxType
) => {
  const sizeFrequencyDestination = (function () {
    if (boxType === 'cst') {
      return Frames.CustomizeBoxFrame
    }

    if (curatedMeatGroupSet.has(boxType)) {
      return Frames.CuratedMeatTypeSelectionFrame
    }
    return Frames.DealsFrame
  })()

  switch (completedFrame) {
    case 'BoxTypeSelectionFrame':
      return Frames.BoxSizeFrequencyFrame
    case 'BoxSizeFrequencyFrame':
      return sizeFrequencyDestination
    case 'CuratedMeatTypeSelectionFrame':
      return Frames.DealsFrame
    case 'CustomizeBoxFrame':
      return Frames.DealsFrame
    case 'DealsFrame':
      return Frames.PaymentFrame
  }
}

export const CHECKOUT_PAYMENT_STORAGE_KEY = 'bbCheckoutPayment'
export const CHECKOUT_SEEN_PAGES_STORAGE_KEY = 'bbCheckoutSeenPages'
export const CHECKOUT_SUBSCRIPTION_STORAGE_KEY = 'bbCheckoutSubscription'
export const SESSION_STORAGE_CHECKOUT_ID_KEY = 'checkout_id'
export const COOKIE_CHECKOUT_ID_KEY = 'checkout_id'
export const SESSION_STORAGE_IS_CART_RECLAIMER_KEY = 'is_cart_reclaimer'
export const COOKIE_COUPON_CODE = 'static_coupon_code'
export const COOKIE_OFFER_IDENTIFIER = 'exclusive_offer'
export const MOBILE_X_PADDING = 16
export const TABLET_X_PADDING = 32
export const ID_TOS_CHECKBOX = 'tos-checkbox'
export const SESSION_STORAGE_QUICKBUY_ENABLED_KEY = 'quickbuy_enabled'

export const backupShippingWindowText = 'Ships in 1-2 days.'

// Type-safe way of ensuring we're abiding by the type of the value
// from a singular instance of the MaxSkuQuantities Record<>
const boxSizeMaxQuantities: MaxSkuQuantities[0] = {
  classic: 2,
  big: 4,
}

export const limitedCutsSkus: MaxSkuQuantities = {
  '971323': boxSizeMaxQuantities, // top sirloin
  '971350': boxSizeMaxQuantities, // ribeye
  '971411': boxSizeMaxQuantities, // filet mignon
  '971439': boxSizeMaxQuantities, // new york strip
}
