import { IconClose } from '@butcherbox/freezer'
import type * as Types from 'design/components/Modal/Modal.types'
import * as Styled from 'design/components/Modal/ModalCloseButton.styles'
import React from 'react'

const ModalCloseButton: React.FC<Types.CloseButtonProps> = ({
  size = 'text',
  ...props
}) => {
  return (
    <Styled.CloseButton as="button" {...props}>
      <IconClose customColor={{ base: 'spicedCrimson' }} size={size} />
    </Styled.CloseButton>
  )
}

export default ModalCloseButton
