import { TABLET_MIN_WIDTH } from 'design/theme'
import Cookies from 'js-cookie'
import { IAuthenticatedUserAttributes } from './useGetOptimizelyAttributesAuthenticated'
import { IUnauthenticatedUserAttributes } from './useGetOptimizelyAttributesUnauthenticated'

export const mutateBaseOptimizelyAttributes = <
  UserAttributes extends
    | IAuthenticatedUserAttributes
    | IUnauthenticatedUserAttributes
>(
  userAttributes: UserAttributes,
  localStorageUserId: string | null
): UserAttributes => {
  if (window.innerWidth > TABLET_MIN_WIDTH) {
    userAttributes.device_type = 'desktop'
  }

  if (Boolean(Cookies.get('has_authenticated')) || localStorageUserId) {
    userAttributes.has_logged_in = true
  }

  return userAttributes
}
