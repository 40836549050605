import recursiveMap, { Leaf } from './recursiveMap'

/**
 * Clean unicode spaces in object leafs recursively
 */
export function cleanJsonStrings<T extends Record<string, any>>(data: T) {
  return recursiveMap(data, (v) => replaceUnicodeSpaces(v)) as T
}

/**
 * Clean any unicode spaces from string if provided
 */
export function replaceUnicodeSpaces(v: Leaf): Leaf {
  if (!v || typeof v !== 'string') return v
  const regex = /\s+/g
  return v.replace(regex, ' ')
}
