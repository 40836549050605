import { Flex } from '@chakra-ui/core'
import { keyframes, styled } from 'design'

export const fadeIn = keyframes`from { opacity: 0; }`

export const Overlay = styled.div<{ showHeader?: boolean }>`
  animation: ${fadeIn} 200ms ease-out;
  background-color: ${(p) => p.theme.colors.scrim['50pct']};
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: ${(p) => p.theme.zIndices.overlay};

  ${(p) => p.theme.media.desktop} {
    background-color: ${(p) => p.theme.colors.scrim['50pct']};
  }
`

export const ModalContent = styled(Flex)`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  flex-shrink: 1;
  flex-grow: 1;
`

export const Container = styled.dialog<{
  maxModalHeight?: number
  maxModalWidth?: number
  showHeader?: boolean
}>`
  // dialog by default has fit-content which is not respected in the safari 15.4+
  height: initial;
  left: 50%;
  margin: 0;
  max-height: ${(p) =>
    p.maxModalHeight ||
    90}vh; /* Fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * ${(p) => p.maxModalHeight || 90});
  max-width: ${(p) => p.maxModalWidth || 95}vw;
  overflow: hidden;
  padding: 0;
  position: fixed;
  top: ${({ showHeader }) => (showHeader ? '10%' : '50%')};
  transform: translate(
    -50%,
    ${({ showHeader }) => (showHeader ? '-10%' : '-50%')}
  );
  z-index: ${(p) => p.theme.zIndices.modal};
  ${(p) => p.theme.media.desktop} {
    top: ${({ showHeader }) => (showHeader ? '10%' : '50%')};
  }
`
