import qs from 'querystringify'
import React from 'react'
import { useLocalStorage, useSessionStorage } from '~/hooks/useStorage'
import { SESSION_STORAGE_IS_CART_RECLAIMER_KEY } from '~/routes/CheckoutFlow/constants'
import { mutateBaseOptimizelyAttributes } from '~/hooks/mutateBaseOptimizelyAttributes'

// All potential unauthenticated attributes and possible values should be here
// We currently use the presence of device_type as a proxy for whether the attributes have
// been loaded to determine if we can send Optimizely data to Segment
export type IUnauthenticatedUserAttributes = {
  device_type: 'mobile' | 'desktop'
  is_cart_reclaimer: boolean
  has_logged_in: boolean
}

// This function should take in whatever data is needed to determine user attributes for the unauthenticated pages.
// If the data is not yet available, it should return null.
function determineOptimizelyUserAttributesUnauthenticated(
  isCartReclaimer: boolean,
  localStorageUserId: string | null
): IUnauthenticatedUserAttributes | null {
  // If any data we need is not available, return null
  if (typeof window === 'undefined') return null

  // This object should be created with default values for every attribute currently in use.
  const userAttributes: IUnauthenticatedUserAttributes = {
    device_type: 'mobile',
    is_cart_reclaimer: false,
    has_logged_in: false,
  }

  const params = qs.parse(window.location.search) as {
    cart?: string
  }

  if (params.cart || isCartReclaimer) {
    userAttributes.is_cart_reclaimer = true
  }

  return mutateBaseOptimizelyAttributes(userAttributes, localStorageUserId)
}

// This hook is used to access the data needed to generate unauthenticated user attributes and passes it into a memoized version of the
// function used to determine which attributes apply to a given user.
export function useGetOptimizelyAttributesUnauthenticated() {
  const [isCartReclaimer] = useSessionStorage(
    SESSION_STORAGE_IS_CART_RECLAIMER_KEY,
    false
  )
  const [ajsUserID]: string[] | null[] = useLocalStorage('ajs_user_id', null)

  const userAttributes = React.useMemo(
    () =>
      determineOptimizelyUserAttributesUnauthenticated(
        isCartReclaimer,
        ajsUserID
      ),
    [isCartReclaimer, ajsUserID]
  )

  return userAttributes
}

export default useGetOptimizelyAttributesUnauthenticated
