import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'

export const def = () => {
  return {
    id: 'radioLargeChecked',
    name: 'Radio Large Checked',
    category: 'misc',
    path: (
      <>
        <path
          d="M35.5 18c0 9.665-7.835 17.5-17.5 17.5S.5 27.665.5 18 8.335.5 18 .5 35.5 8.335 35.5 18z"
          fill="#fff"
          stroke="currentColor"
        />
        <rect fill="currentColor" height="30" rx="15" width="30" x="3" y="3" />
        <path
          d="M12 17.76L16.077 22 24 14"
          fill="transparent"
          stroke="#fff"
          strokeWidth="2"
        />
      </>
    ),
    viewBox: '0 0 36 36',
  }
}

export const IconRadioLargeChecked = makeIcon(def())
export default IconRadioLargeChecked
