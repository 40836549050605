import dayjs from 'dayjs'
import React from 'react'
import { queryCache, useMutation } from 'react-query'
import { trackIntervalChange } from '~/analytics/events'
import {
  CACHE_KEY_SUBSCRIPTION,
  CACHE_KEY_UPCOMING_ORDER,
} from '~/bb-api/constants'
import { UPDATE_SUBSCRIPTION } from '~/bb-api/endpoints'
import { Subscription, SubscriptionUpdateOrigin } from '~/bb-api/schemata'
import { SubscriptionContext } from '~/context/subscription'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'
import { captureNetworkError } from '~/analytics/errors'
import { cleanSubscription } from '~/utils/subscription'

export const mutateSubscription = (origin: SubscriptionUpdateOrigin) => {
  const { subscription: currentSubscription } = React.useContext(
    SubscriptionContext
  )
  const user = React.useContext(UserContext)

  const mutateSubscription = async (updatedSubscription: Subscription) => {
    const now = dayjs()

    const cleanedSubscription = cleanSubscription(updatedSubscription) // prevent modification by reference

    /**
     * This is generally only a problem with dev/staging data, in prod the crons run and
     * update this value automatically so it will never be in the past.
     */
    if (dayjs(cleanedSubscription.periodEndDate).isBefore(now)) {
      cleanedSubscription.periodEndDate = now.add(1, 'd').format('YYYY-MM-DD')
    }

    return axios
      .put<Subscription>(UPDATE_SUBSCRIPTION(user.id, cleanedSubscription.id), {
        ...cleanedSubscription,
        origin,
      })
      .then((response) => response.data)
  }

  return useMutation(mutateSubscription, {
    onSuccess(updatedSubscription) {
      if (updatedSubscription.interval !== currentSubscription.interval) {
        trackIntervalChange(
          currentSubscription.interval,
          updatedSubscription.interval
        )
      }

      queryCache.setQueryData(CACHE_KEY_SUBSCRIPTION, [updatedSubscription])
      queryCache.invalidateQueries(CACHE_KEY_UPCOMING_ORDER)
    },
    onError(e: any) {
      captureNetworkError(e)
    },
  })
}
