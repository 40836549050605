import { Box } from '@chakra-ui/core'
import { OptimizelyContext } from '@optimizely/react-sdk'
import { rem } from 'design'
import ButcherBoxLogo from 'design/components/ButcherBoxLogo/ButcherBoxLogo'
import { H3, LinkInternal } from 'design/components/Typography/Typography'
import { AllowOnlyOne } from 'design/helpers'
import Cookies from 'js-cookie'
import React from 'react'
import { Helmet } from 'react-helmet'
import { BannerOutlet } from '~/components/Banner'
import Footer from '~/components/Footer/Footer'
import Header from '~/components/Header/Header'
import HeaderWithLogo from '~/components/Header/HeaderWithLogo'
import Layout, { ILayout } from '~/components/Layout'
import {
  JOIN_397_VARIANTS,
  JOIN_397_VARIANT_TYPES,
} from '~/experiments/JOIN-397__remove_footer_in_signup_flow/RemoveFooterExperiment.types'
import useAnonymousId from '~/hooks/useAnonymousId'
import { useBannerContent } from '~/hooks/useBannerContent'
import useGetOptimizelyAttributesUnauthenticated from '~/hooks/useGetOptimizelyAttributesUnauthenticated'
import useSwitchToSubDomain from '~/hooks/useSwitchToSubDomain'
import SEOComponent from '~/layouts/seo'
import { usePageVisibility } from '~/routes/CheckoutFlow/hooks'
import { COOKIE_LANDER_OFFER_TEXT } from '~/utils/lander'

const CartTrigger = React.lazy(() =>
  import(
    /* webpackChunkName: "checkout-shared" */ '~/routes/CheckoutFlow/shared'
  ).then((exp) => ({
    default: exp.CartTrigger,
  }))
)

const WizardNav = React.lazy(() =>
  import(
    /* webpackChunkName: "checkout-shared" */ '~/routes/CheckoutFlow/shared'
  ).then((exp) => ({
    default: exp.WizardNav,
  }))
)

export type IUnauthenticatedLayout = {
  title?: string
  description?: string
  disablePublicity?: boolean
  runCheckoutValidation?: boolean
  hasSettings?: boolean
  shouldRemoveFooter?: JOIN_397_VARIANT_TYPES
} & AllowOnlyOne<
  {
    checkoutHeader?: boolean
    simpleHeader?: boolean
    cta?: string
    disableHeader?: boolean
  },
  'checkoutHeader' | 'simpleHeader' | 'cta' | 'disableHeader'
> &
  Partial<Pick<ILayout, 'SEO'>> &
  Omit<ILayout, 'SEO'>

export default function UnauthenticatedLayout({
  children,
  description,
  title,
  cta,
  SEO,
  disablePublicity,
  runCheckoutValidation = false,
  hasSettings = false,
  shouldRemoveFooter,
  ...props
}: IUnauthenticatedLayout) {
  const [mounted, setMounted] = React.useState(false)
  const { anonymousId } = useAnonymousId()

  const { optimizely } = React.useContext(OptimizelyContext)
  const customUserAttributes = useGetOptimizelyAttributesUnauthenticated()
  const { setContent } = useBannerContent()
  const { isVisible, hasVisibilityChanged } = usePageVisibility()
  useSwitchToSubDomain()
  React.useEffect(() => {
    // If we have custom user attributes available, set them in Optimizely SDK
    !!customUserAttributes &&
      optimizely?.user &&
      optimizely?.setUser({
        id: optimizely.user.id,
        attributes: {
          ...optimizely.user.attributes,
          ...customUserAttributes,
        },
      })
  }, [optimizely, customUserAttributes])
  React.useEffect(() => {
    if (hasSettings || (isVisible && hasVisibilityChanged)) {
      const messageCookie = Cookies.get(COOKIE_LANDER_OFFER_TEXT)
      if (messageCookie) {
        setContent(
          <H3 as="p" data-what="banner-text">
            {messageCookie}
          </H3>
        )
      }
    }
    // Set content is a static reference but it is passed
    // through context so ESLint cannot know this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, hasSettings, hasVisibilityChanged])

  React.useEffect(() => {
    // When the Optimizely user gets updated, we need to trigger a rerender, so we set mounted to true.
    // We return the unsubscribe function from Optimizely as a cleanup for our effect to stop stale versions of
    // setMounted from running on next render or after this component has been unmounted
    return optimizely?.onUserUpdate(() => {
      if (optimizely?.user?.attributes?.device_type) {
        setMounted(true)
      }
    })
  }, [optimizely])

  React.useEffect(() => setMounted(true), [])

  // Push anonymous user id to GTM datalayer for FB pixel events
  React.useEffect(() => {
    // Timeout is needed because GTM typically loads after effects are run
    setTimeout(() => {
      window?.dataLayer?.push(
        {
          event: 'unauthenticated_page_loaded',
        },
        {
          external_id: anonymousId,
          event: 'external_id_set',
        }
      )
    }, 1000)
  }, [anonymousId])

  const removeFooter =
    shouldRemoveFooter &&
    (shouldRemoveFooter === JOIN_397_VARIANTS.REMOVE_FOOTER ||
      shouldRemoveFooter === JOIN_397_VARIANTS.CLICKABLE_LOGO)
      ? true
      : false

  const header = React.useMemo(() => {
    if (props.disableHeader) {
      return null
    } else if (props.simpleHeader) {
      return <HeaderWithLogo />
    } else if (
      props.checkoutHeader &&
      shouldRemoveFooter === JOIN_397_VARIANTS.CLICKABLE_LOGO
    ) {
      return (
        <Box
          display="flex"
          height={rem(38)}
          justifyContent="center"
          margin="auto"
          maxWidth={{ mobile: rem(370), tablet: rem(950) }}
          pos="relative"
        >
          <LinkInternal color="bb.slate" href="/" title="ButcherBox Home">
            <ButcherBoxLogo marginTop={'-12px'} width={194} />
          </LinkInternal>

          {mounted && (
            <React.Suspense fallback={null}>
              <CartTrigger pos="absolute" right={0} top={rem(4)} />
              <WizardNav runCheckoutValidation={runCheckoutValidation} />
            </React.Suspense>
          )}
        </Box>
      )
    } else if (props.checkoutHeader) {
      return (
        <Box
          display="flex"
          height={rem(38)}
          justifyContent="center"
          margin="auto"
          maxWidth={{ mobile: rem(370), tablet: rem(950) }}
          pos="relative"
        >
          <ButcherBoxLogo marginTop={'-12px'} width={194} />

          {mounted && (
            <React.Suspense fallback={null}>
              <CartTrigger pos="absolute" right={0} top={rem(4)} />
              <WizardNav runCheckoutValidation={runCheckoutValidation} />
            </React.Suspense>
          )}
        </Box>
      )
    }

    return <Header auth={false} cta={cta} />
  }, [props, mounted, cta, runCheckoutValidation, shouldRemoveFooter])

  return (
    <Layout
      Footer={
        removeFooter ? (
          <Footer
            disablePublicity={disablePublicity}
            isAuthenticated={false}
            showLegalOnly={true}
          />
        ) : (
          <Footer disablePublicity={disablePublicity} isAuthenticated={false} />
        )
      }
      Header={
        <>
          <BannerOutlet />
          {header}
        </>
      }
      SEO={SEO ? SEO : <SEOComponent />}
      {...props}
    >
      {(!!title || !!description) && (
        <Helmet title={title}>
          <meta content={description} name="description" />
        </Helmet>
      )}
      {mounted && children}
    </Layout>
  )
}
