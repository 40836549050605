import dayjs, { Dayjs } from 'dayjs'
import querystringify from 'querystringify'
import { trackError } from '~/analytics/errors'
import axios from '~/utils/axios'

/**
 * ! IMPORTANT: Must be the same as the backend cookie name, see: butcherbox/www/apiv2/classes/hasoffers.class.php
 */
const HAS_OFFERS_COOKIE_NAME = 'BX_HASOFFERS'

const HAS_OFFERS_SUBDOMAIN = process.env.HAS_OFFERS_SUBDOMAIN || 'butcherbox'

/**
 * Note: This type is partial, it only shows the data we care about
 */
type HasOffersTransactionResponse = {
  response: {
    data: {
      transaction_id: string
    }
  }
}

export default async function makeCookie(
  queryString: string
): Promise<{ name?: string; value?: string; expiration?: Dayjs }> {
  const params = querystringify.parse(queryString) as {
    /** partnerId */
    a?: string
    aff_link?: string
    /** offerId */
    o?: string
    /** transactionId  */
    t?: string
    /** tr is a value used to identify specific posts of content, specifically for custom landers. It duplicates the concern of `utm_content`. */
    tr?: string
  }

  const offerId = params.o
  const partnerId = params.a

  if (!offerId || !partnerId) {
    return Promise.resolve({})
  }

  /**
   * If link does not include a transaction id, retrieve one
   */
  if (params.aff_link) {
    let transactionId: string | undefined
    try {
      transactionId = await axios
        .get<HasOffersTransactionResponse>(
          URL_TRANSACTION_ID(offerId, partnerId)
        )
        .then((resp) => resp.data.response.data.transaction_id)
    } catch (err) {
      trackError((scope) => {
        scope.capture(err)
      })
    }

    params.t = transactionId

    if (!transactionId && process.env.NODE_ENV === 'production') {
      trackError((scope) => {
        scope.setContext('offerId', offerId)
        scope.setContext('partnerId', partnerId)
        scope.setContext('HASOFFERS_DOMAIN', HAS_OFFERS_SUBDOMAIN)
        scope.capture(new Error('Could not retrieve Has Offers transaction id'))
      })
    }
  }

  return Promise.resolve({
    name: HAS_OFFERS_COOKIE_NAME, // ! IMPORTANT: See above
    value: JSON.stringify({
      /**
       * The names of these query params are configured in the HasOffers portal.
       *
       * TODO: Work with the affiliate team to rename these values to non-single-letter params
       */
      a: params.a,
      t: params.t,
      o: params.o,
      /**
       * tr is a value used to identify specific posts of content, specifically for custom landers.
       * It duplicates the concern of `utm_content`.
       *
       * TODO: Decommission the use of this parameter in favor of using utm_content to identify
       * specific posts/links across all channels.
       */
      tr: params.tr,
      url: window?.location?.href || '',
    }),
    /**
     * This expiration is requested by our Partnerships/Affiliate team. It is the canonical representation
     * of the maximum time from when a user clicks an affiliate link and when they convert, such that the
     * affiliate receives a credit for driving that conversion.
     */
    expiration: dayjs().add(31, 'day'),
  })
}

export const URL_TRANSACTION_ID = (offerId: string, partnerId: string) =>
  `https://${HAS_OFFERS_SUBDOMAIN}.go2cloud.org/aff_c?offer_id=${offerId}&aff_id=${partnerId}&format=json`
