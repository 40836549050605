export const SEGMENT_LOCALSTORAGE_KEY = 'ajs_anonymous_id'

export enum CANCEL_SAVE_ACTIONS {
  ARE_YOU_SURE = 'are_you_sure',
  ADD_FREE_EXTRAS = 'add_free_extras',
  APPLY_CREDIT = 'apply_credit',
  CHANGE_PLAN_FROM_CANCEL_FLOW = 'change_plan_from_cancel_flow',
  CONTACT_CUSTOMER_SERVICE = 'contact_customer_service',
  DELAY_BY_CANCEL_FLOW = 'delay_by_cancel_flow',
  FREQUENCY_CHANGE = 'frequency_change',
  GO_TO_ACCOUNT_SETTINGS = 'go_to_account_settings',
  LEARN_MORE = 'learn_more',
  OFFER_ADDED = 'offer_added',
  READ_THE_GUIDE = 'read_the_guide',
  REPLACEMENT = 'replacement',
  STAY_SUBSCRIBED = 'stay_subscribed',
  SWITCH_TO_VALUE_BOX = 'switch_to_value_box',
  VISIT_CUSTOM_CUTS_FROM_CANCEL_FLOW = 'visit_custom_cuts_from_cancel_flow',
}

export const FACEBOOK_PIXEL = '_fbp'
