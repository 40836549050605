import React from 'react'
import { SubscriptionContextRemote } from '~/context/subscriptionRemote'
import { returnIsTodayBillingDay } from '~/utils/dates'

const statusMessages = {
  payment_processing:
    'Your current box is processing and can no longer be edited. You can still add member deals to your next box.',
  past_due:
    "Sorry, your box can't be edited while your account is past due. Please visit the Profile & Payment section to update your billing info.",
}

const usePaymentProcessingMessage = () => {
  const { subscription, isLoading, isBlocked } = React.useContext(
    SubscriptionContextRemote
  )

  return React.useMemo(() => {
    const isTodayBillingDay = returnIsTodayBillingDay(
      subscription?.periodEndDate
    )
    const status = isTodayBillingDay
      ? 'payment_processing'
      : subscription?.status
    const message = statusMessages[status] || null
    return { message, isLoading, isBlocked, subscription, isTodayBillingDay }
  }, [subscription, isLoading, isBlocked])
}

export default usePaymentProcessingMessage
