import { useQuery } from 'react-query'
import { CACHE_KEY_CHECKOUT_SETTINGS } from '~/bb-api/constants'
import { GET_CHECKOUT_SETTINGS } from '~/bb-api/endpoints'
import { CheckoutSettings } from '~/bb-api/schemata'
import axios from '~/utils/axios'

export const getCheckoutSettings = () =>
  axios.get(GET_CHECKOUT_SETTINGS).then((res) => res.data as CheckoutSettings)

export default function useCheckoutSettings(
  queryKey: string | Array<String | Object> = CACHE_KEY_CHECKOUT_SETTINGS
) {
  return useQuery(queryKey, getCheckoutSettings, {
    staleTime: Infinity,
  })
}
