import React from 'react'
import { Box, BoxProps } from '@chakra-ui/core'
import { Icon as IconType, IconDef, IconProps } from './types'
import { loadIconById } from 'design/components/Icons/utils'

export const makeIcon = (iconDef: IconDef): IconType => (props: BoxProps) => {
  // Extract svg content and viewbox from the IconDef to rest
  const { path, id, ...rest } = iconDef
  return (
    <Box
      aria-hidden="true"
      as="svg"
      data-name={id}
      data-what="icon"
      display="inline-block"
      flexShrink={0}
      height="1em"
      role="presentation"
      verticalAlign="middle"
      width="1em"
      {...props}
      {...rest}
    >
      {path}
    </Box>
  )
}

export const Icon = ({ name, lazy = false, ...rest }: IconProps) => {
  const CustomIcon = loadIconById(name, lazy, <Box {...rest} />)
  return <CustomIcon data-what="icon" {...rest} />
}

export default Icon
