import { PseudoBox, PseudoBoxProps } from '@chakra-ui/core'
import { rem } from 'design/utils'
import { FieldProps } from 'formik'
import React from 'react'

export const MODAL_CARD_PADDING = 24

export type IModalCard = {
  onClick?: Function
  disabled?: Boolean
} & PseudoBoxProps &
  Partial<FieldProps['field']>

// TODO: Add the concept of Addons to be able to render items to e.g. the left
//       of the content of a ModalCard
const ModalCard = React.forwardRef<HTMLElement, IModalCard>(
  ({ children, onClick, disabled = false, checked = false, ...props }, ref) => {
    return (
      <PseudoBox
        bg="white"
        border="1px solid"
        borderColor="bb.silt"
        boxSizing="border-box"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        onClick={disabled ? undefined : onClick}
        p={rem(MODAL_CARD_PADDING)}
        position="relative"
        ref={ref}
        width="100%"
        {...((onClick || props.as === 'label') && {
          _hover: !disabled && { borderColor: 'bb.spicedCrimson' },
          tabIndex: disabled ? -1 : 0,
          userSelect: 'none',
          borderColor: checked ? 'bb.spicedCrimson' : 'bb.silt',
          cursor: disabled ? 'not-allowed' : 'pointer',
          onKeyUp: (evt) =>
            //evt.key for space is an actual space(" ") for some confounding reason
            (evt.key === 'Enter' || evt.key === ' ') &&
            evt.currentTarget.click(),
        })}
        {...props}
      >
        {children}
      </PseudoBox>
    )
  }
)

export default ModalCard
