import { generateAlphaColors } from './colors-utils'

export const oldColors = {
  barnDoorRed: '#ca3836',
  deepSeaBlue: '#2b4d59',
  pastureGreen: '#436436',
  sunriseGold: '#f9a602',
  castiron: '#666',
  charcoal: '#222',
  ash: '#8d8d8d',
  smoke: '#d9d9d9',
  haze: '#f8f8f8',
  sage: '#a2a975',
  spruce: '#88915c',
  sky: '#5da7e5',
  clementine: '#f09f54',
  midnightBlue: '#0E191D',
} as const
export type OldColor = keyof typeof oldColors
export type OldTextLinkThemes = 'barnDoorRed' | 'charcoal' | 'white'

// new brand colors
const brandColors = {
  black: '#000',
  white: '#fff',
  spicedCrimson: '#CC3429',
  openSea: '#228087',
  pesto: '#787A2B',
  honeyMustard: '#E0B139',
  crimson: '#970f06',
  stone: '#717070',
  slate: '#262421',
  granite: '#A19C97',
  silt: '#EDEBE8',
  ivory: '#FBFAF8',
  wine: '#8C313C',
} as const

// new ui colors
const uiColors = {
  success: '#468036',
  error: '#A32A21',
  inactive: '#FFBBB6',
  information: '#228087',
  warning: '#EDBF4C',
} as const

const scrims = {
  '5pct': 'rgba(0, 0, 0, 0.05)',
  '25pct': 'rgba(0, 0, 0, 0.25)',
  '50pct': 'rgba(0, 0, 0, 0.5)',
} as const

// DEPRECATED
const baseColors = {
  primary: '#EA5A4F',
  primaryDark: '#B4382F',
  secondary: '#A2A975',
  secondaryDark: '#88915C',
  secondaryLight: '#a5a97a',
  success: '#19A460',
  alert: '#E12A2A',
  disabled: '#FFCBC9',
}

export type ColorHues = {
  50?: string
  100?: string
  200?: string
  300?: string
  400?: string
  500?: string
  600?: string
  700?: string
  800?: string
  900?: string
}

export type BBColors = {
  bb: typeof brandColors
  current: string
  scrim: typeof scrims
  transparent: string
  ui: typeof uiColors

  /** @deprecated */
  brand: {
    primary: string
    primaryDark: string
    secondary: string
    secondaryDark: string
    secondaryLight: string
    success: string
    alert: string
    disabled: string
  }
  /** @deprecated */
  whiteAlpha: ColorHues
  /** @deprecated */
  blackAlpha: ColorHues
  /** @deprecated */
  gray: ColorHues
  /** @deprecated */
  primaryAlpha: ColorHues
  /** @deprecated */
  alertAlpha: ColorHues
}

export default {
  bb: brandColors,
  scrim: scrims,
  ui: uiColors,
  transparent: 'transparent',
  current: 'currentColor',

  /** everything below this line is deprecated and will be removed */

  brand: baseColors,

  gray: {
    50: '#F8F8F8',
    100: '#DBDAD8',
    200: '#C2C2C2', // TODO: Request that this be added to pattern library
    300: '#666666',
    400: '#404040',
    500: '#252525',
    600: '#262626',
  },

  // Note: These whiteAlpha and blackAlpha colors are included because
  //       the Chakra CSSReset component expects them to be there.
  // TODO: Override the Chakra CSSReset component to read its reset props
  //       from our own theme, rather than looking for these theme values.
  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  primaryAlpha: generateAlphaColors(baseColors.primary),
  alertAlpha: generateAlphaColors(baseColors.alert),
} as BBColors
