import {
  Box,
  CardMedia,
  Lockup,
  ModalBody,
  ModalFooter,
  Text,
} from '@butcherbox/freezer'
import { rem } from 'design'
import React from 'react'
import * as Styles from '~/components/ListingCard/ListingCardModalContent.css'
import { TEST_ID } from '~/constants/cypress'
import type { ListingCardModalContentProps } from './ListCard.types'

export function ListingCardModalContent({
  Button,
  product,
  quantity,
}: ListingCardModalContentProps) {
  return (
    <>
      <CardMedia alt={product.description} height={250} src={product.image} />
      <ModalBody
        data-attr-sku={product.sku}
        data-cy={TEST_ID.MODAL_LISTING_CARD_INFO}
        data-cy-current-quantity={quantity}
        data-cy-sku={product.sku}
      >
        <Box className={Styles.ModalContent}>
          <Lockup textAlign="left">
            <Text variant="H2Bold">{product.description}</Text>
            <Text variant="Body1Regular">
              <span
                dangerouslySetInnerHTML={{ __html: product.fullDescription }}
              />
            </Text>
            <Box
              aria-label="Product information"
              as="ul"
              css={{ '> li > *': { marginLeft: rem(-2) } }}
            >
              <Text component="li" variant="Body1Regular">
                <strong>Pack Size</strong> {product.packSize}
              </Text>
            </Box>
          </Lockup>
        </Box>
      </ModalBody>
      {Button ? <ModalFooter>{Button}</ModalFooter> : undefined}
    </>
  )
}

export default ListingCardModalContent
