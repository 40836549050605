import { useExperiment } from '@optimizely/react-sdk'
import React from 'react'
import useTrackOptimizelyExperimentSeen from './useTrackOptimizelyExperimentSeen'

type useExperimentParameters = Parameters<typeof useExperiment>
export type HookOverrides = useExperimentParameters[2]
// Currently, workarounds are necessary to use this hook in statically generated components:
// https://nbox-341.atlassian.net/wiki/spaces/BBWEBB/pages/1512145027/Challenges+with+Static+Site+Generation
export default function useOptimizelyExperiment(
  ...parameters: useExperimentParameters
) {
  const experimentKey = parameters[0]
  const experimentValues = useExperiment(...parameters)

  const trackExperimentSeen = useTrackOptimizelyExperimentSeen()

  React.useEffect(() => {
    const [variationKey] = experimentValues
    if (variationKey) {
      trackExperimentSeen(experimentKey, variationKey)
    }
  }, [experimentKey, experimentValues, trackExperimentSeen])

  return experimentValues
}
