import { Dayjs } from 'dayjs'
import { DateYMDString } from 'design/date-utils'

export type HolidayDeliveryWindow = {
  name: string
  billDateWindowStart: DateYMDString
  billDateWindowEnd: DateYMDString
  deliveryWindowEnd: DateYMDString
}

export const holidays: HolidayDeliveryWindow[] = [
  {
    name: 'Thanksgiving',
    billDateWindowStart: '2023-11-13',
    billDateWindowEnd: '2023-11-25',
    deliveryWindowEnd: '2023-12-01',
  },
  {
    name: 'Christmas',
    billDateWindowStart: '2023-12-18',
    billDateWindowEnd: '2023-12-24',
    deliveryWindowEnd: '2024-01-02',
  },
  {
    name: "New Year's Eve",
    billDateWindowStart: '2023-12-25',
    billDateWindowEnd: '2023-12-31',
    deliveryWindowEnd: '2024-01-06',
  },
]

export const holidayWindowForDate = (
  day: Dayjs
): HolidayDeliveryWindow | null =>
  holidays.find(({ billDateWindowStart, billDateWindowEnd }) =>
    day.isBetween(billDateWindowStart, billDateWindowEnd, 'day', '[]')
  )
