import Cookies from 'js-cookie'
import React from 'react'
import { getCookieDomain } from '~/utils/cookie'
import useOptimizelyFeature from './useOptimizelyFeature'

const useSwitchToSubDomain = () => {
  const [switchToSubDomain] = useOptimizelyFeature(
    'stay-1043-switch-to-sub-domains'
  )
  const [authUrl, setAuthUrl] = React.useState('')
  const [buyUrl, setBuyUrl] = React.useState('')

  let localAuthUrl = ''
  let localBuyUrl = ''
  let host = ''

  if (typeof window !== 'undefined') {
    localAuthUrl = window.location.host.includes('local')
      ? window.location.origin
      : process.env.AUTH_URL

    localBuyUrl = window.location.host.includes('local')
      ? window.location.origin
      : process.env.BUY_URL
    host = window.location.origin
  }

  React.useEffect(() => {
    if (switchToSubDomain) {
      Cookies.set('SWITCH_TO_SUBDOMAIN', 'yes', { domain: getCookieDomain() })
      setAuthUrl(
        process.env.NODE_ENV !== 'production'
          ? localAuthUrl
          : process.env.AUTH_URL || 'https://member.butcherbox.com'
      )

      setBuyUrl(
        process.env.NODE_ENV !== 'production'
          ? localBuyUrl
          : process.env.BUY_URL || 'https://buy.butcherbox.com'
      )
    } else {
      Cookies.remove('SWITCH_TO_SUBDOMAIN', {
        domain: getCookieDomain(),
      })
      setAuthUrl(host)
      setBuyUrl(host)
    }
  }, [switchToSubDomain, localAuthUrl, localBuyUrl, host])
  return { AUTH_URL: authUrl, BUY_URL: buyUrl }
}

export default useSwitchToSubDomain
