import { IconWarningCircle } from '@butcherbox/freezer'
import { Box, Flex } from '@chakra-ui/core'
import { rem } from 'design'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import type { QualityBadgeProps } from './ListCard.types'
const CARD_BADGE_SIZE = 30
const CARD_BADGE_OFFSET = 8

export const QuantityBadge = ({
  corner = 'right',
  product,
  quantity,
  ...props
}: QualityBadgeProps) => {
  return 'isUnavailable' in product && product.isUnavailable ? (
    <Box
      aria-hidden
      background="white"
      borderRadius={100}
      h={rem(CARD_BADGE_SIZE)}
      left={corner === 'left' ? rem(-CARD_BADGE_OFFSET) : undefined}
      position="absolute"
      right={corner === 'right' ? rem(-CARD_BADGE_OFFSET) : undefined}
      top={rem(-CARD_BADGE_OFFSET)}
      w={rem(CARD_BADGE_SIZE)}
      zIndex={1}
      {...props}
    >
      <IconWarningCircle customColor={{ base: 'spicedCrimson' }} size="fixed" />
    </Box>
  ) : (
    <Flex
      alignItems="center"
      aria-hidden
      bg="white"
      border="2px solid"
      borderColor="bb.spicedCrimson"
      borderRadius="100%"
      d={quantity > 0 ? 'flex' : 'none'}
      h={rem(CARD_BADGE_SIZE)}
      justifyContent="center"
      left={corner === 'left' ? rem(-CARD_BADGE_OFFSET) : undefined}
      pos="absolute"
      right={corner === 'right' ? rem(-CARD_BADGE_OFFSET) : undefined}
      top={rem(-CARD_BADGE_OFFSET)}
      w={rem(CARD_BADGE_SIZE)}
      zIndex={1}
      {...props}
    >
      <Body
        color="bb.spicedCrimson"
        mt={rem(-1)}
        title={`${product.description} quantity ${quantity}`}
      >
        <strong>{quantity}</strong>
      </Body>
    </Flex>
  )
}

export default QuantityBadge
