import { Box, Text } from '@butcherbox/freezer'
import { RouteComponentProps } from '@reach/router'
import { LinkExternal } from 'design/components/Typography/Typography'
import React from 'react'
import FallbackFull from '~/components/ErrorBoundary/fallbacks/Fallback.Full'
import * as Styles from '~/components/ErrorBoundary/fallbacks/Fallback.Full.css'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'

interface BaseErrorBoundaryProps {
  error?: Error
}

export const BaseErrorBoundary = ({ error }: BaseErrorBoundaryProps) => {
  return (
    <>
      <FallbackFull
        error={error}
        linkDestination="https://justcook.butcherbox.com/"
        linkText="browse our cooking content"
      />
      <SupportMessage />
    </>
  )
}

interface ErrorBoundryProps extends RouteComponentProps {
  error?: Error
}

export default function ErrorBoundary({ error }: ErrorBoundryProps) {
  return (
    <UnauthenticatedLayout simpleHeader={true}>
      <BaseErrorBoundary error={error} />
    </UnauthenticatedLayout>
  )
}

const SupportMessage = () => {
  return (
    <Box className={Styles.SupportMessageContainerWrapper}>
      <Box className={Styles.SupportMessageContainer}>
        <Text variant="H2Bold">We’re here to help.</Text>
        <Text variant="H3Bold">
          For any subscription related questions, please contact our support
          team at{' '}
          <LinkExternal href="mailto:support@butcherbox.com" target="_self">
            support@butcherbox.com
          </LinkExternal>
        </Text>
      </Box>
    </Box>
  )
}
