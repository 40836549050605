import React from 'react'
import { Box, VisuallyHidden } from '@butcherbox/freezer'
import { IPanelTable } from './PanelTable.types'

export function PanelTable({
  caption = 'Total',
  children,
  ...props
}: IPanelTable) {
  return (
    <Box {...props}>
      <Box component="table" w={'100%'}>
        <caption>
          <VisuallyHidden component="span">{caption}</VisuallyHidden>
        </caption>
        {children}
      </Box>
    </Box>
  )
}
