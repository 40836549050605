import {
  IconInformationCircle,
  IconProps,
  IconSuccessCircle,
  IconWarningCircle,
} from '@butcherbox/freezer'
import { Box } from '@chakra-ui/core'
import { rem } from 'design'
import Toast from 'design/components/Toast/Toast'
import type { IToast } from 'design/components/Toast/Toast.types'
import theme from 'design/theme'
import React from 'react'
import { LiveMessage } from 'react-aria-live'
import ReactDOM from 'react-dom'
import { HEADER_HEIGHT } from '~/components/Header/Header'

type ToastDefinition = IToast & {
  // defaults to 5 seconds
  duration?: number
}

export type ShowToast = (
  variant: IToast['status'] | 'custom',
  toast: ToastDefinition
) => void

export const ToastContext = React.createContext<ShowToast>(undefined)
export const ToastOutletContext = React.createContext<
  (ref: React.RefObject<HTMLDivElement>) => void
>(undefined)

// @ts-ignore
ToastContext.Provider.displayName = 'ToastContextProvider'
// @ts-ignore
ToastOutletContext.Provider.displayName = 'ToastOutletContextProvider'

/**
 * Place a toast outlet in a certain spot in the DOM if desired such that
 * toasts will render from that position, rather than off the <body> tag.
 */
export const ToastOutlet = () => {
  const setPortalRef = React.useContext(ToastOutletContext)
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => setPortalRef(ref), [setPortalRef])

  /**
   * display: contents prevents the stickiness from being affected by an
   * additional parent container element.
   */
  return <Box d="contents" ref={ref} />
}

const toastIconProps: IconProps = {
  size: 'fixed',
  pixelSize: 24,
  marginRight: 8,
  customColor: { base: 'white' },
}

export const ToastProvider = ({ children }) => {
  const [mounted, setMounted] = React.useState(false)
  const handleRef = React.useRef<number>(undefined)
  const [toastProps, setToastProps] = React.useState<ToastDefinition>(undefined)
  const [portalRef, setPortalRef] = React.useState<
    React.RefObject<HTMLDivElement>
  >(undefined)

  React.useEffect(() => setMounted(true), [])

  // TODO: Refactor this so the different presentations are made available in
  //       the Toast.tsx file rather than the context provider
  const showToast: ShowToast = React.useCallback((variant, toast) => {
    switch (variant) {
      case 'success':
        return setToastProps({
          status: variant,
          renderIcon: () => <IconSuccessCircle {...toastIconProps} />,
          ...toast,
        })
      case 'error':
        return setToastProps({
          status: variant,
          renderIcon: () => <IconWarningCircle {...toastIconProps} />,
          ...toast,
        })
      case 'warning':
        return setToastProps({
          status: variant,
          renderIcon: () => <IconWarningCircle {...toastIconProps} />,
          ...toast,
        })
      case 'info':
        return setToastProps({
          status: variant,
          renderIcon: () => <IconInformationCircle {...toastIconProps} />,
          ...toast,
        })
      case 'custom':
        return setToastProps({
          status: variant,
          ...toast,
        })
      default:
        return setToastProps(toast)
    }
  }, [])

  React.useEffect(() => {
    if (handleRef.current) window.clearTimeout(handleRef.current)

    if (toastProps) {
      handleRef.current = window.setTimeout(() => {
        handleRef.current = null
        setToastProps(undefined)
      }, toastProps.duration || 5000)
    }

    return () => window.clearTimeout(handleRef.current)
  }, [toastProps])

  return (
    <ToastOutletContext.Provider value={setPortalRef}>
      <ToastContext.Provider value={showToast}>
        {mounted &&
          ReactDOM.createPortal(
            <Box
              aria-atomic="true"
              aria-live="assertive"
              id="toast-component"
              position="sticky"
              top={rem(HEADER_HEIGHT)}
              zIndex={theme.zIndices.banner}
            >
              {!!toastProps && (
                <>
                  <Toast {...toastProps} key="toast" />
                  <LiveMessage
                    aria-live={
                      toastProps?.status === 'warning' ||
                      toastProps?.status === 'error'
                        ? 'assertive' // interrupts whatever the screen reader is saying
                        : 'polite' // waits until screen reader has finished reading its text
                    }
                    message={toastProps.children}
                  />
                </>
              )}
            </Box>,
            portalRef?.current || document.body
          )}

        {children}
      </ToastContext.Provider>
    </ToastOutletContext.Provider>
  )
}
