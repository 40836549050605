import { Modal } from '@butcherbox/freezer'
import React from 'react'
import { trackIngredientsSeen } from '~/analytics/events'
import useIngredients from '~/hooks/useIngredients'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import { ListingCardModalContent } from '../ListingCard'
import ModalIngredient from '../ModalIngredient/ModalIngredient'
import type { CutInfoProps } from './CutInfoModal.types'

export function CutInfoModal({
  onClose,
  renderButton,
  product,
  quantity,
  variant,
  showImage = false,
}: CutInfoProps) {
  const [isIngredientEnabled] = useOptimizelyFeature('shop-426_ingredient_link')

  const ingredients = useIngredients()
  React.useEffect(() => {
    ingredients(product) &&
      trackIngredientsSeen(product.description, product.sku, variant)
  }, [product, variant, ingredients])

  return (
    <>
      {isIngredientEnabled && !showImage ? (
        <ModalIngredient
          Button={renderButton({ withinInfoModal: true })}
          onClose={onClose}
          product={product}
          variant={variant}
        />
      ) : (
        <Modal
          data-what="product-detail-modal"
          onClose={onClose}
          size="fit"
          title="Cut Information"
        >
          <ListingCardModalContent
            Button={renderButton({ withinInfoModal: true })}
            product={product}
            quantity={quantity}
          />
        </Modal>
      )}
    </>
  )
}

export default CutInfoModal
