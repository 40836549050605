import {
  Box,
  Drawer,
  IconLoadingSpinner,
  OverlayContainer,
} from '@butcherbox/freezer'
import React from 'react'
import { QueryStatus } from 'react-query'
import { UpcomingOrder } from '~/bb-api/schemata'
import * as Styles from './CartDrawer.css'
import Addons from './components/Addons/Addons'
import BoxDetails from './components/BoxDetails/BoxDetails'
import Totals from './components/Totals/Totals'
import Extras from './components/Extras/Extras'
import Deals from './components/Deals/Deals'
import NextBox from './components/NextBox/NextBox'
import Offers from './components/Offers/Offers'
import { BoxItemWithIsUnavailable } from '~/hooks/useCustomerBoxItemsWithAvailability'

const CartDrawerUI = ({
  order,
  onClose,
  status,
  products,
}: {
  order: UpcomingOrder
  status: QueryStatus
  products: Array<BoxItemWithIsUnavailable>
  onClose: () => void
}) => {
  return (
    <OverlayContainer>
      <Drawer
        isDismissable
        isOpen
        onClose={onClose}
        placement="right"
        title="Cart"
        zIndex="navigation"
      >
        <Box className={Styles.DrawerContents}>
          {status === 'success' ? (
            <>
              <NextBox
                billDate={order?.subscription?.periodEndDate}
                onClose={onClose}
                orderTotal={order?.total}
              />
              <BoxDetails
                box={order.subscription.box}
                onClose={onClose}
                products={products}
              />
              <Addons
                addons={order.subscription.box.addons}
                onClose={onClose}
                price={order.subscription.box.addonsPrice}
              />
              <Deals
                invoiceItems={order.invoiceItems}
                onClose={onClose}
                price={order.invoiceItemsPrice}
              />
              <Offers offers={order.subscription.box.offers} />
              <Extras extras={order.extras} />
              <Totals order={order} />
            </>
          ) : (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              style={{ height: '100%' }}
            >
              <IconLoadingSpinner />
            </Box>
          )}
        </Box>
      </Drawer>
    </OverlayContainer>
  )
}

export default CartDrawerUI
