import React from 'react'
import { renderWhenSubscriptionAvailable } from '~/context/subscriptionRemote'
import useCustomerBoxItemsWithAvailability from '~/hooks/useCustomerBoxItemsWithAvailability'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import CartDrawerUI from './CartDrawer.ui'

const CartDrawer = ({ onClose }: { onClose: () => void }) => {
  const { data: order, status } = useUpcomingOrder()
  const products = useCustomerBoxItemsWithAvailability()
  return (
    <CartDrawerUI
      onClose={onClose}
      order={order}
      products={products}
      status={status}
    />
  )
}

export default renderWhenSubscriptionAvailable(CartDrawer)
