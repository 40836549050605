import React from 'react'
import * as Styles from './PanelSlashThroughHeading.css'
import type * as TYPES from './PanelSlashThroughHeading.types'
import { Box, Text } from '@butcherbox/freezer'
import { TEST_ID } from '~/constants/cypress'
import { formatPriceFromCents } from '~/utils'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'

export function PanelSlashThroughHeading({
  panelHeading = 'Member Deals',
  actualPrice = 0.0,
  markupPrice = 0.0,
  subheading = false,
  source = 'body',
}: TYPES.PanelSlashThroughHeadingProps) {
  const isStrikeThrough = markupPrice > actualPrice
  const percentageDifference =
    markupPrice > actualPrice
      ? Math.round(((markupPrice - actualPrice) / markupPrice) * 100)
      : 0

  const [isTopLineSavingReceive] = useOptimizelyExperiment(
    'shop-675__hide_top_line_savings_on_next_box'
  )
  let showPercentOff = isStrikeThrough ? true : false
  let showStrikeThrough = isStrikeThrough ? true : false
  if (
    source === 'header' &&
    isTopLineSavingReceive !== null &&
    isStrikeThrough
  ) {
    showPercentOff = isTopLineSavingReceive === 'control' ? true : false
    showStrikeThrough = isTopLineSavingReceive != 'variation_1' ? true : false
  }
  return (
    <Box
      className={subheading ? Styles.PanelChildHead : Styles.PanelHead}
      data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_DEALS}
      data-cy-price={actualPrice}
    >
      <Box className={Styles.Heading}>
        <Text
          component={subheading ? 'h4' : 'h3'}
          variant={subheading ? 'H4Bold' : 'Subhead1'}
        >
          {panelHeading}
        </Text>
      </Box>
      <Box>
        {showPercentOff && (
          <Box className={Styles.Content}>
            <Text color="spicedCrimson" variant="Body2Regular">
              You save {percentageDifference}%
            </Text>
          </Box>
        )}
        <Box className={Styles.Content}>
          {showStrikeThrough && (
            <Text color="stone" variant="H4Bold">
              <span style={{ textDecoration: 'line-through' }}>
                {formatPriceFromCents(markupPrice)}
              </span>
              &nbsp;
            </Text>
          )}
          {actualPrice > 0 && (
            <Text color="slate" variant={subheading ? 'H4Bold' : 'H3Bold'}>
              {formatPriceFromCents(actualPrice)}
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  )
}
export default PanelSlashThroughHeading
