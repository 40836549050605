import React from 'react'
import { Flex, FlexProps, PseudoBox, VisuallyHidden } from '@chakra-ui/core'
import { Box, Text, TextLink } from '@butcherbox/freezer'
import { rem, shortId, useTheme } from 'design'
import { CTA } from 'design/components/Typography/Typography'
import { RequireOnlyOne } from 'design/helpers'
import { BaseProduct, Product } from '~/bb-api/schemata'
import dayjs from 'dayjs'
import { formatPriceFromCents } from '~/utils'
import * as Styles from './CardProduct.css'
import AnimalIcons, {
  AnimalIconName,
} from '~/components/AnimalIcons/AnimalIcons'
import IconAttnGrabWarning from '../Icons/defs/AttentionGrabberWarning'

type IBaseCard = {
  Action?: any
  Flag?: any
  iconName?: AnimalIconName
  imageStyleProps?: object
  imgUrl?: string
  ProductBadge?: JSX.Element
  subtitle: any
  title?: any
  price?: any
  isShowPrice?: boolean
  isUnavailable?: boolean
  expirationDate?: string | null
} & FlexProps

export type ICardProductProps = RequireOnlyOne<IBaseCard, 'imgUrl' | 'iconName'>

export const BaseCard: React.FC<ICardProductProps> = ({
  Action,
  iconName,
  imgUrl,
  Flag,
  imageStyleProps,
  isUnavailable = false,
  ProductBadge,
  title,
  subtitle,
  price,
  ...props
}) => {
  const ariaLabel = React.useMemo(() => shortId(), [])

  const ProductImage =
    imgUrl && imgUrl !== 'NULL' ? (
      <Box
        className={Styles.ProductImage}
        style={{
          backgroundImage: `url(${imgUrl})`,
        }}
      >
        {isUnavailable && (
          <Flex
            alignItems="center"
            backgroundColor="scrim.50pct"
            height="100%"
            justifyContent="center"
            position="absolute"
            width="100%"
            zIndex={1}
          >
            <Text color="white" variant="Body2Regular">
              Unavailable
            </Text>
          </Flex>
        )}
      </Box>
    ) : (
      /** TODO: Do icon CardProducts need to be renderable as unavailable? */
      <Box className={Styles.IconBox}>
        <AnimalIcons iconName={iconName} />
      </Box>
    )

  return (
    <Flex
      {...props}
      as="div"
      data-what="product-card"
      display="flex"
      justifyContent="space-between"
      position="relative"
    >
      {isUnavailable && <CardProductWarning />}
      {Flag}
      <Box display="flex">
        <Box className={Styles.BaseCard} style={imageStyleProps}>
          {ProductBadge}
          {ProductImage}
        </Box>

        <Box>
          <Text color="slate" id={ariaLabel} variant="H4Condensed">
            {title}
          </Text>
          <Text color="stone" variant="Body2Regular">
            {subtitle}
          </Text>
          {Action}
        </Box>
      </Box>
      {typeof price === 'number' ? (
        <Box className={Styles.Addons}>
          <Text color="slate" variant="H4Condensed">
            {formatPriceFromCents(price)}
          </Text>
        </Box>
      ) : null}
    </Flex>
  )
}

const CardProduct = ({
  product,
  ...props
}: Omit<
  ICardProductProps,
  'iconName' | 'imgUrl' | 'isUnavailable' | 'title' | 'subtitle' | 'price'
> & { product: Product | BaseProduct }) => {
  return (
    <BaseCard
      {...props}
      imgUrl={product.image}
      isUnavailable={
        'isUnavailable' in product ? product.isUnavailable : undefined
      }
      price={props.isShowPrice ? product.price : null}
      subtitle={
        <>
          <VisuallyHidden as="span">
            Each unit of {product.description} contains&nbsp;
          </VisuallyHidden>
          {product.packSize}
          {props.expirationDate && dayjs(props.expirationDate).isValid() && (
            <Box>
              Expires {dayjs(props.expirationDate).format('MMMM D, YYYY')}
            </Box>
          )}
        </>
      }
      title={product.description}
    />
  )
}

export const CardProductAction = ({ children, ...props }) => {
  return (
    <TextLink component="a" theme={'slate'} variant={'Body2Regular'} {...props}>
      {children}
    </TextLink>
  )
}

const cardProductFlagZIndex = 1
const cardProductWarningZIndex = cardProductFlagZIndex + 1

export const CardProductFlag = ({ children, ...props }) => {
  const theme = useTheme()
  return (
    <PseudoBox
      _before={{
        borderLeft: `0.125rem solid ${theme.colors.bb.crimson}`,
        borderRight: '0.125rem solid transparent',
        borderTop: '0.125rem solid transparent',
        // @ts-ignore TODO: Remove once we upgrade past chakra 0.7.0
        content: `""`,
        ml: rem(-2),
        display: 'block',
        position: 'absolute',
        left: 0,
        top: '100%',
        transform: 'rotate(180deg)',
      }}
      backgroundColor="bb.spicedCrimson"
      height={rem(16)}
      ml={rem(-2)}
      mt={rem(4)}
      position="absolute"
      px={rem(6)}
      textAlign="center"
      zIndex={cardProductFlagZIndex}
      {...props}
    >
      <CTA color="white">{children}</CTA>
    </PseudoBox>
  )
}

/**
 * TODO: Revisit the size once the SVG no longer contains a box shadow
 */
const CardProductWarning = () => {
  return (
    <>
      <IconAttnGrabWarning
        color="ui.error"
        left={rem(-8)}
        position="absolute"
        role="presentation"
        size={rem(30)}
        top={rem(-8)}
        zIndex={cardProductWarningZIndex}
      />
    </>
  )
}

export default CardProduct
