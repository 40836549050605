import { BASE_FONT_SIZE } from './sizes'

export const pluralWordSwap = (
  amount: number,
  singularPhrase: string,
  pluralPhrase: string
) => (amount === 1 ? singularPhrase : pluralPhrase)

export const pluralize = (amount: number, word: string) =>
  pluralWordSwap(amount, word, `${word}s`)

export const pluralLinkingVerb = (amount: number) =>
  pluralWordSwap(amount, 'is', 'are')

/**
 * Converts a pixel value to responsive units. Don't use this for things like border widths unless you actually want the borders to scale with zoom.
 */
export const rem = (pixels: number) =>
  `${(pixels / BASE_FONT_SIZE).toFixed(3)}rem`

// Generates a random string of numbers and lowercase characters
export const shortId = () => {
  return Math.random().toString(36).substring(2, 15)
}

/**
 * Programmatically move voiceover focus with Javascript.
 * https://silvantroxler.ch/2016/setting-voiceover-focus-with-javascript/
 */
export const setA11yFocus = (element: HTMLElement, preventScroll = false) => {
  const focusInterval = 10 // ms, time between function calls
  const focusTotalRepetitions = 10 // number of repetitions

  element.setAttribute('tabindex', '0')
  element.blur()

  let focusRepetitions = 0

  const interval = window.setInterval(function () {
    element.focus({ preventScroll: preventScroll })
    focusRepetitions++
    if (focusRepetitions >= focusTotalRepetitions) {
      window.clearInterval(interval)
    }
  }, focusInterval)
}
