import React from 'react'
import { BoxItem } from '~/bb-api/schemata'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { PanelSection } from '~/components/Panel/PanelSection/PanelSection'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import CartProductList from '../shared/CartProductList'
import { CartSection } from '../../CartDrawer.css'
import { Box } from '@butcherbox/freezer'

const Extras = ({ extras }: { extras: Array<BoxItem> }) => {
  if (!extras || extras.length <= 0) return <></>
  return (
    <Box className={CartSection}>
      <PanelSection content="Extras" contentAddon="Free" />
      <PanelBreak />
      <CartProductList
        aria-label="Free extra products included in your upcoming order"
        productArray={duplicateItemsByQuantity(extras)}
      />
    </Box>
  )
}

export default Extras
