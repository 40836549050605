import React from 'react'
import qs from 'querystringify'

import { SEGMENT_LOCALSTORAGE_KEY } from '~/analytics/constants'
import { nanoid } from 'nanoid'
import Cookies from 'js-cookie'
import topDomain from '@segment/top-domain'

function getAnonymousIdFromLocalStorage(): string | undefined {
  try {
    // this is the localstorage key for analytics.js anonymous ID
    const lsValue = window?.localStorage.getItem(SEGMENT_LOCALSTORAGE_KEY)
    return lsValue ? JSON.parse(lsValue) : undefined
  } catch (error) {
    // let this get caught by our reporting tools
    console.log(error)
    return undefined
  }
}

function setAnonymousIdInLocalStorage(anonoymousId: string) {
  try {
    window?.localStorage.setItem(
      SEGMENT_LOCALSTORAGE_KEY,
      JSON.stringify(anonoymousId)
    )
  } catch (error) {
    // let this get caught by our reporting tools
    console.log(error)
  }
}

type AnonymousIdContextValue = {
  anonymousId: string
  setAnonymousId: React.Dispatch<React.SetStateAction<string>>
}

export const AnonymousIdContext = React.createContext<AnonymousIdContextValue>({
  anonymousId: '',
  setAnonymousId: () => {},
})

interface AnonymousIdProviderProps {
  children: React.ReactNode
}

export function AnonymousIdProvider({ children }: AnonymousIdProviderProps) {
  const anonymousIdFromCookie = Cookies.get('ajs_anonymous_id')

  const [anonymousId, setAnonymousId] = React.useState<string>(() => {
    // Check for anonymous ID being passed as query param first for cart reclamation
    // TODO: remove duplicate logic from on-client-entry script
    const params = qs.parse(location.search) as {
      ajs_aid?: string
    }

    if (params.ajs_aid) {
      return params.ajs_aid
    }

    const anonymousIdFromLocalStorage = getAnonymousIdFromLocalStorage()
    if (anonymousIdFromLocalStorage) {
      return anonymousIdFromLocalStorage
    }

    if (anonymousIdFromCookie) {
      return anonymousIdFromCookie
    }

    /**
     * Generate our own if one doesn't exist yet.
     * This also covers the adblock use case if segment
     * isn't allowed to load and thus an AUID wouldn't
     * exist in localStorage.
     */
    return nanoid()
  })

  React.useEffect(() => {
    if (anonymousId !== '') {
      setAnonymousIdInLocalStorage(anonymousId)

      // if analytics.js is available, let it know this is meant to be the auid
      // else, manually set the cookie value
      if (window.analytics?.setAnonymousId) {
        window.analytics?.setAnonymousId(anonymousId)
      } else {
        // Match logic used by segment for domain https://github.com/segmentio/analytics.js/blob/master/analytics.js#L3474
        const domain = '.' + topDomain(window.location.href)
        Cookies.set('ajs_anonymous_id', JSON.stringify(anonymousId), {
          domain,
        })
      }
    }
  }, [anonymousId, anonymousIdFromCookie])

  return (
    <AnonymousIdContext.Provider
      value={{
        anonymousId,
        setAnonymousId,
      }}
    >
      {children}
    </AnonymousIdContext.Provider>
  )
}
