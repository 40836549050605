import dayjs, { Dayjs } from 'dayjs'
import CardProductGroup from 'design/components/CardProductGroup/CardProductGroup'
import {
  ModalBody,
  ModalFooter,
  Text,
  Lockup,
  Button,
  ButtonProps,
  Box,
} from '@butcherbox/freezer'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React, { useMemo, useState } from 'react'
import { MutationConfig } from 'react-query'
import { Subscription, SubscriptionUpdateOrigin } from '~/bb-api/schemata'
import Calendar, {
  CalendarCustomDay,
  DateOverrideType,
} from '~/components/Calendar/Calendar'
import createDeliveryWindow from '~/components/Calendar/deliveryWindow'
import { TEST_ID } from '~/constants/cypress'
import { SubscriptionContext } from '~/context/subscription'
import { UserContext } from '~/context/user'
import { mutateSubscription } from '~/hooks/mutateSubscription'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import { formatDeliveryWindow } from '~/utils'
import { MemberDealsEmptyState } from '../EmptyStates'
import ModalBackButton from 'design/components/Modal/ModalBackButton'
import { isCalendarDayInvalid } from '~/utils/dates'
import { trackError } from '~/analytics/errors'
import * as Styles from './CalendarBillDate.css'
import BoxSettingsModalContentWrapper from '~/routes/AccountBoxSettings/BoxSettingsModals/BoxSettingsModalContentWrapper'

export type ICalendarBillDate = {
  customDates?: CalendarCustomDay[]
  buttonProps?: Partial<ButtonProps>
  headerLabel?: string
  initialBillDate?: Dayjs
  onError?: MutationConfig<Subscription, unknown, Subscription>['onError']
  /** Pass a custom save operation that doesn't update the current subscription. */
  onSave?: (nextBillDate: string) => Promise<any>
  onSuccess?: MutationConfig<Subscription, unknown, Subscription>['onSuccess']
  saveLabel?: string
  showHeader?: boolean
  trackingOrigin?: SubscriptionUpdateOrigin
  lastValidDate?: Dayjs
}

const CalendarBillDate: React.FC<ICalendarBillDate> = ({
  customDates = [],
  buttonProps = { size: 'small' },
  headerLabel = 'Confirm next bill date',
  initialBillDate,
  saveLabel = 'Save changes',
  showHeader = false,
  onSave,
  onSuccess,
  onError,
  trackingOrigin = 'account_pages',
  lastValidDate = dayjs().add(6, 'month'),
}) => {
  const showToast = React.useContext(ToastContext)
  const { shipZone } = React.useContext(UserContext)
  const { subscription } = React.useContext(SubscriptionContext)
  const [savePending, setSavePending] = React.useState(false)
  const [billDateCalendarScreen, setBillDateCalendarScreen] = React.useState(
    true
  )
  const [
    billDateReviewDealsScreen,
    setBillDateReviewDealsScreen,
  ] = React.useState(false)
  const [updateSubscription] = mutateSubscription(trackingOrigin)
  const { data: upcomingOrder } = useUpcomingOrder()

  // Need local copy of bill date to reflect unsaved changes in UI
  const initialLocalBillDate =
    initialBillDate?.toISOString() || subscription.periodEndDate
  const [localBillDate, setLocalBillDate] = React.useState(initialLocalBillDate)
  const deliveryWindow = shipZone
    ? createDeliveryWindow(localBillDate, shipZone)
    : []
  const formattedBillDate = dayjs(localBillDate).format('MMM D')
  const formattedDeliveryWindow = formatDeliveryWindow(deliveryWindow)
  const ATFormattedDeliveryWindow = formatDeliveryWindow(deliveryWindow, true)

  const [restrictionRemoveDeals, setRestrictionRemoveDeals] = useState(false)
  const [earliestCutOffDate, setEarliestCutOffDate] = useState('')
  const [expiredInvoiceItems, setExpiredInvoiceItems] = useState([])

  React.useEffect(() => {
    if (upcomingOrder !== undefined) {
      const allFilteredInvoiceItems = []
      upcomingOrder.invoiceItems.forEach((invoiceItemSet) => {
        invoiceItemSet.created_at.forEach((invoiceItem) => {
          if (
            dayjs(invoiceItem.expire_on).diff(localBillDate, 'day') < 0 &&
            dayjs(invoiceItem.expire_on).diff(dayjs(), 'day') > 0
          ) {
            allFilteredInvoiceItems.push({
              ...invoiceItemSet,
              created_at: [invoiceItem],
            })

            if (
              earliestCutOffDate === '' ||
              dayjs(invoiceItem.expire_on) < dayjs(earliestCutOffDate)
            )
              setEarliestCutOffDate(invoiceItem.expire_on)
          }
        })
      })
      setExpiredInvoiceItems(allFilteredInvoiceItems)

      setRestrictionRemoveDeals(
        !!expiredInvoiceItems.length && subscription.status === 'active'
      )
    }
  }, [
    setRestrictionRemoveDeals,
    setEarliestCutOffDate,
    setExpiredInvoiceItems,
    localBillDate,
    upcomingOrder,
    subscription,
    expiredInvoiceItems.length,
    earliestCutOffDate,
  ])

  const invalidDates = useMemo(
    () =>
      customDates.filter(
        (range) => range.dateOverrideType === DateOverrideType.blackOut
      ),
    [customDates]
  )

  React.useEffect(() => {
    /**
     * Skip autoseek if not using the initialBillDate prop.
     */
    if (!initialBillDate) return

    const localBillDateParsed = dayjs(localBillDate)
    let nextBillDateParsed = localBillDateParsed

    /**
     * If initial bill date is set to an invalid date, automatically seek forward to
     * the first valid date.
     */
    while (
      isCalendarDayInvalid(
        nextBillDateParsed,
        invalidDates.map((date) => date.day)
      )
    ) {
      nextBillDateParsed = nextBillDateParsed.add(1, 'd')
    }

    if (nextBillDateParsed !== localBillDateParsed) {
      setLocalBillDate(nextBillDateParsed.toISOString())
    }
  }, [initialBillDate, invalidDates, localBillDate])

  const submitBillDate = React.useCallback(async () => {
    setSavePending(true)

    const periodEndDate = dayjs(localBillDate).format('YYYY-MM-DD')

    try {
      if (onSave) {
        await onSave(periodEndDate)
      } else {
        await updateSubscription(
          {
            ...subscription,
            periodEndDate,
          },
          {
            onSuccess,
            onError,
          }
        )
      }
    } catch (e) {
      showToast('error', {
        children:
          'There was an issue updating your bill date, please contact customer service.',
      })
      trackError((scope) => {
        scope.setContext('newBillDate', periodEndDate)
        scope.capture(e)
      })
    } finally {
      setSavePending(false)
    }
  }, [
    localBillDate,
    onError,
    onSave,
    onSuccess,
    showToast,
    subscription,
    updateSubscription,
  ])

  const submitReviewDeals = React.useCallback(async () => {
    setBillDateCalendarScreen(false)
    setBillDateReviewDealsScreen(true)
  }, [])

  const handleModalBack = React.useCallback(async () => {
    setBillDateCalendarScreen(true)
    setBillDateReviewDealsScreen(false)
  }, [])

  const calendarCtaAttributes = billDateCalendarScreen
    ? restrictionRemoveDeals
      ? {
          title: 'Review Deals',
          action: submitReviewDeals,
          data_cy: TEST_ID.BUTTON_BOX_DATE_REVIEW_DEALS,
        }
      : {
          title: saveLabel,
          action: submitBillDate,
          data_cy: TEST_ID.BUTTON_BOX_DATE_MODAL_SAVE,
        }
    : {
        title: 'Confirm new bill date',
        action: submitBillDate,
        data_cy: TEST_ID.BUTTON_BOX_DATE_REMOVE_DEALS,
      }

  return (
    <>
      {showHeader && headerLabel && (
        <Box className={Styles.HeaderLabelContainer}>
          {billDateReviewDealsScreen && (
            <ModalBackButton
              color="bb.spicedCrimson"
              data-cy={TEST_ID.MODAL_BACK_BUTTON}
              data-what="modal-back-button"
              onClick={handleModalBack}
              title="Back"
            />
          )}
          <Lockup py={16}>
            <Text textAlign="center" variant="H2Bold">
              {headerLabel}
            </Text>
          </Lockup>
        </Box>
      )}
      <BoxSettingsModalContentWrapper>
        <ModalBody paddingTop={0}>
          {showHeader && (
            /** Allow bill date and delivery window to scroll with content */
            <Box className={Styles.DateHeader}>
              <Box className={Styles.BillDateAndDeliveryWindowContainer}>
                <Box className={Styles.BillDate}>
                  <Text
                    component="p"
                    display="flex"
                    flexDirection="column"
                    textAlign="center"
                    variant="Body1Regular"
                  >
                    <strong>Bill Date</strong>
                    <span
                      data-chromatic="ignore"
                      data-cy={TEST_ID.LOCAL_BOX_DATE}
                      data-cy-local-bill-date={dayjs(localBillDate).format()}
                    >
                      {formattedBillDate}
                    </span>
                  </Text>
                </Box>
                <Box className={Styles.EstimatedDeliveryDate}>
                  <Text
                    component="p"
                    display="flex"
                    flexDirection="column"
                    textAlign="center"
                    variant="Body1Regular"
                  >
                    <strong>Estimated Delivery</strong>
                    <span data-chromatic="ignore">
                      {formattedDeliveryWindow}
                    </span>
                  </Text>
                </Box>
              </Box>
            </Box>
          )}

          {billDateReviewDealsScreen ? (
            <Box className={Styles.BillDateDealsReviewScreen}>
              <Text textAlign="center" variant="Body1Regular">
                Changing your bill date will remove the following deals from
                your box. To keep your deals, your bill date must be before{' '}
                {dayjs(earliestCutOffDate).add(1, 'day').format('MMM D')}.
              </Text>

              {expiredInvoiceItems.length > 0 ? (
                <CardProductGroup
                  aria-label="Member deals included in your upcoming order"
                  cySelectorName={TEST_ID.REVIEW_DEALS_ITEM}
                  products={expiredInvoiceItems}
                  renderAfterList={(deal) => (
                    <>
                      <Lockup paddingTop={8}>
                        <Text color="spicedCrimson" variant="Body1Regular">
                          Bill date must be before{' '}
                          {dayjs(deal.created_at[0].expire_on)
                            .add(1, 'day')
                            .format('MMM D')}{' '}
                          to redeem.
                        </Text>
                      </Lockup>
                    </>
                  )}
                  showGroupedItems
                />
              ) : (
                <MemberDealsEmptyState />
              )}
            </Box>
          ) : (
            <Box className={Styles.CalendarContainer}>
              <Calendar
                customTypes={[
                  {
                    type: 'deliveryWindow',
                    days: deliveryWindow.map((day) => ({ day })),
                  },
                  {
                    type: 'custom',
                    days: customDates,
                  },
                ]}
                data-chromatic="ignore"
                formattedDeliveryWindow={ATFormattedDeliveryWindow}
                lastValidDate={lastValidDate}
                margin="0 auto"
                selectedDate={localBillDate}
                setSelectedDate={setLocalBillDate}
              />
            </Box>
          )}
        </ModalBody>

        <ModalFooter>
          <Box className={Styles.Footer}>
            <Button
              data-cy={calendarCtaAttributes.data_cy}
              disabled={savePending || initialLocalBillDate === localBillDate}
              loading={savePending}
              onClick={calendarCtaAttributes.action}
              {...buttonProps}
            >
              {calendarCtaAttributes.title}
            </Button>
            {restrictionRemoveDeals && billDateCalendarScreen && (
              <Lockup paddingTop={16} px={24} textAlign="center">
                <Text variant="Body2Regular">
                  Some deals in your next box are not available for bill dates
                  after {dayjs(earliestCutOffDate).format('MMM D')}.
                </Text>
              </Lockup>
            )}
          </Box>
        </ModalFooter>
      </BoxSettingsModalContentWrapper>
    </>
  )
}

export default CalendarBillDate
