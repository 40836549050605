import { throttle } from '@github/mini-throttle'
import React from 'react'

function getSize(): {
  innerHeight?: number
  innerWidth?: number
  outerHeight?: number
  outerWidth?: number
} {
  const { innerHeight, innerWidth, outerHeight, outerWidth } = window
  return {
    innerHeight,
    innerWidth,
    outerHeight,
    outerWidth,
  }
}

export default function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState(
    {} as Partial<
      Pick<
        typeof window,
        'innerHeight' | 'innerWidth' | 'outerHeight' | 'outerWidth'
      >
    >
  )

  function handleResize() {
    setWindowSize(getSize())
  }

  React.useEffect(() => {
    const handler = throttle(handleResize, 150)

    handler()
    window.addEventListener('resize', handler)
    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [])

  return windowSize
}
