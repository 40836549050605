import type { CMS } from '~/cms/types'
import { withArtDirection, getImage } from 'gatsby-plugin-image'
import { theme } from '@butcherbox/freezer'

/**
 * This hook is intended for AtomicImageSets as there may be a case
 * where we could get either, and this could be invoked, we accept
 * both as props to avoid breaking the rules of hooks, but we return
 * early if the type is not an ContentfulAtomImageSet
 *
 * AtomImageSets data structure is different as we leverage
 * gatsby-plugin-image, from here we use the helper methods
 * to provide artDirection to set our images for the various breakpoints
 * if they are provided.
 */
export default function useCmsAtomicImageSet(
  ImageSet: CMS.ImageSet | CMS.AtomImageSet
) {
  // This hook is specifically for AtomicImageSets,
  // if working with a legacy ImageSet, bail early
  if (ImageSet.__typename !== 'ContentfulAtomImageSet') return null

  // Storybook does not handle withArtDirection - just return the mobile image.
  if (process.env.IS_STORYBOOK) {
    return ImageSet.mobileImage.image.gatsbyImageData
  }
  // All Three
  if (ImageSet.mobileImage && ImageSet.tabletImage && ImageSet.desktopImage) {
    return withArtDirection(
      getImage(ImageSet.mobileImage.image.gatsbyImageData),
      [
        {
          media: theme.mediaQueries.desktop,
          image: getImage(ImageSet.desktopImage.image.gatsbyImageData),
        },
        {
          media: theme.mediaQueries.tablet,
          image: getImage(ImageSet.tabletImage.image.gatsbyImageData),
        },
      ]
    )
  } else if (
    // No Desktop
    ImageSet.mobileImage &&
    ImageSet.tabletImage &&
    !ImageSet.desktopImage
  ) {
    return withArtDirection(
      getImage(ImageSet.mobileImage.image.gatsbyImageData),
      [
        {
          media: theme.mediaQueries.tablet,
          image: getImage(ImageSet.tabletImage.image.gatsbyImageData),
        },
      ]
    )
  } else if (
    // No Tablet
    ImageSet.mobileImage &&
    !ImageSet.tabletImage &&
    ImageSet.desktopImage
  ) {
    return withArtDirection(
      getImage(ImageSet.mobileImage.image.gatsbyImageData),
      [
        {
          media: theme.mediaQueries.desktop,
          image: getImage(ImageSet.desktopImage.image.gatsbyImageData),
        },
      ]
    )
  } else {
    return getImage(ImageSet.mobileImage.image.gatsbyImageData)
  }
}
