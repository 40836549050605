import {
  Box,
  Button,
  IconLoadingSpinner,
  Lockup,
  rem,
  Text,
  TextLink,
  VisuallyHidden,
} from '@butcherbox/freezer'
import { useFeature } from '@optimizely/react-sdk'
import { RouteComponentProps, Router } from '@reach/router'
import dayjs from 'dayjs'
import CardProduct, {
  BaseCard,
  CardProductAction,
  CardProductFlag,
  ICardProductProps,
} from 'design/components/CardProduct/CardProduct'
import CardProductGroup from 'design/components/CardProductGroup/CardProductGroup'
import CardProductList from 'design/components/CardProductList/CardProductList'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { graphql, Link as GatsbyLink, navigate, useStaticQuery } from 'gatsby'
import React from 'react'
import { captureNetworkError } from '~/analytics/errors'
import { Offer } from '~/bb-api/schemata'
import createDeliveryWindow from '~/components/Calendar/deliveryWindow'
import {
  AddonsEmptyState,
  MemberDealsEmptyState,
} from '~/components/EmptyStates'
import Panel, { PanelHeadline, PanelSubheadline } from '~/components/Panel'
import { AlertPanel } from '~/components/Panel/AlertPanel/AlertPanel'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { PanelSection } from '~/components/Panel/PanelSection/PanelSection'
import PanelSlashThroughHeading from '~/components/Panel/PanelSlashThroughHeading/PanelSlashThroughHeading'
import { PanelTable } from '~/components/Panel/PanelTable/PanelTable'
import { PanelTableRow } from '~/components/Panel/PanelTableRow/PanelTableRow'
import TooltipPaymentProcessing from '~/components/TooltipPaymentProcessing'
import { TEST_ID } from '~/constants/cypress'
import { SubscriptionContext } from '~/context/subscription'
import { UserContext } from '~/context/user'
import mutateOfferStatus from '~/hooks/mutateOfferStatus'
import useCustomerBoxItemsWithAvailability from '~/hooks/useCustomerBoxItemsWithAvailability'
import useLatestShipment from '~/hooks/useLatestShipment'
import useMemberDealSavingReceipt from '~/hooks/useMemberDealSavingReceipt'
import useUpcomingOrder from '~/hooks/useUpcomingOrder'
import AccountLayout from '~/layouts/AccountLayout'
import EditBoxDateModal from '~/routes/AccountBoxSettings/BoxSettingsModals/EditBoxDateModal'
import EditBoxTypeModal from '~/routes/AccountBoxSettings/BoxSettingsModals/EditBoxTypeModal'
import AccountHero from '~/routes/AccountYourBox/AccountHero'
import { EditBoxCTA } from '~/routes/AccountYourBox/EditBoxCTA'
import ModalPauseOffer from '~/routes/AccountYourBox/ModalPauseOffer'
import ModalRemoveItem from '~/routes/AccountYourBox/ModalRemoveItem'
import {
  formatDeliveryWindow,
  formatPriceFromCents,
  getPlanFlavorText,
  getPlanIcon,
} from '~/utils'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import { RouterPage } from '~/utils/route'
import {
  PAGE_URL_ROOT,
  URL_EDIT_BILLING,
} from '../AccountProfilePayment/constants'
import * as Styles from './AccountYourBox.css'
import SlashThroughDealsSummary from './SlashThroughDealsSummary'

// URL Constants
export const EDIT_PLAN = 'edit-plan'
const EDIT_BILL_DATE = 'edit-bill-date'
const CONFIRM_REMOVE_ITEM = 'confirm-remove-item'
const CONFIRM_PAUSE_UNPAUSE_OFFER = 'confirm-pause-unpause-offer'

const AccountYourBox: React.FC<RouteComponentProps> = () => {
  const { data: latestShipment } = useLatestShipment()
  const { shipZone } = React.useContext(UserContext)
  const { subscription } = React.useContext(SubscriptionContext)
  const { data: upcomingOrder } = useUpcomingOrder()
  const [showHolidayShippingNotice] = useFeature(
    'web_2399_holiday_shipping_bill_date_notice'
  )

  const { heroImage } = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "account-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const {
    slashThroughPrice,
    isSavingReceipt,
    totalSlashThroughPriceSave,
  } = useMemberDealSavingReceipt()

  // Get products by section
  const products = duplicateItemsByQuantity(
    useCustomerBoxItemsWithAvailability()
  )
  const memberDeals = duplicateItemsByQuantity(
    upcomingOrder?.invoiceItems || []
  )

  const addons = duplicateItemsByQuantity(
    upcomingOrder?.subscription.box.addons || []
  )
  const offers = duplicateItemsByQuantity(subscription.box.offers)
  const extras = duplicateItemsByQuantity(upcomingOrder?.extras || [])

  const isPastDue = subscription.status === 'past_due'
  const isCustom = subscription.box.type === 'cst'
  const { name: planName, type: boxType } = subscription.box

  // Get formatted values by section
  const billDateFormatted = dayjs(subscription.periodEndDate).format('MMM D')

  // a new user cannot pick their bill date (they are billed as soon as they subscribe),
  // but they can pick their ship date (i.e., "shipmentWeek").
  // an existing user can't technically pick their ship date;
  // they adjust their ship date by adjusting their bill date ("datePaid").
  const currentBoxWindowFormatted = React.useMemo(() => {
    const date = latestShipment?.shipmentWeek || latestShipment?.datePaid
    return formatDeliveryWindow(createDeliveryWindow(date, shipZone))
  }, [shipZone, latestShipment?.shipmentWeek, latestShipment?.datePaid])

  const nextBoxWindowFormatted = React.useMemo(() => {
    return formatDeliveryWindow(
      createDeliveryWindow(subscription.periodEndDate, shipZone)
    )
  }, [shipZone, subscription.periodEndDate])

  const boxPriceFormatted = formatPriceFromCents(subscription.box.basePrice)

  const addonsPriceFormatted = formatPriceFromCents(
    upcomingOrder?.subscription.box.addonsPrice || 0
  )

  const subTotalPriceFormatted = formatPriceFromCents(
    upcomingOrder?.subtotal || 0
  )
  const totalPriceFormatted = formatPriceFromCents(upcomingOrder?.total || 0)
  const taxAmountFormatted = formatPriceFromCents(upcomingOrder?.taxAmount || 0)
  const mostRecentOrderDatePaidFormatted = latestShipment
    ? dayjs(latestShipment.datePaid).format('MMM D')
    : 'TBD'
  const handleModalClose = React.useCallback(
    () => navigate('/account/your-box'),
    []
  )
  return (
    <AccountLayout>
      <VisuallyHidden data-cy={TEST_ID.ACCOUNT_YOUR_BOX_HEADER}>
        <h1>ButcherBox - Your Account</h1>
      </VisuallyHidden>
      {showHolidayShippingNotice && (
        <AlertPanel marginBottom={{ mobile: 16, tablet: 24 }}>
          <Lockup>
            <Text variant="Subhead1">Holiday Shipping Notice</Text>
            <Text variant="Body1Regular">
              Please be prepared for changes or delays in shipping this holiday
              season. We recommend planning ahead and ordering what you'll need
              for the holidays early, and keeping an eye out for your box later
              in the evening or beyond typical delivery hours.
            </Text>
          </Lockup>
        </AlertPanel>
      )}
      {!isPastDue ? (
        <AccountHero image={heroImage} testId={TEST_ID.ACCOUNT_YOUR_BOX_HERO} />
      ) : null}
      {
        /**
         * Current Box
         */
        (latestShipment?.shipmentWeek &&
          !latestShipment?.trackingInformation?.status) ||
        latestShipment?.trackingInformation?.status === 'processing' ||
        latestShipment?.trackingInformation?.status === 'in_transit' ? (
          <Panel marginBottom={24}>
            <PanelHeadline>Current Box</PanelHeadline>
            <Box className={Styles.SubheadlineWrap}>
              <PanelSubheadline>
                <strong>Billed: </strong> {mostRecentOrderDatePaidFormatted}
              </PanelSubheadline>

              {latestShipment.trackingInformation?.url && (
                <TextLink
                  href={latestShipment.trackingInformation?.url}
                  marginLeft={8}
                  rel="noopener noreferrer"
                >
                  Track box
                </TextLink>
              )}
            </Box>

            <Text variant="Body1Regular">
              <strong>Estimated delivery: </strong> {currentBoxWindowFormatted}
            </Text>
          </Panel>
        ) : null
      }

      {/**
       * Next Box
       *
       * Modify settings for the next box you will receive
       */}
      {subscription ? (
        <Panel data-where="next_box_panel">
          <PanelHeadline>Next Box</PanelHeadline>
          <Box className={Styles.SubheadlineWrap}>
            <PanelSubheadline
              data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_BILL_DATE}
              data-cy-box-date={dayjs(subscription.periodEndDate).format()}
            >
              {isPastDue ? (
                <>
                  Please update your billing information. After you save your
                  updated information, your card will be charged and your box
                  will be processed right away.
                </>
              ) : (
                <Box component="span" marginRight={12}>
                  <strong>Bill date:</strong> {billDateFormatted}
                </Box>
              )}
            </PanelSubheadline>

            <TextLink
              aria-label={`Update billing date, currently ${dayjs(
                subscription.periodEndDate
              ).format('MMMM D, YYYY')}`}
              color={'spicedCrimson'}
              component={GatsbyLink}
              data-cy={TEST_ID.LINK_CHANGE_BOX_DATE}
              data-what={isPastDue ? 'update_billing_info' : 'update_bill_date'}
              to={
                isPastDue
                  ? `${PAGE_URL_ROOT}/${URL_EDIT_BILLING}`
                  : `/account/your-box/${EDIT_BILL_DATE}`
              }
            >
              {isPastDue ? 'Update Payment' : 'Update'}
            </TextLink>
          </Box>
          {!isPastDue && (
            <PanelSubheadline>
              <strong>Estimated delivery:</strong> {nextBoxWindowFormatted}
            </PanelSubheadline>
          )}

          <PanelSection
            content={planName}
            contentAddon={boxPriceFormatted}
            data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_PLAN}
            data-cy-box-size={subscription.box.size}
            data-cy-box-type={subscription.box.type}
            data-cy-price={subscription.box.basePrice}
            paddingTop={28}
            title="Your subscription"
          />

          <PanelBreak />

          <CardProductList
            aria-label={
              isCustom
                ? `${planName} box products included in your upcoming order`
                : ''
            }
            products={
              isCustom && products.length
                ? products.map((product) => {
                    return <CardProduct key={product.sku} product={product} />
                  })
                : [
                    <BaseCard
                      iconName={getPlanIcon(boxType)}
                      key={boxType}
                      subtitle={getPlanFlavorText(boxType)}
                      title={
                        <VisuallyHidden>{subscription.box.name}</VisuallyHidden>
                      }
                    />,
                  ]
            }
          />

          <EditBoxCTA />

          {/**
           * Addons
           */}

          <PanelSection
            content="Add-ons"
            contentAddon={addons.length > 0 ? addonsPriceFormatted : undefined}
            data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_ADDONS}
            data-cy-price={subscription.box.addonsPrice}
            paddingTop={28}
          />

          <PanelBreak />

          {addons.length > 0 ? (
            <>
              <CardProductList
                aria-label="Addon products included in your upcoming order"
                orientation="column"
                products={addons.map((product, index) => {
                  return (
                    <CardProduct
                      Action={
                        <TooltipPaymentProcessing>
                          {({ isInteractionDisabled }) => (
                            <CardProductAction
                              aria-disabled={isInteractionDisabled}
                              aria-label={`Remove addon ${product.description} from your subscription`}
                              data-attr-sku={product.sku}
                              data-cy={TEST_ID.LINK_REMOVE_ADDON}
                              data-cy-sku={product.sku}
                              data-what="remove-addon"
                              disabled={isInteractionDisabled}
                              onClick={() =>
                                navigate(
                                  `/account/your-box/${CONFIRM_REMOVE_ITEM}`,
                                  {
                                    state: {
                                      item: product,
                                      itemId: product.sku,
                                      productId: product.sku,
                                      productType: 'addon',
                                    },
                                  }
                                )
                              }
                            >
                              Remove
                            </CardProductAction>
                          )}
                        </TooltipPaymentProcessing>
                      }
                      data-attr-sku={product.sku}
                      data-cy={TEST_ID.CARD_ADDON}
                      data-cy-sku={product.sku}
                      isShowPrice
                      key={index}
                      product={product}
                    />
                  )
                })}
              />

              <Text marginTop={12} variant="Body2Regular">
                Stock up on essentials.
                <br />
                <TextLink
                  component={GatsbyLink}
                  to={'/account/addons'}
                  variant={'Body2Regular'}
                >
                  Manage Add-ons
                </TextLink>
              </Text>
            </>
          ) : (
            <AddonsEmptyState />
          )}

          {/**
           * Member deals
           */}

          <PanelSlashThroughHeading
            actualPrice={upcomingOrder?.invoiceItemsPrice}
            markupPrice={slashThroughPrice}
            source="header"
          />

          <PanelBreak />

          {memberDeals.length > 0 ? (
            <CardProductGroup
              aria-label="Member deals included in your upcoming order"
              products={memberDeals}
              renderAfterList={(deal) => (
                <CardProductAction
                  aria-label={`Remove member deal ${deal.description} from your upcoming order`}
                  data-cy={TEST_ID.LINK_REMOVE_DEAL}
                  data-what="remove-member-deal"
                  marginTop={12}
                  onClick={() =>
                    navigate(`/account/your-box/${CONFIRM_REMOVE_ITEM}`, {
                      state: {
                        itemId: deal.sku,
                        productId: deal.sku,
                        productType: 'memberDeal',
                        item: deal,
                      },
                    })
                  }
                >
                  Remove deal
                </CardProductAction>
              )}
              showGroupedItems
            />
          ) : (
            <MemberDealsEmptyState />
          )}

          {/**
           * Offers
           */}
          {offers.length > 0 ? (
            <>
              <PanelSection
                content="Offers"
                contentAddon="FREE"
                data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_OFFERS}
                data-cy-price={subscription.box.offersPrice}
                paddingTop={28}
              />
              <PanelBreak />
              <CardProductList
                aria-label="Offers included in your upcoming order"
                products={offers.map((offer, index) => (
                  <CardProductOffer key={index} offer={offer} />
                ))}
              />
            </>
          ) : null}

          {/**
           * Extras
           */}
          {extras.length > 0 ? (
            <>
              <PanelSection
                content="Extras"
                contentAddon="FREE"
                data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_EXTRAS}
                data-cy-price={upcomingOrder?.extrasPrice}
                paddingTop={28}
              />
              <PanelBreak />
              <CardProductList
                aria-label="Free extra products included in your upcoming order"
                products={extras.map((extra, index) => (
                  <CardProduct key={index} product={extra} />
                ))}
              />
            </>
          ) : null}

          <PanelTable caption="Box subtotal">
            {/**
             * Subtotal
             */}
            <PanelTableRow
              content="Subtotal"
              contentAddon={`${subTotalPriceFormatted}`}
              data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_SUBTOTAL}
              data-cy-price={upcomingOrder?.subtotal}
              panelPaddingTop={rem(19)}
            />

            {/**
             * Estimated Taxes
             */}
            <PanelTableRow
              content="Estimated Taxes"
              contentAddon={`${taxAmountFormatted}`}
              data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_TAXES}
              data-cy-price={upcomingOrder?.taxAmount}
              panelPaddingTop={rem(19)}
            />

            {/**
             * Shipping
             */}
            <PanelTableRow
              content="Shipping"
              contentAddon="Free"
              panelPaddingTop={rem(16)}
            />

            {/**
             * Credits
             */}
            {!!upcomingOrder?.creditAmount ? (
              <PanelTableRow
                content="Credits"
                contentAddon={formatPriceFromCents(
                  -Math.abs(upcomingOrder.creditAmount)
                )}
                data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_CREDITS}
                data-cy-price={upcomingOrder?.creditAmount}
                panelPaddingTop={rem(16)}
              />
            ) : null}

            {/**
             * Coupons
             */}
            {!!upcomingOrder?.discountAmount ? (
              <PanelTableRow
                content="Coupons"
                contentAddon={formatPriceFromCents(
                  -Math.abs(upcomingOrder.discountAmount)
                )}
                data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_DISCOUNTS}
                data-cy-price={upcomingOrder.discountAmount}
                panelPaddingTop={rem(16)}
              />
            ) : null}
          </PanelTable>

          <PanelBreak />

          <PanelTable caption="Box total">
            {/**
             * Total
             */}
            <PanelTableRow
              content="Total"
              contentAddon={`${totalPriceFormatted}`}
              data-cy={TEST_ID.SECTION_ACCOUNT_YOUR_BOX_TOTAL}
              data-cy-price={upcomingOrder?.total}
              panelPaddingBottom={rem(32)}
              panelPaddingTop={rem(12)}
            />
          </PanelTable>

          {isSavingReceipt ? (
            <SlashThroughDealsSummary
              totalSlashThroughPriceSave={totalSlashThroughPriceSave}
            ></SlashThroughDealsSummary>
          ) : null}
        </Panel>
      ) : (
        <IconLoadingSpinner />
      )}

      {/**
       * Page footer actions
       */}
      <Text marginTop={16} textAlign="center" variant="Body2Regular">
        To view or change your box size or frequency, please visit your&nbsp;
        <TextLink
          component={GatsbyLink}
          to={'/account/box-settings'}
          variant={'Body2Regular'}
        >
          box settings page.
        </TextLink>
      </Text>

      {/* Modal routing */}
      <Router>
        <RouterPage
          path={EDIT_BILL_DATE}
          renderRoute={() => (
            <EditBoxDateModal handleModalClose={handleModalClose} />
          )}
        />
        <RouterPage
          path={EDIT_PLAN}
          renderRoute={() => (
            <EditBoxTypeModal handleModalClose={handleModalClose} />
          )}
        />
        <RouterPage
          path={CONFIRM_REMOVE_ITEM}
          renderRoute={(props) => (
            <ModalRemoveItem handleModalClose={handleModalClose} {...props} />
          )}
        />
        <RouterPage
          path={CONFIRM_PAUSE_UNPAUSE_OFFER}
          renderRoute={(props) => (
            <ModalPauseOffer handleModalClose={handleModalClose} {...props} />
          )}
        />
      </Router>
    </AccountLayout>
  )
}

const CardProductOffer: React.FC<
  { offer: Offer } & Partial<Omit<ICardProductProps, 'iconName'>> &
    RouteComponentProps
> = ({ offer, ...props }) => {
  // Destructure here to allow the whole offer to be passed as a complete object
  const { description, isPausable, sku, status, expirationDate } = offer
  const showToast = React.useContext(ToastContext)
  const [mutate, { status: resumeOfferStatus }] = mutateOfferStatus('activate')
  const resumeOffer = React.useCallback(
    () =>
      mutate(sku, {
        onSuccess: () =>
          showToast('success', {
            children: `Success! Resumed offer ${description}`,
          }),
        onError: (e: any) => {
          captureNetworkError(e, { sku })
          showToast('error', {
            children: `Could not resume offer ${description}. Please contact customer support.`,
          })
        },
      }),
    [mutate, sku, showToast, description]
  )

  const action = React.useMemo(() => {
    if (!isPausable) return

    return ((offerStatus) => {
      switch (offerStatus) {
        case 'paused':
          return (
            <Button
              onClick={() => resumeOfferStatus !== 'loading' && resumeOffer()}
              textVariant={'Body2Regular'}
              title={`Resume offer ${description}`}
              variant={'text'}
              width={'standard'}
            >
              <Box display={'inlineFlex'}>
                Resume offer
                {resumeOfferStatus === 'loading' && (
                  <IconLoadingSpinner
                    marginLeft={8}
                    marginTop={8}
                    size={'text'}
                  />
                )}
              </Box>
            </Button>
          )
        case 'active':
          return (
            <Button
              onClick={() =>
                navigate(`/account/your-box/${CONFIRM_PAUSE_UNPAUSE_OFFER}`, {
                  state: offer,
                })
              }
              textVariant={'Body2Regular'}
              title={`Pause offer ${description}`}
              variant={'text'}
              width={'standard'}
            >
              Pause offer
            </Button>
          )
        default:
          return
      }
    })(status)
  }, [isPausable, status, description, resumeOfferStatus, resumeOffer, offer])

  return (
    <CardProduct
      Action={action}
      expirationDate={expirationDate}
      Flag={<CardProductFlag>Free</CardProductFlag>}
      product={offer}
      {...props}
    />
  )
}

export default AccountYourBox
