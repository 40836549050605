import { OptimizelyContext } from '@optimizely/react-sdk'
import React from 'react'
import {
  OptimizelyExperimentsAvailable,
  OptimizelyFeatureFlags,
} from '~/analytics/types'

export default function useGetOptimizelyConfig() {
  const { optimizely } = React.useContext(OptimizelyContext)
  const experimentsMap = optimizely?.getOptimizelyConfig()?.experimentsMap
  const featuresMap = optimizely?.getOptimizelyConfig()?.featuresMap
  const enabledFeatures = optimizely?.getEnabledFeatures()

  // Storing a reference to enabledFeatures for manual comparison.
  const enabledFeaturesReference = React.useRef(enabledFeatures)

  // For adding Optimizely experiments and the variant a user is bucketed into for
  // reporting to Segment, we create an object of the experiment ids matched to the variant ids,
  // which we then add as traits in an identify call and a track event
  // we are using device type as a flag for our userAttributes having been set by our setUser call
  // TODO investigate the implications of user data changing mid session in regards to experiments available
  // We currently use the presence of device_type here and in the featureflags below as a proxy for whether
  // the attributes have been loaded to determine if we can send Optimizely data to Segment
  const experimentsAvailable: OptimizelyExperimentsAvailable = React.useMemo(() => {
    return !optimizely ||
      !experimentsMap ||
      !optimizely?.user?.attributes?.device_type
      ? {}
      : Object.keys(experimentsMap)
          .map((experimentKey) => {
            const experiment = experimentsMap[experimentKey]
            const id = `experiment_${experiment?.id}`
            const variation = optimizely.getVariation(experimentKey)
            const variantId = experiment?.variationsMap[variation]?.id
            return [id, variantId]
          })
          .reduce((obj, [id, variant]) => {
            obj[id] = variant

            return obj
          }, {})
  }, [experimentsMap, optimizely])

  // If enabledFeatures from Optimizely is different than our current reference,
  // reset our reference. This will trigger the featureFlags useMemo to be recalculated
  React.useEffect(() => {
    if (
      JSON.stringify(enabledFeatures) !==
      JSON.stringify(enabledFeaturesReference.current)
    ) {
      enabledFeaturesReference.current = enabledFeatures
    }
  }, [enabledFeatures])

  // Generate a list of available feature flags and their status for Segment track event.
  // We use a reference to enabledFeatures in our dependency array and NOT the actual enabledFeatures returned from Optimizely.
  // This is because the enabledFeatures returned from Optimizely doesn't provide a stable reference
  // for useMemo to actually determine if changes have been made.
  // By using a reference to enabledFeatures, we ensure we don't over-track our Optimizely events.
  const featureFlags: OptimizelyFeatureFlags = React.useMemo(() => {
    return !optimizely ||
      !featuresMap ||
      !optimizely?.user?.attributes?.device_type
      ? {}
      : Object.keys(featuresMap)
          .map((featureKey) => {
            const id = `feature_${featuresMap[featureKey]?.id}`
            const status = enabledFeaturesReference.current.includes(featureKey)
              ? 'on'
              : 'off'

            return [id, status]
          })
          .reduce((obj, [id, status]) => {
            obj[id] = status

            return obj
          }, {})
  }, [featuresMap, enabledFeaturesReference, optimizely])

  const track = React.useCallback<typeof optimizely.track>(
    (...args) => {
      optimizely?.track(...args)
    },
    [optimizely]
  )

  return {
    experimentsAvailable,
    featureFlags,
    experimentsMap,
    featuresMap,
    track,
  }
}
