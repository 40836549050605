import React from 'react'

//https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react/
export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(true)
  const prevVisibilityRef = React.useRef(false)
  const onVisibilityChange = () =>
    setIsVisible(document.visibilityState !== 'hidden')

  React.useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange, false)
    prevVisibilityRef.current = isVisible
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange)
    }
  })

  return {
    isVisible,
    hasVisibilityChanged: isVisible !== prevVisibilityRef.current,
  }
}
