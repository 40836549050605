import { Box, BoxProps, ListItem } from '@chakra-ui/core'
import { rem, styled } from 'design'
import colors from 'design/colors'
import { BreakpointAliases } from 'design/theme'
import { NavItem } from 'design/types'
import { Link } from 'gatsby'
import React from 'react'

export type ISideNavigation = {
  layoutSwitchBreakpoint?: keyof BreakpointAliases
  navItems: NavItem[]
  activeItem?: NavItem
} & BoxProps

const SideNavigation: React.FC<ISideNavigation> = ({
  layoutSwitchBreakpoint = 'desktop',
  navItems,
  activeItem,
  ...props
}) => {
  return (
    <Box
      as="ul"
      bg="white"
      data-what="side-navigation"
      data-where="navigation"
      display={{ base: 'inline-flex', [layoutSwitchBreakpoint]: 'flex' }}
      flexDirection={{ mobile: 'row', [layoutSwitchBreakpoint]: 'column' }}
      listStyleType="none"
      shadow={{ base: 'md', [layoutSwitchBreakpoint]: 'unset' }}
      width="auto"
      {...props}
    >
      {navItems.map((item, index) => (
        <StyledListItem
          data-what="side-navigation-item"
          key={index}
          layoutSwitchBreakpoint={layoutSwitchBreakpoint}
        >
          <SideNavItem
            activeItem={activeItem}
            data-what={item['data-what']}
            item={item}
            layoutSwitchBreakpoint={layoutSwitchBreakpoint}
          />
        </StyledListItem>
      ))}
    </Box>
  )
}

const StyledListItem = styled(ListItem)<{
  layoutSwitchBreakpoint: keyof BreakpointAliases
}>`
  flex: 1 1 auto;
  margin: auto;

  ${(p) => p.theme.media[p.layoutSwitchBreakpoint]} {
    flex: unset;
    margin: 0;

    & + & {
      border-top-width: ${rem(2)};
      border-top-color: ${(p) => p.theme.colors.bb.ivory};
    }
  }
`

export type ISideNavItem = {
  item: NavItem
  layoutSwitchBreakpoint: keyof BreakpointAliases
  activeItem?: NavItem
}

const SideNavItem: React.FC<ISideNavItem> = ({
  item,
  activeItem,
  layoutSwitchBreakpoint,
}) => {
  if (
    item.options &&
    item.options.type === 'button' &&
    item.options.handleClick
  ) {
    return (
      <NavButton
        className={activeItem === item ? 'active-item' : ''}
        layoutSwitchBreakpoint={layoutSwitchBreakpoint}
        onClick={item.options.handleClick}
        style={{
          background: item.options.useVariant ? colors.bb.ivory : 'white',
          borderLeftColor:
            item.options.useVariant && activeItem !== item
              ? colors.bb.ivory
              : '',
        }}
      >
        {item.title}
      </NavButton>
    )
  } else {
    return (
      <NavLink
        activeClassName="active-item"
        data-what={item['data-what']}
        layoutSwitchBreakpoint={layoutSwitchBreakpoint}
        partiallyActive
        target="_blank"
        to={item.url}
      >
        {item.title}
      </NavLink>
    )
  }
}

const StyledNavItem = styled.div<{
  layoutSwitchBreakpoint: keyof BreakpointAliases
}>`
  align-items: center;
  border-top-color: #fff;
  border-top-width: ${rem(4)};
  display: flex;
  font-family: Lato;
  font-size: ${rem(12)};
  font-weight: 700;
  letter-spacing: ${rem(0.5)};
  line-height: ${rem(16)};
  justify-content: center;
  line-height: ${rem(16)};
  padding: ${rem(12)} ${rem(12)} ${rem(14)};
  position: relative;
  text-transform: uppercase;

  &:hover {
    color: ${(p) => p.theme.colors.bb.spicedCrimson};
  }

  &.active-item {
    border-top-color: ${(p) => p.theme.colors.bb.spicedCrimson};
  }

  ${(p) => p.theme.media[p.layoutSwitchBreakpoint]} {
    border-left-width: ${rem(3.98)};
    border-left-color: #fff;
    border-top-width: 0;
    padding: ${rem(25)} 0 ${rem(24)} ${rem(28.02)};
    justify-content: flex-start;

    &.active-item {
      border-left-color: ${(p) => p.theme.colors.bb.spicedCrimson};
    }
  }
`

const StyledButton = styled.button`
  height: 100%;
  width: 100%;
  display: flex;
  gap: ${rem(7)};
  align-items: center;
  justify-content: center;
`

const NavButton = StyledNavItem.withComponent(StyledButton)
const NavLink = StyledNavItem.withComponent(Link)

export default SideNavigation
