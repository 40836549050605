import { Box, BoxProps } from '@chakra-ui/core'
import { rem } from 'design'
import SideNavigation from 'design/components/SideNavigation/SideNavigation'
import React from 'react'
import { BypassBlockTarget } from '~/components/BypassBlock'
import { AccountLinks } from '~/data'

type IAccountLayout = BoxProps

const AccountLayout: React.FC<IAccountLayout> = ({ children, ...props }) => {
  return (
    <Box
      d={{ mobile: 'block', tablet: 'flex' }}
      m={['0', '0 auto']}
      maxWidth={['auto', rem(856)]}
      minHeight={['auto', 'calc(100vh - 358px)']}
      px={rem(16)}
      py={{ base: rem(20), desktop: rem(28) }}
      w="100%"
      {...props}
    >
      <Box
        aria-label="Account settings"
        as="nav"
        d={{ mobile: 'none', tablet: 'block' }}
        flex="0 0 13.5rem"
        mr={4}
      >
        <SideNavigation
          data-where="navigation"
          layoutSwitchBreakpoint="tablet"
          navItems={AccountLinks}
          position={'sticky'}
          top="5rem"
        />
      </Box>
      <BypassBlockTarget flex="1 1 auto">{children}</BypassBlockTarget>
    </Box>
  )
}

export default AccountLayout
