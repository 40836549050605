import { Box, ButtonLink, Lockup, Text } from '@butcherbox/freezer'
import { PseudoBoxProps } from '@chakra-ui/core'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import * as Styles from './AccountHero.css'

type AccountHeroProps = PseudoBoxProps & {
  image: {
    childImageSharp: {
      fluid: React.ComponentPropsWithRef<typeof BackgroundImage>['fluid']
    }
  }
  testId: string
}

export default function AccountHero({ image, testId }: AccountHeroProps) {
  const heroButtonText = 'View All Member Deals'

  return (
    <Box
      className={Styles.Container}
      data-cy={testId}
      data-where="page_your_box"
    >
      <BackgroundImage fluid={image.childImageSharp.fluid}>
        <Box className={Styles.Tint} />
        <Lockup className={Styles.Text}>
          <Text color="white" component="h2" variant="Eyebrow">
            Score exclusive
          </Text>
          <Text color="white" variant="DisplayTwo">
            Member Deals
          </Text>
        </Lockup>
        <Box className={Styles.ButtonStyle}>
          <ButtonLink
            aria-label={heroButtonText}
            data-what="view_member_deals"
            href="/account/deals"
          >
            {heroButtonText}
          </ButtonLink>
        </Box>
      </BackgroundImage>
    </Box>
  )
}
