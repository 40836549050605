import { datadogRum } from '@datadog/browser-rum'

type DatadogRum = typeof datadogRum
type CustomDatadogRum = DatadogRum & { q?: [] }

declare global {
  interface Window {
    DD_RUM?: CustomDatadogRum
    q?: Function[]
  }
}

export default {
  id: 'dd-snippet',
  url: 'https://www.datadoghq-browser-agent.com/datadog-rum-v4.js',
  delay: { mobile: 0, default: 0 },
  async: true,
  defer: true,
  executeCheck: function () {
    return (
      (process.env.NODE_ENV !== 'test' && process.env.NETLIFY === 'true') ||
      process.env.NODE_ENV === 'development'
    )
  },
  pre: function (script) {
    //@ts-ignore value of DD_RUM mutates after injection
    window['DD_RUM'] = window['DD_RUM'] || {
      q: [],
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      onReady: function (c) {
        return null
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      setUser: function (c) {
        return null
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      addError: function (e, c) {
        return null
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      addAction: function (e, c) {
        return null
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      init: function (c) {
        return null
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      startSessionReplayRecording: function (c) {
        return null
      },
    }
    return script
  },
  post: function () {
    window.DD_RUM.onReady(function () {
      const getConfig = (env) => {
        switch (env) {
          case 'production':
            return {
              allowedTracingOrigins: [
                'https://butcherbox.com',
                'https://www.butcherbox.com',
              ],
              applicationId: '34255e8c-57b9-4159-a5a9-2a2d011cf56e',
              clientToken: 'pub2ad4f0e284956c7dc59dd8a3f9a5ede6',
              site: 'datadoghq.com',
              service: 'butcherbox',
              env: 'production',
              version: `${process.env.COMMIT_REF || 'unknown'}`,
              sampleRate: 100,
              tracingSampleRate: 100,
              trackFrustrations: true,
              trackInteractions: true,
              trackSessionAcrossSubdomains: true,
              trackUserInteractions: true,
              enableExperimentalFeatures: ['clickmap'],
            }
          default:
            return {
              allowedTracingOrigins: [
                'https://butcherbox.dev',
                'https://local.bboxtools.net',
                'https://www.butcherbox.dev',
              ],
              applicationId: '421b1ce2-086f-40c5-b8c3-8d90a5c8ea21',
              clientToken: 'pubc2b8f8bf59372de808007944860aa256',
              site: 'datadoghq.com',
              service: 'butcherbox-dev',
              env: 'development',
              version: `${process.env.COMMIT_REF || 'unknown'}`,
              sampleRate: 100,
              trackFrustrations: false,
              tracingSampleRate: 100,
              trackInteractions: true,
              trackSessionAcrossSubdomains: true,
              trackUserInteractions: false,
            }
        }
      }

      const productionHostnames = [
        'www.butcherbox.com',
        'member.butcherbox.com',
        'buy.butcherbox.com',
      ]

      const env = productionHostnames.includes(window.location.hostname)
        ? 'production'
        : 'dev'
      window.DD_RUM.init(getConfig(env))
      window.DD_RUM.startSessionReplayRecording()
    })
  },
}
