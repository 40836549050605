import { useLocation } from '@reach/router'
import { shortId } from 'design'
import React from 'react'
import type * as Types from 'design/components/Modal/Modal.types'
import * as Styled from 'design/components/Modal/ModalDialog.styles'

const ModalLabelledByIdContext = React.createContext<string>('')
// @ts-ignore
ModalLabelledByIdContext.Provider.displayName = 'ModalLabelledByIdContext'

/**
 * Compose this component inside of <Modal>.
 *
 * ```jsx
 * <Modal onClose={eventHandler}>
 *   <ModalDialog>
 *     <ModalHeader>Something</ModalHeader>
 *
 *     <ModalContent>
 *       Arbitrary content
 *     </ModalContent>
 *
 *     <ModalFooter>
 *       {SaveButton}
 *     </ModalFooter>
 *   </ModalDialog>
 * </Modal>
 * ```
 */
const ModalDialog = ({ children, ...props }: Types.ModalDialogProps) => {
  const ref = React.useRef(null)
  const location = useLocation()

  React.useEffect(() => {
    ref.current.querySelector('[tabindex], button, a')?.focus()
  }, [location.pathname])

  /**
   * TODO: Add a check to children of ModalDialog to ensure that there is only
   *       one ModalHeader component rendered as children.
   */
  const modalDialogId = React.useMemo(() => `modal-dialog-${shortId()}`, [])

  if (process.env.NODE_ENV !== 'production') {
    React.useEffect(() => {
      const idImpls = document.querySelectorAll(`#${modalDialogId}`)
      if (idImpls.length > 1) {
        console.error(
          `Rendered a modal with ${idImpls.length} implementations of HTML ` +
            `id: ${modalDialogId}. Expected only 1. Ensure that only one ` +
            `ModalHeader is present in this ModalDialog.`,
          idImpls
        )
      }
    }, [modalDialogId])
  }

  return (
    <ModalLabelledByIdContext.Provider value={modalDialogId}>
      <Styled.ModalDialog
        aria-labelledby={modalDialogId}
        aria-modal="true"
        ref={ref}
        role="dialog"
        {...props}
      >
        {children}
      </Styled.ModalDialog>
    </ModalLabelledByIdContext.Provider>
  )
}

export default ModalDialog
