import { Box, rem } from '@butcherbox/freezer'
import ButcherBoxLogo from 'design/components/ButcherBoxLogo/ButcherBoxLogo'
import { LinkInternal } from 'design/components/Typography/Typography'
import React from 'react'
import { HEADER_HEIGHT } from '~/components/Header/Header'

const HeaderWithLogo = () => {
  return (
    <Box
      alignItems="center"
      background="white"
      display="flex"
      height={rem(HEADER_HEIGHT)}
      justifyContent="center"
      position="sticky"
      style={{
        top: 0,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'silt',
      }}
    >
      <LinkInternal color="bb.slate" href="/" title="ButcherBox Home">
        <ButcherBoxLogo width={194} />
      </LinkInternal>
    </Box>
  )
}

export default HeaderWithLogo
