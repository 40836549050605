import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'

export const def = () => {
  return {
    id: 'radioLargeUnchecked',
    name: 'Radio Large Unhecked',
    category: 'misc',
    path: (
      <path
        d="M35.5 18C35.5 27.665 27.665 35.5 18 35.5C8.33502 35.5 0.5 27.665 0.5 18C0.5 8.33502 8.33502 0.5 18 0.5C27.665 0.5 35.5 8.33502 35.5 18Z"
        fill="transparent"
        stroke="currentColor"
      />
    ),
    viewBox: '0 0 36 36',
  }
}

export const IconRadioLargeUnchecked = makeIcon(def())
export default IconRadioLargeUnchecked
