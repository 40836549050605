type userObject =
  | {
      id: string
      email?: string
      anonymousId?: string
      stripeId?: string
    }
  | {
      anonymousId?: string
    }

class ErrorTrack {
  context: Record<string, unknown>
  constructor() {
    this.context = {}
  }
  setContext(key: string, value: unknown) {
    this.context[key] = value
  }
  setUser(user: userObject) {
    window.DD_RUM?.onReady(() => {
      window.DD_RUM?.setUser(user)
    })
  }
  capture(error: unknown) {
    window.DD_RUM?.onReady(() => {
      window.DD_RUM?.addError(error, this.context)
    })
  }
}

type TrackErrorCallback = (e: ErrorTrack) => void

export const trackError = (callback: TrackErrorCallback) => {
  const error = new ErrorTrack()
  callback.call(this, error)
}

export const captureNetworkError = (e: any, additionalData?: object) => {
  if (!e) return
  if (e instanceof Error) {
    trackError((scope) => {
      scope.capture(e)
    })
  } else if (e.status >= 500) {
    trackError((scope) => {
      scope.capture(new Error('Network request failed'))
      // Set additional data as extras
      if (additionalData) {
        Object.entries(additionalData).forEach(([key, value]) => {
          scope.setContext(key, value)
        })
      }
      scope.setContext('response', e)
    })
  }
}
