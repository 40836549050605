import React from 'react'
import 'microtip/microtip.css'
import { Helmet } from 'react-helmet'
import theme, { rem } from 'design'

const ARROW_SIZE_ADJUSTMENTS = 'scaleY(0.68) scaleX(0.94)'

export default function Tooltip({
  alwaysShow = false,
  children,
  position,
  content,
  ...props
}: React.PropsWithChildren<{
  /**
   * The underlying library supports more positions than this but they have not been tested.
   */
  position: 'top' | 'bottom'
  content: string
  alwaysShow?: boolean
}>) {
  const numChildren = React.Children.count(children)
  if (numChildren === 0) {
    return null
  }

  if (process.env.NODE_ENV !== 'production' && numChildren > 1) {
    throw new Error('Avoid passing multiple children into a <Tooltip>')
  }

  // @ts-ignore Type for cloneElement is potentially too restrictive
  const clonedChildren = React.cloneElement(children, {
    role: 'tooltip',
    'aria-label': content,
    'data-microtip-position': position,
    ...props,
  })
  return (
    <>
      <Helmet>
        <style>{`
        [aria-label][role~="tooltip"][aria-disabled] {
          cursor: not-allowed;
        }
        [aria-label][role~="tooltip"]::before,
        [aria-label][role~="tooltip"]::after {
          /* used primarily for visual regression testing */
          ${alwaysShow ? 'opacity: 1;' : ''}
          ${alwaysShow ? '' : 'display: none'}
        }
        [aria-label][role~="tooltip"]::after {
          padding: ${rem(16)};
          font-family: 'Lato';
          color: White;
          line-height: ${rem(16)};
          font-size: ${rem(14)};
          font-weight: 700;
          border-radius: 0;
          background: ${theme.colors.bb.slate};
          white-space: normal;
          min-width: ${rem(158)};
          text-align: left;
        }
        /* To fix tooltip cutoff from viewport */
        [role~="tooltip"][data-what|="Saturday"]::after{
          left: -50%;
        }
        [role~="tooltip"][data-what|="Sunday"]::after{
          left: 150%;
        }
        @media (max-width: 400px) {
          [role~="tooltip"][data-what|="Saturday"]::after{
            left: -95%;
          }
          [role~="tooltip"][data-what|="Sunday"]::after{
            left: 200%;
          }
          [role~="tooltip"][data-what|="Monday"]::after{
            left: 95%;
          }
        }
        /* Top position */
        [role~="tooltip"][data-microtip-position|="top"]::before {
          transform: translate3d(-50%, 0, 0) ${ARROW_SIZE_ADJUSTMENTS};
        }
        [role~="tooltip"][data-microtip-position|="top"]:hover::before {
          display: unset;
          transform: translate3d(-50%, -5px, 0) ${ARROW_SIZE_ADJUSTMENTS};
        }
        /* Bottom position */
        [role~="tooltip"][data-microtip-position|="bottom"]::before {
          margin-top: 7px;
          transform: translate3d(-50%, -10px, 0) ${ARROW_SIZE_ADJUSTMENTS};
        }
        [role~="tooltip"][data-microtip-position|="bottom"]:hover::before {
          display: unset;
          transform: translate3d(-50%, 0, 0) ${ARROW_SIZE_ADJUSTMENTS};
        }
        [role~="tooltip"][data-microtip-position|="${position}"]:hover::after {
          display: unset;
        }
      `}</style>
      </Helmet>
      {clonedChildren}
    </>
  )
}
