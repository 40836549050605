import { Box } from '@chakra-ui/core'
import { rem, styled } from 'design'
import {
  CLOSE_BUTTON_DEFAULT_SIZE,
  CLOSE_BUTTON_RIGHT_OFFSET,
} from './ModalCloseButton.styles'

export const ModalHeader = styled(Box)`
  background: #fff;
  border-bottom: ${rem(1)} solid ${(p) => p.theme.colors.bb.silt};
  flex-shrink: 0;
  padding-left: ${rem(
    CLOSE_BUTTON_DEFAULT_SIZE + CLOSE_BUTTON_RIGHT_OFFSET * 2
  )};
  padding-right: ${rem(
    CLOSE_BUTTON_DEFAULT_SIZE + CLOSE_BUTTON_RIGHT_OFFSET * 2
  )};
  & > h2 {
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
    text-align: center;
  }
`
