import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import React from 'react'
import { useQuery, isCancelledError } from 'react-query'
import { captureNetworkError } from '~/analytics/errors'
import { CACHE_KEY_SUBSCRIPTION } from '~/bb-api/constants'
import { SUBSCRIPTIONS } from '~/bb-api/endpoints'
import { Subscription } from '~/bb-api/schemata'
import { UserContext } from '~/context/user'
import ErrorBoundary from '~/sections/ErrorBoundary'
import axios from '~/utils/axios'

export interface BaseSubscriptionContext {
  /**
   * When true, changes to the subscription could cause the customer
   * to be overcharged or undercharged. The API will prevent these
   * operations, but we can use this boolean to show relevant UI
   * and disable specific actions in the frontend.
   */
  isBlocked: boolean
  isLoading: boolean
}

export interface SubscriptionContextRemote extends BaseSubscriptionContext {
  subscription?: Subscription
}

export const SubscriptionContextRemote = React.createContext<SubscriptionContextRemote>(
  {
    isBlocked: false,
    isLoading: true,
  }
)

export const SubscriptionProviderRemote: React.FC = ({ children }) => {
  const user = React.useContext(UserContext)
  const { pathname, search } = useLocation()
  const [error, setError] = React.useState(false)

  const {
    data: [subscription] = [],
    status: subscriptionQueryStatus,
    isFetching,
  } = useQuery(
    CACHE_KEY_SUBSCRIPTION,
    () =>
      axios
        .get<{ data: Subscription[] }>(SUBSCRIPTIONS(user.id))
        .then((res) => res.data.data)
        .catch((e: any) => {
          captureNetworkError(e)
          setError(true)
        }),
    {
      enabled: !!user?.id,
      staleTime: 10 * 60 * 1000, // 10 minutes
      useErrorBoundary: false,
      onError: (err) => {
        if (!isCancelledError(err)) {
          setError(true)
        }
      },
    }
  )
  React.useEffect(() => {
    if (subscriptionQueryStatus !== 'success') return

    if (
      subscription?.status === 'canceled' &&
      // on an account page that isn't the cancel flow, cancelled page, or order history page or box settings page
      (pathname.startsWith('/account') || pathname.includes('/member')) &&
      !pathname.startsWith('/account/canceled') &&
      !pathname.startsWith('/account/order-history') &&
      !pathname.includes('/cancel-subscription') &&
      !pathname.startsWith('/account/box-setting')
    ) {
      navigate(`/account/canceled${search}`)
    } else if (
      subscription?.status !== 'canceled' &&
      pathname.startsWith('/account/canceled')
    ) {
      navigate(`/account/your-box${search}`)
    }
  }, [pathname, search, subscription?.status, subscriptionQueryStatus])

  return (
    <SubscriptionContextRemote.Provider
      value={{
        isLoading: subscriptionQueryStatus === 'loading' || isFetching,
        isBlocked: subscription?.status
          ? ([
              'payment_processing',
              'past_due',
            ] as Subscription['status'][]).includes(subscription.status)
          : false,
        subscription,
      }}
    >
      {error ? <ErrorBoundary /> : children}
    </SubscriptionContextRemote.Provider>
  )
}

/**
 * When a component's render function depends on data from the subscription
 * context on first render, use this higher-order-component to ensure that the
 * component does not render until the user is available from the context
 * provider.
 *
 * @param Component Any React component
 */
export function renderWhenSubscriptionAvailable<T>(Component: React.FC<T>) {
  return (props: T) => {
    const { subscription } = React.useContext(SubscriptionContextRemote)

    if (!subscription) return null
    else return <Component {...props} />
  }
}
