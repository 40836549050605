export type JOIN_397_VARIANT_TYPES =
  | 'control'
  | 'remove_footer'
  | 'clickable_logo'

export const JOIN_397_VARIANTS = {
  CONTROL: 'control',
  REMOVE_FOOTER: 'remove_footer',
  CLICKABLE_LOGO: 'clickable_logo',
} as const
