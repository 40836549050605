type LetterSpacings = {
  normal: string
  wide: string
  wider: string
  widest: string
}

type LineHeights = {
  smaller: string
  small: string
  normal: string
  none: string
  shorter: string
  short: string
  base: string
  tall: string
  taller: string
  taller2x: string
  taller3x: string
  taller4x: string
}

type FontWeights = {
  normal: number
  medium: number
  semibold: number
  bold: number
  extrabold: number
}

type Fonts = {
  heading: string
  body: string
  mono: string
}

type FontSizes = {
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  '2xl': string
  '3xl': string
  '4xl': string
  '5xl': string
  '6xl': string
}

const letterSpacings: LetterSpacings = {
  normal: '0',
  wide: '0.03125rem',
  wider: '0.075rem',
  widest: '0.125rem',
}

const lineHeights: LineHeights = {
  smaller: '0.88888888888',
  small: '0.9375',
  normal: 'normal',
  none: '1',
  shorter: '1.066666666666667',
  short: '1.083333333333333',
  base: '1.142857142857143',
  tall: '1.2',
  taller: '1.25',
  taller2x: '1.28571428571',
  taller3x: '1.333333333333333',
  taller4x: '1.5',
}

const fontWeights: FontWeights = {
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
}

const fonts: Fonts = {
  heading: `Lato, Sans-Serif`,
  body: `Lato, Sans-Serif`,
  mono: `Lato, Sans-Serif`,
}

const fontSizes: FontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.25rem',
  xl: '1.5rem',
  '2xl': '1.875rem',
  '3xl': '2.25rem',
  '4xl': '3rem',
  '5xl': '3.75rem',
  '6xl': '4.25rem',
}

export default {
  letterSpacings,
  lineHeights,
  fontWeights,
  fonts,
  fontSizes,
}
