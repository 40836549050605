import {
  Box,
  IconAnimalChicken,
  IconAnimalCow,
  IconAnimalFish,
  IconAnimalPig,
  IconProps,
} from '@butcherbox/freezer'
import React from 'react'
import { getNewBrandColorName } from '~/cms/components/ModuleMarketingCopy/utils/color'

const animalIcons = [
  'outlineBeef',
  'outlinePork',
  'outlineChicken',
  'outlineFish',
  'outlineBeefChicken',
  'outlineBeefPork',
  'outlineBeefChickenPorkSquare',
]
export type AnimalIconName = typeof animalIcons[number]

const AnimalIcons = ({
  iconName,
  color = 'white',
  size = 'scale',
}: {
  iconName: AnimalIconName
  color?: IconProps['customColor']['base']
  size?: IconProps['size']
}) => {
  const baseIconProps: IconProps = {
    customColor: { base: getNewBrandColorName(color) },
    size: size,
  }

  switch (iconName) {
    case 'outlineBeef':
      return <IconAnimalCow {...baseIconProps} />
    case 'outlinePork':
      return <IconAnimalPig {...baseIconProps} />
    case 'outlineChicken':
      return <IconAnimalChicken {...baseIconProps} />
    case 'outlineFish':
      return <IconAnimalFish {...baseIconProps} />
    case 'outlineBeefChicken':
      return (
        <>
          <Box width="50%">
            <IconAnimalCow {...baseIconProps} />
          </Box>
          <Box width="50%">
            <IconAnimalChicken {...baseIconProps} />
          </Box>
        </>
      )
    case 'outlineBeefPork':
      return (
        <>
          <Box width="50%">
            <IconAnimalCow {...baseIconProps} />
          </Box>
          <Box width="50%">
            <IconAnimalPig marginLeft={8} {...baseIconProps} />
          </Box>
        </>
      )
    case 'outlineBeefChickenPorkSquare':
      return (
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          width="100%"
        >
          <Box width="50%">
            <IconAnimalCow marginRight={8} {...baseIconProps} />
          </Box>
          <Box width="50%">
            <IconAnimalChicken {...baseIconProps} />
          </Box>
          <Box width="50%">
            <IconAnimalPig {...baseIconProps} />
          </Box>
        </Box>
      )
    default:
      return (
        <>
          <IconAnimalCow marginRight={8} {...baseIconProps} />
          <IconAnimalPig {...baseIconProps} />
          <IconAnimalChicken {...baseIconProps} />
        </>
      )
  }
}

export default AnimalIcons
