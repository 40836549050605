import React from 'react'
import {
  Box,
  Header as FreezerHeader,
  MobileNav,
  OverlayContainer,
  buildDataAttributes,
  HeaderProps,
} from '@butcherbox/freezer'
import { useOverlayTriggerState } from 'react-stately'
import useUnauthenticatedNavState from './hooks/useUnauthenticatedNavState'
import useAuthenticatedNavState from './hooks/useAuthenticatedNavState'
import { Link } from 'gatsby'
import CartDrawer from '../CartDrawer/CartDrawer'

export const HEADER_HEIGHT = 72
const Header = ({ cta, auth }: { cta?: string; auth: boolean }) => {
  // Determine which hook to use based on the auth prop
  const dataHook = auth ? useAuthenticatedNavState : useUnauthenticatedNavState

  // Invoke the hook to fetch the data, any modifications/customizations should be done
  // within the hook, not against this returned state object to avoid re-renders.
  const state = dataHook(cta)

  // Drawer State Management
  const mobileNavState = useOverlayTriggerState({})
  const cartDrawerState = useOverlayTriggerState({})

  /**
   * This is the default render function for all links provided
   * to to the Header, and used for every link/button encountered in the
   * state payload. If you want to customize a specific instance, you can provide
   * a `render` prop to any NavItemLink
   * */
  const renderLink: HeaderProps['renderLink'] = ({
    link,
    className,
    children,
    ref,
    props,
  }) => {
    return (
      <Box
        className={className}
        component={link.isExternal ? 'a' : Link}
        href={link.isExternal ? link.href : undefined}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
          mobileNavState.close()
          link.onClick && link?.onClick(e)
        }}
        rel={link.rel}
        target={link.target}
        //@ts-expect-error polymorphic gatsby link utilizes to not href
        to={link.isExternal ? undefined : link.href}
        {...(link.data ? buildDataAttributes(link.data) : undefined)}
        ref={ref}
        tabIndex={link.tabIndex}
        {...props}
      >
        {children}
      </Box>
    )
  }

  return (
    <>
      <FreezerHeader
        onCartClick={auth ? cartDrawerState.open : undefined}
        onHamburgerClick={mobileNavState.open}
        renderLink={renderLink}
        state={state}
      />
      {mobileNavState.isOpen && (
        <OverlayContainer>
          <MobileNav
            isDismissable
            isOpen
            onClose={mobileNavState.close}
            renderLink={renderLink}
            state={state}
          />
        </OverlayContainer>
      )}
      {cartDrawerState.isOpen && <CartDrawer onClose={cartDrawerState.close} />}
    </>
  )
}
export default Header
