import React from 'react'
import useWindowSize from '../hooks/useWindowSize'
import { DESKTOP_MIN_WIDTH, TABLET_MIN_WIDTH } from '../theme'

type ILayout = {
  isDesktop: boolean
  isMobile: boolean
  isTablet: boolean
}

export const LayoutContext = React.createContext<ILayout>(undefined)

// @ts-ignore
LayoutContext.Provider.displayName = 'LayoutProvider'

export const LayoutProvider = ({ children }) => {
  const w = useWindowSize()

  return (
    <LayoutContext.Provider
      value={{
        /**
         * TODO: Refactor other usages of isMobile so that it can be mobile
         *       only and not both mobile and tablet
         */
        isDesktop: w.innerWidth >= DESKTOP_MIN_WIDTH,
        isMobile: w.innerWidth < TABLET_MIN_WIDTH,
        isTablet:
          w.innerWidth >= TABLET_MIN_WIDTH && w.innerWidth < DESKTOP_MIN_WIDTH,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

/*
  Example of use in component:

  const Foo = () => {
    const value = useContext(LayoutContext);

    return (
        <div>The answer is {value}.</div>
    );
  }
*/
