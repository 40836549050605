import { Box, TextLink, VisuallyHidden } from '@butcherbox/freezer'
import { BaseCard } from 'design/components/CardProduct/CardProduct'
import React from 'react'
import type { Box as BBox } from '~/bb-api/schemata'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { PanelSection } from '~/components/Panel/PanelSection/PanelSection'
import type { BoxItemWithIsUnavailable } from '~/hooks/useCustomerBoxItemsWithAvailability'
import { formatPriceFromCents, getPlanFlavorText, getPlanIcon } from '~/utils'
import { normalizeBoxName } from '~/utils/boxVariants'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import { CartSection } from '../../CartDrawer.css'
import CartProductList from '../shared/CartProductList'
import { Link } from 'gatsby'
import { PaymentProcessingMessage } from '~/routes/AccountYourBox/EditBoxCTA'
import usePaymentProcessingMessage from '~/hooks/usePaymentProcessingMessage'

const BoxDetails = ({
  box,
  products,
  onClose,
}: {
  box: BBox
  products: Array<BoxItemWithIsUnavailable>
  onClose: () => void
}) => {
  const { name, type, basePrice } = box
  const isCustomBox = box.type === 'cst'
  const ariaLabel = isCustomBox
    ? `${normalizeBoxName(box)} box products included in your upcoming order`
    : ''

  const { isTodayBillingDay, subscription } = usePaymentProcessingMessage()
  const userCanCustomizeBox =
    isCustomBox &&
    !isTodayBillingDay &&
    subscription?.status !== 'payment_processing'

  return (
    <Box className={CartSection}>
      <PanelSection
        content={name}
        contentAddon={formatPriceFromCents(basePrice)}
      />
      {userCanCustomizeBox ? (
        <TextLink
          component={Link}
          data-what={'customize-your-cuts-card-drawer'}
          onClick={onClose}
          to="/account/customize-your-box"
        >
          Edit Your Cuts
        </TextLink>
      ) : (
        isCustomBox && <PaymentProcessingMessage onClose={onClose} />
      )}
      <PanelBreak />
      <CartProductList
        aria-label={ariaLabel}
        fallback={
          type && (
            <BaseCard
              iconName={getPlanIcon(type)}
              imageStyleProps={{ color: 'white' }}
              key={type}
              m={0}
              subtitle={getPlanFlavorText(type)}
              title={<VisuallyHidden>{name}</VisuallyHidden>}
            />
          )
        }
        productArray={duplicateItemsByQuantity(products)}
      />
    </Box>
  )
}

export default BoxDetails
