import { Box, Button, ButtonLink, Lockup, Text } from '@butcherbox/freezer'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import * as Styles from './Fallback.Full.css'
import ImageSetBackground from '~/components/ImageSetBackground/ImageSetBackground'

export default ({
  error,
  errorMessage,
  linkDestination,
  linkText,
  is404 = false,
}: {
  errorMessage?: string
  error?: unknown
  linkDestination?: string
  linkText?: string
  is404?: boolean
}) => {
  const imageData = useStaticQuery(graphql`
    query {
      imageSet: contentfulImageSet(
        uniqueId: { eq: "error_boundary_background" }
      ) {
        ...ImageSet
      }
    }
  `)
  const isChunkLoadError = React.useMemo(() => {
    return (
      error instanceof Error &&
      /loading chunk \d* failed/i.test(error?.message ?? '')
    )
  }, [error])

  return (
    <ImageSetBackground
      breakpoints={{ mobile: '(max-width: 767px)' }}
      imageSet={imageData.imageSet}
    >
      <Box className={Styles.ErrorBoundaryFull}>
        <Lockup className={Styles.ErrorBoundaryMessage}>
          <Text component="h1" variant="DisplayOne">
            Sorry!
          </Text>
          <Text component="h2" variant="H2Bold">
            {is404
              ? "This page hasn't finished cooking."
              : 'We are unable to process your request.'}
          </Text>
          <Text variant="H3Bold">
            {errorMessage
              ? errorMessage
              : `An error occurred and we're working to fix the problem. Please check back soon.`}
          </Text>
          {isChunkLoadError ? (
            <Button
              data-testid="btn-refresh-page"
              onClick={() => window?.location.reload()}
            >
              Refresh Page
            </Button>
          ) : (
            <ButtonLink
              data-testid="btn-home-link"
              href={linkDestination ?? '/'}
            >
              {linkText ?? 'Butcherbox Home'}
            </ButtonLink>
          )}
        </Lockup>
      </Box>
    </ImageSetBackground>
  )
}
