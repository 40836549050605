import { Subscription } from '~/bb-api/schemata'

export const cleanSubscription = (subscription: Subscription): Subscription => {
  const cleanedSubscription = { ...subscription } // prevent modification by reference

  // Filter out box items and addons with 0 quantities
  cleanedSubscription.box.addons = cleanedSubscription?.box.addons.filter(
    (addon) => addon.quantity > 0
  )

  cleanedSubscription.box.items = cleanedSubscription?.box.items.filter(
    (boxItem) => boxItem.quantity > 0
  )

  /* Delete box items for non-custom subscriptions. Some users have custom box items associated with
    their accounts from the old frontend, but this state is no longer valid when updating a subscription */
  if (
    cleanedSubscription.box.type !== 'cst' &&
    cleanedSubscription.box.items.length > 0
  ) {
    cleanedSubscription.box.items = []
  }

  return cleanedSubscription
}
