import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'

export const def = () => {
  return {
    id: 'radioSmallChecked',
    name: 'Radio Small Checked',
    category: 'misc',
    path: (
      <>
        <rect
          fill="#fff"
          height="14.967"
          rx="7.484"
          stroke="currentColor"
          width="15"
          x=".5"
          y=".535"
        />
        <path
          clipRule="evenodd"
          d="M8 13.008c2.761 0 5-2.234 5-4.99a4.995 4.995 0 00-5-4.99c-2.761 0-5 2.235-5 4.99a4.995 4.995 0 005 4.99z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </>
    ),
    viewBox: '0 0 16 17',
  }
}

export const IconRadioSmallChecked = makeIcon(def())
export default IconRadioSmallChecked
