import qs from 'querystringify'
import dayjs, { Dayjs } from 'dayjs'

const SHARE_A_SALE_COOKIE_NAME = 'sscid'

export default async function makeCookie(
  queryString: string
): Promise<{ name?: string; value?: string; expiration?: Dayjs }> {
  const params = qs.parse(queryString) as {
    sscid?: string
  }

  if (!params.sscid) {
    return Promise.resolve({})
  }

  return Promise.resolve({
    name: SHARE_A_SALE_COOKIE_NAME,
    value: params.sscid,
    expiration: dayjs().add(31, 'day'),
  })
}
