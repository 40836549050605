import {
  API,
  API_V3_BASE_PATH,
  API_V3_CUSTOMER,
  API_V4_BASE_PATH,
  API_V4_CUSTOMER,
} from '~/bb-api/constants'
import {
  Box,
  BoxType,
  CheckoutCartData,
  Offer,
  Order,
  Subscription,
  User,
} from './schemata'

export const CREATE_BASE_PATH = (apiVersion: API = API.V3) =>
  apiVersion === API.V4 ? API_V4_BASE_PATH : API_V3_BASE_PATH

//TODO in order to use these resources, we need to refactor the actual endpoint constants into functions so we can pass down apiVersion
export const customerResource = (path: string, apiVersion: API = API.V3) => {
  return (userId: User['id']) =>
    `${CREATE_BASE_PATH(apiVersion)}/customer/${userId}/${path}`
}

export const subscriptionResource = (
  path?: string,
  apiVersion: API = API.V3
) => {
  return (userId: User['id'], subscriptionId: Subscription['id']) =>
    customerResource(
      `subscription/${subscriptionId}${path ? '/' + path : ''}`,
      apiVersion
    )(userId)
}

export const boxTypeResource = (path: string, apiVersion: API = API.V3) => {
  return (userId: User['id'], boxType: BoxType) =>
    customerResource(`box-types/${boxType}/${path}`, apiVersion)(userId)
}

export const orderResource = (path: string, apiVersion: API = API.V3) => {
  return (userId: User['id'], orderId: Order['id']) =>
    customerResource(`orders/${orderId}/${path}`, apiVersion)(userId)
}

export const offerResource = (apiVersion: API = API.V3) => {
  return (
    userId: User['id'],
    subscriptionId: Subscription['id'],
    offerId: Offer['sku']
  ) =>
    subscriptionResource(`offer/${offerId}`, apiVersion)(userId, subscriptionId)
}

// TODO: rename these to not be method-specific since they're not
export const APPLY_CREDIT = customerResource('customer-service-credits', API.V4)
export const CREATE_ACCOUNT = `${CREATE_BASE_PATH(API.V4)}/accounts`
export const CREATE_CHECKOUT_CART = `${CREATE_BASE_PATH(API.V4)}/carts`
export const CREATE_SUBSCRIPTION = `${CREATE_BASE_PATH(API.V4)}/subscriptions`
export const SUBSCRIPTION_ISSUE = subscriptionResource('issues', API.V4)
export const CHECKOUT_EMAIL = `${CREATE_BASE_PATH(API.V4)}/checkout`
export const GET_ADDONS = `${CREATE_BASE_PATH(API.V4)}/addons`
export const GET_BOX_ITEMS = `${CREATE_BASE_PATH(API.V4)}/box-items`
export const GET_BOX_SIZES = (type: Box['type']) =>
  `${CREATE_BASE_PATH(API.V4)}/box-types/${type}/box-sizes`
export const GET_BOX_TYPES = `${CREATE_BASE_PATH(API.V4)}/box-types`
export const GET_CANCEL_SETTINGS = customerResource(
  'cancellation-options',
  API.V4
)
export const GET_CUSTOMER = (userId: User['id'], apiVersion: API = API.V4) =>
  `${apiVersion ? API_V4_CUSTOMER : API_V3_CUSTOMER}/${userId}`
export const GET_CHECKOUT_CART = (cart: CheckoutCartData['id']) =>
  `${CREATE_BASE_PATH(API.V4)}/carts/${cart}`
export const GET_CHECKOUT_SETTINGS = `${CREATE_BASE_PATH(
  API.V4
)}/checkout-settings`
export const GET_CHECKOUT_DEALS = `${CREATE_BASE_PATH(API.V4)}/checkout/deals`
export const GET_CUSTOMER_BOX_ITEMS = customerResource('box-items', API.V4)
export const GET_DEALS = customerResource('deals', API.V4)
export const GET_CUSTOMER_STATS = customerResource('stats', API.V4)
export const GET_SPOTLIGHT_DEALS = customerResource(
  'deals/deals-salmon-spotlight',
  API.V4
)
export const GET_FEATURED_DEAL = customerResource('featured-deal', API.V4)
export const GET_ORDERS = customerResource('orders', API.V4)
export const GET_RESUBSCRIBE_SETTINGS = (wordpressPostId: string) =>
  `${CREATE_BASE_PATH(API.V4)}/resubscribe-settings/${wordpressPostId}`
export const GET_SHIP_ZONE = customerResource('ship-zone')
export const GET_UPCOMING_ORDER = subscriptionResource('upcoming-order', API.V4)
export const GET_LATEST_SHIPMENT = () =>
  subscriptionResource('latest-shipment', API.V4)
export const ORDER_ISSUE = orderResource('issues', API.V4)
export const SUBSCRIPTIONS = customerResource('subscriptions', API.V4)
export const UPDATE_ADDON = subscriptionResource('addon', API.V4)
export const UPDATE_BILLING_INFORMATION = customerResource(
  'billing-information',
  API.V4
)
export const UPDATE_SHOPIFY_BILLING_INFORMATION = customerResource(
  'mail-update-payment-method',
  API.V4
)
export const UPDATE_CHECKOUT_CART = (checkoutID) =>
  `${CREATE_BASE_PATH(API.V4)}/carts/${checkoutID}`
export const UPDATE_EMAIL = customerResource('email', API.V4)
export const UPDATE_CHECKOUT_SUMMARY = `${CREATE_BASE_PATH(
  API.V4
)}/checkout-summary`
export const UPDATE_INVOICE_ITEM = subscriptionResource('invoice-item', API.V4)
export const UPDATE_OFFER = offerResource(API.V4)
export const UPDATE_SUBSCRIPTION = subscriptionResource(null, API.V4)
export const UPDATE_SHIPPING_INFORMATION = customerResource(
  'shipping-address',
  API.V4
)
export const UPDATE_SMS_CONSENT = customerResource('sms', API.V4)
export const UPDATE_PASSWORD = customerResource('password', API.V4)

export const POST_CUSTOMER_ANONYMOUS_ID = (userId: User['id']) => {
  return `${GET_CUSTOMER(userId, API.V4)}/anonymous-id`
}

export const USER_ACTION = customerResource('user-action', API.V4)
