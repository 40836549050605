import React from 'react'
import { styled } from 'design'

type ShifterProps = {
  top?: string
  right?: string
  bottom?: string
  left?: string
}

const Wrapper = styled.div<ShifterProps>`
  [aria-label][role~='tooltip']::after {
    padding-top: ${(p) => p.top};
    padding-right: ${(p) => p.right};
    padding-bottom: ${(p) => p.bottom};
    padding-left: ${(p) => p.left};
  }
`

/**
 * When a `TooltipPaymentProcessing` would overflow the edge of the screen,
 * wrap it in this component, with props to shift the tooltip in the necessary direction(s).
 *
 * This component adds padding, which adds "whitespace" to the tooltip, but this space is
 * off the screen so we're okay with it.
 */
const TooltipShifter = ({ children, ...props }) => (
  <Wrapper {...props}>{children}</Wrapper>
)

export default TooltipShifter
