import { GroupedBoxItem, Product } from '~/bb-api/schemata'

export default function useIngredients() {
  return (product: Product & { groupedProducts?: GroupedBoxItem[] }) => {
    const ingredientData: Array<
      Pick<Product, 'description' | 'ingredients'>
    > = []
    if (product.groupedProducts && product?.groupedProducts.length !== 0) {
      product.groupedProducts.forEach((product) => {
        if (product.ingredients) {
          ingredientData.push({
            description: product.description,
            ingredients: product.ingredients,
          })
        }
      })
    } else if (product?.ingredients) {
      ingredientData.push({
        description: product.description,
        ingredients: product.ingredients,
      })
    }
    return ingredientData
  }
}
