import {
  CSSReset,
  ThemeProvider as ChakraThemeProvider,
  useTheme as useChakraTheme,
} from '@chakra-ui/core'
import baseStyled, { CreateStyled } from '@emotion/styled'
import { css, keyframes, Global } from '@emotion/core'
import theme from './theme'

export type BBTheme = typeof theme

// "as unknown" unsets the default typing and allows us to override with our own representation
const useTheme = (useChakraTheme as unknown) as () => BBTheme
const ThemeProvider = (ChakraThemeProvider as unknown) as React.FC<{
  theme?: BBTheme
}>

/**
 * Trying to create a source of truth for these common activities
 * vs scattered imports from chakra, emotion, etc
 *
 * This makes future refactoring a bit easier too if we decide to
 * switch implementations or something like that.
 *
 * If you need an advanced API then go ahead and import from the other places
 */
export { css, keyframes, useTheme, CSSReset, Global, ThemeProvider }
export const styled = baseStyled as CreateStyled<BBTheme>
export * from './utils'
export default theme
