import { Box, IconCheck } from '@butcherbox/freezer'
import { Flex } from '@chakra-ui/core'
import { BodySmaller, H4 } from 'design/components/Typography/Typography'
import React from 'react'
import type { CardImageStatusProps } from './ListCard.types'

export const CardImageStatus = ({
  quantity,
  isUnavailable,
}: CardImageStatusProps) => (
  <Flex
    alignItems="center"
    aria-hidden
    bg="rgba(0, 0, 0, 0.67)"
    bottom="0"
    color="white"
    d={quantity > 0 || isUnavailable ? 'flex' : 'none'}
    flexDir="column"
    justifyContent="center"
    left="0"
    pos="absolute"
    right="0"
    top="0"
    zIndex={0}
  >
    {isUnavailable ? (
      <>
        <BodySmaller>Unavailable</BodySmaller>
        {quantity > 0 && <BodySmaller>Please remove</BodySmaller>}
      </>
    ) : (
      <>
        {quantity > 0 && (
          <>
            <Box marginBottom={4}>
              <IconCheck customColor={{ base: 'white' }} size="text" />
            </Box>
            <H4>In Your Box</H4>
          </>
        )}
      </>
    )}
  </Flex>
)

export default CardImageStatus
