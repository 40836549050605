import get from 'dlv'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { CACHE_KEY_ORDERS } from '~/bb-api/constants'
import { GET_ORDERS } from '~/bb-api/endpoints'
import { Order } from '~/bb-api/schemata'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'
import qs from 'querystringify'

export default function useOrders(params?: {
  limit?: number
  offset?: number
}) {
  const user = useContext(UserContext)
  const userId = get(user, 'id')
  const queryString = qs.stringify(params, true)
  const endPoint = `${GET_ORDERS(userId)}${queryString}`
  const key = `${CACHE_KEY_ORDERS}${queryString}`

  return useQuery(
    key,
    () => axios.get(endPoint).then((response) => response.data.data as Order[]),
    {
      enabled: !!userId,
      staleTime: 10 * 60 * 1000, // 10 minutes
      useErrorBoundary: true,
    }
  )
}
