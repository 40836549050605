import React from 'react'
import { trackError } from '~/analytics/errors'
import { Product } from '~/bb-api/schemata'
import { ViewedProductContext } from '~/context/viewedProducts'
import { useVisible } from './useVisible'

export default function useVisibleProduct(
  product: Product,
  options: Partial<IntersectionObserverInit>
) {
  const [cardRef, isVisible] = useVisible<HTMLDivElement>(options)
  const { trackProductSeen } = React.useContext(ViewedProductContext) ?? {}
  if (typeof trackProductSeen !== 'function') {
    const error = new Error(
      'ViewedProductContext not found. Cannot track visible product.'
    )
    // Prevent Storybook from throwing error or capturing excemption
    if (process.env.NODE_ENV === 'test') {
      return
    }
    if (process.env.NODE_ENV !== 'production') {
      throw error
    } else {
      trackError((scope) => {
        scope.capture(error)
      })
    }
  }

  React.useEffect(() => {
    if (isVisible && trackProductSeen) trackProductSeen({ sku: product.sku })
  }, [isVisible, trackProductSeen, product.sku])

  return cardRef
}
