import { RouteComponentProps, WindowLocation } from '@reach/router'

type RevisedRouteComponentProps = Omit<RouteComponentProps, 'location'> & {
  location?: WindowLocation<any>
}

export const RouterPage = ({
  renderRoute,
  ...props
}: RevisedRouteComponentProps & {
  renderRoute: (props: RevisedRouteComponentProps) => JSX.Element
}) => renderRoute(props)
