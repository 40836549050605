import React from 'react'
import { useQuery } from 'react-query'
import { CACHE_KEY_BOX_ITEMS } from '~/bb-api/constants'
import { GET_CUSTOMER_BOX_ITEMS } from '~/bb-api/endpoints'
import { AvailableCustomCuts } from '~/bb-api/schemata'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'

export default function useCustomerBoxItems() {
  const { id } = React.useContext(UserContext)

  return useQuery(
    [CACHE_KEY_BOX_ITEMS, id],
    () =>
      axios
        .get<AvailableCustomCuts>(GET_CUSTOMER_BOX_ITEMS(id))
        .then((res) => res.data),
    {
      enabled: !!id,
      staleTime: 10 * 60 * 1000, // 10 minutes
    }
  )
}
