import times from 'lodash.times'

interface BoxItemLike {
  quantity: number
}

/**
 * The current UI design is to render items with quantity > 1 multiple times
 * vs just putting a quantity amount next to it.
 */
export default function duplicateItemsByQuantity<T extends BoxItemLike>(
  items: T[]
): T[] {
  const result: T[] = []

  for (const item of items) {
    times(item.quantity, () => result.push({ ...item, quantity: 1 }))
  }

  return result
}
