import qs from 'querystringify'
import { SEGMENT_LOCALSTORAGE_KEY } from '~/analytics/constants'
import { setCookie } from '~/utils/cookie'
import makeHasOffersCookie from '~/utils/has-offers'
import makeShareASaleCookie from '~/utils/share-a-sale'

export const onClientEntry = () => {
  /**
   * Set the Has Offers tracking cookie if relevant data can be parsed
   * from the query params.
   *
   * ! IMPORTANT: Current business logic is to prioritize the most recent affiliate
   *              referral. We blank out the cookie before setting it to force an update.
   */

  makeHasOffersCookie(window.location.search).then(
    ({ name, value, expiration }) => {
      if (name && value && expiration) {
        setCookie(name, value, expiration)
      }
    }
  )

  /**
   * Set the Share A Sale tracking cookie if relevant data can be parsed from
   * the query params.
   *
   * ! IMPORTANT: Current business logic is to prioritize the most recent affiliate
   *              referral. We blank out the cookie before setting it to force an update.
   */
  makeShareASaleCookie(window.location.search).then(
    ({ name, value, expiration }) => {
      if (name && value && expiration) {
        setCookie(name, value, expiration)
      }
    }
  )

  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (typeof window.IntersectionObserver === `undefined`) {
    // @ts-ignore
    import(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }

  // Check for Segment anonymous ID in query params and set it in local storage
  // in case Segment script is blocked.
  // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/querystring/
  const params = qs.parse(location.search) as {
    ajs_aid?: string
  }

  if (params.ajs_aid) {
    try {
      window.localStorage.setItem(
        SEGMENT_LOCALSTORAGE_KEY,
        JSON.stringify(params.ajs_aid)
      )

      // if analytics.js is available, let it know this is meant to be the auid
      window.analytics?.setAnonymousId(params.ajs_aid)
    } catch (e) {
      console.log(e)
    }
  }
}
