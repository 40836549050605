import { graphql, useStaticQuery } from 'gatsby'
import { CMS } from '~/cms/types'
import { PLANS_AND_ADDONS } from '~/routes/constants'
import { cleanJsonStrings } from '~/utils/unicode'
import useSwitchToSubDomain from './useSwitchToSubDomain'

const useSetCartOffer = () => {
  const { BUY_URL } = useSwitchToSubDomain()
  /*
    Instead of creating a new set of fragments, the below query
    uses the exact properties found in the 'Offer' and 'Coupon' fragments
    to grab what is needed based on what we currently use on /get-started
  */
  const { pageData } = cleanJsonStrings(
    useStaticQuery<{
      pageData: CMS.ProtectedPage<CMS.ProductOfferLander>
      memberPerksImage: {
        childImageSharp: { fluid: any }
      }
    }>(graphql`
      query {
        pageData: contentfulProtectedPage(
          pageId: { eq: "signup-interstitial" }
        ) {
          content {
            ... on ContentfulProductOfferLander {
              offer {
                id
                contentful_id
                slug
                expiration(formatString: "")
                bannerText
                offerId
                utmCampaign
                __typename
              }
              coupon {
                id
                contentful_id
                couponCode
                __typename
              }
            }
          }
        }
      }
    `)
  )

  const offerQueryString = new URLSearchParams({
    exclusive_offer: pageData?.content?.offer?.offerId?.toString(),
  }).toString()

  return {
    id: pageData?.content?.offer?.id,
    expiration: pageData?.content?.offer?.expiration,
    bannerText: pageData?.content?.offer?.bannerText,
    couponCode: pageData?.content?.coupon?.couponCode,
    offerId: pageData?.content?.offer?.offerId,
    cartDealUrl: `${BUY_URL}/${PLANS_AND_ADDONS}?${offerQueryString}`,
  }
}

export default useSetCartOffer
