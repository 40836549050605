import React from 'react'
import type { BoxProps } from '@chakra-ui/core'
import { H2 } from 'design/components/Typography/Typography'
import * as Styled from 'design/components/Modal/ModalHeader.styles'

const ModalLabelledByIdContext = React.createContext<string>('')
// @ts-ignore
ModalLabelledByIdContext.Provider.displayName = 'ModalLabelledByIdContext'

/**
 * This component is optional if the modal design calls for it.
 *
 * ```jsx
 * <Modal onClose={eventHandler}>
 *   <ModalDialog>
 *     <ModalHeader>Something</ModalHeader>
 *
 *     <ModalContent>
 *       Arbitrary content
 *     </ModalContent>
 *
 *     <ModalFooter>
 *       {SaveButton}
 *     </ModalFooter>
 *   </ModalDialog>
 * </Modal>
 * ```
 */
const ModalHeader: React.FC<{ variant?: 'standalone' | 'bare' } & BoxProps> = ({
  variant = 'standalone',
  children,
  ...props
}) => {
  const modalLabelledById = React.useContext(ModalLabelledByIdContext)

  switch (variant) {
    case 'standalone':
      return (
        <Styled.ModalHeader data-what="modal-header" {...props}>
          <H2 id={modalLabelledById}>{children}</H2>
        </Styled.ModalHeader>
      )
    case 'bare':
      return (
        <H2 data-what="modal-header" id={modalLabelledById} {...props}>
          {children}
        </H2>
      )
  }
}

export default ModalHeader
