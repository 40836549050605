import { PseudoBox } from '@chakra-ui/core'
import { ITypography } from 'design/components/Typography/Typography'
import { rem } from 'design/utils'
import React from 'react'

/**
 * @deprecated - Use Text/Body1Regular from The Freezer
 */
export const Body: ITypography = (props) => {
  return (
    <PseudoBox
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'Body1Regular' : undefined
      }
      data-what="body-content"
      fontFamily="Lato"
      fontSize={rem(16)}
      fontWeight={400}
      lineHeight={rem(24)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/Body2Regular from The Freezer
 */
export const BodySmaller: ITypography = (props) => {
  return (
    <PseudoBox
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'Body2Regular' : undefined
      }
      data-what="body-content"
      fontFamily="Lato"
      fontSize={rem(14)}
      fontWeight={400}
      lineHeight={rem(18)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/Micro from The Freezer
 */
export const Tiny: ITypography = (props) => {
  return (
    <PseudoBox
      as="p"
      data-freezer={process.env.NODE_ENV !== 'production' ? 'Micro' : undefined}
      data-what="tiny-content"
      fontFamily="Lato"
      fontSize={rem(12)}
      fontWeight={400}
      lineHeight={rem(16)}
      {...props}
    />
  )
}
