import React from 'react'
import { GroupedBoxItem } from '~/bb-api/schemata'
import { AddonsEmptyState } from '~/components/EmptyStates'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { PanelSection } from '~/components/Panel/PanelSection/PanelSection'
import { formatPriceFromCents } from '~/utils'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import CartProductList from '../shared/CartProductList'
import { CartSection } from '../../CartDrawer.css'
import { Box } from '@butcherbox/freezer'

const Addons = ({
  price,
  addons,
  onClose,
}: {
  price: number
  addons: Array<GroupedBoxItem>
  onClose?: () => void
}) => {
  return (
    <Box className={CartSection}>
      <PanelSection
        content="Add-ons"
        contentAddon={price !== 0 ? formatPriceFromCents(price) : ''}
      />
      <PanelBreak />
      {addons && addons.length > 0 ? (
        <CartProductList
          aria-label="Addon products included in your upcoming order"
          productArray={duplicateItemsByQuantity(addons)}
        />
      ) : (
        <AddonsEmptyState onClose={onClose} />
      )}
    </Box>
  )
}
export default Addons
