import 'core-js/proposals/promise-all-settled' // remove with https://github.com/gatsbyjs/gatsby/pull/26895
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { useContext, useRef } from 'react'
import { queryCache, useMutation } from 'react-query'
import { trackDecrementAddon, trackIncrementAddon } from '~/analytics/events'
import {
  CACHE_KEY_SUBSCRIPTION,
  CACHE_KEY_UPCOMING_ORDER,
} from '~/bb-api/constants'
import { UPDATE_ADDON } from '~/bb-api/endpoints'
import {
  Addon,
  GroupedBoxItem,
  Subscription,
  UpcomingOrder,
} from '~/bb-api/schemata'
import { SubscriptionContext } from '~/context/subscription'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'
import { calculatePriceForUpcomingOrder } from '~/utils/price'
import { captureNetworkError } from '~/analytics/errors'
import useGetOptimizelyConfig from './useGetOptimizelyConfig'

const mutateAddons = (action: 'increment' | 'decrement') => () => {
  const user = useContext(UserContext)
  const { subscription } = useContext(SubscriptionContext)
  const queue = useRef([])
  const settled = useRef<Promise<any>>()
  const showToast = useContext(ToastContext)
  const { track } = useGetOptimizelyConfig()

  const mutateAddonsQuantity = (addon: Addon) => {
    const { sku } = addon
    const promise = axios
      .post<GroupedBoxItem>(UPDATE_ADDON(user.id, subscription.id), {
        action,
        sku,
      })
      .then((res) => {
        const isIncrement = action === 'increment'

        // TODO: Move these into hooks so they have the benefit
        // of being able to hook into contexts and other hooks
        if (isIncrement) trackIncrementAddon(res.data)
        else trackDecrementAddon(res.data)

        // Cannot include in track[Increment/Decrement]Addon function
        // since Optimizely is not available on `window`
        track('addon_quantity_changed', undefined, undefined, {
          value: isIncrement ? 1 : -1,
        })
      })

    queue.current.push(promise)
    settled.current = Promise.allSettled(queue.current)

    return promise
  }

  return useMutation(mutateAddonsQuantity, {
    onMutate(addon) {
      // Cancel outgoing queries to prevent overriding local value until
      // final request resolves
      queryCache.cancelQueries(CACHE_KEY_SUBSCRIPTION)
      queryCache.cancelQueries(CACHE_KEY_UPCOMING_ORDER)

      // Save copy of cached values for rollback function
      const prevSubscriptionState: Subscription[] = queryCache.getQueryData(
        CACHE_KEY_SUBSCRIPTION
      )
      const prevUpcomingOrderState: UpcomingOrder = queryCache.getQueryData(
        CACHE_KEY_UPCOMING_ORDER
      )

      // Find affected sku index, if exists
      const itemToUpdateIndex = prevUpcomingOrderState.subscription.box.addons.findIndex(
        (item) => {
          return item.sku === addon.sku
        }
      )

      let updatedItem: GroupedBoxItem
      const delta = action === 'increment' ? 1 : -1

      // If already in box, add or subtract from item quantity,
      // else create new placeholder item
      if (itemToUpdateIndex > -1) {
        updatedItem = {
          ...prevUpcomingOrderState.subscription.box.addons[itemToUpdateIndex],
        }
        updatedItem.quantity += delta
      } else {
        updatedItem = {
          ...addon,
          groupedItems: [],
          quantity: 1,
          amount: 0,
        }
      }

      // Optimistically update to new value
      queryCache.setQueryData(
        CACHE_KEY_UPCOMING_ORDER,
        (oldData: UpcomingOrder) => {
          const updatedAddons = oldData.subscription.box.addons.slice()

          // Add updated item to invoice items, overwriting if necessary
          if (itemToUpdateIndex > -1) {
            if (updatedItem.quantity === 0) {
              updatedAddons.splice(itemToUpdateIndex, 1)
            } else {
              updatedAddons.splice(itemToUpdateIndex, 1, updatedItem)
            }
          } else {
            updatedAddons.push(updatedItem)
          }

          const updatedPrice = updatedAddons.reduce(
            (price, addon) => price + addon.price * addon.quantity,
            0
          )

          // it's basically the same data as upcoming order, so just nesting it 🤷‍♂️
          queryCache.setQueryData(
            CACHE_KEY_SUBSCRIPTION,
            (oldSubArray: Subscription[]) => {
              const oldSub = oldSubArray[0]
              return [
                {
                  ...oldSub,
                  box: {
                    ...oldSub.box,
                    addons: updatedAddons,
                    addonsPrice: updatedPrice,
                  },
                },
              ]
            }
          )

          const updatedUpcomingOrder = {
            ...oldData,
            subscription: {
              ...oldData.subscription,
              box: {
                ...oldData.subscription.box,
                addons: updatedAddons,
                addonsPrice: updatedPrice,
              },
            },
          }

          updatedUpcomingOrder.total = calculatePriceForUpcomingOrder(
            updatedUpcomingOrder
          )

          return updatedUpcomingOrder
        }
      )

      // Return the old value to rollback if necessary
      return () => {
        queryCache.setQueryData(CACHE_KEY_SUBSCRIPTION, prevSubscriptionState)
        queryCache.setQueryData(
          CACHE_KEY_UPCOMING_ORDER,
          prevUpcomingOrderState
        )
      }
    },
    async onSettled() {
      const currentPromise = settled.current
      await currentPromise

      // this can change in the interim
      if (currentPromise === settled.current) {
        // Overwrite optimistic updates
        queryCache.invalidateQueries(CACHE_KEY_SUBSCRIPTION)
        queryCache.invalidateQueries(CACHE_KEY_UPCOMING_ORDER)

        queue.current.length = 0
        settled.current = undefined
      }
    },
    onError(e: any, product, rollback: () => void) {
      captureNetworkError(e)
      rollback()

      showToast('error', {
        children: `We couldn't ${
          action === 'increment'
            ? `add ${product.description} to your box`
            : `remove ${product.description} from your box`
        }, please confirm the selections in your Account.`,
      })
    },
  })
}

export const mutateIncrementAddon = mutateAddons('increment')
export const mutateDecrementAddon = mutateAddons('decrement')
