import { Box, PseudoBox, PseudoBoxProps } from '@chakra-ui/core'
import { rem } from 'design'
import ModalCard, { IModalCard } from 'design/components/ModalCard/ModalCard'
import Radio from 'design/components/Radio/Radio'
import { H4 } from 'design/components/Typography/Typography'
import { getAllOptions } from 'design/helpers/getAllOptions'
import {
  radioKeyboardNavLeft,
  radioKeyboardNavRight,
} from 'design/helpers/radioKeyboardNav'
import { FieldProps, useFormikContext } from 'formik'
import { nanoid } from 'nanoid'
import React from 'react'

type IModalCardRadioFlag = { children: string }
export type IModalCardRadio = {
  BadgeComponent?: JSX.Element
  disabled?: boolean
  FlagComponent?: React.FC<IModalCardRadioFlag>
  labelProps?: IModalCard
  size: 'xs' | 'sm' | 'lg'
  learnMoreContent: any //TODO: proper type
  fieldName: string
  alignLabel?: string
  variant?: 'stacked' | 'inline'
} & FieldProps &
  IModalCard

const ModalCardRadio: React.FC<IModalCardRadio> = ({
  BadgeComponent,
  children,
  field,
  labelProps,
  size = 'lg',
  disabled = false,
  FlagComponent,
  learnMoreContent,
  fieldName,
  value,
  alignLabel,
  variant = 'inline',
  ...props
}) => {
  const ref = React.useRef(null)
  const { values, setFieldValue, submitForm } = useFormikContext()

  const currentSelectedValue = Object.values(values) //key can be different depending on form
  const isChecked = value === currentSelectedValue[0]

  const radioTop = (size) => {
    switch (size) {
      case 'lg':
        return rem(12)
      case 'sm':
        return undefined
      case 'xs':
        return rem(10)
    }
  }
  const inputId = `${nanoid()}-label`

  return (
    <ModalCard
      alignItems={size === 'sm' || size === 'xs' ? 'left' : 'center'}
      aria-disabled={disabled}
      as="div"
      checked={isChecked}
      cursor={disabled ? 'not-allowed' : 'pointer'} ///this is to get the first and last for accessibility without having to pass a bunch of refs around
      data-option
      data-what="radio"
      flexDirection="column"
      flexShrink={0}
      maxWidth={{ base: '100%', tablet: rem(348) }}
      minHeight={size === 'sm' ? rem(56) : rem(128)}
      onClick={(e) => {
        e.preventDefault() //this is to prevent clicks on the label from moving focus to the input
        setFieldValue(fieldName, value)
      }}
      onKeyDown={(e) => {
        if (e.key === ' ' || e.key === 'ArrowDown' || e.key === 'ArrowUp') {
          e.preventDefault()
          //this is to prevent the page form scrolling if a user hits spacebar to select the radio or is going through radio buttons using arrow keys (browser should still move a focused item in view)
        }
      }}
      onKeyUp={(e) => {
        const nodeArr = getAllOptions(ref)
        const currentIndex = Array.prototype.indexOf.call(nodeArr, ref.current)
        if (e.key === ' ') {
          setFieldValue(fieldName, value)
        } else if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
          radioKeyboardNavRight(nodeArr, currentIndex, setFieldValue)
        } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
          radioKeyboardNavLeft(nodeArr, currentIndex, setFieldValue)
        } else if (e.key === 'Enter') {
          submitForm()
        }
      }}
      p={rem(12)}
      ref={ref}
      tabIndex={0}
      {...labelProps}
    >
      {BadgeComponent && (
        <Box left={0} pos="absolute" top={0} transform="translate(-20%, -20%)">
          {BadgeComponent}
        </Box>
      )}
      <Box
        alignItems={alignLabel || 'center'}
        as="label"
        display="flex"
        flexDirection={variant === 'stacked' ? 'column' : 'row'}
        //@ts-ignore
        htmlFor={inputId}
        justifyContent={variant === 'stacked' ? undefined : 'space-between'}
        w="100%"
      >
        {children}

        <Radio
          as="div"
          disabled={disabled}
          disableTabIndex
          field={field}
          inputId={inputId}
          position={variant === 'stacked' ? 'absolute' : undefined}
          right={size === 'xs' ? rem(10) : rem(12)}
          size={size === 'xs' ? 'sm' : 'lg'}
          top={radioTop(size)}
          {...props}
        />
      </Box>
      {learnMoreContent}
      {disabled && FlagComponent}
    </ModalCard>
  )
}

export const Flag: React.FC<IModalCardRadioFlag & PseudoBoxProps> = ({
  children,
  ...props
}) => (
  <PseudoBox
    _before={{
      borderLeft: `${rem(4)} solid`,
      borderLeftColor: 'bb.crimson',
      borderTop: `${rem(4)} solid transparent`,
      content: `""`,
      display: 'block',
      position: 'absolute',
      bottom: rem(-4),
      right: rem(0),
      transform: 'rotate(90deg)',
    }}
    backgroundColor="bb.spicedCrimson"
    position="absolute"
    right={rem(-4)}
    top={rem(18)}
    {...props}
  >
    <H4 as="span" color="white" px={rem(16)} py={rem(4)}>
      {children}
    </H4>
  </PseudoBox>
)

export default ModalCardRadio
