import { Box, Button } from '@butcherbox/freezer'
import Form from 'design/components/Form/Form'
import Input from 'design/components/Input/Input'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { Field, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { trackNewsletterOptIn } from '~/analytics/events'
import axios from '~/utils/axios'

const FORM_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Please enter your email'),
})

const FooterNewsletterForm = () => {
  const showToast = React.useContext(ToastContext)
  return (
    <Box
      alignItems={{ mobile: 'center', desktop: 'flexStart' }}
      flexDirection="column"
      textAlign={{ mobile: 'center', desktop: 'left' }}
    >
      <Formik
        initialValues={{ email: '' }}
        onSubmit={async ({ email }) => {
          const formData = new FormData()
          formData.set('method', 'add_newsletter')
          formData.set('form', `newsletter_email=${encodeURIComponent(email)}`)
          try {
            const {
              data: { success },
            } = await axios.post(
              '/apiv2/ajax/ajax_footer_newsletter.php',
              formData
            )
            if (!success) {
              throw new Error('Could not join user to newsletter.')
            }

            trackNewsletterOptIn(email)

            showToast('success', {
              children: 'Thank you for joining our newsletter!',
            })
          } catch (err) {
            showToast('error', {
              children:
                "Sorry, we couldn't add you to the newsletter. Please try again later.",
            })
          }
        }}
        validationSchema={FORM_SCHEMA}
      >
        {({ isSubmitting, handleSubmit }) => {
          return (
            <Form
              aria-label="Join email newsletter form"
              onSubmit={handleSubmit}
              title="Join email newsletter form"
            >
              <Box alignItems="flexStart" flexDirection={{ desktop: 'column' }}>
                <Field
                  background="white"
                  color="bb.slate"
                  component={Input}
                  label="Email Address"
                  name="email"
                  type="email"
                />
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  marginTop={8}
                  size="fluid"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default FooterNewsletterForm
