import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  PseudoBox,
  PseudoBoxProps,
} from '@chakra-ui/core'
import { rem } from 'design'
import React from 'react'

export type ICard = {
  Button?: any
  Content?: any
  contentProps?: FlexProps
  Decoration?: any
  Image?: any
  imageProps?: BoxProps
  wrapperProps?: FlexProps
} & PseudoBoxProps

const makeCard = ({
  variant,
}: {
  variant: 'portrait' | 'landscape' | 'landscapeWide'
}) => {
  const orientation = ['landscape', 'landscapeWide'].includes(variant)
    ? 'landscape'
    : 'portrait'
  const wrapperProps =
    variant === 'landscape'
      ? {
          px: { tablet: rem(48), desktop: rem(64) },
          py: { tablet: rem(40), desktop: rem(60) },
        }
      : {}
  const imageProps = variant === 'landscape' ? { width: '50%' } : {}

  return React.forwardRef(
    (
      { Button, Content, contentProps, Decoration, Image, ...props }: ICard,
      ref
    ) => {
      return (
        <PseudoBox
          bg="#fff"
          color="bb.slate"
          d="flex"
          data-what="card"
          flexDirection={orientation === 'portrait' ? 'column' : 'row'}
          pos="relative"
          ref={ref}
          textAlign="center"
          width="100%"
          {...props}
        >
          <Flex
            flexDirection="column"
            flexGrow={1}
            flexShrink={1}
            justifyContent="space-between"
            order={1}
            overflow={orientation === 'landscape' ? 'hidden' : undefined}
            {...wrapperProps}
          >
            <Flex
              flexDirection="column"
              flexGrow={1}
              px={rem(16)}
              py={rem(20)}
              {...contentProps}
            >
              {Content}
            </Flex>

            {Button &&
              React.cloneElement(Button, {
                textAlign: orientation === 'landscape' ? 'left' : undefined,
              })}
          </Flex>

          {Decoration}
          {!!Image && (
            <Box flexShrink={0} pos="relative" {...imageProps}>
              {Image}
            </Box>
          )}
        </PseudoBox>
      )
    }
  )
}

export default makeCard({ variant: 'portrait' })

export const CardLandscape: React.FC<ICard> = makeCard({
  variant: 'landscape',
})

export const CardLandscapeWide: React.FC<ICard> = makeCard({
  variant: 'landscapeWide',
})
