import { PseudoBox } from '@chakra-ui/core'
import { rem } from 'design'
import { Info } from 'design/components/SVGs'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import { ModalTriggerProps } from './ListCard.types'

const ModalTrigger: React.FC<ModalTriggerProps> = (props) => (
  <PseudoBox
    _hover={{ bg: 'white' }}
    alignItems="center"
    as="button"
    bg="rgba(255, 255, 255, 0.75)"
    bottom={rem(8)}
    cursor="pointer"
    d="flex"
    data-cy={TEST_ID.MODAL_LISTING_CARD_INFO}
    h={rem(24)}
    justifyContent="center"
    pos="absolute"
    right={rem(8)}
    title={`More information about ${props.product.description}`}
    w={rem(24)}
    zIndex={1}
    {...props}
  >
    <Info />
  </PseudoBox>
)
export default ModalTrigger
