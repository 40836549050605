import React from 'react'
import { makeIcon } from 'design/components/Icons/Icons'
import { shortId } from 'design/utils'

export const def = () => {
  const iconIdSuffix = shortId()
  return {
    id: 'attentionGrabberWarning',
    name: 'Attention Grabber Warning',
    category: 'misc',
    path: (
      <>
        <path
          clipRule="evenodd"
          d="M20.5 33C28.508 33 35 26.508 35 18.5S28.508 4 20.5 4 6 10.492 6 18.5 12.492 33 20.5 33z"
          fill="#fff"
          fillRule="evenodd"
        />
        <g filter={`url(#attentionGrabberWarning${iconIdSuffix})`}>
          <path
            clipRule="evenodd"
            d="M20.5 31C27.404 31 33 25.404 33 18.5S27.404 6 20.5 6 8 11.596 8 18.5 13.596 31 20.5 31zM35 18.5C35 26.508 28.508 33 20.5 33S6 26.508 6 18.5 12.492 4 20.5 4 35 10.492 35 18.5z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </g>
        <path
          d="M19.787 22h1.426L22 12.804V11h-3v1.804L19.787 22zm-.702 5h2.83v-3h-2.83v3z"
          fill="currentColor"
        />
        <defs>
          <filter
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
            height="41"
            id={`attentionGrabberWarning${iconIdSuffix}`}
            width="41"
            x="0"
            y="0"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="3" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend
              in="SourceGraphic"
              in2="effect1_dropShadow"
              result="shape"
            />
          </filter>
        </defs>
      </>
    ),
    viewBox: '0 0 41 41',
  }
}

export const IconAttnGrabWarning = makeIcon(def())
export default IconAttnGrabWarning
