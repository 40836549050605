import React from 'react'
import * as Styles from './AlertPanel.css'
import Panel from '../index'
import { Box, BoxProps, IconWarningCircle } from '@butcherbox/freezer'

export function AlertPanel({ children, ...props }: BoxProps) {
  return (
    <Panel className={Styles.AlertPanel} {...props}>
      <Box className={Styles.Icon}>
        <IconWarningCircle customColor={{ base: 'error' }} size="small" />
      </Box>
      {children}
    </Panel>
  )
}
