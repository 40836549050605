import { css, Global } from 'design'
import React from 'react'
import AbrilFatfaceWOFF from '~/design/fonts/AbrilFatface-Regular.woff'
import AbrilFatfaceWOFF2 from '~/design/fonts/AbrilFatface-Regular.woff2'
import LatoRegularWOFF from '~/design/fonts/Lato-Regular.woff'
import LatoRegularWOFF2 from '~/design/fonts/Lato-Regular.woff2'
import LatoBoldWOFF from '~/design/fonts/Lato-Bold.woff'
import LatoBoldWOFF2 from '~/design/fonts/Lato-Bold.woff2'

/*
  Font Weights / Common Names

  100 Thin, Hairline
  200 Extra Light, Ultra Light
  300 Light
  400 Normal, Regular
  500 Medium
  600 Semi Bold, Demi Bold
  700 Bold
  800 Extra Bold, Ultra Bold
  900 Black, Heavy
*/

const AbrilFatfaceStyles = css`
  @font-face {
    font-family: 'Abril Fatface';
    font-weight: 400;
    font-style: 'normal';
    src: url('${AbrilFatfaceWOFF2}') format('woff2'),
      url('${AbrilFatfaceWOFF}') format('woff');
  }
`

const LatoStyles = css`
  @font-face {
    font-family: 'Lato';
    font-weight: 400;
    font-style: 'normal';
    src: url('${LatoRegularWOFF2}') format('woff2'),
      url('${LatoRegularWOFF}') format('woff');
  }
  @font-face {
    font-family: 'Lato';
    font-weight: 700;
    font-style: 'normal';
    src: url('${LatoBoldWOFF2}') format('woff2'),
      url('${LatoBoldWOFF}') format('woff');
  }
`

/**
 * Include this component on the page to ensure that the non-standard font
 * families are loaded.
 */
export const AbrilFatfaceFontLoader = (): JSX.Element => (
  <Global styles={AbrilFatfaceStyles} />
)
export const LatoFontLoader = (): JSX.Element => <Global styles={LatoStyles} />
