import React, { ReactNode } from 'react'
import {
  Box,
  FormControl,
  FormControlProps,
  Input as ChakraInput,
  InputGroup,
  InputRightAddon,
  Textarea as ChakraTextarea,
} from '@chakra-ui/core'
import { FieldProps } from 'formik'
import FormErrorMessage from 'design/components/FormErrorMessage/FormErrorMessage'
import { IconName } from 'design/components/Icons/types'
import { rem, shortId } from 'design/utils'
import { TEST_ID } from '~/constants/cypress'

const EXTENSION_NUDGE = 28
const INPUT_PADDING = 16

export type IInputProps = {
  _asTextArea?: boolean
  autoComplete?: string
  autoFocus?: boolean
  errors?: Array<{
    icon?: IconName
    text: string
  }>
  floatLabel?: boolean
  id?: string
  inputProps?: object
  label: string
  labelProps?: object
  maxLength?: number
  handleOnChange?: <T extends any>(value: T) => T | null
  required?: boolean
  resize?: 'none'
  errorSpace?: boolean
  rightAddonChildren?: ReactNode
  type?: HTMLInputElement['type']
} & FormControlProps &
  Partial<FieldProps>

const Input = ({
  _asTextArea = false,
  'aria-describedby': ariaDescribedBy,
  autoComplete,
  autoFocus,
  field,
  floatLabel = false,
  form,
  height,
  id,
  inputProps = {},
  label,
  labelProps = {},
  maxLength,
  required = false,
  resize = 'none',
  errorSpace,
  rightAddonChildren = null,
  handleOnChange = null,
  type = 'text',
  // We avoid using Chakra UI isFullWidth because it doesn't play nicely with
  // optionally setting the `width` attribute on an Input. Instead, we have the
  // default value for `width` be 100%, which is effectively what isFullWidth
  // implements. Then, we always pass `width` into the <Input> element.
  width = '100%',
  ...props
}: IInputProps): JSX.Element => {
  // TODO: Refactor this component to avoid the need to use ids to link label
  const internalId = React.useMemo(
    () => id || `input-${field.name}-${shortId()}`,
    [id, field.name]
  )

  const { onChange, ...fieldRest } = field

  /**
   * The Chakra Textarea component composes the Chakra Input component, so we
   * mirror that implementation in our own abstractions as well.
   */
  const InputType = _asTextArea ? ChakraTextarea : ChakraInput
  /**
   * When the text content is centered, ensure that we account for the 2px
   * vertical offset in our din-2014 font
   */
  const inputTopPadding = _asTextArea
    ? { pt: floatLabel && field.value ? '5dinOffset' : '1dinOffset' }
    : { pt: floatLabel && field.value ? '1.1875rem' : 'dinOffset' }
  const labelTopPadding = _asTextArea
    ? { pt: floatLabel && field.value ? '2dinOffset' : '1_5dinOffset' }
    : { pt: floatLabel && field.value ? '1dinOffset' : '3dinOffset' }

  const hasError = !!form.errors[field.name] && form.submitCount > 0
  const errorId = `${internalId}-error`

  return (
    <FormControl
      // bg="white"
      alignItems="flex-start"
      data-what={_asTextArea ? 'textarea-wrapper' : 'input-wrapper'}
      height={_asTextArea ? height : 'auto'}
      isInvalid={hasError}
      isRequired={required}
      minHeight={rem(48)}
      position="relative"
      width={width}
      {...props}
    >
      <Box
        aria-hidden
        as="label"
        background-color="#fff"
        color={
          !floatLabel && field.value
            ? 'transparent'
            : hasError
            ? 'ui.error'
            : 'bb.stone'
        }
        data-what={_asTextArea ? 'textarea-label' : 'input-label'}
        fontSize={floatLabel && field.value ? 'xs' : 'md'}
        // @ts-ignore
        htmlFor={internalId}
        left="0"
        pb="0"
        pl={rem(INPUT_PADDING)}
        position="absolute"
        transition={floatLabel && 'all 0.1s ease-in-out'}
        userSelect="none"
        zIndex={1}
        {...labelTopPadding}
        {...labelProps}
      >
        {label}
      </Box>
      <InputGroup height={_asTextArea ? height : rem(48)} width={width}>
        <InputType
          _focus={{ borderColor: 'bb.spicedCrimson' }}
          _hover={{ borderColor: 'bb.spicedCrimson' }}
          _invalid={{
            borderColor: 'ui.error',
          }}
          aria-describedby={`${ariaDescribedBy ? ariaDescribedBy : ''} ${
            hasError ? errorId : ''
          }`}
          aria-label={label}
          aria-placeholder={label}
          autoComplete={autoComplete}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          backgroundColor="white"
          border="1px"
          borderColor={'bb.stone'}
          borderSize="sm"
          css={{
            // nudge the right padding if an extension rendered a nugget on the input
            '&[style]': { paddingRight: rem(EXTENSION_NUDGE) },

            '&:-webkit-autofill': {
              // fixes the autofill yellow color that gets applied
              WebkitBackgroundClip: 'text',
            },

            '&::-webkit-autofill, &::-webkit-contacts-auto-fill-button, &::-webkit-credentials-auto-fill-button': {
              right: rightAddonChildren ? rem(34) : rem(INPUT_PADDING),
              position: 'absolute',
            },
          }}
          data-what={_asTextArea ? 'textarea' : 'input'}
          focusBorderColor="Highlight"
          fs="md"
          // @ts-ignore https://github.com/chakra-ui/chakra-ui/issues/205
          height={_asTextArea ? height : rem(48)}
          id={internalId}
          lineHeight="taller4x"
          maxLength={maxLength}
          onChange={(evt) => {
            // transform value if able
            evt.target.value =
              typeof handleOnChange === 'function'
                ? handleOnChange(evt.target.value)
                : evt.target.value

            onChange(evt)
          }}
          outlined
          pl={rem(INPUT_PADDING)}
          resize={resize}
          rounded="none"
          type={!_asTextArea ? type : undefined}
          // @ts-ignore https://github.com/chakra-ui/chakra-ui/issues/205
          width={width}
          {...inputTopPadding}
          {...fieldRest}
          {...inputProps}
        />

        {rightAddonChildren && (
          <InputRightAddon
            backgroundColor="transparent" // Override Chakra defaults
            border="transparent" // Override Chakra defaults
            // Override Chakra hiding of InputRightAddon when focusing the
            // sibling Input.
            // cf: https://github.com/chakra-ui/chakra-ui/issues/300
            // @ts-ignore `css` is not a public prop but we need it here
            bottom={_asTextArea ? rem(INPUT_PADDING) : undefined}
            css={{
              '::-ms-clear': {
                display: 'none',
              },
              // nudge the right padding if an extension rendered a nugget on the input
              'input[style] ~ &': {
                paddingRight: rem(14),
              },
            }} // Override Chakra defaults
            height="auto"
            pl="0"
            position="absolute" // Override Chakra defaults
            pr="0"
            right={rem(INPUT_PADDING)}
            top={_asTextArea ? undefined : '50%'}
            transform={_asTextArea ? undefined : 'translateY(-50%)'}
            zIndex={0}
          >
            {rightAddonChildren}
          </InputRightAddon>
        )}
      </InputGroup>
      {hasError && (
        <FormErrorMessage
          data-cy={
            _asTextArea ? TEST_ID.INPUT_TEXT_AREA_ERROR : TEST_ID.INPUT_ERROR
          }
          data-what={_asTextArea ? 'textarea-error' : 'input-error'}
          hasError={hasError}
          id={errorId}
          mt={rem(4)}
          pb={rem(4)}
          pos="relative"
          textAlign="left"
          zIndex={1}
        >
          {form.errors[field.name]}
        </FormErrorMessage>
      )}
      {!hasError && errorSpace && (
        <div
          style={{
            fontSize: rem(12),
            marginTop: rem(4),
            minHeight: rem(20),
          }}
        >
          {' '}
        </div>
      )}
    </FormControl>
  )
}

export default Input
