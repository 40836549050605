import React from 'react'
import useCmsImageSet from '~/hooks/useCmsImageSet'
import type * as Type from './ImageSetBackground.types'
import Img from 'gatsby-image'
import * as Styles from './ImageSetBackground.css'
import clsx from 'clsx'
import { GatsbyImage } from 'gatsby-plugin-image'
import useCmsAtomicImageSet from '~/hooks/useCmsAtomImageSet'
/**
 * An background image set returns a gatsby-image that is rendered
 * from the contentful ImageSet content type.
 */
export default function ImageSetBackground({
  imageSet,
  imageSetSizes = { d: 1, t: 1, m: 1 },
  alt,
  height,
  children,
  imageStyle = {
    objectFit: 'cover',
    objectPosition: '50% 50%',
  },
  breakpoints,
  fullWidth = false,
  className,
  style,
  ...props
}: Type.ImageSetBackgroundProps) {
  const sources = useCmsImageSet(
    imageSet,
    imageSetSizes,
    fullWidth,
    breakpoints
  )
  const atomSources = useCmsAtomicImageSet(imageSet)

  return (
    <div
      className={clsx(Styles.container, className)}
      style={{ height: height, ...style }}
      {...props}
    >
      {imageSet.__typename === 'ContentfulImageSet' && (
        <Img
          alt={alt ? alt : imageSet.imageAlt}
          className={Styles.image.legacy}
          fluid={sources}
          imgStyle={imageStyle}
          // *** absolute position is *also* applied via a style tag
          // because gatsby applies relative via a style tag
          // this avoids !important
          style={{ position: 'absolute' }}
        />
      )}
      {imageSet.__typename === 'ContentfulAtomImageSet' && (
        <GatsbyImage
          alt={alt ? alt : imageSet.imageAlt}
          className={Styles.image.atomImage}
          image={atomSources}
          imgClassName={Styles.imageSetSprinkles({
            objectPosition: {
              mobile: imageSet.mobileImage.focalPoint,
              tablet: imageSet.tabletImage
                ? imageSet.tabletImage.focalPoint
                : undefined,
              desktop: imageSet.desktopImage
                ? imageSet.desktopImage.focalPoint
                : undefined,
            },
          })}
          // *** absolute position is *also* applied via a style tag
          // because gatsby applies relative via a style tag
          // this avoids !important
          style={{ position: 'absolute' }}
        />
      )}
      <div className={Styles.content}>{children}</div>
    </div>
  )
}
