import { VisuallyHidden, Box } from '@chakra-ui/core'
import { rem, shortId } from 'design'
import Card, { CardLandscape } from 'design/components/Card/Card'
import { CardHTMLImage } from 'design/components/CardImage/CardImage'
import { Body, H2, H3 } from 'design/components/Typography/Typography'
import { graphql, useStaticQuery } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import React from 'react'
import useVisibleProduct from '~/hooks/useVisibleProduct'
import { formatPriceFromCents } from '~/utils'
import { CardImageStatus, QuantityBadge } from '.'
import type * as Types from './ListCard.types'

const DEAL_CARD_IMAGE_HEIGHT = 212

//TODO this should be replaced with the Freezer Card when the experiment is made permanent
// use DealGridLayout.tsx
export function DealListingCard({
  renderButton,
  Decoration,
  product,
  quantity,
  variant = 'tall',
  ...props
}: Types.DealListingCardProps) {
  const ariaLabel = React.useMemo(() => shortId(), [])
  const imageData = useStaticQuery(graphql`
    query {
      soldOut: file(relativePath: { eq: "soldout.png" }) {
        childImageSharp {
          fluid(maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const dealCardRef = useVisibleProduct(product, { threshold: 0.5 })

  const {
    SubheadComponent,
    imageHeight,
    contentProps,
    display,
    CardComponent,
  } = ((): Types.CardVariationProps => {
    switch (variant) {
      case 'tall':
        return {
          CardComponent: Card,
          imageHeight: rem(DEAL_CARD_IMAGE_HEIGHT),
          SubheadComponent: H2,
        }
      case 'wide':
        return {
          CardComponent: CardLandscape,
          imageHeight: '100%',
          SubheadComponent: H3,
          display: 'flex',
          contentProps: { flex: '1 1 50%', p: 0 },
        }
      default: {
        const guard: never = variant // exhaustiveness check
        return guard
      }
    }
  })()

  return (
    <CardComponent
      aria-describedby={ariaLabel}
      Button={renderButton({ withinInfoModal: false })}
      Content={
        <>
          <H2 as="h3" id={ariaLabel} textAlign="left">
            {product.description}
          </H2>

          <SubheadComponent
            as="h4"
            color="bb.spicedCrimson"
            mb={rem(9)}
            mt={rem(-1)}
            textAlign="left"
          >
            {product.shouldRedirect ? (
              <VisuallyHidden>
                Price:&nbsp;{formatPriceFromCents(product.price)}
              </VisuallyHidden>
            ) : (
              <Box as="span">
                <VisuallyHidden>Price:&nbsp;</VisuallyHidden>
                {formatPriceFromCents(product.price)}
              </Box>
            )}
          </SubheadComponent>

          <Body
            dangerouslySetInnerHTML={{ __html: product.fullDescription }}
            textAlign="left"
          />
        </>
      }
      contentProps={contentProps}
      Decoration={Decoration}
      ref={dealCardRef}
      {...(display ? { display } : {})}
      Image={
        <>
          {product.isSoldOut ? (
            <GatsbyImage
              fluid={imageData.soldOut?.childImageSharp.fluid}
              style={{ height: imageHeight }}
            />
          ) : (
            <CardHTMLImage
              height={imageHeight}
              href={product.image}
              role="presentation"
            />
          )}

          <QuantityBadge product={product} quantity={quantity} />

          <CardImageStatus
            isUnavailable={product.isUnavailable}
            quantity={quantity}
          />
        </>
      }
      {...props}
    />
  )
}

export default DealListingCard
