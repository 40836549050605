import { css } from 'design'

export default css`
  strong {
    font-weight: 700;
  }

  em {
    font-family: Lato, Times, serif;
    font-style: italic;
  }

  /**
    * In firefox, disable the browser-injected red box shadow around input elements
    * that are required, visited, and blurred without a valid value.
    */
  input:required {
    box-shadow: none;
  }
  ul,
  ol {
    list-style: none;
  }
`
