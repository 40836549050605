import { Box, IconWarningCircle } from '@butcherbox/freezer'
import { IconSize } from '@butcherbox/freezer/dist/components/icons/icon.types'
import React from 'react'

interface FormErrorIconProps {
  size: IconSize
}
const FormErrorIcon = ({ size }: FormErrorIconProps) => {
  return (
    <Box marginRight={8} marginTop={4}>
      <IconWarningCircle
        aria-hidden
        customColor={{ base: 'error' }}
        display={'inline'}
        marginLeft={4}
        size={size}
        verticalAlign="text-top"
      />
    </Box>
  )
}

export default FormErrorIcon
