import { Box } from '@chakra-ui/core'
import { rem, styled } from 'design'

export const ModalFooter = styled(Box)`
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
  padding: ${rem(12)};
  text-align: center;
  & > button {
    min-width: ${rem(160)};
  }
`
