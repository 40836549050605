//  https://www.w3.org/TR/2017/WD-wai-aria-practices-1.1-20170628/examples/radio/radio-1/radio-1.html

/**
 * replicates native radio button keyboard navigation when user hits right or down arrow.
 * @param {NodeListOf<Element>} nodeArr - getAllOptions(ref) -- gets all surrounding options.
 * @param {number} currentIndex - index of ref.current in nodeArr.
 * @param {function} setFieldValue -  function from useFormikContext to update the selected field
 */

export const radioKeyboardNavRight = (
  nodeArr: NodeListOf<Element>,
  currentIndex: number,
  setFieldValue: (type: string, value: string) => void
) => {
  if (currentIndex !== nodeArr.length - 1) {
    //making sure we're not at the end of the options
    const nextElement = nodeArr[currentIndex + 1] as HTMLElement
    const nextField = nextElement.querySelector('input')
    setFieldValue(nextField.getAttribute('name'), nextField.value)

    nextElement.focus()
  } else {
    //if at the end, we want to automatically loop back to the start.
    const firstElement = nodeArr[0] as HTMLElement
    const firstField = firstElement.querySelector('input')
    setFieldValue(firstField.getAttribute('name'), firstField.value)

    firstElement.focus()
  }
  return
}

/**
 * replicates native radio button keyboard navigation when user hits left or up arrow.
 * @param {NodeListOf<Element>} nodeArr - getAllOptions(ref) -- gets all surrounding options.
 * @param {number} currentIndex - index of ref.current in nodeArr.
 * @param {function} setFieldValue -  function from useFormikContext to update the selected field
 */
export const radioKeyboardNavLeft = (
  nodeArr: NodeListOf<Element>,
  currentIndex: number,
  setFieldValue: (type: string, value: string) => void
) => {
  if (currentIndex !== 0) {
    const prevElement = nodeArr[currentIndex - 1] as HTMLElement
    const prevField = prevElement.querySelector('input')
    setFieldValue(prevField.getAttribute('name'), prevField.value)
    prevElement.focus()
  } else {
    const lastElement = nodeArr[nodeArr.length - 1] as HTMLElement
    const lastInput = lastElement.querySelector('input')
    setFieldValue(lastInput.getAttribute('name'), lastInput.value)

    lastElement.focus()
  }
  return
}
