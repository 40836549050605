import type { BoxProps } from '@chakra-ui/core'
import React from 'react'
import * as Styled from 'design/components/Modal/ModalFooter.styles'
/**
 * This component is optional if the modal design calls for it.
 *
 * ```jsx
 * <Modal onClose={eventHandler}>
 *   <ModalDialog>
 *     <ModalHeader>Something</ModalHeader>
 *
 *     <ModalContent>
 *       Arbitrary content
 *     </ModalContent>
 *
 *     <ModalFooter>
 *       {SaveButton}
 *     </ModalFooter>
 *   </ModalDialog>
 * </Modal>
 * ```
 */
const ModalFooter = ({ children, ...props }: BoxProps) => {
  return (
    <Styled.ModalFooter data-what="modal-footer" {...props}>
      {children}
    </Styled.ModalFooter>
  )
}

export default ModalFooter
