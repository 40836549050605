import get from 'dlv'
import { useContext } from 'react'
import { useQuery } from 'react-query'
import { CACHE_KEY_LATEST_SHIPMENT } from '~/bb-api/constants'
import { GET_LATEST_SHIPMENT } from '~/bb-api/endpoints'
import { Shipment } from '~/bb-api/schemata'
import { SubscriptionContextRemote } from '~/context/subscriptionRemote'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'

export default function useLatestShipment() {
  const user = useContext(UserContext)
  const { subscription } = useContext(SubscriptionContextRemote)

  const userId = get(user, 'id')
  const subscriptionId = get(subscription, 'id')
  const endPoint = GET_LATEST_SHIPMENT()(userId, subscriptionId)

  const shouldQuery =
    !!userId && !!subscriptionId && subscription.status !== 'canceled'

  return useQuery(
    CACHE_KEY_LATEST_SHIPMENT,
    () => axios.get<Shipment>(endPoint).then((response) => response.data),
    {
      enabled: shouldQuery,
      staleTime: 10 * 60 * 1000, // 10 minutes
    }
  )
}
