import { IconLogo } from '@butcherbox/freezer'
import { Box } from '@chakra-ui/core'
import { rem } from 'design/utils'
import React from 'react'
import IconButcherBoxLine from '../Icons/defs/ButcherBoxLine'

const TRADEMARK_ICON_SIZE_SCALE = 0.054054054054054
const TRADEMARK_ICON_OFFSET_SCALE = 0.014414414414414
const LOGO_ASPECT_RATIO = 0.333333333333333

type IButcherBoxLogo = {
  /**
   * number as pixels is required in order to scale the trademark symbol
   *
   * ! IMPORTANT: Do not use rem() here, just pass the pixel value instead.
   */
  width: number | number[]
}

/**
 * Renders the ButcherBox logo icon with a trademark symbol that does not interfere with layout
 *
 * TODO: Enable rem() usage for width and height by parsing the pixel value from a rem string
 */
export default function ButcherBoxLogo({
  width,
  ...props
}: IButcherBoxLogo &
  Omit<
    React.ComponentProps<typeof IconButcherBoxLine>,
    'name' | 'width' | 'height'
  >) {
  const { finalWidth, finalHeight } = React.useMemo<{
    offset: string | string[]
    size: string | string[]
    finalWidth: string | string[]
    finalHeight: string | string[]
  }>(() => {
    switch (true) {
      case Array.isArray(width):
        return (width as number[]).reduce<{
          offset: string[]
          size: string[]
          finalWidth: string[]
          finalHeight: string[]
        }>(
          ({ offset, size, finalWidth, finalHeight }, val) => ({
            offset: [...offset, rem(-val * TRADEMARK_ICON_OFFSET_SCALE)],
            size: [...size, rem(val * TRADEMARK_ICON_SIZE_SCALE)],
            finalWidth: [...finalWidth, rem(val)],
            finalHeight: [...finalHeight, rem(val * LOGO_ASPECT_RATIO)],
          }),
          { offset: [], size: [], finalWidth: [], finalHeight: [] }
        )
      case Boolean(width):
        return {
          offset: rem(-(width as number) * TRADEMARK_ICON_OFFSET_SCALE),
          size: rem((width as number) * TRADEMARK_ICON_SIZE_SCALE),
          finalWidth: rem(width as number),
          finalHeight: rem((width as number) * LOGO_ASPECT_RATIO),
        }
      default:
        // Arbitrary, safe defaults based on logo width of 111 pixels
        return {
          size: rem(6),
          offset: rem(-1.6),
          finalHeight: rem((width as number) * LOGO_ASPECT_RATIO),
          finalWidth: rem(width as number),
        }
    }
  }, [width])

  return (
    <Box
      style={{ height: `${finalHeight}`, width: `${finalWidth}` }}
      {...props}
      display={'flex'}
    >
      <IconLogo customColor={{ base: 'slate' }} size="scale" />
    </Box>
  )
}
