import { Button, ButtonProps } from '@chakra-ui/core'
import { Link } from '@reach/router'
import { rem, useTheme } from 'design'
import ButtonToast from 'design/components/Button/ButtonToast'
import {
  LinkExternal,
  LinkInternal,
} from 'design/components/Typography/Typography'
import React from 'react'
import { LiveMessage } from 'react-aria-live'

// Chakra's typings are a little off here, non-strings are fine
interface OverriddenChakraButtonProps extends ButtonProps {
  loadingText?: any
}

export type IButtonProps = {
  error?: string
  size?: 'sm' | 'lg'
  'data-what'?: string
  children?: any
  innerRef?:
    | React.RefObject<HTMLButtonElement>
    | React.RefObject<HTMLLinkElement>
    | React.RefObject<HTMLElement>
    | ((instance: HTMLButtonElement) => void)
    | React.MutableRefObject<HTMLButtonElement>
    | React.MutableRefObject<HTMLLinkElement>
    | React.MutableRefObject<HTMLElement>
} & Omit<OverriddenChakraButtonProps, 'as' | 'children'>
const ChakraButton = (Button as unknown) as React.FC<OverriddenChakraButtonProps>

const Y_OFFSET = 2
const LARGE_BUTTON_HEIGHT = 48 - Y_OFFSET
const SMALL_BUTTON_HEIGHT = 40 - Y_OFFSET
const SECONDARY_BUTTON_BORDER_WIDTH = 2
const SHOW_ERROR_DURATION_MS = 5000

type Linkable<
  LinkType extends 'a' | typeof LinkExternal | typeof LinkInternal | typeof Link
> = { as: LinkType } & React.ComponentProps<LinkType> & IButtonProps

//we're doing something incredibly hacky to make typescript happy; we are passing the ref as an innerRef prop.
export function ButtonPrimary(props: Linkable<'a'>): JSX.Element
export function ButtonPrimary(props: Linkable<typeof LinkExternal>): JSX.Element
export function ButtonPrimary(props: Linkable<typeof LinkInternal>): JSX.Element
export function ButtonPrimary(props: Linkable<typeof Link>): JSX.Element
export function ButtonPrimary(props: IButtonProps): JSX.Element
export function ButtonPrimary({
  error = '',
  _disabled,
  _hover,
  innerRef,
  isLoading,
  size = 'lg',
  children,
  ...props
}: any): any {
  const loadingProps = React.useMemo(
    () =>
      isLoading
        ? ({
            'aria-busy': true,
            'aria-live': 'assertive',
            'aria-valuetext': 'Loading...',
          } as const)
        : {},
    [isLoading]
  )

  const [showError, setShowError] = React.useState(false)
  const handleRef = React.useRef<number>(undefined)
  const [buttonFocused, setButtonFocused] = React.useState(false)

  React.useEffect(() => {
    if (error && buttonFocused) {
      setShowError(true)
      if (handleRef.current) window.clearTimeout(handleRef.current)
      handleRef.current = window.setTimeout(() => {
        handleRef.current = null
        setShowError(false)
      }, SHOW_ERROR_DURATION_MS)
    }
  }, [error, buttonFocused])

  // Cleanup function in separate effect to prevent it from running continuously
  // when dependencies change
  React.useEffect(() => () => window.clearTimeout(handleRef.current), [])

  return (
    <ChakraButton
      _disabled={{
        // Chakra couples loading styles to disabled styles by default, need to conditionally override
        bg: isLoading ? 'bb.spicedCrimson' : 'ui.inactive',
        color: isLoading ? 'white' : 'bb.spicedCrimson',
        cursor: isLoading ? 'wait' : 'not-allowed',
        ..._disabled,
      }}
      _hover={{ bg: 'bb.crimson', ..._hover }}
      bg="bb.spicedCrimson"
      borderRadius="0"
      color={'white'}
      data-what="button"
      fontFamily="Lato"
      fontSize={rem(12)}
      fontWeight="bold"
      height="auto"
      isLoading={isLoading}
      letterSpacing={rem(0.5)}
      lineHeight={
        size === 'lg' ? rem(LARGE_BUTTON_HEIGHT) : rem(SMALL_BUTTON_HEIGHT)
      }
      loadingText={children}
      onBlur={() => setButtonFocused(false)}
      onFocus={() => setButtonFocused(true)}
      onPointerDown={() => setButtonFocused(true)}
      onPointerUp={() => setButtonFocused(false)}
      paddingTop={rem(Y_OFFSET)} // optical centering
      px={rem(16)}
      ref={innerRef}
      textAlign="center"
      textDecoration="none !important"
      textTransform="uppercase"
      whiteSpace="nowrap"
      {...loadingProps}
      {...props}
    >
      {children}
      {error && showError && (
        <ButtonToast duration={SHOW_ERROR_DURATION_MS} error={error} />
      )}
    </ChakraButton>
  )
}

export const LargeButtonPrimary: typeof ButtonPrimary = (props) => (
  <ButtonPrimary {...props} size="lg" />
)

export const SmallButtonPrimary: typeof ButtonPrimary = (props) => (
  <ButtonPrimary {...props} size="sm" />
)

export function ButtonSecondary(props: Linkable<'a'>): JSX.Element
export function ButtonSecondary(
  props: Linkable<typeof LinkExternal>
): JSX.Element
export function ButtonSecondary(
  props: Linkable<typeof LinkInternal>
): JSX.Element
export function ButtonSecondary(props: Linkable<typeof Link>): JSX.Element
export function ButtonSecondary(props: IButtonProps): JSX.Element
export function ButtonSecondary(props: any): any {
  return (
    <ButtonPrimary
      _disabled={{
        bg: props.isLoading ? 'white' : 'ui.inactive',
        borderColor: 'bb.spicedCrimson',
        color: 'bb.spicedCrimson',
        cursor: props.isLoading ? 'wait' : 'not-allowed',
      }}
      _hover={{
        bg: 'bb.crimson',
        borderColor: 'bb.crimson',
        color: 'white',
      }}
      bg="white"
      borderColor="bb.spicedCrimson"
      borderStyle="solid"
      borderWidth={SECONDARY_BUTTON_BORDER_WIDTH}
      color="bb.spicedCrimson"
      lineHeight={
        props.size === 'lg'
          ? rem(LARGE_BUTTON_HEIGHT - SECONDARY_BUTTON_BORDER_WIDTH * 2)
          : rem(SMALL_BUTTON_HEIGHT - SECONDARY_BUTTON_BORDER_WIDTH * 2)
      }
      {...props}
    />
  )
}

export const LargeButtonSecondary: typeof ButtonSecondary = (props) => (
  <ButtonSecondary {...props} size="lg" />
)

export const SmallButtonSecondary: typeof ButtonSecondary = (props) => (
  <ButtonSecondary {...props} size="sm" />
)

export type IProgressButton = {
  completeText?: string
  currentCount: number
  totalCount: number
} & Omit<ButtonProps, 'size' | 'children'>

export const ProgressButton: React.FC<IProgressButton> = ({
  completeText = 'Buy Now',
  currentCount,
  totalCount,
  ...props
}) => {
  const progress = currentCount > 0 ? (currentCount / totalCount) * 100 : 0
  const disabled = progress !== 100 || currentCount > totalCount
  const theme = useTheme()

  const gradient = React.useMemo(
    () =>
      `#fff linear-gradient(to right, ${theme.colors.ui.inactive}, ${theme.colors.ui.inactive} ${progress}%, transparent ${progress}%, transparent 100%)`,
    [progress, theme.colors.ui.inactive]
  )

  const text = disabled
    ? `${currentCount} of ${totalCount} selected`
    : completeText

  return (
    <ButtonPrimary
      _disabled={{
        background: gradient,
        color: 'bb.spicedCrimson',
        cursor: props.isLoading ? 'wait' : 'not-allowed',
      }}
      _hover={{ background: gradient, color: 'bb.spicedCrimson' }}
      borderColor="bb.spicedCrimson"
      borderStyle="solid"
      borderWidth={SECONDARY_BUTTON_BORDER_WIDTH}
      isDisabled={disabled}
      lineHeight={rem(LARGE_BUTTON_HEIGHT - SECONDARY_BUTTON_BORDER_WIDTH * 2)}
      size="lg"
      {...props}
    >
      <LiveMessage aria-live="polite" message={props.title || text} />
      {text}
    </ButtonPrimary>
  )
}

export const TextButton: typeof ButtonSecondary = (props) => (
  <ButtonSecondary
    _hover={{ border: 'none', color: 'bb.crimson' }}
    bg="transparent !important"
    border="none"
    lineHeight={rem(16)}
    {...props}
  />
)
