import {
  Modal,
  Box,
  ModalBody,
  ModalFooter,
  Button,
  IconLoadingSpinner,
} from '@butcherbox/freezer'
import ModalCardRadio, {
  Flag,
  IModalCardRadio,
} from 'design/components/ModalCardRadio/ModalCardRadio'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { Field, Form, Formik } from 'formik'
import { navigate } from 'gatsby'
import React, { useContext } from 'react'
import { BoxType, BoxTypeDefinition } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import { SubscriptionContext } from '~/context/subscription'
import { mutateSubscription } from '~/hooks/mutateSubscription'
import useBoxTypes from '~/hooks/useBoxTypes'
import useOrders from '~/hooks/useOrders'
import BoxVariants from '~/utils/boxVariants'
import BoxSettingsModalContentWrapper from './BoxSettingsModalContentWrapper'
import * as Styles from './EditBoxTypeModal.css'

type IEditBoxTypeModal = {
  handleModalClose: () => void
}

const RadioCardBoxType = ModalCardRadio as React.FC<
  {
    value: BoxType
  } & IModalCardRadio
>

const EditBoxTypeModal: React.FC<IEditBoxTypeModal> = ({
  handleModalClose,
}) => {
  const { subscription } = useContext(SubscriptionContext)
  const { data: orders = [], status: isLoading = '' } = useOrders({ limit: 3 })
  const showToast = useContext(ToastContext)
  const { data, status } = useBoxTypes()
  const [updateSubscription] = mutateSubscription('account_pages')

  const shouldShowBoxType = React.useCallback(
    (boxType: BoxType) => {
      if (subscription.box.type === boxType) return true
      return orders.some((order) => {
        return order.box.type === boxType
      })
    },
    [subscription, orders]
  )
  const hooksStatus = React.useMemo(() => {
    if (status === 'success' && isLoading !== 'loading') return 'success'
    if (status === 'error') return 'error'
    return 'loading'
  }, [status, isLoading])

  const boxProducts = ((hooksStatus) => {
    switch (hooksStatus) {
      case 'loading':
        return (
          <Box display="flex" justifyContent="center" marginTop={40}>
            <IconLoadingSpinner />
          </Box>
        )
      case 'error':
        return 'error'
      case 'success':
      default:
        return (
          <Box className={Styles.GridContainer} component="fieldset">
            {data
              ?.filter((x: BoxTypeDefinition) => {
                return x.type === 'all_beef'
                  ? shouldShowBoxType('all_beef')
                  : x.isVisible ||
                      (shouldShowBoxType('basic_beef_chicken_pork') &&
                        x.type === 'basic_beef_chicken_pork')
              })
              .sort((a, b) =>
                // Show non-disabled items first
                a.isDisabled === b.isDisabled ? 0 : a.isDisabled ? 1 : -1
              )
              .map(({ name, type, isDisabled }, index, arr) => {
                const isLastOddElement =
                  index === arr.length - 1 && !!((index + 1) % 2)
                const { render } = BoxVariants[type]
                return (
                  <Field
                    component={RadioCardBoxType}
                    data-cy={TEST_ID.MODAL_OPTION}
                    disabled={isDisabled}
                    fieldName="type"
                    FlagComponent={
                      isDisabled ? <Flag>Sold Out</Flag> : undefined
                    }
                    key={name}
                    labelProps={
                      isLastOddElement && {
                        gridColumn: { base: 'auto', tablet: 'span 2' },
                        justifySelf: 'center',
                      }
                    }
                    name="type"
                    type="radio"
                    value={type}
                    variant="stacked"
                  >
                    {render({ name, isDisabled })}
                  </Field>
                )
              })}
          </Box>
        )
    }
  })(hooksStatus)

  return (
    <Modal
      data-what="edit-box-type-modal"
      onClose={handleModalClose}
      size="fit"
      title="Change plan"
    >
      <Formik
        initialValues={{ type: subscription.box.type }}
        onSubmit={({ type }) => {
          if (type === 'cst' && subscription.box.type !== 'cst') {
            // Changing the box type to a custom box must be done using a
            // different API which also includes the selected box items.
            return navigate('/account/customize-your-box')
          } else if (type === subscription.box.type) {
            // If user saves the same selection they already had, just close modal
            return handleModalClose()
          }

          const updatedSubscription = {
            ...subscription,
            box: {
              ...subscription.box,
              type,
              items: [], // Assumes curated box
            },
          }

          return updateSubscription(updatedSubscription, {
            onSuccess: () => {
              handleModalClose()
              showToast('success', {
                children: 'Successfully updated box type',
              })
            },
            onError: () => {
              handleModalClose()
              showToast('error', {
                children:
                  'There was a problem updating your box type. Please try again or contact customer support for help.',
              })
            },
          })
        }}
      >
        {({ isSubmitting, handleSubmit }) => {
          return (
            <Box
              className={Styles.Form}
              component={Form}
              data-cy={TEST_ID.MODAL_BOX_TYPE}
              //@ts-ignore
              noValidate
              onSubmit={handleSubmit}
            >
              <BoxSettingsModalContentWrapper>
                <ModalBody>{boxProducts}</ModalBody>
                <ModalFooter>
                  <Button
                    data-cy={TEST_ID.MODAL_SUBMIT}
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    size="small"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </ModalFooter>
              </BoxSettingsModalContentWrapper>
            </Box>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default EditBoxTypeModal
