import dayjs, { Dayjs } from 'dayjs'
import { ENVIRONMENT, getEnvironment } from './env'

export const getCookieDomain = function () {
  const env = getEnvironment(window.location)
  let cookieDomain = ''

  if (env === ENVIRONMENT.PRODUCTION) {
    cookieDomain = 'butcherbox.com'
  }

  if (env === ENVIRONMENT.STAGING) {
    cookieDomain = 'staging.bboxtools.net'
  }

  // this condition only for deploy preview testing
  if (
    env === ENVIRONMENT.STAGING &&
    window.location.hostname.endsWith('netlify.app')
  ) {
    cookieDomain = window.location.hostname
  }

  if (env === ENVIRONMENT.DEV) {
    cookieDomain = 'local.bboxtools.net'
  }

  return cookieDomain
}

export const setCookie = function (
  name: string,
  value: string = '',
  date: Dayjs = dayjs().add(1, 'd')
) {
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; domain=${getCookieDomain()}; expires=${date.toString()}; path=/`
}
