export enum TEST_ID {
  ACCOUNT_ADDONS = 'ACCOUNT_ADDONS',
  ACCOUNT_BOX_SETTINGS = 'ACCOUNT_BOX_SETTINGS',
  ACCOUNT_BROWSE_RECIPES_HEADER = 'ACCOUNT_BROWSE_RECIPES_HEADER',
  ACCOUNT_CANCELED = 'ACCOUNT_CANCELED',
  ACCOUNT_CREDITS = 'ACCOUNT_CREDITS',
  ACCOUNT_CUSTOMIZE_YOUR_BOX = 'ACCOUNT_CUSTOMIZE_YOUR_BOX',
  ACCOUNT_DEALS = 'ACCOUNT_DEALS',
  ACCOUNT_HELP = 'ACCOUNT_HELP',
  ACCOUNT_MEMBER_FEEDBACK_FORM = 'ACCOUNT_MEMBER_FEEDBACK_FORM',
  ACCOUNT_MEMBER_FEEDBACK_FORM_SUBMITTED = 'ACCOUNT_MEMBER_FEEDBACK_FORM_SUBMITTED',
  ACCOUNT_MEMBERSHIP_REVIEW_BACKGROUND = 'ACCOUNT_MEMBERSHIP_REVIEW_BACKGROUND',
  ACCOUNT_MEMBERSHIP_REVIEW_FILTER_LABEL = 'ACCOUNT_MEMBERSHIP_REVIEW_FILTER_LABEL',
  ACCOUNT_MEMBERSHIP_REVIEW_FILTER_QUANTITY = 'ACCOUNT_MEMBERSHIP_REVIEW_FILTER_QUANTITY',
  ACCOUNT_MEMBERSHIP_REVIEW_FILTER_WEIGHT = 'ACCOUNT_MEMBERSHIP_REVIEW_FILTER_WEIGHT',
  ACCOUNT_MEMBERSHIP_REVIEW_FULL_CUT_LIST = 'ACCOUNT_MEMBERSHIP_REVIEW_FULL_CUT_LIST',
  ACCOUNT_MEMBERSHIP_REVIEW_NO_STATS_FOUND = 'ACCOUNT_MEMBERSHIP_REVIEW_NO_STATS_FOUND',
  ACCOUNT_MEMBERSHIP_REVIEW_RECIPE = 'ACCOUNT_MEMBERSHIP_REVIEW_RECIPE',
  ACCOUNT_ORDER_HISTORY = 'ACCOUNT_ORDER_HISTORY',
  ACCOUNT_PROFILE_PAYMENT = 'ACCOUNT_PROFILE_PAYMENT',
  ACCOUNT_WELCOME_GUIDE_HEADER = 'ACCOUNT_WELCOME_GUIDE_HEADER',
  ACCOUNT_YOUR_BOX_HEADER = 'ACCOUNT_YOUR_BOX_HEADER',
  ACCOUNT_YOUR_BOX_HERO = 'ACCOUNT_YOUR_BOX_HERO',
  BOX_SETTINGS_SECTION_BOX_DATE = 'BOX_SETTINGS_SECTION_BOX_DATE',
  BOX_SETTINGS_SECTION_FREQUENCY = 'BOX_SETTINGS_SECTION_FREQUENCY',
  BOX_SETTINGS_SECTION_PERKS = 'BOX_SETTINGS_SECTION_PERKS',
  BOX_SETTINGS_SECTION_SIZE = 'BOX_SETTINGS_SECTION_SIZE',
  BOX_SETTINGS_SECTION_TYPE = 'BOX_SETTINGS_SECTION_TYPE',
  BUTTON_ADD_TO_BOX = 'BUTTON_ADD_TO_BOX',
  BUTTON_BOX_DATE_MODAL_SAVE = 'BUTTON_BOX_DATE_MODAL_SAVE',
  BUTTON_BOX_DATE_REMOVE_DEALS = 'BUTTON_BOX_DATE_REMOVE_DEALS',
  BUTTON_BOX_DATE_REVIEW_DEALS = 'BUTTON_BOX_DATE_REVIEW_DEALS',
  BUTTON_CUSTOMIZE_BOX_SIZE = 'BUTTON_CUSTOMIZE_BOX_SIZE',
  BUTTON_CUSTOMIZE_PROGRESS = 'BUTTON_CUSTOMIZE_PROGRESS',
  BUTTON_CUSTOMIZE_SAVE = 'BUTTON_CUSTOMIZE_SAVE',
  BUTTON_CUSTOMIZE_UPGRADE = 'BUTTON_CUSTOMIZE_UPGRADE',
  BUTTON_DECREMENT = 'BUTTON_DECREMENT',
  BUTTON_EXPAND_CUT_LIST = 'BUTTON_EXPAND_CUT_LIST',
  BUTTON_INCREMENT = 'BUTTON_INCREMENT',
  BUTTON_NAVIGATE_JUSTCOOK = 'BUTTON_NAVIGATE_JUSTCOOK',
  BUTTON_NEXT_MONTH = 'BUTTON_NEXT_MONTH',
  BUTTON_PREVIOUS_MONTH = 'BUTTON_PREVIOUS_MONTH',
  BUTTON_REPORT_AN_ISSUE = 'BUTTON_REPORT_AN_ISSUE',
  BUTTON_RESTRICTION_BANNER = 'BUTTON_RESTRICTION_BANNER',
  BUTTON_RESTRICTION_DEAL = 'BUTTON_RESTRICTION_DEAL',
  BYPASS_BLOCK = 'BYPASS_BLOCK',
  CALENDAR = 'CALENDAR',
  CALENDAR_CURRENT_MONTH = 'CALENDAR_CURRENT_MONTH',
  CANCEL_FLOW_COMPLETED = 'CANCEL_FLOW_FINISHED',
  CANCEL_FLOW_OPTION = 'CANCEL_FLOW_OPTION',
  CANCEL_FLOW_PANEL = 'CANCEL_FLOW_PANEL',
  CANCEL_FLOW_SAVE = 'CANCEL_FLOW_SAVE',
  CANCEL_FLOW_STOP_SUBSCRIPTION = 'CANCEL_FLOW_STOP_SUBSCRIPTION',
  CANCEL_FLOW_TRIGGER = 'CANCEL_FLOW_TRIGGER',
  CARD_ADDON = 'CARD_ADDON',
  CARD_CHECKOUT_DEAL = 'CARD_CHECKOUT_DEAL',
  CARD_CURATED_MEAT = 'CARD_CURATED_MEAT',
  CARD_CUSTOM_CUT = 'CARD_CUSTOM_CUT',
  CARD_DEAL = 'CARD_DEAL',
  CARD_RANKED_TOP_FOUR = 'CARD_RANKED_TOP_FOUR',
  CHECKOUT_FLOW = 'CHECKOUT_FLOW',
  CHECKOUT_FLOW_ADDONS = 'CHECKOUT_FLOW_ADDONS',
  CHECKOUT_FLOW_ADDONS_PANEL = 'CHECKOUT_FLOW_ADDONS_PANEL',
  CHECKOUT_FLOW_BOX_CUSTOMIZE = 'CHECKOUT_FLOW_BOX_CUSTOMIZE',
  CHECKOUT_FLOW_BOX_SIZE = 'CHECKOUT_FLOW_BOX_SIZE',
  CHECKOUT_FLOW_BOX_SIZES = 'CHECKOUT_FLOW_BOX_SIZES',
  CHECKOUT_FLOW_BOX_TYPE = 'CHECKOUT_FLOW_BOX_TYPE',
  CHECKOUT_FLOW_BOX_TYPES = 'CHECKOUT_FLOW_BOX_TYPES',
  CHECKOUT_FLOW_CART_BOX_NAME_AND_PRICE = 'CHECKOUT_FLOW_CART_BOX_NAME_AND_PRICE',
  CHECKOUT_FLOW_CART_CLOSE = 'CHECKOUT_FLOW_CART_CLOSE',
  CHECKOUT_FLOW_CART_CUSTOM_CUTS = 'CHECKOUT_FLOW_CART_CUSTOM_CUTS',
  CHECKOUT_FLOW_CART_PRODUCT_CARD = 'CHECKOUT_FLOW_CART_PRODUCT_CARD',
  CHECKOUT_FLOW_CART_TRIGGER = 'CHECKOUT_FLOW_CART_TRIGGER',
  CHECKOUT_FLOW_CONGRATULATIONS = 'CHECKOUT_FLOW_CONGRATULATIONS',
  CHECKOUT_FLOW_CURATED_MEAT = 'CHECKOUT_FLOW_CURATED_MEAT',
  CHECKOUT_FLOW_CURATED_MEAT_CARD = 'CHECKOUT_FLOW_CURATED_MEAT_CARD',
  CHECKOUT_FLOW_CURRENT_FREQUENCY_SELECTION = 'CHECKOUT_FLOW_CURRENT_FREQUENCY_SELECTION',
  CHECKOUT_FLOW_DEALS = 'CHECKOUT_FLOW_DEALS',
  CHECKOUT_FLOW_FREQUENCY_FIELDSET = 'CHECKOUT_FLOW_FREQUENCY_FIELDSET',
  CHECKOUT_FLOW_LEARN_MORE = 'CHECKOUT_FLOW_LEARN_MORE',
  CHECKOUT_FLOW_NEXT_FRAME = 'CHECKOUT_FLOW_NEXT_FRAME',
  CHECKOUT_FLOW_OPEN_FREQUENCY_FORM = 'CHECKOUT_FLOW_OPEN_FREQUENCY_FORM',
  CHECKOUT_FLOW_PAYMENT = 'CHECKOUT_FLOW_PAYMENT',
  CHECKOUT_FLOW_PAYMENT_SUBMIT = 'CHECKOUT_FLOW_PAYMENT_SUBMIT',
  CHECKOUT_FLOW_PERK_LIST = 'CHECKOUT_FLOW_PERK_LIST',
  CHECKOUT_FLOW_PLAN_TYPE_CARD = 'CHECKOUT_FLOW_PLAN_TYPE_CARD',
  CHECKOUT_FLOW_TERMS_AND_CONDITIONS_CHECKBOX = 'CHECKOUT_FLOW_TERMS_AND_CONDITIONS_CHECKBOX',
  CHECKOUT_PROGRESS_BAR = 'CHECKOUT_PROGRESS_BAR',
  CUTS_FILTER = 'CUTS_FILTER',
  CUTS_FILTER_APPLY_BUTTON = 'CUTS_FILTER_APPLY_BUTTON',
  CUTS_FILTER_CLEAR_BUTTON = 'CUTS_FILTER_CLEAR_BUTTON',
  CUTS_FILTER_CLOSE_BUTTON = 'CUTS_FILTER_CLOSE_BUTTON',
  DELAY_BOX_BUTTON = 'DELAY_BOX_BUTTON',
  DELAY_CANCEL_FLOW_LINK = 'DELAY_CANCEL_FLOW_LINK',
  DROPDOWN = 'DROPDOWN',
  EXPAND_DETAILS = 'EXPAND_DETAILS',
  FILTER_BY_CUT_BUTTON = 'FILTER_BY_CUT_BUTTON',
  FORM_BILLING = 'FORM_BILLING',
  FORM_EMAIL = 'FORM_EMAIL',
  FORM_SHIPPING_ADDRESS = 'FORM_SHIPPING_ADDRESS',
  HERO_BACKGROUND = 'HERO_BACKGROUND',
  HERO_SUBHEAD = 'HERO_SUBHEAD',
  HOMEPAGE = 'HOMEPAGE',
  HOMEPAGE_COLLAPSIBLE_FOOTER = 'HOMEPAGE_COLLAPSIBLE_FOOTER',
  HOMEPAGE_COLLAPSIBLE_FOOTER_CTA = 'HOMEPAGE_COLLAPSIBLE_FOOTER_CTA',
  HOMEPAGE_HERO = 'HOMEPAGE_HERO',
  ICON_INFO = 'ICON_INFO',
  INPUT_ERROR = 'INPUT_ERROR',
  INPUT_TEXT_AREA_ERROR = 'INPUT_TEXT_AREA_ERROR',
  LINK_CHANGE_BOX_DATE = 'LINK_CHANGE_BOX_DATE',
  LINK_CHANGE_BOX_FREQUENCY = 'LINK_CHANGE_BOX_FREQUENCY',
  LINK_CHANGE_BOX_SIZE = 'LINK_CHANGE_BOX_SIZE',
  LINK_CHANGE_BOX_TYPE = 'LINK_CHANGE_BOX_TYPE',
  LINK_EDIT_BILLING = 'LINK_EDIT_BILLING',
  LINK_EDIT_EMAIL = 'LINK_EDIT_EMAIL',
  LINK_EDIT_SHIPPING_ADDRESS = 'LINK_EDIT_SHIPPING_ADDRESS',
  LINK_REMOVE_ADDON = 'LINK_REMOVE_ADDON',
  LINK_REMOVE_DEAL = 'LINK_REMOVE_DEAL',
  LOADING_SPINNER = 'LOADING_SPINNER',
  LOCAL_BOX_DATE = 'LOCAL_BOX_DATE',
  MEMBER_HOMEPAGE_ADDONS = 'MEMBER_HOMEPAGE_ADDONS',
  MEMBER_HOMEPAGE_DEALS = 'MEMBER_HOMEPAGE_DEALS',
  MEMBER_HOMEPAGE_FEATURED_DEAL = 'MEMBER_HOMEPAGE_FEATURED_DEAL',
  MODAL_BACK_BUTTON = 'MODAL_BACK_BUTTON',
  MODAL_BOX_DATE = 'MODAL_BOX_DATE',
  MODAL_BOX_FREQUENCY = 'MODAL_BOX_FREQUENCY',
  MODAL_BOX_SIZE = 'MODAL_BOX_SIZE',
  MODAL_BOX_TYPE = 'MODAL_BOX_TYPE',
  MODAL_CANCEL = 'MODAL_CANCEL',
  MODAL_CLOSE_BUTTON = 'MODAL_CLOSE_BUTTON',
  MODAL_HOW_CAN_WE_FIX_THIS = 'MODAL_HOW_CAN_WE_FIX_THIS',
  MODAL_LISTING_CARD_INFO = 'MODAL_LISTING_CARD_INFO',
  MODAL_OPTION = 'MODAL_OPTION',
  MODAL_OTHER_ISSUE_REPORT = 'MODAL_OTHER_ISSUE_REPORT',
  MODAL_REMOVE_ITEM_CONFIRM = 'MODAL_REMOVE_ITEM_CONFIRM',
  MODAL_REPLACEMENT_CUTS = 'MODAL_REPLACEMENT_CUTS',
  MODAL_REPORT_AN_ISSUE = 'MODAL_REPORT_AN_ISSUE',
  MODAL_RESHIP_CONFIRM = 'MODAL_RESHIP_CONFIRM',
  MODAL_SELECT_AFFECTED_ITEMS = 'MODAL_SELECT_AFFECTED_ITEMS',
  MODAL_SHIPPING_ADDRESS_INVALID = 'MODAL_SHIPPING_ADDRESS_INVALID',
  MODAL_SHIPPING_ADDRESS_SUGGESTION = 'MODAL_SHIPPING_ADDRESS_SUGGESTION',
  MODAL_SUBMIT = 'MODAL_SUBMIT',
  MULTI_SELECT_ACCORDION_GROUP = 'MULTI_SELECT_ACCORDION_GROUP',
  MULTI_SELECT_ACCORDION_ITEM = 'MULTI_SELECT_ACCORDION_ITEM',
  NARRATIVE_SPOTLIGHT_DEALS = 'NARRATIVE_SPOTLIGHT_DEALS',
  ORDER_HISTORY_BILL_DATE = 'ORDER_HISTORY_DELIVERY_DATE',
  ORDER_HISTORY_BOX_TYPE = 'ORDER_HISTORY_BOX_TYPE',
  ORDER_HISTORY_PANEL = 'ORDER_HISTORY_PANEL',
  ORDER_HISTORY_TRACKING_LINK = 'ORDER_HISTORY_TRACKING_LINK',
  PANEL_COMBO_ITEM = 'PANEL_COMBO_ITEM',
  PAYMENT_PAST_DUE_MESSAGE = 'PAYMENT_PAST_DUE_MESSAGE',
  PAYMENT_PROCESSING_MESSAGE = 'PAYMENT_PROCESSING_MESSAGE',
  PRODUCT_QUANTITY = 'PRODUCT_QUANTITY',
  RECIPE_ARE_YOU_SURE_MODAL_CLOSE = 'RECIPE_ARE_YOU_SURE_MODAL_CLOSE',
  RECIPE_ARE_YOU_SURE_MODAL_NEVERMIND = 'RECIPE_ARE_YOU_SURE_MODAL_NEVERMIND',
  RECIPE_REMOVE_ARE_YOU_SURE_MODAL = 'RECIPE_REMOVE_ARE_YOU_SURE_MODAL',
  RECIPE_REMOVE_BUTTON = 'RECIPE_REMOVE_BUTTON',
  RECIPE_SAVE_BUTTON = 'RECIPE_SAVE_BUTTON',
  RECIPES_EMPTY_STATE = 'RECIPES_EMPTY_STATE',
  REPORT_ISSUE_LINK = 'REPORT_ISSUE_LINK',
  RESUB_EYEBROW = 'RESUB_EYEBROW',
  RESUB_OFFER_EXPIRED = 'RESUB_OFFER_EXPIRED',
  RESUB_SUBMIT = 'RESUB_SUBMIT',
  RESUBSCRIBE_CONFIRMATION_MODAL = 'RESUBSCRIBE_CONFIRMATION_MODAL',
  REVIEW_DEALS_ITEM = 'REVIEW_DEALS_ITEM',
  SECTION_ACCOUNT_ORDER_HISTORY_EXPANDED_TOTAL = 'SECTION_ACCOUNT_ORDER_HISTORY_EXPANDED_TOTAL',
  SECTION_ACCOUNT_YOUR_BOX_ADDONS = 'SECTION_ACCOUNT_YOUR_BOX_ADDONS',
  SECTION_ACCOUNT_YOUR_BOX_BILL_DATE = 'SECTION_ACCOUNT_YOUR_BOX_BILL_DATE',
  SECTION_ACCOUNT_YOUR_BOX_CREDITS = 'SECTION_ACCOUNT_YOUR_BOX_CREDITS',
  SECTION_ACCOUNT_YOUR_BOX_DEALS = 'SECTION_ACCOUNT_YOUR_BOX_DEALS',
  SECTION_ACCOUNT_YOUR_BOX_DISCOUNTS = 'SECTION_ACCOUNT_YOUR_BOX_DISCOUNTS',
  SECTION_ACCOUNT_YOUR_BOX_EXTRAS = 'SECTION_ACCOUNT_YOUR_BOX_EXTRAS',
  SECTION_ACCOUNT_YOUR_BOX_OFFERS = 'SECTION_ACCOUNT_YOUR_BOX_OFFERS',
  SECTION_ACCOUNT_YOUR_BOX_PLAN = 'SECTION_ACCOUNT_YOUR_BOX_PLAN',
  SECTION_ACCOUNT_YOUR_BOX_SUBTOTAL = 'SECTION_ACCOUNT_YOUR_BOX_SUBTOTAL',
  SECTION_ACCOUNT_YOUR_BOX_TAXES = 'SECTION_ACCOUNT_YOUR_BOX_TAXES',
  SECTION_ACCOUNT_YOUR_BOX_TOTAL = 'SECTION_ACCOUNT_YOUR_BOX_TOTAL',
  SECTION_PROFILE_PAYMENT_BILLING = 'SECTION_PROFILE_PAYMENT_BILLING',
  SECTION_PROFILE_PAYMENT_EMAIL = 'SECTION_PROFILE_PAYMENT_EMAIL',
  SECTION_PROFILE_PAYMENT_SHIPPING_ADDRESS = 'SECTION_PROFILE_PAYMENT_SHIPPING_ADDRESS',
  TEXT_BOX_SETTINGS_CURRENT_SELECTION = 'TEXT_BOX_SETTINGS_CURRENT_SELECTION',
  TOAST = 'TOAST',
  TOAST_BUTTON_ERROR = 'TOAST_BUTTON_ERROR',
  VIEW_SAVED_RECIPES_CTA = 'VIEW_SAVED_RECIPES_CTA',
  WELCOME_GUIDE_SECTION = 'WELCOME_GUIDE_SECTION',
  WHATS_FOR_DINNER_PRODUCT = 'WHATS_FOR_DINNER_PRODUCT',
  WHATS_FOR_DINNER_RECIPE_FLAG = 'WHATS_FOR_DINNER_RECIPE_FLAG',
  WHATS_FOR_DINNER_RECIPE_LINK = 'WHATS_FOR_DINNER_RECIPE_LINK',
  WHATS_FOR_DINNER_SEARCH_RESULTS = 'WHATS_FOR_DINNER_SEARCH_RESULTS',
}

export enum ATTR {
  BOX_DATE = 'data-cy-box-date',
  BOX_FREQUENCY = 'data-cy-box-frequency',
  BOX_DELAY_WEEKS = 'data-cy-box-delay',
  PRICE = 'data-cy-price',
  BOX_SIZE = 'data-cy-box-size',
  BOX_TYPE = 'data-cy-box-type',
  CALENDAR_DAY_DATE = 'data-cy-calendar-date',
  CALENDAR_DAY_INVALID = 'data-cy-is-date-valid',
  CHECKOUT_CART_ADDONS = 'data-cy-checkout-cart-addons',
  CHECKOUT_CART_BOX_NAME = 'data-cy-checkout-cart-box-name',
  CHECKOUT_PLAN_TYPE = 'data-cy-checkout-plan-type',
  CHECKOUT_MEAT_TYPE = 'data-cy-checkout-meat-type',
  CURRENT_QUANTITY = 'data-cy-current-quantity',
  CUTS_RANK = 'data-cy-cuts-rank',
  ELEMENT = 'data-cy',
  LOCAL_BOX_DATE = 'data-cy-local-bill-date',
  MAX_QUANTITY = 'data-cy-max-quantity',
  ORDER_TOTAL = 'data-cy-order-total',
  REPORTABLE = 'data-cy-reportable',
  RECIPE_LINK = 'data-cy-recipe-link',
  RECIPE_SAVE = 'data-cy-recipe-save',
  RECIPE_NAME = 'data-cy-recipe-name',
  SKU = 'data-cy-sku',
  TOAST_STATUS = 'data-cy-toast-status',
  TRACKING_INFO = 'data-cy-tracking-info',
  VALUE = 'value',
}
