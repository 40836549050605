import baseAxios, { AxiosError, AxiosRequestConfig } from 'axios'
import { SEGMENT_LOCALSTORAGE_KEY } from '~/analytics/constants'
import { captureNetworkError, trackError } from '~/analytics/errors'
import {
  API_DOMAIN,
  API_V3_CUSTOMER,
  API_V4_CUSTOMER,
} from '~/bb-api/constants'
import { getAccessToken, login, logout } from '~/utils/auth0'

const axios = baseAxios.create()

/**
 * Add any behaviors that should be applied to HTTP requests
 * and responses. Note that the interceptor will be applied to
 * ALL API calls and thus you'll want to filter them according
 * to the request URL, etc.
 *
 * Docs: https://github.com/axios/axios#interceptors
 */

// agent.interceptors.request.use(config => { /* some behavior */ return config; })
// agent.interceptors.response.use(response => { /* some behavior */ return response; })

export const attachApiCookies = async (config: AxiosRequestConfig) => {
  // Attach cookies to all API requests
  if (
    config.url?.includes(API_DOMAIN) &&
    process.env.NODE_ENV === 'production'
  ) {
    config.withCredentials = true
  }

  return config
}

export const attachSegmentAnonymousId = async (config: AxiosRequestConfig) => {
  if (config.url?.includes(API_DOMAIN)) {
    try {
      /**
       * Default to pulling this from localStorage instead of using analytics.js directly in case
       * adblock is enabled and analytics.js user resolution isn't available at all. If localstorage
       * doesn't return a value, then we attempt to use analytics.js in case it is running and has access
       * to the value through other means
       *
       * see optimizely.tsx for where we set a manual AUID if one doesn't already exist
       */
      try {
        let auid = window.localStorage.getItem(SEGMENT_LOCALSTORAGE_KEY)
        auid = auid ? JSON.parse(auid) : ''

        if (!auid) {
          // If we don't get anything back from localStorage, try using Segment id resolution
          // This is in a separate if block to prevent Brave from blocking the entire usage of
          // auid based on the usage of a blocked script
          const segmentId = window.analytics?.user()?.anonymousId() || ''
          auid = segmentId
        }

        // we must send the anonymous ID to segment along with known user ID
        // to faciliate optimizely tracking which relies on anonymous ID
        config.headers['X-Segment-Anonymous-Id'] = auid
      } catch (e) {
        // If we get an error from JSON parse in Safari/iOS, try to use the string from localstorage as is
        const fallbackAuid = window.localStorage.getItem(
          SEGMENT_LOCALSTORAGE_KEY
        )
        config.headers['X-Segment-Anonymous-Id'] = fallbackAuid || ''
      }
    } catch (e) {
      // fall back to empty string in the worst case so API calls don't break entirely
      config.headers['X-Segment-Anonymous-Id'] = ''
      trackError((scope) => {
        scope.capture(new Error(e))
      })
    }
  }

  return config
}

export const attachAuthToken = async (config: AxiosRequestConfig) => {
  // Ensure that we only send tokens when using authenticated BB APIs
  if (
    config.url?.includes(API_V3_CUSTOMER) ||
    config.url?.includes(API_V4_CUSTOMER)
  ) {
    try {
      const token = await getAccessToken()

      config.headers['Authorization'] = 'Bearer ' + token.trim()

      return config
    } catch (e) {
      captureNetworkError(e)
      logout()
      throw e // rethrow error now that logout is finished and error has been alerted
    }
  }

  return config
}

// TODO: Sometimes this function receives an argument that isn't an AxiosError.
//       We should investigate why this is.
export const handleResponseAuthError = (e: AxiosError) => {
  // ! IMPORTANT: This assumes that netlify functions will not attempt to authenticate
  //              the user.
  const { url, responseURL } = e.request || {}
  const status = e.response?.status
  const AUTH_URL =
    process.env.NODE_ENV !== 'production'
      ? window.location.origin
      : process.env.AUTH_URL || 'https://member.butcherbox.com'
  if (
    (responseURL?.includes(API_DOMAIN) || url?.includes(API_DOMAIN)) &&
    status === 401
  ) {
    logout()
    login({
      redirectUri: `${AUTH_URL}/authorize?redirect=${window.location.pathname}`,
    })
    return Promise.reject(e.response)
  }

  if (status && status >= 400) {
    return Promise.reject(e.response)
  } else {
    // Defer to context-specific exception handling if the error is not auth-related
    if (e instanceof Error) {
      throw e
    } else {
      throw new Error(e)
    }
  }
}
axios.interceptors.request.use(attachApiCookies)
axios.interceptors.request.use(attachAuthToken)
axios.interceptors.request.use(attachSegmentAnonymousId)
axios.interceptors.response.use(undefined, handleResponseAuthError)

/**
 * TODO: Currently the @see handleResponseAuthError function throws the AxiosError.response
 * object rather than throwing the entire AxiosError itself. This prevents us from using the
 * @see baseAxios.isAxiosError function in error consumers to achieve type safety on AxiosError
 * objects. We should migrate the @see handleResponseAuthError function to raise the entire
 * AxiosError, but for now we can shadow the API of `isAxiosError` to infer the type.
 */
export const isAxiosError = (err: any): err is AxiosError['response'] => {
  if (typeof err.status !== 'undefined') {
    return true
  }

  return false
}
export default axios
