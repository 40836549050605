import React from 'react'
import * as Styles from './PanelComboItem.css'
import { Text, Box } from '@butcherbox/freezer'
import { IPanelComboItem } from './PanelComboItem.types'

export function PanelComboItem({
  title,
  titleAddon,
  children,
  headingLevel = 'h3',
  slashThroughHeading = null,
  ...props
}: IPanelComboItem) {
  return (
    <Box {...props} className={Styles.PanelComboItem}>
      {slashThroughHeading ?? (
        <Box className={Styles.ComboItemHeader}>
          <Text component={headingLevel} variant="H4Condensed">
            {title}
          </Text>
          <Text component={headingLevel} variant="H4Condensed">
            {titleAddon}
          </Text>
        </Box>
      )}
      <Box>{children}</Box>
    </Box>
  )
}
