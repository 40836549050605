import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from '@reach/router'
import { AxiosError } from 'axios'
import { navigate } from 'gatsby'
import React from 'react'
import { useQuery } from 'react-query'
import { captureNetworkError, trackError } from '~/analytics/errors'
import * as identifiers from '~/analytics/identifiers'
import { API, CACHE_KEY_CUSTOMER } from '~/bb-api/constants'
import { GET_CUSTOMER } from '~/bb-api/endpoints'
import { User } from '~/bb-api/schemata'
import mutateUserAnonymousId from '~/hooks/mutateUserAnonymousId'
import useAnonymousId from '~/hooks/useAnonymousId'
import useGetOptimizelyConfig from '~/hooks/useGetOptimizelyConfig'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import useSwitchToSubDomain from '~/hooks/useSwitchToSubDomain'
import ErrorBoundary from '~/sections/ErrorBoundary'
import { getUserIdFromAuth0User } from '~/utils/auth0'
import axios from '~/utils/axios'

export const UserContext = React.createContext<User>(undefined)

export const UserProvider = ({ children }) => {
  /**
   * Retreive the initial user object from Auth0
   * Value Progression:
   * user: undefined -> false -> {user}
   * isAuthenticated: false -> true
   */
  const { user: authUser, isAuthenticated } = useAuth0() as ReturnType<
    typeof useAuth0
  > & {
    user: {
      [key: string]: string
      sub: string
    }
  }
  const [error, setError] = React.useState(false)

  const { AUTH_URL } = useSwitchToSubDomain()
  /**
   * Temporary feature flag while we monitor the rollout/release of this for WEB-3141
   * https://app.optimizely.com/v2/projects/18148360268/features/21031773398/rules
   * https://nbox-341.atlassian.net/browse/WEB-3141
   */
  const [dispatchAnonymousIdFeatureEnabled] = useOptimizelyFeature(
    'post_anonymousid_to_api'
  )
  const [dispatchMutateUserAnonymousId] = mutateUserAnonymousId()

  const dispatchPostUserAnonymousId = React.useCallback(
    async (userId: number, anonymousId: string) => {
      return dispatchMutateUserAnonymousId(
        { anonymousId, userId },
        {
          onError: (error: AxiosError['response']) => {
            /**
             * A 400 error means the anonymousId is already in use by another member.
             * A 409 error means the user already has an anonymousId. If we encounter
             * a 409, that means the code in this file is not behaving properly.
             * Just simply console.log'ing the error so we have visiblity of it
             * in DataDog. No action needs to be taken on errors here.
             */
            if (error.status === 400 || error.status === 409) {
              console.log(error)
            }
          },
        }
      )
    },
    [dispatchMutateUserAnonymousId]
  )

  /**
   * On valid user, get the customer URL eg: /api/v4/customer/123
   */
  const url = React.useMemo(() => {
    if (authUser) {
      const uid = getUserIdFromAuth0User(authUser)
      return uid ? GET_CUSTOMER(parseInt(uid, 10), API.V4) : ''
    }
    return ''
  }, [authUser])

  /**
   * Query the BB API to get the user object
   */
  const { data: user } = useQuery<User>(
    CACHE_KEY_CUSTOMER,
    () => {
      return axios
        .get<User>(url)
        .then((res) => res.data)
        .catch((e: any) => {
          captureNetworkError(e)
          setError(true)
          return undefined
        })
    },
    { enabled: !!url, staleTime: Infinity, useErrorBoundary: true }
  )

  /**
   * Auth0 Email address is used for identify calls,
   * This checks for an edge case where the auth0 email != db email
   * and dispatches that error.
   */
  React.useEffect(() => {
    if (
      isAuthenticated &&
      user &&
      authUser &&
      user.emailAddress &&
      authUser.email &&
      user.emailAddress !== authUser.email &&
      user.emailAddress.toLowerCase() !== authUser.email.toLowerCase()
    ) {
      trackError((scope) => {
        scope.setContext(
          'emailAddresses',
          `${user.emailAddress}^${authUser.email}`.replace(/@|\./g, '_')
        )
        scope.capture(new Error('Auth0 Email and BB User Email Mismatched'))
      })
    }
  }, [isAuthenticated, user, authUser])

  /**
   * REDIRECT useEffect
   * If the user is authenticated, and on a reserved path,
   * redirect them to /member
   */
  const { pathname } = useLocation()
  React.useEffect(() => {
    if (
      isAuthenticated &&
      (pathname === '/' ||
        pathname.startsWith('/get-started') ||
        pathname.startsWith('/plans-and-addons'))
    ) {
      navigate(`${AUTH_URL}/member`)
    }
  }, [isAuthenticated, pathname, AUTH_URL])

  /**
   * Segment Identify useEffect
   * Segment identify calls are separated as we want to trigger these
   * when experimentsAvailable changes as well
   */
  const { experimentsAvailable } = useGetOptimizelyConfig()
  React.useEffect(() => {
    if (user?.emailAddress && user?.id) {
      identifiers.segmentIdentify({
        userId: user.id.toString(),
        traits: {
          ...experimentsAvailable,
          email: user.emailAddress,
        },
      })
    }
  }, [user, experimentsAvailable])

  /**
   * Get the initially-set anonymousId
   */
  const { anonymousId, setAnonymousId } = useAnonymousId()

  /**
   * General Identify useEffect
   * This is keyed off of email address so it automatically re-identifies
   * the user if they change their email (most downstream tools care about
   * email more than customer ID.)
   */

  React.useEffect(() => {
    if (user?.emailAddress) {
      identifiers.dataLayerPush(user)
      identifiers.errorTrackingIdentify({ customer: user })
    } else {
      identifiers.errorTrackingIdentify({ anonymousId: anonymousId })
    }
  }, [anonymousId, user?.emailAddress, user?.id, user])

  /**
   * If a user has a persisted anonymousId, update the anonymousId context
   * with it so all references to anonymousId can use it. If the user does not
   * have an anonymousId, dispatch a request to the API to set it, ignoring
   * the request status and response.
   */
  React.useEffect(() => {
    if (!user) {
      return
    }
    if (user.anonymousId === null) {
      if (dispatchAnonymousIdFeatureEnabled) {
        dispatchPostUserAnonymousId(user.id, anonymousId)
      }
    } else {
      setAnonymousId(user.anonymousId)

      /**
       * If the user has a persisted anonymous id, we want to
       * store it in Segment as a user trait
       */
      identifiers.segmentIdentify({
        userId: user.id.toString(),
        traits: {
          anonymous_id_persisted: user.anonymousId,
          email: user.emailAddress,
        },
      })
    }
    // Disabling eslint rule since we only want to run this if the user object changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchAnonymousIdFeatureEnabled, user])

  return (
    <UserContext.Provider value={user}>
      {error ? <ErrorBoundary /> : children}
    </UserContext.Provider>
  )
}

/**
 * When a component's render function depends on data from the user context on
 * first render, use this higher-order-component to ensure that the component
 * does not render until the user is available from the context provider.
 *
 * @param Component Any React component
 */
export const renderWhenUserAvailable = (Component) => (props) => {
  const { id } = React.useContext(UserContext)
  if (!id) {
    return null
  }
  return <Component {...props} />
}
