import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Text,
  TextLink,
} from '@butcherbox/freezer'
import { RouteComponentProps, WindowLocation } from '@reach/router'
import React from 'react'
import { trackError } from '~/analytics/errors'
import { Addon, Deal } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import { mutateDecrementAddon } from '~/hooks/mutateAddons'
import { mutateDecrementInvoiceItem } from '~/hooks/mutateInvoiceItem'
import * as Styles from './AccountYourBox.css'

export type ProductType = 'addon' | 'memberDeal'

export type ProductInfo = {
  item: Addon | Deal
  itemId: (Addon | Deal)['sku']
  productId: string
  productType: ProductType
}

export type ModalRemoveItemProps = RouteComponentProps & {
  location?: WindowLocation<ProductInfo>
  handleModalClose: () => void
}

const ModalRemoveItem: React.FC<ModalRemoveItemProps> = ({
  location,
  handleModalClose,
}) => {
  // If user navigates to modal without having clicked 'remove item',
  // navigate to base page and return null
  if (location.state == null) {
    handleModalClose()
    return null
  }

  const [
    decrementAddon,
    { status: decrementAddonStatus },
  ] = mutateDecrementAddon()
  const [
    decrementInvoiceItem,
    { status: decrementInvoiceItemStatus },
  ] = mutateDecrementInvoiceItem()

  const { item, itemId, productType } = location.state

  const removeItem = React.useCallback(async () => {
    const decrement =
      productType === 'addon' ? decrementAddon : decrementInvoiceItem

    try {
      decrement(item)
      handleModalClose()
    } catch (e) {
      trackError((scope) => {
        scope.setContext('productData', {
          item: itemId,
          productType: productType,
        })
        scope.capture(e)
      })
    }
  }, [
    decrementAddon,
    decrementInvoiceItem,
    handleModalClose,
    item,
    itemId,
    productType,
  ])

  const isLoading =
    decrementAddonStatus === 'loading' ||
    decrementInvoiceItemStatus === 'loading'

  const ModalPropsType: ModalProps = {
    title: 'Are you sure?',
    size: 'm',
    fullBleed: false,
  }

  return (
    <Modal
      data-cy={TEST_ID.MODAL_REMOVE_ITEM_CONFIRM}
      data-what="remove-product-modal"
      onClose={handleModalClose}
      {...ModalPropsType}
    >
      <ModalBody>
        <Box className={Styles.RemoveItemModalBody}>
          <Text variant="Body1Regular">
            {`Removing this ${
              productType === 'addon'
                ? 'add-on item'
                : 'limited time member deal'
            } will remove it from your box and you will
            not be charged.`}
          </Text>
          <Button
            data-cy={TEST_ID.MODAL_SUBMIT}
            data-what={
              productType === 'addon'
                ? 'remove_addon_confirm'
                : 'remove_member_deal_confirm'
            }
            data-where={
              productType === 'addon'
                ? 'remove_addon_modal'
                : 'remove_member_deal_modal'
            }
            disabled={isLoading}
            loading={isLoading}
            onClick={removeItem}
            type="submit"
            variant="primary"
            width="compact"
          >
            {`Yes, remove ${productType === 'addon' ? 'add-on' : 'deal'}`}
          </Button>
          <TextLink
            data-cy={TEST_ID.MODAL_CANCEL}
            data-what={
              productType === 'addon'
                ? 'nevermind_addon_keep'
                : 'nevermind_member_deal_keep'
            }
            data-where={
              productType === 'addon'
                ? 'remove_addon_modal'
                : 'remove_member_deal_modal'
            }
            href=""
            onClick={handleModalClose}
            variant="CTA"
          >
            {`Nevermind, I want the ${
              productType === 'addon' ? 'add-on' : 'deal'
            }`}
          </TextLink>
        </Box>
      </ModalBody>
    </Modal>
  )
}

export default ModalRemoveItem
