import { Flex, FlexProps } from '@chakra-ui/core'
import { Body } from 'design/components/Typography/Typography'
import { rem } from 'design/utils'
import React from 'react'
import FormErrorIcon from './FormErrorIcon'

type FormErrorMessageProps = FlexProps & {
  hasError?: boolean
  id?: string
}

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
  children,
  hasError,
  id,
  ...props
}) => (
  <Flex
    alignItems={'center'}
    color="ui.error"
    data-what="input-error-message"
    id={id}
    mt={rem(6)}
    {...props}
  >
    {hasError && (
      // This box wrapper is a workaround for setting a specific pixel size on a legacy icon.
      // Once this input is replaced with a freezer component it will be irrelevant.
      <FormErrorIcon size={'text'} />
    )}
    <Body
      aria-live={hasError ? 'assertive' : 'off'}
      fontSize={rem(12)}
      lineHeight="normal"
    >
      {children}
    </Body>
  </Flex>
)

export default FormErrorMessage
