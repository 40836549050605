import { Box, rem } from '@butcherbox/freezer'
import CardProduct, {
  CardProductFlag,
} from 'design/components/CardProduct/CardProduct'
import CardProductList, {
  ICardProductList,
} from 'design/components/CardProductList/CardProductList'
import React from 'react'
import { BoxItem, GroupedBoxItem } from '~/bb-api/schemata'
import { QuantityBadge } from '~/components/ListingCard'
import { formatPriceFromCents } from '~/utils'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import CartComboItem from './CartComboItem'

type ICartProductList = {
  fallback?: any
  productArray: (GroupedBoxItem | BoxItem)[]
  showFreeFlag?: boolean
  showGroupedItems?: boolean
  showQuantity?: boolean
  showQuantityOfOne?: boolean
} & Partial<ICardProductList>

type IProduct = BoxItem & { groupedItems?: BoxItem[] }

export const CartProductList: React.FC<ICartProductList> = ({
  fallback = null,
  productArray,
  showFreeFlag = false,
  showGroupedItems = false,
  showQuantity = false,
  showQuantityOfOne = false,
  ...props
}) => {
  const shouldShowProductBadge = (product: IProduct): boolean =>
    showQuantity &&
    (product.quantity > 1 || (showQuantityOfOne && product.quantity === 1))

  return (
    <Box marginTop={4}>
      {productArray && productArray.length ? (
        <CardProductList
          collapseAfterNProducts={6}
          orientation="column"
          products={productArray.map((product, idx) => {
            const { description, price, sku } = product
            const groupedItems =
              'groupedItems' in product ? product.groupedItems : []

            return showGroupedItems && groupedItems?.length > 0 ? (
              <CartComboItem
                key={sku}
                title={description}
                titlePrice={formatPriceFromCents(price)}
              >
                {duplicateItemsByQuantity<BoxItem>(groupedItems).map(
                  (groupedProduct, innerIdx) => {
                    return (
                      <CardProduct
                        css={{
                          ':not(:last-child)': { marginBottom: rem(8) },
                        }}
                        data-cy-sku={product.sku}
                        Flag={
                          showFreeFlag &&
                          groupedProduct.price === 0 && (
                            <CardProductFlag>Free</CardProductFlag>
                          )
                        }
                        key={innerIdx}
                        product={groupedProduct}
                        ProductBadge={
                          shouldShowProductBadge(product) ? (
                            <QuantityBadge
                              product={product}
                              quantity={product.quantity}
                              right={rem(-4)}
                              top={rem(-5)}
                            />
                          ) : undefined
                        }
                      />
                    )
                  }
                )}
              </CartComboItem>
            ) : (
              <CardProduct
                as="li"
                data-cy-sku={product.sku}
                Flag={
                  showFreeFlag &&
                  product.price === 0 && <CardProductFlag>Free</CardProductFlag>
                }
                key={idx}
                product={product}
                ProductBadge={
                  shouldShowProductBadge(product) ? (
                    <QuantityBadge
                      product={product}
                      quantity={product.quantity}
                      right={rem(-4)}
                      top={rem(-5)}
                    />
                  ) : undefined
                }
              />
            )
          })}
          {...props}
        />
      ) : (
        fallback
      )}
    </Box>
  )
}
export default CartProductList
