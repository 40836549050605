import { Box, BoxProps } from '@chakra-ui/core'
import { rem } from 'design'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'

type ICardImageProps = {
  href: string
} & BoxProps

const CardImage: React.FC<ICardImageProps> = ({ href, ...props }) => {
  return (
    <Box
      as={BackgroundImage}
      backgroundColor="white"
      backgroundImage={`url(${href})`}
      backgroundPosition="center center"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      height={rem(112)}
      width="100%"
      {...props}
    />
  )
}

export const CardHTMLImage = (props: ICardImageProps) => (
  <CardImage as="img" {...props} />
)

export default CardImage
