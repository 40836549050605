import React from 'react'
import { Subscription } from '~/bb-api/schemata'
import { BaseSubscriptionContext } from './subscriptionRemote'

export interface SubscriptionContext extends BaseSubscriptionContext {
  subscription: Subscription
}

export const SubscriptionContext = React.createContext<SubscriptionContext>(
  // Since `undefined` isn't a valid value of the provider, type SubscriptionContext,
  // we need to tell TS to just go with it anyway. In addition, we want an error in
  // the consumers of this context that aren't wrapped with the provider.
  (undefined as unknown) as SubscriptionContext
)

type SubscriptionProviderProps = Omit<SubscriptionContext, 'isLoading'>

export const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({
  children,
  isBlocked,
  subscription,
}) => {
  return (
    <SubscriptionContext.Provider
      value={{
        isBlocked,
        isLoading: false,
        subscription,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  )
}
