import { PseudoBox, PseudoBoxProps } from '@chakra-ui/core'
import { rem } from 'design/utils'
import { GatsbyLinkProps, Link as GatsbyLink, navigate } from 'gatsby'
import React, { AnchorHTMLAttributes, ComponentProps } from 'react'
import { ITypography } from 'design/components/Typography/Typography'

export const CTA: ITypography = React.forwardRef((props, ref) => (
  <PseudoBox
    data-what="cta-content"
    fontFamily="Lato"
    fontSize={rem(12)}
    fontWeight={700}
    letterSpacing={rem(0.5)}
    lineHeight={rem(16)}
    ref={ref}
    textTransform="uppercase"
    {...props}
  />
))

export const BaseLink: React.FC<
  { rel?: string; target?: string } & PseudoBoxProps
> = (props) => {
  return (
    <PseudoBox
      _hover={{ textDecoration: 'underline' }}
      border="none"
      color="bb.spicedCrimson"
      cursor="pointer"
      data-what="link"
      display="inline-block"
      fontSize="inherit"
      letterSpacing="inherit"
      lineHeight="inherit"
      {...props}
    />
  )
}

export const LinkExternal: React.FC<
  { href: string } & PseudoBoxProps & AnchorHTMLAttributes<any>
> = (props) => {
  return (
    <BaseLink as="a" rel="noopener noreferrer" target="_blank" {...props} />
  )
}

export const LinkInternal: React.FC<
  // we want the prop interface to be the same as LinkExternal to prevent mistakes
  { href: string } & Omit<GatsbyLinkProps<{}>, 'to'> & PseudoBoxProps
> = (props) => {
  return (
    <BaseLink
      as={GatsbyLink}
      {...props}
      // @ts-ignore
      to={props.href || '/'}
    />
  )
}

export const LinkInternalEnterKeySafe = ({
  children,
  ...props
}: ComponentProps<typeof LinkInternal>) => {
  /*ok, so this is an INSANE edge case where sometimes, if you opened the link with an enter key press when it was at the bottom of the screen,
  that enter keypress would fire on the autofocused first radio button, submit the form, and instantaneously close it.
  To prevent this, I am preventing the link from opening on enter and manually navigating to that page.
  Also need to do this on the account box settings.
  */
  const handlers = {
    onKeyDown: (e) => {
      if (e.key === 'Enter') e.preventDefault()
    },
    onKeyUp: (e) => {
      if (e.key === 'Enter') navigate(props.href)
    },
  }

  return (
    <LinkInternal {...handlers} {...props}>
      {children}
    </LinkInternal>
  )
}

export const MenuLink: ITypography = (props) => {
  return (
    <PseudoBox
      as="button"
      data-what="button"
      display="inline-block"
      fontFamily="Lato"
      fontSize={rem(14)}
      fontWeight={700}
      lineHeight={rem(24)}
      textAlign="inherit"
      width="100%"
      {...props}
    />
  )
}

export const MenuButton: React.FC<
  React.ComponentProps<'button'> & React.ComponentProps<typeof MenuLink>
> = (props) => {
  return <MenuLink as="button" {...props} />
}
export const LinkButton: React.FC<
  React.ComponentProps<'button'> & React.ComponentProps<typeof BaseLink>
> = (props) => {
  return <BaseLink as="button" role="button" {...props} />
}
