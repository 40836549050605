import CardProduct, {
  CardProductFlag,
} from 'design/components/CardProduct/CardProduct'
import CardProductList from 'design/components/CardProductList/CardProductList'
import partition from 'lodash.partition'
import React from 'react'
import { GroupedBoxItem } from '~/bb-api/schemata'
import { PanelComboItem } from '~/components/Panel/PanelComboItem/PanelComboItem'
import PanelSlashThroughHeading from '~/components/Panel/PanelSlashThroughHeading/PanelSlashThroughHeading'
import { TEST_ID } from '~/constants/cypress'
import { formatPriceFromCents } from '~/utils'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'

type ProductsWithOptionalGroupedItems = GroupedBoxItem

export default function CardProductGroup({
  cySelectorName = TEST_ID.PANEL_COMBO_ITEM,
  products,
  renderAfterList = null,
  showFreeFlag = true,
  showGroupedItems = false,
  ...props
}: {
  renderAfterList?: (product: ProductsWithOptionalGroupedItems) => JSX.Element
  products: ProductsWithOptionalGroupedItems[]
  showFreeFlag?: boolean
  showGroupedItems?: boolean
  cySelectorName?: string
}) {
  const [productsWithGroupedItems, singularProducts] = React.useMemo<
    Array<ProductsWithOptionalGroupedItems[]>
  >(
    () =>
      partition(products, (product) =>
        showGroupedItems && product.groupedItems
          ? !!product.groupedItems.length
          : false
      ),
    [products, showGroupedItems]
  )

  return (
    <>
      {singularProducts.length > 0 && (
        <CardProductList
          {...props}
          products={singularProducts.map((product, index) => (
            <React.Fragment key={index}>
              <CardProduct
                Flag={
                  showFreeFlag &&
                  product.price === 0 && <CardProductFlag>FREE</CardProductFlag>
                }
                mt="0"
                product={product}
              />
              {renderAfterList && renderAfterList(product)}
            </React.Fragment>
          ))}
        />
      )}
      {productsWithGroupedItems.length > 0 &&
        productsWithGroupedItems.map((product, index) => (
          <PanelComboItem
            data-attr-sku={product.sku}
            data-cy={cySelectorName}
            data-cy-sku={product.sku}
            data-what="product-card-group"
            headingLevel={'h4'}
            key={index}
            slashThroughHeading={
              product.price > 0 &&
              parseFloat(product.markupPrice) * 100 > product.price ? (
                <PanelSlashThroughHeading
                  actualPrice={product.price}
                  markupPrice={parseFloat(product.markupPrice) * 100}
                  panelHeading={product.description}
                  subheading={true}
                />
              ) : null
            }
            title={product.description}
            titleAddon={
              product.price === 0 ? 'FREE' : formatPriceFromCents(product.price)
            }
          >
            <CardProductList
              {...props}
              products={duplicateItemsByQuantity(product.groupedItems)
                .reduce((groupedProducts, groupedProduct, idx) => {
                  const isFree =
                    groupedProduct.price === 0 ||
                    groupedProduct.freeQuantity >
                      groupedProducts.filter(
                        (obj) => obj.sku === groupedProduct.sku
                      ).length
                  return [
                    ...groupedProducts,
                    {
                      sku: groupedProduct.sku,
                      card: (
                        <CardProduct
                          Flag={
                            showFreeFlag &&
                            isFree && <CardProductFlag>FREE</CardProductFlag>
                          }
                          key={idx} // Use idx because skus might be repeated
                          mt="0"
                          product={groupedProduct}
                        />
                      ),
                    },
                  ]
                }, [])
                .map((groupedProduct) => groupedProduct.card)}
            />
            {renderAfterList && renderAfterList(product)}
          </PanelComboItem>
        ))}
    </>
  )
}
