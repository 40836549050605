import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { CMS } from '~/cms/types'
import { convertRangeToArrayOfDays, YYYY_MM_DD } from '~/utils/dates'
import { DateYMDString } from 'design/date-utils'
import {
  CalendarCustomDay,
  DateOverrideType,
} from '~/components/Calendar/Calendar'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(localizedFormat)

export const UNAVAILABLE_DATE_TEXT =
  'To help improve your delivery experience and reduce carrier delays, this bill date is unavailable.'

// flatten ranges, since ContentfulCalendarCustomizedDateRange *is* a range,
// while ContentfulCalendarCustomizedDates *has* ranges.
const flattenDateRangeGroups = (customizedDates: CMS.CustomizedDateRange[]) =>
  customizedDates.reduce<CMS.CalendarCustomizedDateRange[]>((ranges, range) => {
    if (
      range.__typename === 'ContentfulCalendarCustomizedDates' &&
      !!range.dateRangeOverrides
    ) {
      return [...ranges, ...range.dateRangeOverrides]
    } else if (range.__typename === 'ContentfulCalendarCustomizedDateRange') {
      return [...ranges, range]
    }
    if (process.env.NODE_ENV !== 'production') {
      throw new Error(
        `Unexpected data received (type ${range.__typename}): ${JSON.stringify(
          range
        )}`
      )
    }
    return ranges
  }, [])

const createBlackoutDatesFromStartDateOffset = (
  startDateOffset: number
): CMS.CalendarCustomizedDateRange => ({
  startDateTime: dayjs().startOf('day').format(YYYY_MM_DD),
  endDateTime: dayjs()
    .add(startDateOffset, 'd')
    .endOf('day')
    .format(YYYY_MM_DD),
  tooltipText: UNAVAILABLE_DATE_TEXT,
  dateOverrideType: DateOverrideType.blackOut,
  __typename: 'ContentfulCalendarCustomizedDateRange',
})

export const parseCustomDateRanges = ({
  startDateOffset,
  customizedDates,
}: CMS.Calendar): CMS.CalendarCustomizedDateRange[] => {
  const allRanges: CMS.CalendarCustomizedDateRange[] = []

  // TODO: handle the offset somewhere else?
  // set the rolling blackout date range
  if (startDateOffset > 0) {
    allRanges.push(createBlackoutDatesFromStartDateOffset(startDateOffset))
  }

  if (!customizedDates) return allRanges

  const flattenedRanges = flattenDateRangeGroups(customizedDates)

  const flattenedRangesWithInfo = flattenedRanges.map(
    // remove extraneous data
    ({ id: _i, contentful_id: _c, dateOverrideType, ...range }) => ({
      ...range,
      // convert any ranges in the old BlackoutRange format
      dateOverrideType: dateOverrideType || DateOverrideType.blackOut,
    })
  )

  return allRanges.concat(flattenedRangesWithInfo)
}

export const convertRangeToArrayOfCustomDays = (
  dateRanges: CMS.CalendarCustomizedDateRange[]
): CalendarCustomDay[] => {
  const allDatesTable: Record<string, CalendarCustomDay> = {}

  dateRanges.flat().forEach(({ startDateTime, endDateTime, ...rangeInfo }) => {
    const days = convertRangeToArrayOfDays(startDateTime, endDateTime)

    days.forEach((day) => {
      const { blackOut } = DateOverrideType
      // formatting without spaces results in the object being sorted by date for free 😁
      const formattedDate = day.format('YYYYMMDD') as DateYMDString
      const newDay = { day, ...rangeInfo }

      /*
       * This logic is based on the simple rule that a blackout date takes precedence over
       * any other type of custom date.
       * Note that this rule is implemented in a very basic way, and will result in the following cases:
       *  - If a blackout date overlaps with another blackout date, the last one encountered will be kept.
       *  - If any non-blackout date overlaps with any other non-blackout date, the last one encountered will be kept.
       * In the real world, our Content Managers should be ensuring such confusing overlaps do not occur.
       * If new custom day types are added in the future, their precedence will have to be managed by updating this
       *  logic.
       * */
      const isBlackoutDate = newDay.dateOverrideType === blackOut
      const dateNotYetCounted = !allDatesTable[formattedDate]
      // blackout info replaces any existing info for this day
      // all days except blackout days MAY be replaced by later instances of the same date.
      if (isBlackoutDate || dateNotYetCounted) {
        allDatesTable[formattedDate] = newDay
      }
    })
  })

  return Object.values(allDatesTable)
}
