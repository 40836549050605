import baseLoadable from '@loadable/component'
import baseLoadableVisiblity from 'react-loadable-visibility/loadable-components'
import { Icon, IconName } from 'design/components/Icons/types'

const resolveComponentFunction = (component: { default: Icon }): Icon =>
  component.default

export const loadIconById = (
  id: IconName,
  useVisibility: boolean = false,
  fallback?: JSX.Element
) => {
  /**
   * Loadable Component Knowledge Share:
   * When using loadable components the function name 'loadable' is very important
   * when this is minified/uglified/chunked - the name is preserved, loadable functions
   * will not be identified / mapped to chunks if the function name differs
   */

  const loadable = useVisibility ? baseLoadableVisiblity : baseLoadable
  switch (id) {
    case 'americanExpressCard':
      return loadable(() => import('design/components/Icons/defs/CcAmex'), {
        fallback: fallback,
        resolveComponent: resolveComponentFunction,
      })
    case 'attentionGrabberWarning':
      return loadable(
        () => import('design/components/Icons/defs/AttentionGrabberWarning'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'blankCardBack':
      return loadable(
        () => import('design/components/Icons/defs/CcBlankCardBack'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'blankCardFront':
      return loadable(
        () => import('design/components/Icons/defs/CcBlankCardFront'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'butcherBoxLine':
      return loadable(
        () => import('design/components/Icons/defs/ButcherBoxLine'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'butcherBoxSolidLine':
      return loadable(
        () => import('design/components/Icons/defs/ButcherBoxSolidLine'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'chatBubble':
      return loadable(() => import('design/components/Icons/defs/ChatBubble'), {
        fallback: fallback,
        resolveComponent: resolveComponentFunction,
      })
    case 'infoBubble':
      return loadable(() => import('design/components/Icons/defs/InfoBubble'), {
        fallback: fallback,
        resolveComponent: resolveComponentFunction,
      })
    case 'lock':
      return loadable(() => import('design/components/Icons/defs/Lock'), {
        fallback: fallback,
        resolveComponent: resolveComponentFunction,
      })
    case 'masterCardCard':
      return loadable(
        () => import('design/components/Icons/defs/CcMasterCard'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'passwordShow':
      return loadable(
        () => import('design/components/Icons/defs/PasswordShow'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'passwordHide':
      return loadable(
        () => import('design/components/Icons/defs/PasswordHide'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'phone':
      return loadable(() => import('design/components/Icons/defs/Phone'), {
        fallback: fallback,
        resolveComponent: resolveComponentFunction,
      })
    case 'radioLargeChecked':
      return loadable(
        () => import('design/components/Icons/defs/RadioLargeChecked'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'radioLargeUnchecked':
      return loadable(
        () => import('design/components/Icons/defs/RadioLargeUnchecked'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'radioSmallChecked':
      return loadable(
        () => import('design/components/Icons/defs/RadioSmallChecked'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'radioSmallUnchecked':
      return loadable(
        () => import('design/components/Icons/defs/RadioSmallUnchecked'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'radioSolid':
      return loadable(() => import('design/components/Icons/defs/RadioSolid'), {
        fallback: fallback,
        resolveComponent: resolveComponentFunction,
      })
    case 'satisfactionGuarantee':
      return loadable(
        () => import('design/components/Icons/defs/SatisfactionGuarantee'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'sendReminderTwoColor':
      return loadable(
        () => import('design/components/Icons/defs/SendReminder'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    case 'vegan':
      return loadable(() => import('design/components/Icons/defs/Vegan'), {
        fallback: fallback,
        resolveComponent: resolveComponentFunction,
      })
    case 'visaCard':
      return loadable(() => import('design/components/Icons/defs/CcVisa'), {
        fallback: fallback,
        resolveComponent: resolveComponentFunction,
      })
    case 'warningCircle':
      return loadable(
        () => import('design/components/Icons/defs/WarningCircle'),
        {
          fallback: fallback,
          resolveComponent: resolveComponentFunction,
        }
      )
    // If you receive a type error here - you likely haven't created a case
    // statement for that icon above.
    default:
      const _exhautivenesscheck: never = id
      return _exhautivenesscheck
  }
}
