import { useLocation } from '@reach/router'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

type SEOProps = {
  description?: string
  /**
   * Either a path or URL to an image to display in social platforms.
   *
   * Expects a path to begin with a / and will render with a CDN domain.
   * Expects a url to begin with // and will default to TCP.
   */
  image?: string
  pathname?: string
  title?: string
  shouldIndex?: boolean
}

const SEO = ({
  title,
  description,
  image,
  pathname,
  shouldIndex = true,
}: SEOProps) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultDescription,
          defaultImage,
          defaultTitle,
          siteUrl,
          titleTemplate,
          twitterUsername,
          logo,
          author,
          address,
          contact,
        },
      },
    }) => {
      const urlPath = pathname ? pathname : useLocation()?.pathname
      const img = image || defaultImage
      const imgUrl =
        img.slice(0, 2) === '//' ? `https:${img}` : `${siteUrl}${img}`
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: imgUrl,
        url: `${siteUrl}${urlPath || '/'}`,
        logo,
        author,
        siteUrl,
        address,
        contact,
      }

      return (
        <>
          <Helmet defaultTitle={seo.title} titleTemplate={titleTemplate}>
            <html lang="en" />
            <meta charSet="utf-8" />
            <meta content={seo.description} name="description" />
            <link href={seo.url} rel="canonical" />

            <meta content="en_US" property="og:locale" />
            <meta content="website" property="og:type" />
            <meta content={seo.title} property="og:title" />
            <meta content={seo.description} property="og:description" />
            <meta content="ButcherBox" property="og:site_name" />
            <meta content={seo.url} property="og:url" />
            <meta content={seo.image} property="og:image" />

            <meta content="summary" name="twitter:card" />
            <meta content={twitterUsername} name="twitter:creator" />
            <meta content={seo.description} name="twitter:description" />
            <meta content={seo.title} name="twitter:title" />
            <meta content={seo.title} name="twitter:domain" />
            <meta content={seo.image} name="twitter:image:src" />
            <meta content={seo.image} name="twitter:image" />

            <meta content={seo.image} name="image" />

            {shouldIndex ? null : <meta content="noindex" name="robots" />}

            <meta content="6JV5dCDAb64cloDhYrIgjM0Vmr4" name="alexaVerifyID" />
            <meta
              content="no0Xi7CUhjQ8gfrWrMS7QxPxEwTbiqsGE6Wz30T4cuU"
              name="google-site-verification"
            />
            <script type="application/ld+json">
              {`
          {
            "@context": "http://www.schema.org",
            "@type": "Corporation",
            "name": "${seo.author}",
            "url": "${seo.siteUrl}",
            "logo": "${seo.logo}",
            "description": "${seo.description}",
            "address": {
              "@type": "PostalAddress",
              "postOfficeBoxNumber": "${seo.address.poBox}",
              "addressLocality": "${seo.address.city}",
              "addressRegion": "${seo.address.state}",
              "postalCode": "${seo.address.postalCode}",
              "addressCountry": "${seo.address.country}"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "${seo.contact.general.telephone}",
              "contactType": "general"
            }
          }
        `}
            </script>
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultDescription
        defaultImage: image
        defaultTitle: title
        siteUrl: url
        titleTemplate
        twitterUsername
        author
        logo
        address {
          poBox
          city
          state
          postalCode
          country
        }
        contact {
          general {
            telephone
          }
        }
      }
    }
  }
`
