import { Box, rem, Text, TextLink } from '@butcherbox/freezer'
import { Link } from 'gatsby'
import { BaseLink } from 'design/components/Typography/Typography'
import React from 'react'
import TooltipShifter from '~/components/CartDrawer/components/NextBox/TooltipShifter'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import TooltipPaymentProcessing from '~/components/TooltipPaymentProcessing'
import { CloseButton } from './CloseButton'
import { formatDate, formatPriceFromCents } from '~/utils'

const NextBox = ({
  onClose,
  orderTotal,
  billDate,
}: {
  onClose: () => void
  orderTotal: number
  billDate: string
}) => {
  return (
    <Box
      background="white"
      borderBottom="silt"
      marginBottom={16}
      paddingTop={28}
      px={16}
      w="100%"
    >
      <Text display="flex" justifyContent="spaceBetween" variant="H2Bold">
        Next Box
        <CloseButton fontSize={rem(14)} onClick={onClose} />
      </Text>
      <PanelBreak />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        justifyContent="spaceBetween"
      >
        <Box component="dl">
          <Text component="dt" display="inline" variant="Body1Bold">
            Total&nbsp;
          </Text>
          <Text component="dd" display="inline" variant="Body1Regular">
            {formatPriceFromCents(orderTotal)}
          </Text>
          <br />
          <Text component="dt" display="inline" variant="Body1Bold">
            Bill Date&nbsp;
          </Text>
          <Text component="dd" display="inline" variant="Body1Regular">
            {formatDate(billDate, 'MMM D')}
          </Text>
        </Box>

        <TooltipShifter right={rem(100)}>
          <TooltipPaymentProcessing>
            {({ isInteractionDisabled }) =>
              isInteractionDisabled ? (
                <BaseLink aria-disabled={true} as="p" fontSize={rem(16)}>
                  Edit Box
                </BaseLink>
              ) : (
                <TextLink
                  component={Link}
                  onClick={onClose}
                  to="/account/your-box"
                >
                  Edit Box
                </TextLink>
              )
            }
          </TooltipPaymentProcessing>
        </TooltipShifter>
      </Box>
    </Box>
  )
}

export default NextBox
