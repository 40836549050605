// adapted from https://github.com/butcherbox/butcherbox/blob/e31d14913a24d1defa5c8c33de0ee9a1745e6ecf/www/bacon-for-life/js/scripts.js
import dayjs, { Dayjs } from 'dayjs'
import qs from 'querystringify'
import { setCookie } from '~/utils/cookie'

type ISetLanderCookies = {
  couponCode?: string
  /** The unique slug of the lander used in the DB to track conversions. */
  lander: string
  offer?: {
    expiry: Dayjs
    id: number
  }
  /** The text to be displayed in the black banner for the coupon/offer during checkout. */
  offerText?: string
  /** When a query parameter value isn't set in the URL, use these values instead */
  queryDefaults?: {
    utm_campaign?: string
  }
}

export const COOKIE_LANDER_CAMPAIGN = 'bbmc_lander'
export const COOKIE_LANDER_COUPON_CODE = 'static_coupon_code'
export const COOKIE_LANDER_OFFER_ID = 'exclusive_offer'
export const COOKIE_LANDER_OFFER_TEXT = 'affiliate_specify_offer'

export default function setLanderCookies(options: ISetLanderCookies) {
  setCookie(COOKIE_LANDER_CAMPAIGN, options.lander)

  // Merge query param defaults with url state
  const queryParams = qs.parse(location.search || '')
  const queryParamsWithDefaults = Object.entries(
    options?.queryDefaults || {}
  ).reduce((params, [utm, val]) => {
    if (!val) return params

    return {
      [utm]: encodeURIComponent(val),
      ...params,
    }
  }, queryParams)
  const newUrl = [
    location.protocol,
    '//',
    location.host,
    location.pathname,
    qs.stringify(queryParamsWithDefaults, true),
  ].join('')

  // Add any default query params to URL
  window.history.replaceState({ path: newUrl }, '', newUrl)

  // Track all query params as cookies
  for (const [param, val] of Object.entries(queryParamsWithDefaults)) {
    setCookie(param, val, dayjs().add(30, 'day'))
  }

  if (options.couponCode) {
    setCookie(COOKIE_LANDER_COUPON_CODE, options.couponCode)
  }

  if (options.offer) {
    setCookie(
      COOKIE_LANDER_OFFER_ID,
      String(options.offer.id),
      options.offer.expiry
    )
  }

  if (options.offerText) {
    setCookie(COOKIE_LANDER_OFFER_TEXT, options.offerText)
  }

  window.dataLayer?.push({ ecomm_orderinfo: { offer: options.offerText } })
}
