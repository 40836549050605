import React from 'react'
import ModalContext from 'design/components/Modal/ModalContext'

function ModalProvider({ children }) {
  const modalRef = React.useRef<HTMLDivElement>()
  const [context, setContext] = React.useState<HTMLDivElement>()

  /**
   * component refs aren't initialized until after mount
   */
  React.useEffect(() => {
    setContext(modalRef.current)

    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    const resizeHandler = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    resizeHandler()

    window.addEventListener('resize', resizeHandler)
    return () => window.removeEventListener('resize', resizeHandler)
  }, [])

  return (
    <>
      <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
      <div ref={modalRef} />
    </>
  )
}
export default ModalProvider
