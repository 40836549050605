import { graphql, useStaticQuery } from 'gatsby'
import { CMS } from '~/cms/types'

export const useEditBoxDateCalendar = () => {
  const { calendar } = useStaticQuery<{ calendar: CMS.Calendar }>(graphql`
    query {
      calendar: contentfulCalendar(
        calendarId: { eq: "account-edit-box-date-modal" }
      ) {
        ...Calendar
      }
    }
  `)

  if (!calendar) {
    throw new Error(
      `Expected to find Contentful Calendar with calendarId="account-edit-box-date-modal"`
    )
  }
  return calendar
}
