import Cookies from 'js-cookie'
import querystringify from 'querystringify'
import { CANCEL_SAVE_ACTIONS, FACEBOOK_PIXEL } from '~/analytics/constants'
import {
  OptimizelyExperimentsAvailable,
  OptimizelyFeatureFlags,
  ProductCategory,
  ProductListCategory,
  SegmentQueueEvent,
} from '~/analytics/types'
import {
  Address,
  BaseDeal,
  BaseProduct,
  Box,
  BoxItem,
  BoxSize,
  BoxType,
  Deal,
  GroupedBoxItem,
  Offer,
  Order,
  OrderIssue,
  Product,
  Subscription,
  SubscriptionIntervalSupported,
  UserActionDataType,
  UserActionName,
} from '~/bb-api/schemata'
import { ValidPaths } from '~/components/CancelFlowModals/constants'
import { IReportAnIssue } from '~/context/reportAnIssue'
import { StagedSubscription } from '~/context/stagedSubscription'
import { EasyPostAddress } from '~/functions/types/easypost'
import { Frames } from '~/routes/CheckoutFlow/constants'
import { ICongratulationsState } from '~/routes/CheckoutFlow/PaymentFrame/types'
import { stripLeadingSlash } from '~/utils'
import { trackError } from './errors'
import { makeSegmentProduct } from './factories'
import { Segment } from './types'

export type TrackingFn<T extends Object> = (
  properties: T,
  callback?: () => void
) => void

export const trackProductListViewed: TrackingFn<{
  /**
   * ! IMPORTANT: This tracking expects an ordered array, according to the order that the UI
   *              will render the products. Avoid calling this function until you are sure of
   *              the order that given products will be provided.
   */
  orderedProducts: BaseProduct[]
  productCategory: ProductCategory
  productListCategory: ProductListCategory
}> = ({ orderedProducts, productCategory, productListCategory }, callback) => {
  const segmentProducts = orderedProducts.map<Segment.Product>((product, idx) =>
    makeSegmentProduct({ ...product, position: idx, productCategory })
  )

  window.analytics?.track(
    'Product List Viewed',
    {
      products: segmentProducts,
      category: productListCategory,
    },
    callback
  )
}

export interface ViewedProduct {
  sku: Product['sku']
  ts: number
}

export const trackProductsViewed: TrackingFn<{
  viewedProducts: ViewedProduct[]
}> = ({ viewedProducts }, callback) => {
  window.analytics?.track(
    'Products Viewed',
    { products: viewedProducts },
    callback
  )
}

export const trackIncrementAddon = (product: BoxItem) => {
  window.analytics?.track('Addon Quantity Increased', {
    name: product.description,
    price: product.price / 100,
    quantity: product.quantity,
    quantity_delta: 1,
    sku: product.sku,
    total: (product.price / 100) * product.quantity,
    total_delta: product.price / 100,
    type: 'addon',
  })
}

export const trackDecrementAddon = (product: BoxItem) => {
  window.analytics?.track('Addon Quantity Reduced', {
    name: product.description,
    price: product.price / 100,
    quantity: product.quantity,
    quantity_delta: -1,
    sku: product.sku,
    total: (product.price / 100) * product.quantity,
    total_delta: -(product.price / 100),
    type: 'addon',
  })
}

export const trackIncrementMemberDeal = (product: BoxItem) => {
  window.analytics?.track('Member Deal Quantity Increased', {
    name: product.description,
    price: product.price / 100,
    quantity: product.quantity,
    quantity_delta: 1,
    sku: product.sku,
    total: (product.price / 100) * product.quantity,
    total_delta: product.price / 100,
    type: 'member deal',
  })
}

export const trackDecrementMemberDeal = (product: BoxItem) => {
  window.analytics?.track('Member Deal Quantity Reduced', {
    name: product.description,
    price: product.price / 100,
    quantity: product.quantity,
    quantity_delta: -1,
    sku: product.sku,
    total: (product.price / 100) * product.quantity,
    total_delta: -(product.price / 100),
    type: 'member deal',
  })
}

export const getUtmParams = (location) => {
  return querystringify.parse(location.search) as {
    utm_campaign?: string
    utm_content?: string
    utm_medium?: string
    utm_source?: string
    utm_term?: string
    impId?: string
    irclickid?: string
  }
}

export const trackHeapInitialPageView = (isAuthenticated: boolean) => {
  const params = getUtmParams(location)
  return window.heap?.track('Initial Pageview', {
    path: location.pathname,
    title: document.title,
    domain: location.hostname,
    url: location.href,
    utmCampaign: params.utm_campaign || null,
    utmContent: params.utm_content || null,
    utmMedium: params.utm_medium || null,
    utmSource: params.utm_source || null,
    utmTerm: params.utm_term || null,
    impId: params.impId || null,
    irclickid: params.irclickid || null,
    'Has Authenticated': isAuthenticated,
  })
}

export const trackHeapCardErrors = (errorMessage) => {
  return window.heap?.track('Payment Validation Error', {
    errorMessage: errorMessage,
    path: location.pathname,
    title: document.title,
    domain: location.hostname,
    url: location.href,
  })
}

export const trackAccountExistsError = (errorMessage: string) => {
  return window.heap?.track('Account Creation Error', {
    errorMessage: errorMessage,
    path: location.pathname,
  })
}

export const trackHeapEventProperties = (isAuthenticated: boolean) => {
  window.heap?.clearEventProperties()
  window.heap?.addEventProperties({
    'Has Authenticated': isAuthenticated,
  })
}

export const trackInvoiceItemsAddedToCheckoutCart = (
  stagedSubscription: StagedSubscription,
  invoiceItems: Array<GroupedBoxItem>
) => {
  const {
    quantity: totalDealsInBoxCount,
    price: totalDealsInBoxRevenue,
  } = invoiceItems.reduce(
    (acc: { quantity: number; price: number }, deal: GroupedBoxItem) => {
      return {
        quantity: acc.quantity + deal.quantity,
        price: acc.price + deal.quantity * deal.price,
      }
    },
    { quantity: 0, price: 0 }
  )

  const uniqueDealsInBoxCount = invoiceItems.length
  const heapPayload = {
    addons: JSON.stringify(stagedSubscription.box.addons),
    dealsInBox: JSON.stringify(invoiceItems),
    interval: stagedSubscription.interval,
    items: JSON.stringify(stagedSubscription.box.items),
    planType: stagedSubscription.box.type,
    totalDealsInBoxCount,
    totalDealsInBoxRevenue,
    uniqueDealsInBoxCount,
  }
  window.heap?.track('Invoice Items Added to Cart', heapPayload)
}

export function issueTypeToSegmentReason(
  issueType: OrderIssue['issueType']
): OrderIssue['issueType'] | void {
  if (issueType === 'other') {
    trackError((scope) => {
      scope.capture(new Error('issueType "other" not implemented.'))
    })
  } else {
    return issueType
  }
}

export const trackIssueCreditIssued = (
  issue: OrderIssue,
  orderId: Order['id'],
  amount: IReportAnIssue['creditAmount']
) => {
  window.analytics?.track('Credit Issued', {
    affected_product_skus: issue.items || [],
    amount,
    order_id: `${orderId}`,
    reason: issueTypeToSegmentReason(issue.issueType),
  })
}

export const trackIssueOrderReshipped = (
  issue: OrderIssue,
  orderId: Order['id']
) => {
  window.analytics?.track('Order Reshipped', {
    order_id: `${orderId}`,
    reason: issueTypeToSegmentReason(issue.issueType),
  })
}

export const trackIssueReplacementsAdded = (
  issue: OrderIssue,
  orderId: Order['id'],
  upcomingOrderId?: string
) => {
  window.analytics?.track('Replacements Added To Upcoming Order', {
    affected_product_skus: issue.items || [],
    order_id: `${orderId}`,
    reason: issueTypeToSegmentReason(issue.issueType),
    upcoming_order_id: upcomingOrderId,
  })
}

export const trackCancellationStarted = () => {
  window.analytics?.track('Cancellation Started')
}

export const trackCancellationAborted = () => {
  window.analytics?.track('Cancellation Aborted')
}

export const trackCancellationStepViewed = (
  pathname: ValidPaths,
  optionText?: string
) => {
  if (pathname) {
    const option_id = pathname === '/' ? 'index' : stripLeadingSlash(pathname)
    window.analytics?.track('Cancellation Step Viewed', {
      option_id: option_id,
      option_text: optionText,
    })
  }
}

export const trackCancellationSave = (
  action: CANCEL_SAVE_ACTIONS,
  primaryReason?: ValidPaths,
  secondaryReason?: ValidPaths
) => {
  const primary_reason_id =
    primaryReason === '/' ? 'index' : stripLeadingSlash(primaryReason)
  const secondary_reason_id =
    secondaryReason === '/' ? 'index' : stripLeadingSlash(secondaryReason)

  window.analytics?.track('Cancellation Saved', {
    action,
    primary_reason_id,
    secondary_reason_id,
  })
}

export const trackCancellationComplete = (
  primaryReason: ValidPaths,
  secondaryReason?: ValidPaths
) => {
  const primary_reason_id =
    primaryReason === '/' ? 'index' : stripLeadingSlash(primaryReason)
  const secondary_reason_id =
    secondaryReason === '/' ? 'index' : stripLeadingSlash(secondaryReason)

  window.analytics?.track('Customer Cancelled', {
    primary_reason_id,
    secondary_reason_id,
  })
}

export const trackCustomBoxQuantityIncreased = (product: BoxItem) =>
  window.analytics?.track('Custom Box Cut Quantity Increased', {
    name: product.description,
    quantity_delta: 1,
    quantity: product.quantity,
    sku: product.sku,
    type: 'custom cut',
  })

export const trackCustomBoxQuantityReduced = (product: BoxItem) =>
  window.analytics?.track('Custom Box Cut Quantity Reduced', {
    name: product.description,
    quantity_delta: -1,
    quantity: product.quantity,
    sku: product.sku,
    type: 'custom cut',
  })

export const trackBoxCustomized = (
  currentProducts: BoxItem[],
  addedProducts: BoxItem[],
  removedProducts: BoxItem[]
) =>
  window.analytics?.track('Box Customized', {
    added_skus: addedProducts.map((x) => x.sku),
    current_product_names: currentProducts.map((x) => x.description),
    current_skus: currentProducts.map((x) => x.sku),
    removed_skus: removedProducts.map((x) => x.sku),
  })

const getNormalizedInterval = (
  rawInterval: Subscription['interval']
): '2w' | '4w' | '6w' | '8w' | 'legacy' => {
  switch (rawInterval) {
    case 'every_14_days':
      return '2w'
    case 'every_month':
      return '4w'
    case 'every_42_days':
      return '6w'
    case 'every_2_months':
      return '8w'
    default:
      return 'legacy'
  }
}

export const trackPlanChosen = (planId: BoxType, planName: Box['name']) => {
  const fbp = Cookies.get(FACEBOOK_PIXEL)

  window.analytics?.track('Subscription Plan Chosen', {
    plan_id: planId,
    plan_description: planName,
    fbp: fbp ? fbp : '',
  })
}

export const trackIntervalChange = (
  prevInterval: Subscription['interval'],
  nextInterval: Subscription['interval']
) =>
  window.analytics?.track('Subscription Frequency Changed', {
    next_frequency: getNormalizedInterval(nextInterval),
    prev_frequency: getNormalizedInterval(prevInterval),
  })

export const trackShippingAddressRecommendationIgnored = (
  recommendedAddress: EasyPostAddress,
  userProvidedAddress: Address
) => {
  window.analytics?.track('Address Recommendation Ignored', {
    recommended_address: {
      address_line_one: recommendedAddress.street1,
      address_line_two: recommendedAddress.street2,
      city: recommendedAddress.city,
      postal_code: recommendedAddress.zip,
      state: recommendedAddress.state,
    },
    user_provided_address: {
      address_line_one: userProvidedAddress.addressLineOne,
      address_line_two: userProvidedAddress.addressLineTwo,
      city: userProvidedAddress.city,
      postal_code: userProvidedAddress.postalCode,
      state: userProvidedAddress.state,
    },
  })
}

export const trackSignIn = (email: string) =>
  window.analytics?.track('Signed In', { email })
export const trackSignInImpersonated = (csEmail: string) => {
  window.analytics?.track('Signed In, Impersonated', { csEmail })
}
export const trackSignOut = (email: string) =>
  window.analytics?.track('Signed Out', { email })

export const trackCheckoutMarketingOptIn: TrackingFn<{
  couponCode?: string
  email: string
  offer?: Offer
  offerId?: string
  utmCampaign?: string
  utmContent?: string
  utmMedium?: string
  utmSource?: string
  utmTerm?: string
}> = (
  {
    couponCode,
    email,
    offer,
    offerId,
    utmCampaign = '',
    utmContent = '',
    utmMedium = '',
    utmSource = '',
    utmTerm = '',
  },
  callback
) => {
  window.analytics?.track(
    'Checkout Marketing Opt In',
    {
      coupon: couponCode || undefined,
      email,
      offer_id: offerId,
      offer_description: offer?.description,
      offer_sku: offer?.sku,
      utm_campaign: utmCampaign,
      utm_content: utmContent,
      utm_medium: utmMedium,
      utm_source: utmSource,
      utm_term: utmTerm,
    },
    callback
  )
}

export const trackNewsletterOptIn = (email: string) =>
  window.analytics?.track('Newsletter Opt In', { email })

export const trackSignupAddonsChosen = (addons: BoxItem[]) => {
  const shapedAddons = addons.map(({ description, quantity, sku }) => ({
    description,
    quantity,
    sku,
  }))

  window.analytics?.track('Subscription Recurring Add-ons Chosen', {
    addons: shapedAddons,
  })
}

export const trackSignupAddonsQuantityIncreased = (
  product: BoxItem,
  quantity: number
) => {
  window.analytics?.track('Addon Quantity Increased', {
    name: product.description,
    price: product.price / 100,
    quantity: quantity,
    quantity_delta: 1,
    sku: product.sku,
    total: (product.price / 100) * quantity,
    total_delta: product.price / 100,
    type: 'addon',
  })
}

export const trackSignUpAddonsQuantityDecrease = (
  product: BoxItem,
  quantity: number
) => {
  window.analytics?.track('Addon Quantity Reduced', {
    name: product.description,
    price: product.price / 100,
    quantity: quantity,
    quantity_delta: -1,
    sku: product.sku,
    total: (product.price / 100) * quantity,
    total_delta: -(product.price / 100),
    type: 'addon',
  })
}

export const trackPasswordChanged = (
  origin: 'account_pages' | 'post_checkout_form'
) => window.analytics?.track('Password Changed', { origin })

const transformBoxSize = (boxSize: BoxSize) => {
  switch (boxSize) {
    case 'classic':
      return 'classic'
    case 'big':
      return 'xl'
    default:
      trackError((scope) => {
        scope.capture(
          new Error(`Invalid box size provided. Received: "${boxSize}"`)
        )
      })
      throw new Error('Invalid box size provided')
  }
}

export const trackBoxSizeChosen = (boxSize: BoxSize) => {
  const fbp = Cookies.get(FACEBOOK_PIXEL)

  window.analytics?.track('Subscription Box Size Chosen', {
    box_size: transformBoxSize(boxSize),
    fbp: fbp ? fbp : '',
  })
}

export const trackSignupBoxFrequencyChosen = (
  interval: SubscriptionIntervalSupported
) => {
  const frequency_weeks = {
    every_14_days: 2,
    every_month: 4,
    every_42_days: 6,
    every_2_months: 8,
  }[interval]
  window.analytics?.track('Subscription Box Frequency Chosen', {
    frequency_weeks,
  })
}

export const trackSignupCustomCutsChosen = (items: Box['items']) => {
  window.analytics?.track('Subscription Custom Box Cuts Chosen', {
    items: items.map((item) => {
      return {
        sku: item.sku,
        quantity: item.quantity,
        description: item.description,
      }
    }),
  })
}

export const trackCheckoutStepViewed = (checkoutID: string, step: number) => {
  window.analytics?.track('Checkout Step Viewed', {
    checkout_id: checkoutID,
    step: step,
  })
}

export const trackCheckoutStepCompleted: TrackingFn<{
  checkout_id: string
  step: number
}> = ({ checkout_id, step }, callback) =>
  window.analytics?.track(
    'Checkout Step Completed',
    {
      checkout_id,
      step,
    },
    callback
  )

export const trackExitIntentInteraction = (
  action: 'accept' | 'decline',
  product_id: string,
  product_description: string
) => {
  window.analytics?.track('Exit Intent Interaction', {
    action,
    product_id,
    product_description,
  })
}

export const trackEmailOptinModalSeen = (
  pagePath: string,
  modalTrigger: string
) => {
  const heapPayload = {
    pagePath: pagePath,
    modalTrigger: modalTrigger,
  }
  window.heap?.track('Email Optin Modal Seen', heapPayload)
}

export const trackEmailOptinModalSubmitted = (
  pagePath: string,
  modalTrigger: string,
  email: string
) => {
  const heapPayload = {
    pagePath: pagePath,
    modalTrigger: modalTrigger,
    email: email,
  }
  window.heap?.track('Email Optin Modal Submitted', heapPayload)
}
export const cancellationFlowOfferSeen = (
  offer_sku: string,
  offer_description: string
) => {
  window.analytics?.track('Cancellation Flow Offer Seen', {
    offer_sku: offer_sku,
    offer_description: offer_description,
  })
}

export const cancellationFlowOfferAdded = (
  offer_sku: string,
  offer_description: string
) => {
  window.analytics?.track('Cancellation Flow Offer Added', {
    offer_sku: offer_sku,
    offer_description: offer_description,
  })
}

export const waitFor: <T extends TrackingFn<Object>>(
  fn: T,
  opts: {
    /**
     * timeout represents the number of milliseconds to wait before resolving the promise
     */
    timeout: number
    /**
     * throwOnTimeout will reject the returned promise rather than resolve it
     */
    throwOnTimeout?: boolean
  }
) => (props: Parameters<T>[0]) => Promise<void | string> = (
  fn,
  { timeout, throwOnTimeout = false }
) => {
  return (props) =>
    new Promise((resolve, reject) => {
      const timeoutId = window?.setTimeout(() => {
        const message = `Timed out waiting ${
          timeout || 'unknown'
        } milliseconds for tracking function ${
          fn?.name || 'unknown'
        } to complete.`

        trackError((scope) => {
          scope.capture(new Error(message))
        })
        throwOnTimeout ? reject(message) : resolve(message)
        resolve()
      }, timeout)

      fn(props, () => {
        window?.clearTimeout(timeoutId)
        resolve()
      })
    })
}

export const trackOptimizelyExperimentSeen = (
  experimentId: string,
  variant: string,
  experimentKey: string,
  variantKey: string
) => {
  window.analytics?.track('Optimizely Experiment Seen', {
    experiment_id: experimentId,
    experiment_variation: variant,
    experiment_key: experimentKey,
    variation_key: variantKey,
  })
}

export const trackOptimizelyExperimentsAvailable = (
  experimentsAvailable: OptimizelyExperimentsAvailable
) => {
  window.analytics?.track('Optimizely Experiments Available', {
    experiments_available: experimentsAvailable,
  })
}

export const trackOptimizelyFeatureFlags = (
  featureFlags: OptimizelyFeatureFlags
) => {
  window.analytics?.track('Optimizely Feature Flags', {
    feature_flags: featureFlags,
  })
}

export const trackOptimizelyFeatureSeen = (featureId) => {
  window.analytics?.track('Optimizely Feature Seen', {
    feature_id: featureId,
  })
}

/**
 * This Segment event is named to match the authenticated deal add event,
 * but it is currently only used for tracking the initial CTA deal
 * add in the checkout flow, with slightly different properties tracked.
 * This should be changed once checkout deals are a permanent feature.
 */
export const trackCheckoutDealAdded = (
  product: Deal,
  displayOrder: number,
  quantity: number
) => {
  window.analytics?.track('Checkout Deal Quantity Increased', {
    sku: product.sku,
    description: product.description,
    price: product.price / 100,
    display_order: displayOrder,
    quantity,
    quantity_delta: 1,
    total: (product.price / 100) * quantity,
  })
}

export const trackCheckoutDealRemoved = (
  product: Deal,
  displayOrder: number,
  quantity: number
) => {
  window.analytics?.track('Checkout Deal Quantity Decreased', {
    sku: product.sku,
    description: product.description,
    price: product.price / 100,
    display_order: displayOrder,
    quantity,
    quantity_delta: -1,
    total: (product.price / 100) * quantity,
  })
}

export const trackConversionWithPixels: TrackingFn<
  Pick<
    ICongratulationsState,
    | 'discountAmount'
    | 'discountCode'
    | 'email'
    | 'extras'
    | 'shipping'
    | 'subscription'
    | 'taxAmount'
    | 'total'
    | 'phoneNumber'
  >
> = (
  {
    discountAmount,
    discountCode,
    email,
    extras,
    shipping,
    subscription,
    taxAmount,
    total,
    phoneNumber,
  },
  callback
) => {
  const orderId = `${subscription.id}-${subscription.month}`

  /** START GOOGLE ANALYTICS */
  try {
    window?.ga('require', 'ec')

    subscription.box.items.forEach((item) => {
      window?.ga('ec:addProduct', {
        id: item.sku,
        name: item.description,
        category: item.meatGroup,
        price: item.price / 100,
        quantity: item.quantity,
      })
    })

    subscription.box.addons.forEach((item) => {
      window?.ga('ec:addProduct', {
        id: item.sku,
        name: item.description,
        category: item.meatGroup,
        price: item.price / 100,
        quantity: item.quantity,
      })
    })

    extras.forEach((item) => {
      window?.ga('ec:addProduct', {
        id: item.sku,
        name: item.description,
        category: item.meatGroup,
        price: 0,
        quantity: item.quantity,
      })
    })

    window?.ga('ec:setAction', 'purchase', {
      id: orderId,
      affiliation: 'Butcherbox', // In case the transation is from an affiliate, otherwise this can be removed
      revenue: total / 100,
      tax: taxAmount / 100,
      shipping: 0,
      coupon: discountAmount,
    })
  } catch (e) {
    console.error(
      'Error processing Google Analytics e-commerce conversion data',
      e
    )
    trackError((scope) => {
      scope.capture(e)
    })
  }
  /** END GOOGLE ANALYTICS */

  /** START GTM DATA LAYER */
  const gtmPayload = {
    // TODO: email & shipping are possibly undefined. Consider leaving these keys unset in the GTM payload.
    ecomm_pagetype: 'purchase',
    ecomm_prodid: ['123A', 'H456'], // took these values from PHP, no idea
    ecomm_totalvalue: total / 100,
    ecomm_transId: orderId,
    customer_name: [shipping?.firstName, shipping?.lastName]
      .filter(Boolean)
      .join(' '),
    email: email ?? undefined,
    ship_address1: shipping?.addressLineOne,
    ship_address2: shipping?.addressLineTwo,
    ecomm_order_city: shipping?.city,
    event: 'GTM Purchase Data Loaded',
    ecomm_order_state: shipping?.state,
    ship_zipcode: shipping?.postalCode,
    ecomm_prod_name: subscription.box.name,
    ecomm_affiliation: Cookies.get('utm-term'), // optional, set by utils/lander.ts if the customer enters with UTM
    ecomm_order_tax: taxAmount / 100,
    ecomm_coupon: discountCode,
  }

  const transactionCompletePayload = {
    event: 'transactionComplete',
    google_tag_params: {
      ecomm_pagetype: 'purchase',
      transactionType: 'subscription',
      ecomm_totalvalue: (total - taxAmount) / 100,
      ecomm_transId: orderId,
    },
  }

  const enhancedConversionPayload = {
    enhanced_conversion_data: {
      email: email || undefined,
      phone_number: phoneNumber,
      first_name: shipping?.firstName,
      last_name: shipping?.lastName,
      home_address: {
        street: shipping?.addressLineOne,
        city: shipping?.city,
        region: shipping?.state,
        postal_code: shipping?.postalCode,
        country: 'US',
      },
    },
  }

  try {
    window.dataLayer ||= []

    window.dataLayer.push(gtmPayload)
    window.dataLayer.push(transactionCompletePayload)
    window.dataLayer.push(enhancedConversionPayload)
  } catch (e) {
    console.error('Error processing GTM datalayer conversion data', e)

    trackError((scope) => {
      scope.setContext('payload', gtmPayload)
      scope.capture(e)
    })
  }
  /** END GTM DATA LAYER */

  if (typeof callback !== 'function') return
  callback()
}

type PriceFormat = {
  type: BoxType
  size: BoxSize
  price: number
}

export const trackPlanPricesSeen = (
  prices: Array<PriceFormat>,
  path: Frames
) => {
  window.analytics?.track('Plan Prices Seen', {
    prices: prices,
    path,
  })
}

export const trackSiteFeedbackformSubmitted = () => {
  window.analytics?.track('Site Feedback Submitted')
}

export const trackUserActionTaken = <N extends UserActionName>(
  actionName: UserActionDataType<N>['actionName'],
  actionData: UserActionDataType<N>['actionData']
) => {
  window.analytics?.track('User Action Taken', {
    action_name: actionName,
    data: actionData,
  })
}

export enum datadogDealsPageStates {
  REDIRECT = 'deals_page_redirect',
}

export type datadogDealsAssistiveData = {
  dealsToDisplayLength: number
  dealsEnabled: boolean
  dealsIntendedForDisplay: BaseDeal[]
}

export const trackDataDogDealsRedirect = (
  eventTitle: datadogDealsPageStates,
  helperData: datadogDealsAssistiveData
) => {
  if (helperData) {
    window.DD_RUM?.addAction(eventTitle, {
      dealsToDisplayLength: helperData?.dealsToDisplayLength,
      dealsEnabled: helperData?.dealsEnabled,
      dealsIntendedForDisplay: helperData?.dealsIntendedForDisplay,
      dealsIntendedForDisplayLength:
        helperData?.dealsIntendedForDisplay?.length,
    })
  } else {
    window.DD_RUM?.addAction(eventTitle, {
      'Custom Attributes Not Provided/Missing': true,
    })
  }
}

export const datadogCCNumInputError = {
  INCOMPLETE: 'card_number_incomplete',
} as const

export type datadogCCNumInputErrorTypes = {
  errorText: string
}

export const trackDataDogCCNumInputError = (
  eventTitle: typeof datadogCCNumInputError[keyof typeof datadogCCNumInputError],
  helperData: datadogCCNumInputErrorTypes
) => {
  window.DD_RUM?.addAction(eventTitle, {
    errorText: helperData.errorText,
  })
}

export const trackDataDogOrderEvent = (
  eventTitle: string,
  message?: string
) => {
  //TODO: add error codes once standardized
  window.DD_RUM?.addAction(eventTitle, {
    message: message || 'success',
  })
}

export const trackDataDogErrorBoundaryEvent = (
  eventTitle: string,
  message?: string
) => {
  window.DD_RUM?.addAction(eventTitle, {
    message: message,
  })
}

export enum dataDogCheckoutFunnel {
  SUCCESS = 'checkout_funnel__success',
  FAILED = 'checkout_funnel__fail_server_error',
  USER_ERROR = 'checkout_funnel__fail_user_error',
}

export const trackIngredientsSeen = (
  title: string,
  sku: string,
  type: 'deal' | 'addon' | 'custom'
) => {
  window.analytics?.track('Ingredients Viewed', {
    name: title,
    sku: sku,
    type: type,
  })
}

export const addEventToSegmentQueue = (event: SegmentQueueEvent) => {
  const queue = window.segmentQueue || []
  queue.push(event)
  window.segmentQueue = queue
}

export enum dataDogAddressValidationFunnel {
  PO_BOX_ERROR = 'shipping_form__po_box_validation_error',
}

export const trackDataDogPOBoxErrorEvent = (eventTitle: string) => {
  window.DD_RUM?.addAction(eventTitle, {
    message: 'PO Box Validation Error',
  })
}

export const addOptimizelyExperimentRUMUserAction = (
  actionName: string,
  message?: string,
  experimentId?: string,
  variantId?: string,
  experimentKey?: string,
  variationKey?: string
) => {
  window.DD_RUM?.addAction(actionName, {
    message: message || 'success',
    experimentId,
    variantId,
    experimentKey,
    variationKey,
  })
}
