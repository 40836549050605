import React from 'react'
import { BannerContext } from '~/components/Banner'
import { H3 } from 'design/components/Typography/Typography'
import useCheckoutSettings from '~/hooks/useCheckoutSettings'

type Options = {
  useCheckoutSettings?: boolean
}

/**
 * useAcquisitionBannerContent enables setting banner text that may be overridden by checkout settings
 */
export const useAcquisitonBannerContent = () => {
  const { content, setContent } = React.useContext(BannerContext)
  const { status, data } = useCheckoutSettings()

  const setBannerContent = React.useCallback(
    (
      content?: React.ReactNode,
      options: Options = {
        useCheckoutSettings: false,
      }
    ) => {
      if (options.useCheckoutSettings) {
        if (status === 'loading') {
          return
        }
        if (data?.bannerMessage) {
          setContent(
            <H3 as="p" data-what="banner-text">
              {data.bannerMessage}
            </H3>
          )
          return
        }
      }

      setContent(content)
      return
    },
    // setContent is constant
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, data?.bannerMessage]
  )

  return {
    content,
    setContent: setBannerContent,
  }
}

/**
 * useBannerContent enables simple access to the banner, suitable for use in the account pages
 */
export const useBannerContent = () => {
  const { content, setContent } = React.useContext(BannerContext)

  return {
    content,
    setContent,
  }
}
