import React from 'react'
import {
  addEventToSegmentQueue,
  trackOptimizelyExperimentSeen,
  addOptimizelyExperimentRUMUserAction,
} from '~/analytics/events'
import useGetOptimizelyConfig from './useGetOptimizelyConfig'

export default function useTrackOptimizelyExperimentSeen() {
  const eventDispatched = React.useRef(false)
  const { experimentsMap } = useGetOptimizelyConfig()

  return React.useCallback(
    (experimentKey: string, variationKey: string) => {
      if (!experimentsMap || eventDispatched.current) return
      const experiment = experimentsMap[experimentKey]

      if (!experiment || !variationKey) return

      const experimentId = experiment?.id
      const variantId = experiment?.variationsMap[variationKey]?.id

      if (!experimentId || !variantId) return

      if (Boolean(window.analytics)) {
        trackOptimizelyExperimentSeen(
          experimentId,
          variantId,
          experimentKey,
          variationKey
        )
      } else {
        addEventToSegmentQueue({
          eventType: 'Optimizely Experiment Seen',
          payload: {
            experiment_id: experimentId,
            experiment_variation: variantId,
            experiment_key: experimentKey,
            variation_key: variationKey,
          },
        })
      }

      // Add RUM Custom User Action in DataDog
      addOptimizelyExperimentRUMUserAction(
        `optimizely_experiment_seen`,
        'Optimizely Experiment Seen',
        experimentId,
        variantId,
        experimentKey,
        variationKey
      )

      eventDispatched.current = true
    },
    [experimentsMap]
  )
}
