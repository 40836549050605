import React from 'react'
import * as Styles from './PanelBreak.css'
import { Box, BoxProps } from '@butcherbox/freezer'

export function PanelBreak({
  intensity = 'normal',
  ...props
}: BoxProps & {
  intensity?: 'normal' | 'light'
}) {
  return (
    <Box
      aria-hidden
      className={Styles.PanelBreak[intensity]}
      component="hr"
      {...props}
    />
  )
}
