import { Text, TextLink } from '@butcherbox/freezer'
import {
  LinkInternal,
  LinkInternalEnterKeySafe,
} from 'design/components/Typography/Links'
import { Link as GatsbyLink } from 'gatsby'
import React from 'react'
import { SubscriptionStatus } from '~/bb-api/schemata'
import { TEST_ID } from '~/constants/cypress'
import usePaymentProcessingMessage from '~/hooks/usePaymentProcessingMessage'
import { EDIT_PLAN } from '~/routes/AccountYourBox/index'

const MEMBER_DEALS = '/account/deals'
const CUSTOMIZE = '/account/customize-your-box'
const PROFILE_PAYMENT = '/account/profile-payment'

const ChangePlanLink = () => (
  <LinkInternalEnterKeySafe
    data-cy={TEST_ID.LINK_CHANGE_BOX_TYPE}
    data-what="change_plan"
    href={EDIT_PLAN}
  >
    Change Plan
  </LinkInternalEnterKeySafe>
)

const EditMeatLink = () => (
  <TextLink
    component={GatsbyLink}
    data-what="edit_meat"
    href={CUSTOMIZE}
    to={CUSTOMIZE}
    variant="Body1Regular"
  >
    Edit Your Cuts
  </TextLink>
)

export const PaymentProcessingMessage = ({
  onClose,
}: {
  onClose?: () => void
}) => (
  <div data-cy={TEST_ID.PAYMENT_PROCESSING_MESSAGE}>
    Your current box is processing and can no longer be edited. You can still
    add member deals to your next box{' '}
    <LinkInternal
      data-what={'deals_clicked_payment_processing'}
      href={MEMBER_DEALS}
      onClick={onClose}
    >
      here.
    </LinkInternal>
  </div>
)

export const PaymentPastDueMessage = () => (
  <div data-cy={TEST_ID.PAYMENT_PAST_DUE_MESSAGE}>
    Sorry, your box can't be edited while your account is past due. Please visit
    the{' '}
    <LinkInternal
      data-what={'update_payment_clicked_payment_past_due'}
      href={PROFILE_PAYMENT}
    >
      Profile & Payment
    </LinkInternal>{' '}
    section to update your billing info.
  </div>
)

const statusMessages: Partial<Record<SubscriptionStatus, JSX.Element>> = {
  payment_processing: <PaymentProcessingMessage />,
  past_due: <PaymentPastDueMessage />,
}

export const EditBoxCTA = () => {
  const { isTodayBillingDay, subscription } = usePaymentProcessingMessage()

  const itIsBillDayStatus = isTodayBillingDay ? 'payment_processing' : null

  const problemMessage =
    statusMessages[itIsBillDayStatus ?? subscription?.status]

  return (
    <Text color="slate" marginTop={12} variant="Body2Regular">
      {problemMessage ||
        (subscription.box.type === 'cst' ? (
          <EditMeatLink />
        ) : (
          <>
            Need to make a change?
            <br />
            <ChangePlanLink />
          </>
        ))}
    </Text>
  )
}
