import { addDinOffset } from './size-utils'

export const BASE_FONT_SIZE = 16

type BaseSizes = {
  dinOffset: string
  px: string
  '0': string
  '1': string
  '2': string
  '3': string
  '4': string
  '5': string
  '6': string
  '8': string
  '10': string
  '12': string
  '16': string
  '20': string
  '24': string
  '32': string
  '40': string
  '48': string
  '56': string
  '64': string
}

type Containers = {
  sm: string
  md: string
  lg: string
  xl: string
}

type LargeSizes = {
  full: string
  '3xs': string
  '2xs': string
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  '2xl': string
  '3xl': string
  '4xl': string
  '5xl': string
  '6xl': string
}

export type Sizes = BaseSizes &
  LargeSizes & {
    containers: Containers
  }

const themeBaseSizes = {
  dinOffset: '0.125rem',
  px: '1px',
  '0': '0',
  '1': '0.25rem',
  '1_5': '0.375rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '7': '1.75rem',
  '8': '2rem',
  '9': '2.25rem',
  '10': '2.5rem',
  '11': '2.75rem',
  '12': '3rem',
  '13': '3.25rem',
  '14': '3.5rem',
  '15': '3.75rem',
  '16': '4rem',
  '17': '4.25rem',
  '18': '4.5rem',
  '19': '4.75rem',
  '20': '5rem',
  '21': '5.25rem',
  '22': '5.5rem',
  '23': '5.75rem',
  '24': '6rem',
  '25': '6.25rem',
  '26': '6.5rem',
  '27': '6.75rem',
  '28': '7rem',
  '29': '7.25rem',
  '30': '7.5rem',
  '31': '7.75rem',
  '32': '8rem',
  '33': '8.25rem',
  '34': '8.5rem',
  '35': '8.75rem',
  '36': '9rem',
  '37': '9.25rem',
  '38': '9.5rem',
  '39': '9.75rem',
  '40': '10rem',
  '41': '10.25rem',
  '42': '10.5rem',
  '43': '10.75rem',
  '44': '11rem',
  '45': '11.25rem',
  '46': '11.5rem',
  '47': '11.75rem',
  '48': '12rem',
  '49': '12.25rem',
  '50': '12.5rem',
  '51': '12.75rem',
  '52': '13rem',
  '53': '13.25rem',
  '54': '13.5rem',
  '55': '13.75rem',
  '56': '14rem',
  '57': '14.25rem',
  '58': '14.5rem',
  '59': '14.75rem',
  '60': '15rem',
  '61': '15.25rem',
  '62': '15.5rem',
  '63': '15.75rem',
  '64': '16rem',
}

export const baseSizes = {
  px: '1px',
  '0': '0',
  '1': '0.25rem',
  '2': '0.5rem',
  '3': '0.75rem',
  '4': '1rem',
  '5': '1.25rem',
  '6': '1.5rem',
  '8': '2rem',
  '10': '2.5rem',
  '12': '3rem',
  '16': '4rem',
  '20': '5rem',
  '24': '6rem',
  '32': '8rem',
  '40': '10rem',
  '48': '12rem',
  '56': '14rem',
  '64': '16rem',
  ...themeBaseSizes,
  ...addDinOffset(themeBaseSizes),
}

const largeSizes = {
  full: '100%',
  '3xs': '14rem',
  '2xs': '16rem',
  xs: '20rem',
  sm: '24rem',
  md: '28rem',
  lg: '32rem',
  xl: '36rem',
  '2xl': '42rem',
  '3xl': '48rem',
  '4xl': '56rem',
  '5xl': '64rem',
  '6xl': '72rem',
}

const containers = {
  sm: '640px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
}

export const sizes: Sizes = {
  ...baseSizes,
  ...largeSizes,
  containers,
}

export const space: BaseSizes = baseSizes
