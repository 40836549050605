import { CMS } from '~/cms/types'
import {
  convertRangeToArrayOfCustomDays,
  parseCustomDateRanges,
} from '~/cms/utils/calendar'
import { useMemo } from 'react'

export const useCustomDateRanges = (calendar: CMS.Calendar) =>
  useMemo(() => {
    const dateRanges = parseCustomDateRanges(calendar)
    return convertRangeToArrayOfCustomDays(dateRanges)
  }, [calendar])
