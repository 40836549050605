import React from 'react'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { Box, Text, BoxProps } from '@butcherbox/freezer'

export const CartComboItem = ({
  title,
  titlePrice,
  children,
  ...props
}: {
  title: string
  titlePrice: string
  children: React.ReactNode
  props?: BoxProps
}) => (
  <Box
    background="ivory"
    border="silt"
    paddingBottom={16}
    paddingTop={20}
    px={16}
    {...props}
  >
    <Text
      color="slate"
      component="h3"
      display="flex"
      justifyContent="spaceBetween"
      variant="H4Bold"
      {...props}
    >
      <span>{title}</span>
      <span>{titlePrice}</span>
    </Text>
    <PanelBreak intensity="light" />
    <Box background="ivory">{children}</Box>
  </Box>
)

export default CartComboItem
