import { Flex, FlexProps } from '@chakra-ui/core'
import { ToastOutlet } from 'design/contexts/Toast/Toast.context'
import React, { PropsWithChildren } from 'react'
import {
  BypassBlock,
  BypassBlockTarget,
  IBypassBlockTarget,
} from '~/components/BypassBlock'

export type ILayout = PropsWithChildren<{
  bg?: FlexProps['color']
  Header?: any
  Footer?: any
  SEO: JSX.Element
}> &
  IBypassBlockTarget

export default function Layout({
  bg = 'white',
  children,
  Header,
  Footer,
  SEO,
  ...props
}: ILayout) {
  return (
    <>
      {SEO}

      <Flex
        bg={bg}
        flexDirection="column"
        minHeight="100vh"
        position="relative"
      >
        <header data-where="header">
          <BypassBlock />

          {Header}
        </header>
        {/**
         * The variable height banner for authenticated layout throws off the Toast's
         * positioning, so we manually compose the outlet below the header such
         * that it becomes sticky at the proper scroll point, but also correctly
         * renders _below_ the header when the banner is present. */}
        <ToastOutlet />

        <BypassBlockTarget
          as="main"
          display="flex"
          flexDirection="column"
          pos="relative"
          {...props}
        >
          {children}
        </BypassBlockTarget>

        {Footer}
      </Flex>
    </>
  )
}
