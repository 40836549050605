import dayjs, { Dayjs } from 'dayjs'
import { FormikErrors, FormikValues } from 'formik'
import { BoxType, BoxItemCategory } from '~/bb-api/schemata'
import { NavItem } from 'design/types'

// Format delivery window for display to user, with option to reformat in screen readers
export const formatDeliveryWindow = (
  deliveryWindow: Dayjs[],
  usesATFormat: boolean = false
) => {
  if (!deliveryWindow.length) return ''
  const deliveryWindowStart = deliveryWindow[0]
  const deliveryWindowEnd = deliveryWindow[deliveryWindow.length - 1]
  return `${deliveryWindowStart.format('MMM D')} ${usesATFormat ? 'to' : '-'}
      ${
        deliveryWindowStart.month() === deliveryWindowEnd.month()
          ? deliveryWindowEnd.format('D')
          : deliveryWindowEnd.format('MMM D')
      }`
}

// Use this to get the product card icon for curated boxes
export const getPlanIcon = (basePlanName: BoxType | BoxItemCategory) => {
  switch (basePlanName) {
    case 'all_beef':
      return 'outlineBeef'
    case 'beef_and_chicken':
      return 'outlineBeefChicken'
    case 'beef_and_pork':
      return 'outlineBeefPork'
    case 'pork':
      return 'outlinePork'
    case 'beef':
      return 'outlineBeef'
    case 'chicken':
      return 'outlineChicken'
    case 'seafood':
      return 'outlineFish'
    default:
      return 'outlineBeefChickenPork'
  }
}

// Use this to get product card text for curated boxes
export const getPlanFlavorText = (basePlanName: BoxType) => {
  const flavorTextItems: string[] = []
  if (basePlanName.includes('beef')) {
    flavorTextItems.push('100% grass fed & grass finished beef')
  }
  if (basePlanName.includes('chicken')) {
    flavorTextItems.push('organic chicken')
  }
  if (basePlanName.includes('pork')) {
    flavorTextItems.push('humanely raised pork')
  }

  if (!flavorTextItems.length) {
    return 'Thoughtfully sourced meat curated for you.'
  }

  const flavorText = flavorTextItems.join(' & ')
  return `${flavorText[0].toUpperCase()}${flavorText.slice(1)}.`
}

export const setItem = (name, item) => {
  window.localStorage.setItem(name, item)
}

export const getItem = (name) => {
  return window.localStorage.getItem(name)
}

export const formatPriceFromCents = (price) => {
  return `${price < 0 ? '-' : ''}$${(Math.abs(price) / 100).toFixed(2)}`
}

// Given a provided string, appends a non breaking unicode space
// between the last two words.
export const formatStringNoOrphans = (str: string) => {
  const arr = str.split(' ')
  if (arr.length < 3) {
    return str
  }
  const last = arr.pop()
  return arr.join(' ') + '\u00A0' + last
}

export const formatPriceWithoutTrailingZeroes = (price: number) => {
  return `${price < 0 ? '-' : ''}$${
    price % 100 === 0
      ? Math.abs(price) / 100
      : (Math.abs(price) / 100).toFixed(0)
  }`
}

export const formatDate = (date: string, format: string) => {
  return dayjs(date).format(format)
}

// Used to get the first validation error from Formik to display
// on form-level error state
export const getFirstFormikError = (errors: FormikErrors<FormikValues>) => {
  return (Object.values(errors).find((el) => el != '') as string) || ''
}

export const stripLeadingSlash: (str?: string) => any = (str) => {
  if (!str) {
    return
  }

  return typeof str === 'string'
    ? str[0] === '/'
      ? str.slice(1)
      : str
    : undefined
}

export const getLinkTarget = (type: NavItem['urlType']) => {
  if (type === 'external-same-tab') {
    return '_self'
  } else if (type === 'external') {
    return '_blank'
  } else {
    return undefined
  }
}
