/**
 * This hook assumes that the customer in the query cache will not be updated
 * on a failed call to the endpoint.
 * See this PR and associated comments for more details: https://github.com/butcherbox/butcherbox-frontend/pull/1495
 */

import { useMutation } from 'react-query'
import { POST_CUSTOMER_ANONYMOUS_ID } from '~/bb-api/endpoints'
import { AnonymousId } from '~/bb-api/schemata'
import axios from '~/utils/axios'

type PersistUserAnonymousIdProps = {
  anonymousId: string
  userId: number
}

async function performUserAnonymousIdMutation({
  anonymousId,
  userId,
}: PersistUserAnonymousIdProps) {
  const response = await axios.post<AnonymousId>(
    POST_CUSTOMER_ANONYMOUS_ID(userId),
    {
      anonymousId,
    }
  )
  return response.data
}

export default function mutateUserAnonymousId() {
  return useMutation(performUserAnonymousIdMutation)
}
