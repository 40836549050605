import {
  IconChevronDown,
  IconChevronUp,
  Box as FreezerBox,
} from '@butcherbox/freezer'
import { Box, Grid, GridProps } from '@chakra-ui/core'
import { styled } from 'design'
import { CTA } from 'design/components/Typography/Typography'
import { rem } from 'design/utils'
import React, { useState } from 'react'

export type ICardProductList = {
  collapseAfterNProducts?: number
  orientation?: 'column' | 'row'
  products: React.ReactElement[]
} & Omit<GridProps, 'children'>

const CardProductList: React.FC<ICardProductList> = ({
  collapseAfterNProducts,
  products,
  orientation,
  ...props
}) => {
  // For long CardProductLists, hide some of the products on mobile and allow
  // users to toggle view of all items
  const [shouldHideProducts, setShouldHideProducts] = useState<boolean>(null)
  const [productsHidden, setProductsHidden] = useState<boolean>(true)

  const handleToggleProducts = () => setProductsHidden(!productsHidden)

  const gridLayout = React.useMemo(() => {
    switch (orientation) {
      case 'column':
        return '1fr'
      case 'row':
        return products.length > 1 ? 'repeat(2, 1fr)' : '1fr'
      default:
        return {
          base: '1fr',
          tablet: products.length > 1 ? 'repeat(2, 1fr)' : '1fr',
        }
    }
  }, [orientation, products])

  React.useEffect(() => {
    if (
      collapseAfterNProducts > 0 &&
      shouldHideProducts === null &&
      products.length > collapseAfterNProducts
    ) {
      setShouldHideProducts(true)
    }
  }, [collapseAfterNProducts, products, productsHidden, shouldHideProducts])

  return (
    <>
      <Grid
        as="ul"
        data-what="product-card-list"
        gridGap={rem(8)}
        gridTemplateColumns={gridLayout}
        position="relative"
        {...props}
      >
        <>
          {products.map((item, index) => {
            const showItem =
              index < collapseAfterNProducts ||
              !shouldHideProducts ||
              !productsHidden

            return (
              <TransitionBox
                as="li"
                className={showItem ? 'isVisible' : ''}
                key={products.indexOf(item)}
                style={{ listStyleType: 'none' }}
              >
                {item}
              </TransitionBox>
            )
          })}
        </>
      </Grid>

      {shouldHideProducts && (
        <CTA
          _hover={{ color: 'bb.crimson' }}
          alignItems="center"
          aria-label={
            productsHidden ? `View all ${products.length} cuts` : `Hide cuts`
          }
          aria-pressed={productsHidden ? `false` : `true`}
          as="button"
          bg="white"
          borderTop="solid 1px"
          borderTopColor="bb.silt"
          color="bb.spicedCrimson"
          cursor="pointer"
          d="flex"
          height={rem(32)}
          mb={rem(12)}
          mt={rem(8)}
          onClick={handleToggleProducts}
          pos="relative"
          pt={rem(13)}
          width="100%"
        >
          <FreezerBox
            component="span"
            display="inlineFlex"
            verticalAlign={'middle'}
          >
            {productsHidden ? `View all ${products.length} cuts` : `Hide cuts`}
            {productsHidden ? (
              <IconChevronDown
                customColor={{ base: 'spicedCrimson' }}
                marginLeft={8}
                size={'text'}
              />
            ) : (
              <IconChevronUp
                customColor={{ base: 'spicedCrimson' }}
                marginLeft={8}
                size={'text'}
              />
            )}
          </FreezerBox>
        </CTA>
      )}
    </>
  )
}

const TransitionBox = styled(Box)`
  opacity: 0;
  position: absolute;
  transition: opacity 500ms ease-out 0s;
  visibility: hidden;
  width: 100%;

  &.isVisible {
    height: auto;
    opacity: 1;
    position: static;
    visibility: visible;
  }

  @media (prefers-reduced-motion) {
    transition: opacity 10ms ease-out 0s;
  }
`

export default CardProductList
