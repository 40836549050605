import * as API from '~/bb-api/schemata'
import { ProductCategory, Segment } from './types'

export const makeSegmentProduct = (
  product: {
    position: number
    productCategory: ProductCategory
  } & API.BaseProduct
): Segment.Product => ({
  sku: product.sku,
  name: product.description,
  price: product.price,
  image_url: product.image,
  position: product.position,
  category: product.productCategory,
})
