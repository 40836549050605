import React, { PropsWithChildren } from 'react'
import { rem } from 'design'
import { IconName } from 'design/components/Icons/types'
import { Box, BoxType, NewSubscriptionBox } from '~/bb-api/schemata'
import { Box as FreezerBox, IconYourChoice, Text } from '@butcherbox/freezer'
import AnimalIcons, {
  AnimalIconName,
} from '~/components/AnimalIcons/AnimalIcons'

type IBoxVariant = PropsWithChildren<{
  name?: string
  isDisabled?: boolean
}>

interface IBoxVariantRenderFn {
  (config?: IBoxVariant): JSX.Element
  (icon: IconName, iconWidth: string): (config?: IBoxVariant) => JSX.Element
}

const makeBoxVariantChildrenIcon = (
  icon: AnimalIconName,
  iconWidth: string
) => ({ children = null, name = '', isDisabled = false } = {}) => (
  <>
    <FreezerBox
      color="slate"
      display="flex"
      height={rem(38.5)}
      paddingBottom={8}
      style={{ opacity: isDisabled ? 0.3 : 1, width: iconWidth }}
    >
      <AnimalIcons color="slate" iconName={icon} />
    </FreezerBox>
    {!!name && (
      <Text color={isDisabled ? 'granite' : 'spicedCrimson'} variant="H2Bold">
        {name}
      </Text>
    )}
    {children}
  </>
)

export function normalizeBoxName<
  T extends
    | Pick<Box, 'name' | 'type'>
    | Pick<NewSubscriptionBox, 'name' | 'type'>
>(box: T) {
  return box.type === 'cst' ? 'Custom' : box.name?.replace(/ (Box|Plan)/, '')
}

export default {
  all_beef: {
    render: makeBoxVariantChildrenIcon('outlineBeef', rem(40)),
  },
  beef_chicken_pork: {
    render: makeBoxVariantChildrenIcon('outlineBeefChickenPork', rem(140)),
  },
  basic_beef_chicken_pork: {
    render: makeBoxVariantChildrenIcon('outlineBeefChickenPork', rem(140)),
  },
  beef_and_pork: {
    render: makeBoxVariantChildrenIcon('outlineBeefPork', rem(90)),
  },
  beef_and_chicken: {
    render: makeBoxVariantChildrenIcon('outlineBeefChicken', rem(90)),
  },
  // Note: Value boxes are not currently available from GET box types API
  value: {
    render: makeBoxVariantChildrenIcon('outlineBeefChickenPork', rem(140)),
  },
  chef_value: {
    render: makeBoxVariantChildrenIcon('outlineBeefChickenPork', rem(140)),
  },
  cst: {
    render: ({ children = null, isDisabled = false } = {}) => (
      <>
        <FreezerBox
          style={{
            height: rem(43),
            overflow: 'hidden',
          }}
        >
          <FreezerBox position={'relative'} style={{ top: -38 }}>
            <IconYourChoice
              aria-hidden={true}
              customColor={{ base: isDisabled ? 'silt' : 'inherit' }}
              pixelSize={120}
              size="fixed"
            />
          </FreezerBox>
        </FreezerBox>
        <Text color={isDisabled ? 'granite' : 'spicedCrimson'} variant="H2Bold">
          Custom Box
        </Text>
        {children}
      </>
    ),
  },
} as Record<BoxType, { render: IBoxVariantRenderFn }>
