// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-index-tsx": () => import("./../../../src/pages/account/index.tsx" /* webpackChunkName: "component---src-pages-account-index-tsx" */),
  "component---src-pages-ada-tsx": () => import("./../../../src/pages/ada.tsx" /* webpackChunkName: "component---src-pages-ada-tsx" */),
  "component---src-pages-authorize-tsx": () => import("./../../../src/pages/authorize.tsx" /* webpackChunkName: "component---src-pages-authorize-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-ccpa-privacy-policy-tsx": () => import("./../../../src/pages/ccpa-privacy-policy.tsx" /* webpackChunkName: "component---src-pages-ccpa-privacy-policy-tsx" */),
  "component---src-pages-congratulations-tsx": () => import("./../../../src/pages/congratulations.tsx" /* webpackChunkName: "component---src-pages-congratulations-tsx" */),
  "component---src-pages-corporate-gifting-tsx": () => import("./../../../src/pages/corporate-gifting.tsx" /* webpackChunkName: "component---src-pages-corporate-gifting-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-get-started-tsx": () => import("./../../../src/pages/get-started.tsx" /* webpackChunkName: "component---src-pages-get-started-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lander-choose-your-offer-g-tsx": () => import("./../../../src/pages/lander/choose-your-offer-g.tsx" /* webpackChunkName: "component---src-pages-lander-choose-your-offer-g-tsx" */),
  "component---src-pages-lander-choose-your-offer-u-tsx": () => import("./../../../src/pages/lander/choose-your-offer-u.tsx" /* webpackChunkName: "component---src-pages-lander-choose-your-offer-u-tsx" */),
  "component---src-pages-lander-groundbeef-forlife-tsx": () => import("./../../../src/pages/lander/groundbeef-forlife.tsx" /* webpackChunkName: "component---src-pages-lander-groundbeef-forlife-tsx" */),
  "component---src-pages-lander-groundbeefforlife-tsx": () => import("./../../../src/pages/lander/groundbeefforlife.tsx" /* webpackChunkName: "component---src-pages-lander-groundbeefforlife-tsx" */),
  "component---src-pages-legacy-browser-tsx": () => import("./../../../src/pages/legacy-browser.tsx" /* webpackChunkName: "component---src-pages-legacy-browser-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-member-tsx": () => import("./../../../src/pages/member.tsx" /* webpackChunkName: "component---src-pages-member-tsx" */),
  "component---src-pages-nystripsbacon-tsx": () => import("./../../../src/pages/nystripsbacon.tsx" /* webpackChunkName: "component---src-pages-nystripsbacon-tsx" */),
  "component---src-pages-plans-and-addons-tsx": () => import("./../../../src/pages/plans-and-addons.tsx" /* webpackChunkName: "component---src-pages-plans-and-addons-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-refer-friend-tsx": () => import("./../../../src/pages/refer/friend.tsx" /* webpackChunkName: "component---src-pages-refer-friend-tsx" */),
  "component---src-pages-resubscribe-tsx": () => import("./../../../src/pages/resubscribe.tsx" /* webpackChunkName: "component---src-pages-resubscribe-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-sms-tsx": () => import("./../../../src/pages/sms.tsx" /* webpackChunkName: "component---src-pages-sms-tsx" */),
  "component---src-pages-sourcing-tsx": () => import("./../../../src/pages/sourcing.tsx" /* webpackChunkName: "component---src-pages-sourcing-tsx" */),
  "component---src-pages-specialty-food-ingredients-tsx": () => import("./../../../src/pages/specialty-food-ingredients.tsx" /* webpackChunkName: "component---src-pages-specialty-food-ingredients-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-routes-lander-offer-product-index-tsx": () => import("./../../../src/routes/Lander/OfferProduct/index.tsx" /* webpackChunkName: "component---src-routes-lander-offer-product-index-tsx" */)
}

