import 'core-js/proposals/promise-all-settled' // remove with https://github.com/gatsbyjs/gatsby/pull/26895
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import { useContext, useRef } from 'react'
import { queryCache, useMutation } from 'react-query'
import {
  trackDecrementMemberDeal,
  trackIncrementMemberDeal,
} from '~/analytics/events'
import { CACHE_KEY_UPCOMING_ORDER } from '~/bb-api/constants'
import { UPDATE_INVOICE_ITEM } from '~/bb-api/endpoints'
import {
  GroupedBoxItem,
  Product,
  UpcomingInvoiceItem,
  UpcomingOrder,
} from '~/bb-api/schemata'
import { SubscriptionContext } from '~/context/subscription'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'
import { calculatePriceForUpcomingOrder } from '~/utils/price'
import { captureNetworkError } from '~/analytics/errors'
import useGetOptimizelyConfig from './useGetOptimizelyConfig'

const mutateInvoiceItem = (action: 'increment' | 'decrement') => () => {
  const user = useContext(UserContext)
  const { subscription } = useContext(SubscriptionContext)
  const queue = useRef([])
  const settled = useRef<Promise<any>>()
  const showToast = useContext(ToastContext)
  const { track } = useGetOptimizelyConfig()

  const mutateInvoiceItemQuantity = (deal: Product) => {
    const { sku } = deal
    const promise = axios
      .post<GroupedBoxItem>(UPDATE_INVOICE_ITEM(user.id, subscription.id), {
        action,
        sku,
      })
      .then((res) => {
        const isIncrement = action === 'increment'
        if (isIncrement) trackIncrementMemberDeal(res.data)
        else trackDecrementMemberDeal(res.data)

        // Cannot include in track[Increment/Decrement]MemberDeal function
        // since Optimizely is not available on `window`
        track('member_deal_quantity_changed', undefined, undefined, {
          value: isIncrement ? 1 : -1,
        })
      })

    queue.current.push(promise)
    settled.current = Promise.allSettled(queue.current)

    return promise
  }

  return useMutation(mutateInvoiceItemQuantity, {
    onMutate(deal) {
      // Cancel outgoing queries to prevent overriding local value until
      // final request resolves
      queryCache.cancelQueries(CACHE_KEY_UPCOMING_ORDER)

      // Save copy of cached values for rollback function
      const prevState: UpcomingOrder = queryCache.getQueryData(
        CACHE_KEY_UPCOMING_ORDER
      )

      // Find affected sku index, if exists
      const itemToUpdateIndex = prevState.invoiceItems.findIndex((item) => {
        return item.sku === deal.sku
      })

      let updatedItem: UpcomingInvoiceItem
      const delta = action === 'increment' ? 1 : -1

      // If already in box, add or subtract from item quantity,
      // else create new placeholder item
      if (itemToUpdateIndex > -1) {
        updatedItem = { ...prevState.invoiceItems[itemToUpdateIndex] }
        updatedItem.quantity += delta
      } else {
        updatedItem = {
          ...deal,
          quantity: 1,
          groupedItems: [],
          amount: 0,
          offerGroup: null,
        }
      }

      // Optimistically update to new value
      queryCache.setQueryData(
        CACHE_KEY_UPCOMING_ORDER,
        (oldData: UpcomingOrder) => {
          const newInvoiceItems = oldData.invoiceItems.slice()

          // Add updated item to invoice items, overwriting if necessary
          if (itemToUpdateIndex > -1) {
            if (updatedItem.quantity === 0) {
              newInvoiceItems.splice(itemToUpdateIndex, 1)
            } else {
              newInvoiceItems.splice(itemToUpdateIndex, 1, updatedItem)
            }
          } else {
            newInvoiceItems.push(updatedItem)
          }

          const updatedUpcomingOrder = {
            ...oldData,
            invoiceItems: newInvoiceItems,
            invoiceItemsPrice: newInvoiceItems.reduce(
              (price, item) => price + item.price * item.quantity,
              0
            ),
          }

          updatedUpcomingOrder.total = calculatePriceForUpcomingOrder(
            updatedUpcomingOrder
          )

          return updatedUpcomingOrder
        }
      )

      // Return the old value to rollback if necessary
      return () => queryCache.setQueryData(CACHE_KEY_UPCOMING_ORDER, prevState)
    },
    onError(e: any, product, rollback: () => void) {
      captureNetworkError(e)
      rollback()

      showToast('error', {
        children: `We couldn't ${
          action === 'increment'
            ? `add ${product.description} to your box`
            : `remove ${product.description} from your box`
        }, please confirm the selections in your Account.`,
      })
    },
    async onSettled() {
      const currentPromise = settled.current
      await currentPromise

      // this can change in the interim
      if (currentPromise === settled.current) {
        // Overwrite optimistic update
        queryCache.invalidateQueries(CACHE_KEY_UPCOMING_ORDER)

        queue.current.length = 0
        settled.current = undefined
      }
    },
  })
}

export const mutateIncrementInvoiceItem = mutateInvoiceItem('increment')
export const mutateDecrementInvoiceItem = mutateInvoiceItem('decrement')
