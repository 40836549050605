import { PseudoBox } from '@chakra-ui/core'
import { rem, styled } from 'design'

export const BACK_BUTTON_DEFAULT_SIZE = 14
export const BACK_BUTTON_LEFT_OFFSET = 17
/**
 * !important is added to override the descendant selector in
 * the main Modal container component that sets all children
 * to 100% height
 */
export const BackButton = styled(PseudoBox)`
  height: auto !important; // see comment above
  line-height: 0;
  position: absolute;
  left: ${rem(BACK_BUTTON_LEFT_OFFSET)};
  top: ${rem(16)};
  z-index: 10;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.bb.crimson};
  }
`
