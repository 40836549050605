import React from 'react'
import { Offer } from '~/bb-api/schemata'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { PanelSection } from '~/components/Panel/PanelSection/PanelSection'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import CartProductList from '../shared/CartProductList'
import { CartSection } from '../../CartDrawer.css'
import { Box } from '@butcherbox/freezer'

const Offers = ({ offers }: { offers: Array<Offer> }) => {
  if (!offers || offers.length <= 0) return <></>
  return (
    <Box className={CartSection}>
      <PanelSection content="Offers" contentAddon="Free" />
      <PanelBreak />
      <CartProductList
        aria-label="Offers included in your upcoming order"
        productArray={duplicateItemsByQuantity(offers)}
        showFreeFlag
      />
    </Box>
  )
}

export default Offers
