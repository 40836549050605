import { User } from '~/bb-api/schemata'
import { trackError } from './errors'

export function dataLayerPush(customer: User) {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'BBContentLoaded',
    customer: customer,
  })
}
// Rename this to a generic errorIdentify with WEB-3513
export function errorTrackingIdentify({
  anonymousId,
  customer,
}: {
  anonymousId?: string
  customer?: User
}) {
  trackError((scope) => {
    if (anonymousId) {
      scope.setUser({
        anonymousId: anonymousId,
      })
    } else {
      scope.setUser({
        id: String(customer.id),
        anonymousId: String(customer.anonymousId),
        stripeId: String(customer.stripeId),
        email: customer.emailAddress,
      })
    }
  })
}

// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
interface ISegmentIdentifyProps {
  userId?: string
  traits: {
    anonymous_id_persisted?: string
    customer_id?: string
    distribution_center?: string
    email: string
    first_name?: string
    last_name?: string
    db_email?: string
  }
  options?: Object
  callback?(): void
}

export const segmentIdentify = ({
  userId,
  traits,
  options,
  callback,
}: ISegmentIdentifyProps) => {
  if (userId) {
    traits.db_email = traits.email
    traits.customer_id = userId
    window.analytics?.identify(userId, traits, options, callback)
  } else {
    window.analytics?.identify(traits, options, callback)
  }
}
