/**
 * Add the 2px offset required to render a `din-2014` typeface according to
 * our designs
 */
export const addDinOffset = (sizes: { [key: string]: string }) => {
  const dinOffsetSizes = {}

  Object.entries(sizes).forEach(([key, val]) => {
    const baseSize = parseFloat(val.split('rem')[0])
    const newSize = `${baseSize + 0.125}rem`
    dinOffsetSizes[`${key}dinOffset`] = newSize
  })

  return dinOffsetSizes
}
