import { PseudoBox } from '@chakra-ui/core'
import { ITypography } from 'design/components/Typography/Typography'
import { rem } from 'design/utils'
import React from 'react'

/**
 * @deprecated - Use Text/DisplayOne from The Freezer
 */
export const HeadlineGotham: ITypography = (props) => {
  return (
    <PseudoBox
      as="h1"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'DisplayOne' : undefined
      }
      data-what="heading"
      fontFamily="Abril Fatface"
      fontSize={rem(48)}
      fontWeight={400}
      letterSpacing={rem(1.5)}
      lineHeight={rem(56)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/DisplayTwo from The Freezer
 */
export const HeadlineGothamSmaller: ITypography = (props) => {
  return (
    <PseudoBox
      as="h2"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'DisplayTwo' : undefined
      }
      data-what="heading"
      fontFamily="Abril Fatface"
      fontSize={rem(36)}
      fontWeight={400}
      letterSpacing={rem(1.5)}
      lineHeight={rem(42)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H1Bold from The Freezer
 */
export const H1: ITypography = (props) => {
  return (
    <PseudoBox
      as="h1"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H1Bold' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(30)}
      fontWeight={400}
      lineHeight={rem(40)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H2Book from The Freezer
 */
export const H2Book: ITypography = (props) => {
  return (
    <PseudoBox
      as="h2"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H2Book' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(21)}
      fontWeight={400}
      lineHeight={rem(32)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H2Bold from The Freezer
 */
export const H2Medium: ITypography = (props) => {
  return (
    <PseudoBox
      as="h2"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H2Medium' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(21)}
      fontWeight={700}
      lineHeight={rem(32)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H2Bold from The Freezer
 */
export const H2Bold: ITypography = (props) => {
  return (
    <PseudoBox
      as="h2"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H2Bold' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(21)}
      fontWeight={700}
      lineHeight={rem(32)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H2Bold from The Freezer
 */
export const H2: ITypography = H2Medium

/**
 * @deprecated - Use Text/H3Book from The Freezer
 */
export const H3Book: ITypography = (props) => {
  return (
    <PseudoBox
      as="h3"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H3Book' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(16)}
      fontWeight={400}
      lineHeight={rem(24)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H3Bold from The Freezer
 */
export const H3Medium: ITypography = (props) => {
  return (
    <PseudoBox
      as="h3"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H3Medium' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(16)}
      fontWeight={700}
      lineHeight={rem(24)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H3Bold from The Freezer
 */
export const H3Bold: ITypography = (props) => {
  return (
    <PseudoBox
      as="h3"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H3Bold' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(16)}
      fontWeight={700}
      lineHeight={rem(24)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H3Bold from The Freezer
 */
export const H3: ITypography = H3Medium

/**
 * @deprecated - Use Text/H4Bold from The Freezer
 */
export const H4Medium: ITypography = (props) => {
  return (
    <PseudoBox
      as="h4"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H4Medium' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(14)}
      fontWeight={700}
      letterSpacing={rem(0.3)}
      lineHeight={rem(24)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H4Condensed from The Freezer
 */
export const H4Tight: ITypography = (props) => {
  return (
    <PseudoBox
      as="h4"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H4Condensed' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(14)}
      fontWeight={700}
      lineHeight={rem(16)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/H4Bold from The Freezer
 */
export const H4: ITypography = H4Medium

/**
 * @deprecated - Use Text/H4Condensed from The Freezer
 */
export const H5: ITypography = (props) => {
  return (
    <PseudoBox
      as="h5"
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'H5Medium' : undefined
      }
      data-what="heading"
      fontFamily="Lato"
      fontSize={rem(14)}
      fontWeight={700}
      letterSpacing={rem(0.3)}
      lineHeight={rem(16)}
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/Subhead1 from The Freezer
 */
export const Subhead: ITypography = (props) => {
  return (
    <PseudoBox
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'Subhead1' : undefined
      }
      data-what="subheading"
      fontFamily="Lato"
      fontSize={rem(14)}
      fontWeight={700}
      letterSpacing={rem(1)}
      lineHeight={rem(16)}
      textTransform="uppercase"
      {...props}
    />
  )
}

/**
 * @deprecated - Use Text/Subhead2 from The Freezer
 */
export const SubheadSmaller: ITypography = (props) => {
  return (
    <PseudoBox
      data-freezer={
        process.env.NODE_ENV !== 'production' ? 'Subhead2' : undefined
      }
      data-what="subheading"
      fontFamily="Lato"
      fontSize={rem(12)}
      fontWeight={700}
      letterSpacing={rem(1)}
      lineHeight={rem(16)}
      textTransform="uppercase"
      {...props}
    />
  )
}
