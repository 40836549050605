import { trackError } from '~/analytics/errors'

export enum ENVIRONMENT {
  DEV = 'DEV',
  STAGING = 'STAGING',
  PRODUCTION = 'PRODUCTION',
}

export function getEnvironment(loc: Pick<Location, 'hostname'>): ENVIRONMENT {
  const { hostname } = loc

  if (
    hostname === 'www.butcherbox.com' ||
    hostname === 'preview.butcherbox.dev'
  ) {
    return ENVIRONMENT.PRODUCTION
  } else if (
    hostname.endsWith('.staging.bboxtools.net') /** php/k8s staging */ ||
    hostname.endsWith('butcherbox.dev') /** netlify staging */ ||
    hostname.endsWith('netlify.app') /** deploy previews */
  ) {
    return ENVIRONMENT.STAGING
  } else if (
    hostname.endsWith('.local') /* macOS localhost aliases */ ||
    hostname === 'localhost' ||
    hostname === 'local.bboxtools.net' ||
    hostname.endsWith('.bboxtools.net')
  ) {
    return ENVIRONMENT.DEV
  }

  trackError((scope) => {
    scope.setContext('location', { ...window.location })
    scope.capture(
      new Error(
        'Unknown hostname in use, auth0 authentication will likely fail.'
      )
    )
  })

  return ENVIRONMENT.PRODUCTION
}
