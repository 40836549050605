import {
  Card,
  CardAction,
  CardBody,
  CardMedia,
  CardQuantity,
  IconCheck,
  Text,
} from '@butcherbox/freezer'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'
import CutInfoModal from '../CutInfoModal/CutInfoModal'
import { CustomCutCardProps } from './CustomCutCard.types'
import useVisibleProduct from '~/hooks/useVisibleProduct'

export function CustomCutCard({
  renderButton,
  product,
  quantity,
  remainingCapacity,
}: CustomCutCardProps) {
  const cardRef = useVisibleProduct(product, { threshold: 0.5 })

  const cardData = {
    cy: TEST_ID.CARD_CUSTOM_CUT,
    [`attr-sku`]: product.sku,
    ['cy-current-quantity']: quantity,
    ['cy-max-quantity']: remainingCapacity,
    'cy-sku': product.sku,
    ['where']: `card-custom-cut-${product.sku}`,
  }
  const [modalOpen, setModalOpen] = React.useState(false)
  const infoClickHandler = () => {
    setModalOpen(true)
  }
  const onClose = () => {
    setModalOpen(false)
  }

  const cardOverlay = () => (
    <>
      <IconCheck customColor={{ base: 'white' }} size="small" />
      <Text color="white" component="span" variant="H4Condensed">
        In Your Box
      </Text>
    </>
  )

  function renderCardDetails() {
    return (
      <>
        <CardBody textAlign="center">
          <Text component="h2" variant="H4Condensed">
            {product.description}
          </Text>
          <Text color="stone" variant="Body1Regular">
            {product.packSize}
          </Text>
        </CardBody>
        <CardAction>
          {React.cloneElement(renderButton({ withinInfoModal: false }), {
            p: 0,
          })}
        </CardAction>
      </>
    )
  }

  return (
    <Card data={cardData} ref={cardRef} width="large">
      <CardMedia
        alt={product.description}
        height={112}
        infoClickHandler={infoClickHandler}
        infoToolTipMessage="Cut Information"
        overlay={quantity > 0 ? cardOverlay : undefined}
        src={product.image}
      >
        <CardQuantity value={quantity > 0 ? `${quantity}` : null} />
      </CardMedia>
      {modalOpen ? (
        <CutInfoModal
          onClose={onClose}
          product={product}
          quantity={quantity}
          renderButton={renderButton}
          variant="custom"
        />
      ) : null}
      {renderCardDetails()}
    </Card>
  )
}

export default CustomCutCard
