import zendesk from './scripts/onInitialClientRender/zendesk'
import datadog from './scripts/onInitialClientRender/dataDog'
import type { ScriptDefinition } from './scripts/script.types'
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs'

const DEFAULT_MOBILE_PIVOT = 768

const scripts: ScriptDefinition[] = [datadog, zendesk]

dayjs.extend(isBetween)

export const executeScript = (script) => {
  if (script.executeCheck && !script.executeCheck()) {
    return false
  }
  const mobilePivot = script.mobilePivot
    ? script.mobilePivot
    : DEFAULT_MOBILE_PIVOT
  const setTimeoutDelay =
    script.delay.mobile && window.innerWidth < mobilePivot
      ? script.delay.mobile
      : script.delay.default
  window.setTimeout(function () {
    if (script.pre) {
      script = script.pre(script)
    }
    const s = document.createElement('script')
    s.src = script.url
    s.async = script.async
    s.defer = script.defer
    s.id = script.id
    if (script.post) {
      s.onload = script.post
    }
    document.getElementsByTagName('head')[0].appendChild(s)
  }, setTimeoutDelay)
}

export const onInitialClientRender = () => {
  scripts.forEach(executeScript)
}
