import { NavItem } from 'design/types'
import { logout } from '~/utils/auth0'
import type { FooterProps } from '@butcherbox/freezer'

// Todo: Refactor to utilize the same format as header.ts
// when sidebar becomes a freezer component and links
// have the standardized type interface
export const AccountLinks: NavItem[] = [
  {
    'data-what': 'next_box',
    title: 'Next Box',
    url: '/account/your-box',
    urlType: 'internal',
  },
  {
    'data-what': 'box_settings',
    title: 'Box Settings',
    url: '/account/box-settings',
    urlType: 'internal',
  },
  {
    'data-what': 'order_history',
    title: 'Order History',
    url: '/account/order-history',
    urlType: 'internal',
  },

  {
    'data-what': 'profile_and_payment',
    title: 'Profile & Payment',
    url: '/account/profile-payment',
    urlType: 'internal',
  },
  {
    'data-what': 'help',
    title: 'Help',
    url: '/account/help',
    urlType: 'internal',
  },
  {
    'data-what': 'membership_review',
    options: {
      handleClick: () => window.open('/account/membership-review', '_self'),
      type: 'button',
      useVariant: true,
    },
    title: 'My Cuts & Recipes',
    urlType: 'internal',
  },
  {
    'data-what': 'log_out',
    options: {
      handleClick: () => logout(),
      type: 'button',
      useVariant: true,
    },
    title: 'Log Out',
  },
]

export const legalNavLinks: FooterProps['state']['legalNavLinks'] = [
  {
    data: { what: 'privacy_policy' },
    title: 'Privacy Policy',
    href: '/privacy-policy',
  },
  {
    data: { what: 'my_privacy_rights' },
    title: 'My Privacy Rights',
    href: '/privacy-policy/#supplementalPrivacyPolicy',
  },
  {
    data: { what: 'terms_of_use' },
    title: 'Terms of Use',
    href: '/terms-of-use',
  },
  {
    data: { what: 'accessibility_statement' },
    title: 'Accessibility Statement',
    href: '/ada',
  },
]

export const mainNavLinks: FooterProps['state']['mainNavLinks'] = [
  {
    data: { what: 'about_us' },
    title: 'About Us',
    href: '/about-us/',
    isExternal: false,
  },
  {
    data: { what: 'help_center' },
    title: 'Help Center',
    href: 'https://butcherbox.zendesk.com/hc/en-us',
    isExternal: true,
  },
  {
    data: { what: 'careers' },
    title: 'Careers',
    href: '/careers/',
    isExternal: false,
  },
  {
    data: { what: 'just_cook' },
    title: 'Just Cook',
    href: 'https://justcook.butcherbox.com/',
    isExternal: true,
  },
]

export const socialLinks: FooterProps['state']['socialLinks'] = [
  {
    data: { what: 'instagram_social' },
    title: 'instagram',
    href: 'https://instagram.com/butcher_box/',
    service: 'ig',
  },
  {
    data: { what: 'pinterest_social' },
    title: 'pinterest',
    href: 'https://www.pinterest.com/Butcher_Box/butcherbox/',
    service: 'pn',
  },
  {
    data: { what: 'twitter_social' },
    title: 'twitter',
    href: 'https://twitter.com/Butcherbox',
    service: 'tw',
  },
  {
    data: { what: 'youtube_social' },
    title: 'youtube',
    href: 'https://www.youtube.com/c/ButcherBox/featured',
    service: 'yt',
  },
  {
    data: { what: 'facebook_social' },
    title: 'facebook',
    href: 'https://www.facebook.com/pages/ButcherBox/1008040249210156?fref=ts',
    service: 'fb',
  },
]
