import type { BoxProps } from '@chakra-ui/core'
import { Flex } from '@chakra-ui/core'
import { rem, styled } from 'design'
import { Body } from 'design/components/Typography/Typography'
import React from 'react'
import { LiveMessage } from 'react-aria-live'
import { TEST_ID } from '~/constants/cypress'

type IButtonToast = {
  error: string
  duration?: number
} & BoxProps

const BUTTON_TOAST_HEIGHT = 24

const ButtonToast: React.FC<IButtonToast> = ({
  error,
  duration = 5000,
  ...props
}) => {
  return (
    <TransitionBox duration={duration}>
      <ErrorMotion>
        <Flex
          alignItems="center"
          backgroundColor="ui.inactive"
          borderColor="bb.spicedCrimson"
          borderWidth="1px"
          data-cy={TEST_ID.TOAST_BUTTON_ERROR}
          justifyContent="center"
          marginBottom={rem(6)}
          minH={rem(BUTTON_TOAST_HEIGHT)}
          padding={rem(4)}
          textAlign="center"
          whiteSpace="normal"
          width="100%"
          {...props}
        >
          <LiveMessage
            aria-live="polite"
            clearOnUnmount
            message={'Error: ' + error}
          />
          <Body
            aria-hidden="true"
            color="bb.slate"
            fontSize={rem(12)}
            fontWeight="normal"
            height="unset"
            lineHeight="1.3"
            maxWidth="100%"
            overflowWrap="break-word"
          >
            {error}
          </Body>
        </Flex>
      </ErrorMotion>
    </TransitionBox>
  )
}

export const TransitionBox = styled('div')<{ duration: number }>`
  animation: 0.5s ease 0s 1 fadeIn,
    0.5s ease-out calc(${(p) => p.duration}ms - 0.5s) 1 fadeout;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media (prefers-reduced-motion) {
    animation: unset;
  }
`

const ErrorMotion = styled('div')`
  bottom: calc(100% - 4px);
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
`

export default ButtonToast
