import { HeaderProps, NavItemLink } from '@butcherbox/freezer'
import { GIFT_BOXES_PATH_STOREFRONT } from './constants'
import { TRY_BB_URL } from '~/routes/constants'

type NavVariants = 'unauth' | 'auth'

/** ButcherBox Logo */
const LOGO: Record<NavVariants, HeaderProps['state']['logo']> = {
  unauth: {
    title: 'ButcherBox',
    type: 'link',
    link: {
      href: '/',
      data: { what: 'bb_logo' },
    },
  },
  auth: {
    title: 'ButcherBox',
    type: 'link',
    link: {
      href: '/member',
      data: { what: 'bb_logo' },
    },
  },
}

/** Primary CTA (Unauth) aka 'Get Started' */
const CTA: Record<NavVariants, HeaderProps['state']['cta']> = {
  unauth: {
    title: 'Choose Your Plan',
    type: 'link',
    link: {
      href: TRY_BB_URL,
    },
  },
  auth: undefined,
}

/** Customer Service Phone Callout */
export const CUSTOMER_SERVICE: Record<
  NavVariants | 'corporateSales',
  HeaderProps['state']['customerService']
> = {
  unauth: {
    text: 'Prefer to talk? Call us to order',
    cta: {
      title: '(855) 981-8568',
      link: {
        href: 'tel:+1-855-981-8568',
        rel: 'noopener noreferrer',
        target: '_self',
        isExternal: true,
      },
    },
  },
  auth: undefined,
  corporateSales: {
    text: 'Prefer to talk? Call our Corporate Gifting Team',
    cta: {
      title: '(888) 930-8139',
      link: {
        href: 'tel:+1-888-930-8139',
        rel: 'noopener noreferrer',
        target: '_self',
        isExternal: true,
      },
    },
  },
}

/** Recipes */
const justCookRecipesSubNav: Array<NavItemLink> = [
  {
    title: 'All recipes',
    link: {
      href: 'https://justcook.butcherbox.com/recipes/',
      target: '_blank',
      data: { what: 'recipes_all' },
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    variant: 'primary',
    type: 'link',
  },
  {
    title: 'Beef',
    link: {
      href: 'https://justcook.butcherbox.com/category/beef/',
      target: '_blank',
      data: { what: 'recipes_beef' },
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    variant: 'primary',
    type: 'link',
  },
  {
    title: 'Pork',
    link: {
      href: 'https://justcook.butcherbox.com/category/pork/',
      target: '_blank',
      data: { what: 'recipes_pork' },
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    variant: 'primary',
    type: 'link',
  },
  {
    title: 'Chicken',
    link: {
      href: 'https://justcook.butcherbox.com/category/chicken/',
      target: '_blank',
      data: { what: 'recipes_chicken' },
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    variant: 'primary',
    type: 'link',
  },
  {
    title: 'Seafood & More',
    link: {
      href: 'https://justcook.butcherbox.com/category/other/',
      target: '_blank',
      data: { what: 'recipes_seafood' },
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    variant: 'primary',
    type: 'link',
  },
]

const RECIPES_SUBNAV: Record<NavVariants, Array<NavItemLink>> = {
  auth: [
    {
      title: 'Browse Recipes',
      link: {
        href: '/account/recipes',
        target: '_self',
        data: { what: 'recipes_browse' },
      },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'Saved Recipes',
      link: {
        href: '/account/saved-recipes',
        target: '_self',
        data: { what: 'saved_recipes' },
      },
      variant: 'primary',
      type: 'link',
    },
    {
      title: "What's for Dinner?",
      link: {
        href: '/account/whats-for-dinner',
        target: '_self',
        data: { what: 'whats_for_dinner' },
      },
      variant: 'primary',
      type: 'link',
    },
  ],
  unauth: justCookRecipesSubNav,
}

/** Account Links */
const accountSubnav: Array<NavItemLink> = [
  {
    title: 'Box Settings',
    link: {
      href: '/account/box-settings',
      target: '_self',
      data: { what: 'box_settings' },
    },
    variant: 'secondary',
    type: 'link',
  },
  {
    title: 'Order History',
    link: {
      href: '/account/order-history',
      target: '_self',
      data: { what: 'order_history' },
    },
    variant: 'secondary',
    type: 'link',
  },
  {
    title: 'Profile & Payment',
    link: {
      href: '/account/profile-payment',
      target: '_self',
      data: { what: 'profile_and_payment' },
    },
    variant: 'secondary',
    type: 'link',
  },
]

const NAV: Record<NavVariants, HeaderProps['state']['nav']> = {
  unauth: [
    {
      title: 'How it Works',
      link: { href: '/how-it-works/' },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'Sourcing',
      link: { href: '/sourcing/' },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'Recipes',
      data: { what: 'recipes' },
      type: 'subnav',
      variant: 'primary',
      subNav: [...RECIPES_SUBNAV.unauth],
    },
    {
      title: 'Gifts',
      link: {
        href: GIFT_BOXES_PATH_STOREFRONT,
        isExternal: true,
        data: { what: 'gifts' },
      },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'FAQs',
      link: { href: '/faqs/' },
      variant: 'primary',
      type: 'link',
    },
    {
      type: 'separator',
      variant: 'primary',
    },
    {
      title: 'Choose your Plan',
      link: { href: TRY_BB_URL },
      variant: 'primary',
      type: 'link',
      renderFor: ['Mobile'],
    },
  ],
  auth: [
    {
      title: 'Customize Your Box',
      link: {
        href: '/account/customize-your-box',
        target: '_self',
        data: { what: 'custom_box_page' },
      },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'Add-ons',
      link: {
        href: '/account/addons',
        target: '_self',
        data: { what: 'add_ons' },
      },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'Deals',
      link: {
        href: '/account/deals',
        target: '_self',
        data: { what: 'deals' },
      },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'Catalog',
      link: {
        href: '/account/deals?lander=catalog',
        target: '_self',
        data: { what: 'catalog' },
      },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'Gifts',
      link: {
        href: GIFT_BOXES_PATH_STOREFRONT,
        target: '_blank',
        rel: 'noopener noreferrer',
        isExternal: true,
        data: { what: 'gifts' },
      },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'Recipes',
      data: { what: 'recipes' },
      type: 'subnav',
      variant: 'primary',
      subNav: [...RECIPES_SUBNAV.auth],
    },
    {
      title: 'Get $50',
      link: {
        href: '/account/credits',
        target: '_self',
        data: { what: 'refer_a_friend' },
      },
      variant: 'primary',
      type: 'link',
    },
    {
      title: 'Next Box',
      link: {
        href: '/account/your-box',
        target: '_self',
        data: { what: 'next_box' },
      },
      variant: 'secondary',
      type: 'link',
      renderFor: ['Mobile'],
    },
    {
      title: 'My Cuts & Recipes',
      link: {
        href: '/account/membership-review',
        target: '_self',
        data: { what: 'membership_review' },
      },
      decoration: 'indicatorDot',
      variant: 'secondary',
      type: 'link',
      renderFor: ['Mobile'],
    },
    {
      title: 'Account',
      variant: 'secondary',
      type: 'subnav',
      renderFor: ['Mobile'],
      subNav: [...accountSubnav],
    },
    {
      title: 'Help',
      link: {
        href: '/account/help',
        target: '_self',
        data: { what: 'help' },
      },
      variant: 'secondary',
      type: 'link',
      renderFor: ['Mobile'],
    },
    {
      title: 'Log out',
      link: {
        href: '/',
        target: '_self',
        data: { what: 'log_out' },
      },
      variant: 'primary',
      decoration: 'spicedCrimson',
      type: 'link',
      renderFor: ['Mobile'],
    },
    {
      type: 'separator',
      variant: 'primary',
      renderFor: ['Desktop'],
    },
    {
      title: 'Account',
      link: { href: '/account/your-box', data: { what: 'account' } },
      variant: 'primary',
      type: 'link',
      renderFor: ['Desktop'],
    },
  ],
}

export const AUTH: HeaderProps['state'] = {
  logo: LOGO.auth,
  cta: CTA.auth,
  customerService: CUSTOMER_SERVICE.auth,
  nav: NAV.auth,
}

export const UNAUTH: HeaderProps['state'] = {
  logo: LOGO.unauth,
  cta: CTA.unauth,
  customerService: CUSTOMER_SERVICE.unauth,
  nav: NAV.unauth,
}
