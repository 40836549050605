import React from 'react'
import { formatPriceFromCents } from '~/utils'
import { Box, Lockup, Text } from '@butcherbox/freezer'
import * as Styles from './SlashThroughDealsSummary.css'

export const SlashThroughDealsSummary = ({ totalSlashThroughPriceSave }) => {
  return (
    <Box className={Styles.DealsSummary}>
      <Lockup textAlign="center">
        <Text component="h2" variant="Subhead2">
          Member Deal Savings
        </Text>
        <Text component="h4" variant="H4Bold">
          You’re saving{' '}
          <strong>{formatPriceFromCents(totalSlashThroughPriceSave)}</strong> on
          this box!
        </Text>
      </Lockup>
    </Box>
  )
}

export default SlashThroughDealsSummary
