import { useContext } from 'react'
import { queryCache, useMutation } from 'react-query'
import {
  CACHE_KEY_SUBSCRIPTION,
  CACHE_KEY_UPCOMING_ORDER,
} from '~/bb-api/constants'
import { UPDATE_OFFER } from '~/bb-api/endpoints'
import { Offer } from '~/bb-api/schemata'
import { SubscriptionContext } from '~/context/subscription'
import { UserContext } from '~/context/user'
import axios from '~/utils/axios'
import { captureNetworkError } from '~/analytics/errors'

export default function mutateOfferStatus(status: 'activate' | 'pause') {
  const user = useContext(UserContext)
  const { subscription } = useContext(SubscriptionContext)

  const mutateOfferStatus = (sku: Offer['sku']) =>
    axios.put<Offer>(UPDATE_OFFER(user.id, subscription.id, sku), {
      status: status === 'activate' ? 'active' : 'paused',
    })

  return useMutation(mutateOfferStatus, {
    onSuccess() {
      queryCache.invalidateQueries(CACHE_KEY_SUBSCRIPTION)
      queryCache.invalidateQueries(CACHE_KEY_UPCOMING_ORDER)
    },
    onError(e) {
      captureNetworkError(e)
    },
  })
}
