import React from 'react'
import { trackProductsViewed, ViewedProduct } from '~/analytics/events'
import { Product } from '~/bb-api/schemata'

// Wait this long before sending a Products Viewed event
const DELAY_BEFORE_SENDING_MS = 1000

export interface ViewedProductContextValue {
  trackProductSeen?: (
    product: { sku: Product['sku'] },
    timestamp?: number
  ) => void
  eventQueue?: ViewedProduct[]
}

export const ViewedProductContext = React.createContext<ViewedProductContextValue>(
  {} as ViewedProductContextValue
)

const initialQueueState = []

type Action =
  | { type: 'productSeen'; payload: ViewedProduct }
  | { type: 'flushQueue' }

function reducer(
  state: typeof initialQueueState,
  action: Action
): ViewedProduct[] {
  switch (action.type) {
    case 'productSeen':
      return [...state, { sku: action.payload.sku, ts: action.payload.ts }]
    case 'flushQueue':
      return []
    default:
      return state
  }
}

export const ViewedProductsProvider = ({ children }) => {
  const [queueState, dispatch] = React.useReducer(reducer, initialQueueState)

  React.useEffect(() => {
    if (!queueState.length) return

    const intervalId = setInterval(() => {
      trackProductsViewed({ viewedProducts: queueState }, () =>
        dispatch({ type: 'flushQueue' })
      )
    }, DELAY_BEFORE_SENDING_MS)

    return () => clearTimeout(intervalId)
  }, [queueState])

  const trackProductSeen = React.useCallback<
    ViewedProductContextValue['trackProductSeen']
  >(
    ({ sku }, ts) => {
      dispatch({
        payload: { sku, ts: ts || Math.floor(Date.now() / 1000) },
        type: 'productSeen',
      })
    },
    [dispatch]
  )

  return (
    <ViewedProductContext.Provider
      value={{
        trackProductSeen,
        eventQueue: queueState,
      }}
    >
      {children}
    </ViewedProductContext.Provider>
  )
}
