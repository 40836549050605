/**
 * Recursively apply function to object or array string leaves
 */
export default function recursiveMap(v: Json, callback: Callback): Json {
  if (Array.isArray(v)) {
    return v.map((item) => (item ? recursiveMap(item, callback) : item))
  } else if (typeof v === 'object') {
    return Object.entries(v || {}).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: value ? recursiveMap(value, callback) : value,
      }),
      {}
    )
  } else {
    return callback(v)
  }
}

type Callback = (v: Leaf) => Leaf
export type Leaf = string | number | boolean | null
export type Json = Leaf | Json[] | { [key: string]: Json }
