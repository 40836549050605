import { UpcomingOrder } from '~/bb-api/schemata'

export const calculatePriceForUpcomingOrder = (
  upcomingOrder: UpcomingOrder
) => {
  return (
    upcomingOrder.subscription.box.basePrice +
    upcomingOrder.subscription.box.addonsPrice +
    upcomingOrder.invoiceItemsPrice +
    upcomingOrder.discountAmount +
    upcomingOrder.creditAmount +
    upcomingOrder.shippingPrice +
    upcomingOrder.taxAmount
  )
}
