export const API_DOMAIN =
  process.env.NODE_ENV !== 'production'
    ? process.env.API_HOST || 'https://local.bboxtools.net:22443'
    : process.env.API_HOST || ''

export const API_V3_BASE_PATH = `${API_DOMAIN}/apiv3`
export const API_V4_BASE_PATH = `${API_DOMAIN}/api/v4`

export const API_V3_CUSTOMER = `${API_V3_BASE_PATH}/customer`
export const API_V4_CUSTOMER = `${API_V4_BASE_PATH}/customer`

export const POST_EASYPOST_ADDRESS = `${API_V4_BASE_PATH}/validate/address`

export const CACHE_KEY_ADDONS = 'addons'
export const CACHE_KEY_BOX_ITEMS = 'boxItems'
export const CACHE_KEY_BOX_SIZES = 'boxSizes'
export const CACHE_KEY_BOX_TYPES = 'boxTypes'
export const CACHE_KEY_CANCEL_SETTINGS = 'cancelSettings'
export const CACHE_KEY_CHECKOUT_CART = 'checkoutCart'
export const CACHE_KEY_CHECKOUT_DEALS = 'checkoutDeals'
export const CACHE_KEY_CHECKOUT_SETTINGS = 'checkoutSettings'
export const CACHE_KEY_CHECKOUT_SETTINGS_PLANS_AND_ADDONS =
  'checkoutSettingsPlans&Addons'
export const CACHE_KEY_CUSTOMER = 'customer'
export const CACHE_KEY_CUSTOMER_STATS = 'customerStats'
export const CACHE_KEY_EXISTING_CART = 'existingCart'
export const CACHE_KEY_MEMBER_DEALS = 'memberDeal'
export const CACHE_KEY_MEMBER_FEATURED_DEAL = 'memberFeaturedDeal'
export const CACHE_KEY_MEMBER_DEAL_LANDER = 'memberDealLander'
export const CACHE_KEY_ORDER_ISSUE = 'orderIssue'
export const CACHE_KEY_ORDERS = 'orders'
export const CACHE_KEY_RESUBSCRIBE_SETTINGS = 'resubscribeSettings'
export const CACHE_KEY_SUBSCRIPTION = 'subscription'
export const CACHE_KEY_UPCOMING_ORDER = 'upcomingOrder'
export const CACHE_KEY_LATEST_SHIPMENT = 'latestShipment'
export const CACHE_KEY_USER_ACTION = 'userAction'

export enum API {
  'V3',
  'V4',
}
