import { CMS } from '~/cms/types'
import { Color, TextLinkProps } from '@butcherbox/freezer'
import { OldColor, oldColors, OldTextLinkThemes } from 'design/colors'

export function isHexLightOrDark(color) {
  color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))

  const r = color >> 16
  const g = (color >> 8) & 255
  const b = color & 255

  // HSP equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light'
  } else {
    return 'dark'
  }
}

/**
 * To determine the text color when a background image is provided, we need to determine
 * if the image provided is DARK or LIGHT based on the data provided by gatsbyImage data
 * this helps us provide accessible text over that background image.
 */
export function getTextColor({
  module,
  design,
}: {
  module: CMS.ModuleMarketingCopy
  design: CMS.ModuleMarketingCopyDesign
}): Color {
  // background Image will take precedence over bg color and return early.
  if (
    design.backgroundImageSupport !== 'Not Supported' &&
    module.backgroundImageSet
  ) {
    const hex =
      module.backgroundImageSet.mobileImage.image.gatsbyImageData
        .backgroundColor
    if (isHexLightOrDark(hex) == 'dark') {
      return 'white'
    } else {
      return 'slate'
    }
  }
  // This switch will likely need to be expanded with more cases.
  // TODO: future considerations, have the freezer be aware of themes for fg/bg combinations
  // and expose a class option that applies both a bg/fg color.
  switch (module.backgroundColor) {
    case 'deepSeaBlue':
      return 'white'
    case 'openSea':
      return 'white'
    case 'haze':
    case 'ivory':
    case 'white':
    default:
      return 'slate'
  }
}

export function getBackgroundColor({
  module,
  design,
}: {
  module: CMS.ModuleMarketingCopy
  design: CMS.ModuleMarketingCopyDesign
}): Color {
  if (
    design.backgroundColorSupport !== 'Not Supported' &&
    module.backgroundColor
  )
    return getNewBrandColorName(module.backgroundColor)
  return 'white'
}

function isOldColor(color: Color | OldColor): color is OldColor {
  return color in oldColors
}

export function getNewBrandColorName(color: Color | OldColor): Color {
  if (isOldColor(color)) {
    switch (color) {
      case 'barnDoorRed':
        return 'spicedCrimson'
      case 'deepSeaBlue':
        return 'openSea'
      case 'pastureGreen':
        return 'pesto'
      case 'sunriseGold':
        return 'honeyMustard'
      case 'midnightBlue':
        return 'slate'
      case 'charcoal':
        return 'slate'
      case 'ash':
        return 'granite'
      case 'smoke':
        return 'silt'
      case 'haze':
        return 'ivory'
      case 'sage':
        return 'pesto'
      case 'spruce':
        return 'pesto'
      case 'sky':
        return 'openSea'
      case 'clementine':
        return 'honeyMustard'
      default:
        return 'slate'
    }
  }

  return color
}

export function getNewBrandLinkTheme(
  theme: TextLinkProps['theme'] | OldTextLinkThemes
): TextLinkProps['theme'] {
  if (isOldColor(theme)) {
    switch (theme) {
      case 'barnDoorRed':
        return 'spicedCrimson'
      case 'charcoal':
        return 'slate'
      default:
        return 'spicedCrimson'
    }
  }
  return theme
}
