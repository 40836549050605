import { klona } from 'klona/json'
import { flatten } from 'lodash'
import React from 'react'
import { BoxItem, Product } from '~/bb-api/schemata'
import { SubscriptionContextRemote } from '~/context/subscriptionRemote'
import useCustomerBoxItems from '~/hooks/useCustomerBoxItems'

export interface BoxItemWithIsUnavailable extends BoxItem {
  isUnavailable: boolean
}

// This hook marks which products in a customer's box are currently unavailable.
export default function useCustomerBoxItemsWithAvailability(): BoxItemWithIsUnavailable[] {
  const { subscription } = React.useContext(SubscriptionContextRemote)
  const { data: rawAvailableProducts = {} } = useCustomerBoxItems()

  // filter out which skus are currently unavailable
  const unavailableItems = React.useMemo(
    () =>
      flatten<Product>(Object.values(rawAvailableProducts))
        .filter((product) => {
          return product.isUnavailable && product.sku
        })
        .map((product) => {
          return product.sku
        }),
    [rawAvailableProducts]
  )

  // check each box item to see if its sku is in the unavailable list, and mark it unavailable if so
  const products = React.useMemo(() => {
    return klona(subscription.box.items).map((boxItem) => {
      return {
        ...boxItem,
        isUnavailable: unavailableItems.includes(boxItem?.sku),
      }
    })
  }, [subscription.box.items, unavailableItems])

  return products
}
