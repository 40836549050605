import React from 'react'
import { UpcomingInvoiceItem } from '~/bb-api/schemata'
import { MemberDealsEmptyState } from '~/components/EmptyStates'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { PanelSection } from '~/components/Panel/PanelSection/PanelSection'

import { formatPriceFromCents } from '~/utils'
import duplicateItemsByQuantity from '~/utils/duplicateItemsByQuantity'
import CartProductList from '../shared/CartProductList'
import { CartSection } from '../../CartDrawer.css'
import { Box } from '@butcherbox/freezer'

const Deals = ({
  price,
  invoiceItems,
  onClose,
}: {
  price: number
  invoiceItems: Array<UpcomingInvoiceItem>
  onClose?: () => void
}) => {
  return (
    <Box className={CartSection}>
      <PanelSection
        content="Exclusive Member Deals"
        contentAddon={price !== 0 ? formatPriceFromCents(price) : ''}
      />
      <PanelBreak />
      {invoiceItems && invoiceItems.length > 0 ? (
        <CartProductList
          aria-label="Member deals included in your upcoming order"
          productArray={duplicateItemsByQuantity(invoiceItems)}
          showGroupedItems
          textTransform="none"
        />
      ) : (
        <MemberDealsEmptyState onClose={onClose} />
      )}
    </Box>
  )
}
export default Deals
