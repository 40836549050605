import React, { InputHTMLAttributes } from 'react'
import {
  Box,
  BoxProps,
  ControlBox,
  ControlBoxProps,
  VisuallyHidden,
} from '@chakra-ui/core'
import { FieldProps } from 'formik'
import { rem } from 'design'
import FormErrorMessage from 'design/components/FormErrorMessage/FormErrorMessage'
import { nanoid } from 'nanoid'
import IconRadioLargeUnchecked from '../Icons/defs/RadioLargeUnchecked'
import IconRadioLargeChecked from '../Icons/defs/RadioLargeChecked'
import IconRadioSmallChecked from '../Icons/defs/RadioSmallChecked'
import IconRadioSmallUnchecked from '../Icons/defs/RadioSmallUnchecked'
export type IRadio = {
  disableTabIndex?: boolean
  checked?: boolean
  disabled?: boolean
  inputProps?: InputHTMLAttributes<any>
  labelProps?: Object
  radioStyleProps?: ControlBoxProps
  size?: 'sm' | 'lg'
  inputId?: string //TODO: this should be required in a refactor
  type?: string
  errorStyleOverride?: React.CSSProperties
} & BoxProps &
  Partial<FieldProps>

/**
 * The flexibility of the standard Chakra UI <Radio> component is does not
 * meet our needs, so we follow their recommendations and use their example to
 * create our own highly customizable Radio.
 *
 * docs: https://github.com/chakra-ui/chakra-ui/blob/master/packages/chakra-ui/src/Radio/index.js
 * docs: https://chakra-ui.com/controlbox
 */
const Radio: React.FC<IRadio> = ({
  disableTabIndex = false,
  disabled = false,
  errorStyleOverride,
  field = {},
  form = {},
  inputProps,
  radioStyleProps = {},
  size = 'sm',
  inputId,
  type, //TODO: when we pass 'type' in as part of the spread props from all of the Field components, it pops up on the wrapping span where it shouldn't. this should be refactored in a future ticket
  ...props
}) => {
  const hasError = !!form.errors[field.name] && form.submitCount > 0
  const errorId = `${nanoid()}-error`

  return (
    <Box
      alignItems="center"
      as="span"
      cursor={disabled ? 'not-allowed' : 'pointer'}
      display="inline-flex"
      {...props}
      verticalAlign="top"
    >
      {/* This is the sibling input, it's visually hidden */}
      <VisuallyHidden
        aria-describedby={!!hasError ? errorId : ''}
        aria-invalid={hasError}
        as="input"
        data-what="radio"
        // @ts-ignore
        disabled={disabled}
        id={inputId}
        // @ts-ignore
        tabIndex={disableTabIndex ? -1 : undefined}
        type={type || 'radio'} //TODO: this should always be a radio but some components are still passing it in
        {...field}
        {...inputProps}
      />
      {/* This is the control box with a check icon as children */}
      <ControlBox
        _hover={{ color: 'bb.spicedCrimson' }}
        as="span"
        size={size === 'lg' ? rem(36) : rem(16)}
        {...radioStyleProps}
        color={field.checked ? `bb.spicedCrimson` : 'bb.granite'}
      >
        {getRadioIcon(size, field.checked)}
      </ControlBox>
      {/* TODO: error shouldn't be in label */}
      {!!hasError && (
        <FormErrorMessage
          data-what="radio-error"
          hasError={!!hasError}
          id={errorId}
          mt={rem(4)}
          pb={rem(4)}
          pos="absolute"
          style={errorStyleOverride}
          textAlign="left"
          zIndex={1}
        >
          {form.errors[field.name]}
        </FormErrorMessage>
      )}
    </Box>
  )
}

const getRadioIcon = (size: IRadio['size'], checked: Boolean): any => {
  switch (true) {
    case size === 'lg' && checked:
      return (
        <IconRadioLargeChecked
          size={rem(36)}
          style={{ pointerEvents: 'none', opacity: '1' }}
          transition="transform 0.2s, opacity 0.2s"
        />
      )
    case size === 'lg' && !checked:
      return (
        <IconRadioLargeUnchecked
          size={rem(36)}
          style={{ pointerEvents: 'none', opacity: '1' }}
          transition="transform 0.2s, opacity 0.2s"
        />
      )
    case size === 'sm' && checked:
      return (
        <IconRadioSmallChecked
          size={rem(16)}
          style={{ pointerEvents: 'none', opacity: '1' }}
          transition="transform 0.2s, opacity 0.2s"
        />
      )
    case size === 'sm' && !checked:
      return (
        <IconRadioSmallUnchecked
          size={rem(16)}
          style={{ pointerEvents: 'none', opacity: '1' }}
          transition="transform 0.2s, opacity 0.2s"
        />
      )
  }
}

export default Radio
