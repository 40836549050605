import {
  Box,
  Button,
  Lockup,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
} from '@butcherbox/freezer'
import { RouteComponentProps, WindowLocation } from '@reach/router'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { Offer } from '~/bb-api/schemata'
import mutateOfferStatus from '~/hooks/mutateOfferStatus'
import { captureNetworkError } from '~/analytics/errors'
import dayjs from 'dayjs'
import * as Styles from './ModalPauseOffer.css'

type ModalPauseOfferProps = RouteComponentProps & {
  location?: WindowLocation<Offer>
  handleModalClose: () => void
}

const ModalPauseOffer: React.FC<ModalPauseOfferProps> = ({
  handleModalClose,
  location,
}) => {
  // If user navigates to modal without having clicked 'remove item',
  // navigate to base page and return null
  if (location.state == null) {
    handleModalClose()
    return null
  }

  const { sku, description, expirationDate } = location.state

  const showToast = React.useContext(ToastContext)
  // Enable pausing an active offer, or the other way around
  const [mutate, { status: mutateStatus }] = mutateOfferStatus('pause')

  const pauseOffer = React.useCallback(
    () =>
      mutate(sku, {
        onSuccess: () =>
          showToast('success', {
            children: `Success! Paused offer ${description}`,
          }),
        onError: (e: any) => {
          captureNetworkError(e, { sku })
          showToast('error', {
            children: `Could not pause offer ${description}. Please contact customer support.`,
          })
        },
        onSettled: () => handleModalClose(),
      }),
    [description, handleModalClose, mutate, showToast, sku]
  )

  const isLoading = mutateStatus === 'loading'
  const expDateIsValid = expirationDate && dayjs(expirationDate).isValid()

  return (
    <Modal
      data-what="pause-offer-modal"
      onClose={handleModalClose}
      size="fit"
      title={expDateIsValid ? 'Are You Sure?' : ''}
    >
      <Box className={Styles.ModalBodyContainer}>
        <ModalBody paddingTop={40}>
          <Lockup>
            <Text textAlign="center" variant="H2Bold">
              Are you sure you want to pause {description}?
            </Text>
            <Text textAlign="center" variant="Body1Regular">
              {expDateIsValid ? (
                <>
                  By choosing to pause this offer, you will not receive{' '}
                  {description} in your box until you resume your offer. Pausing
                  this offer will not change its expiration date of{' '}
                  {dayjs(expirationDate).format('MMMM D, YYYY')}.
                </>
              ) : (
                <>
                  By choosing to pause this offer, you will no longer receive{' '}
                  {description}. Unpause at any time and get it in your next
                  box!
                </>
              )}
            </Text>
          </Lockup>
        </ModalBody>
      </Box>
      <ModalFooter>
        <Button
          disabled={isLoading}
          loading={isLoading}
          onClick={() => pauseOffer()}
          size="small"
          type="submit"
        >
          Pause Offer
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalPauseOffer
