import React from 'react'
import type * as Types from 'design/components/Modal/Modal.types'
import * as Styled from 'design/components/Modal/ModalBackButton.styles'
import { IconChevronLeft } from '@butcherbox/freezer'

const ModalBackButton: React.FC<Types.BackButtonProps> = ({ ...props }) => {
  return (
    <Styled.BackButton as="button" {...props}>
      <IconChevronLeft size="text" />
    </Styled.BackButton>
  )
}

export default ModalBackButton
