import React, { SVGProps } from 'react'
import { TEST_ID } from '~/constants/cypress'

export const Info = (props: SVGProps<any>) => (
  <svg
    data-cy={TEST_ID.ICON_INFO}
    data-what="icon-info"
    height="11.53px"
    viewBox="0 0 4.39 11.53"
    width="4.39px"
    {...props}
  >
    <path
      d="M2.01,0c0.35,0,0.65,0.12,0.9,0.37s0.38,0.55,0.38,0.9s-0.12,0.65-0.38,0.9s-0.55,0.37-0.9,0.37
	c-0.35,0-0.66-0.12-0.91-0.38s-0.38-0.55-0.38-0.9c0-0.35,0.12-0.65,0.38-0.9S1.65,0,2.01,0z M0,4.22l3.25-0.15v6.21
	c0,0.17,0.05,0.3,0.16,0.39S3.68,10.8,3.9,10.8h0.49v0.73H0V10.8h0.48c0.26,0,0.44-0.06,0.55-0.18S1.2,10.3,1.2,10V5.77
	c0-0.27-0.08-0.47-0.23-0.62S0.6,4.93,0.33,4.93H0V4.22z"
    />
  </svg>
)

export const ChevronDown = ({ fill, ...props }) => (
  <svg
    data-what="icon-chevron-down"
    height="9.19px"
    viewBox="0 0 16.31 9.19"
    width="16.31px"
    {...props}
  >
    <polygon
      fill={fill}
      points="16.31,1.54 14.94,0.08 8.2,6.41 1.37,0 0,1.46 7.49,8.48 8.15,9.19"
    />
  </svg>
)
