import { Auth0Provider, useAuth0 } from '@auth0/auth0-react'
import { OptimizelyProvider } from '@optimizely/react-sdk'
import { navigate } from 'gatsby'
import React from 'react'
import { AnonymousIdProvider } from '~/context/anonymousId'
import LocalOptimizelyProvider from '~/context/optimizely'
import BaseLayout from '~/layouts/BaseLayout'
import { setAuth0Client } from '~/utils/auth0'
import { ENVIRONMENT, getEnvironment } from '~/utils/env'
import { getSdkClientScaffold } from '~/utils/optimizely'

export const getAuth0Config = () => {
  const config = {
    audience: '',
    clientId: process.env.AUTH0_CLIENT_ID,
    domain: process.env.AUTH0_DOMAIN,
    cookieDomain: '',
  }

  const env = getEnvironment(window.location)

  if (env === ENVIRONMENT.STAGING) {
    config.clientId = process.env.AUTH0_STAGING_CLIENT_ID
    config.domain = process.env.AUTH0_STAGING_DOMAIN
  }

  config.audience = `https://${config.domain}/api/v2/`

  if (env === ENVIRONMENT.PRODUCTION) {
    // we set it later so the audience remains on the original domain value
    config.domain = 'login.butcherbox.com'
    config.cookieDomain = '.butcherbox.com'
  }

  if (env === ENVIRONMENT.STAGING) {
    // we set it later so the audience remains on the original domain value
    config.domain = 'login.butcherbox.dev'
    config.cookieDomain = '.staging.bboxtools.net'
  }

  if (env === ENVIRONMENT.DEV) {
    config.cookieDomain = 'local.bboxtools.net'
  }

  return config
}

const onRedirectCallback = (appState) => navigate(appState?.returnTo)

export const wrapRootElement = ({ element }) => (
  <BaseLayout>{element}</BaseLayout>
)

export const wrapRootElementSSR = ({ element }) => (
  /**
   * The Optimizely React SDK does not support static site generation natively.
   * To get around this limitation, we mock the interface of the object used
   * to configure the Optimizely context provider internally. It is mocked in
   * such a way as to be a no-op which never moves into a ready state during SSG.
   */
  <OptimizelyProvider optimizely={getSdkClientScaffold()} user={null}>
    {wrapRootElement({ element })}
  </OptimizelyProvider>
)

export const wrapRootElementBrowser = ({ element }) => (
  <AnonymousIdProvider>
    <LocalOptimizelyProvider>
      <Auth0Provider
        {...getAuth0Config()}
        onRedirectCallback={onRedirectCallback}
        redirectUri={`${window.location.origin}/authorize?redirect=/member`}
        scope="openid profile email"
      >
        <CacheAuth0Client />

        {wrapRootElement({ element })}
      </Auth0Provider>
    </LocalOptimizelyProvider>
  </AnonymousIdProvider>
)

/**
 * Because react-auth0 caches the client in the context itself and doesn't
 * expose the client
 */
export const CacheAuth0Client = React.memo(() => {
  const client = useAuth0()

  setAuth0Client(client)

  return null
})
