import React from 'react'
import { AUTH } from '~/data/header'
import { Box, HeaderProps, NavItemLink } from '@butcherbox/freezer'
import usePaymentProcessingMessage from '~/hooks/usePaymentProcessingMessage'
import { logout } from '~/utils/auth0'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import {
  GIFT_BOXES_PATH_SHOP,
  GIFT_BOXES_PATH_STOREFRONT,
} from '~/data/constants'

const useAuthenticatedNavState = () => {
  /**
   * When payment is processing, or account has billing issues,
   * we disable box customization and show a tooltip.
   */
  const {
    isLoading,
    message,
    isTodayBillingDay,
  } = usePaymentProcessingMessage()

  const [isGiftLinkEnabled] = useOptimizelyFeature('ecom_810_remix_otb_rollout')

  return React.useMemo(() => {
    // Default Navigation Fixture Data

    AUTH.nav[4]['link'].href = isGiftLinkEnabled
      ? GIFT_BOXES_PATH_SHOP
      : GIFT_BOXES_PATH_STOREFRONT

    const navigation: HeaderProps['state'] = { ...AUTH }

    /**
     * If there is a message, then we need to update
     * the customize your box item to include a tooltip,
     * and disable the button.
     */
    const customizeYourBoxIndex = navigation.nav.findIndex(
      (i) => i.type === 'link' && i.title === 'Customize Your Box'
    )

    if ((!isLoading && message) || (!isLoading && isTodayBillingDay)) {
      const customizeYourBox_withToolTip: NavItemLink = {
        ...(navigation.nav[customizeYourBoxIndex] as NavItemLink),
        tooltip: {
          message: message,
          position: 'bottom',
          disableAction: true,
        },
      }
      navigation.nav[customizeYourBoxIndex] = customizeYourBox_withToolTip
    } else {
      const customizeYourBox_withoutToolTip: NavItemLink = {
        ...(navigation.nav[customizeYourBoxIndex] as NavItemLink),
        tooltip: undefined,
      }
      navigation.nav[customizeYourBoxIndex] = customizeYourBox_withoutToolTip
    }

    /**
     * Logout should render as a button, and not a link,
     * find the existing `link` and replace it with a new
     * navigation item, with a custom render method.
     */
    const logoutIndex = navigation.nav.findIndex(
      (i) => i.type === 'link' && i.title === 'Log out'
    )
    const logoutAction: NavItemLink = {
      ...(navigation.nav[logoutIndex] as NavItemLink),
      render: ({ link, className, children, ref, props }) => (
        <Box
          className={className}
          component="button"
          onClick={link.onClick}
          ref={ref}
          {...props}
          paddingBottom={60}
        >
          {children}
        </Box>
      ),
    }
    logoutAction.link.onClick = () => logout()
    navigation.nav[logoutIndex] = logoutAction

    return navigation
  }, [isLoading, message, isTodayBillingDay])
}

export default useAuthenticatedNavState
