import { Box } from '@chakra-ui/core'
import { FocusScope } from '@react-aria/focus'
import { useKeyboard } from '@react-aria/interactions'
import { css, Global } from 'design'
import React from 'react'
import ReactDOM from 'react-dom'
import { TEST_ID } from '~/constants/cypress'
import * as Styled from 'design/components/Modal/Modal.styles'
import type * as Types from 'design/components/Modal/Modal.types'
import ModalCloseButton from 'design/components/Modal/ModalCloseButton'
import ModalContext from 'design/components/Modal/ModalContext'
import ModalDialog from 'design/components/Modal/ModalDialog'
import ModalFooter from 'design/components/Modal/ModalFooter'
import ModalHeader from 'design/components/Modal/ModalHeader'
import ModalProvider from 'design/components/Modal/ModalProvider'

const BodyFixed = css`
  body {
    height: 100%;
    overflow: hidden;
    width: 100%;
  }
`

/**
 * Modals are comprised of the outer shell (this component), ModalDialog,
 * and then optionally ModalHeader, ModalFooter, and inner content.
 *
 * ```jsx
 * <Modal onClose={eventHandler}>
 *   <ModalDialog>
 *     <ModalHeader>Something</ModalHeader>
 *
 *     <ModalContent>
 *       Arbitrary content
 *     </ModalContent>
 *
 *     <ModalFooter>
 *       {SaveButton}
 *     </ModalFooter>
 *   </ModalDialog>
 * </Modal>
 * ```
 *
 * Modal is responsible for creating the "portal", adding the overlay, and positioning the modal itself.
 * ModalDialog is responsible for the background color and inner layout of the modal.
 * ModalContent is responsible for adding vertical scroll behavior to the content of the modal.
 * ModalHeader is optional if the modal design calls for it.
 * ModalFooter is optional if the modal design calls for it.
 *
 * Modal and ModalDialog are separate such that you can compose an additional component layer over ModalDialog
 * if desired. This is commonly done if the modal is a Formik form.
 *
 * If you desire to use a container with different positioning than that specified in the Container component below
 * you should set the useContainer prop to false, and then make sure whatever styling and positioning you want
 * on your container is on the element you pass in as the children of this component.
 */
export function Modal({
  closeButtonProps,
  onClose,
  children,
  showCloseButton = true,
  useContainer = true,
  ...props
}: Types.ModalProps) {
  const modalNode = React.useContext(ModalContext)
  const { keyboardProps } = useKeyboard({
    onKeyUp: (e) => {
      if (e.key === 'Escape') onClose()
    },
  })

  const ModalContainer = useContainer ? Styled.Container : Box

  return modalNode
    ? ReactDOM.createPortal(
        <>
          {/* autoFocus is safe here since we're opening a modal */}
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <FocusScope autoFocus contain restoreFocus>
            {/* @ts-ignore TODO: Resolve readonly string type error */}
            <ModalContainer
              data-what="modal"
              role="dialog"
              {...keyboardProps}
              {...props}
              open
            >
              {showCloseButton && (
                <ModalCloseButton
                  color="bb.spicedCrimson"
                  data-cy={TEST_ID.MODAL_CLOSE_BUTTON}
                  data-what="modal-close-button"
                  onClick={onClose}
                  title="Close"
                  {...closeButtonProps}
                />
              )}
              {children}
            </ModalContainer>
          </FocusScope>

          <Styled.Overlay onClick={onClose} showHeader={props.showHeader} />

          <Global styles={BodyFixed} />
        </>,
        modalNode
      )
    : null
}

const ModalContent = Styled.ModalContent

export {
  ModalCloseButton,
  ModalContext,
  ModalDialog,
  ModalFooter,
  ModalHeader,
  ModalProvider,
  ModalContent,
}
