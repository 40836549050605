import React from 'react'
import * as Styles from './PanelSection.css'
import { LinkInternal } from 'design/components/Typography/Typography'
import { Box, Text, rem } from '@butcherbox/freezer'
import { IPanelSection } from './PanelSection.types'

export function PanelSection({
  id = '',
  content,
  contentAddon,
  changeLinkUrl,
  ...props
}: IPanelSection) {
  return (
    <Box className={Styles.Flex} component="h3" {...props}>
      <Text component="span" id={id} variant="Subhead1">
        {content}
      </Text>
      {changeLinkUrl && (
        <LinkInternal href={changeLinkUrl} ml={rem(5)}>
          Change
        </LinkInternal>
      )}
      <Text component="span" variant="H3Bold">
        {contentAddon}
      </Text>
    </Box>
  )
}
