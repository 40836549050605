/**
 * This component is inspired by the work in this blog post:
 * https://www.useragentman.com/blog/2016/12/15/implementing-an-accessible-skip-navigation-link-requires-more-thought-than-youd-think/
 */
import { PseudoBox, PseudoBoxProps } from '@chakra-ui/core'
import { setA11yFocus, styled } from 'design'
import { ButtonPrimary, IButtonProps } from 'design/components/Button/Button'
import React from 'react'
import { TEST_ID } from '~/constants/cypress'

const TARGET_CLASS = 'bypass-block-target'

export type IBypassBlockTarget = PseudoBoxProps
export const BypassBlockTarget: React.FC<IBypassBlockTarget> = (props) => {
  return (
    <PseudoBox
      _focus={{ outline: 'none' }}
      className={TARGET_CLASS}
      tabIndex={-1}
      {...props}
    />
  )
}

export const BypassBlock: React.FC<Omit<IButtonProps, 'children'>> = (
  props
) => {
  const skipToContent = React.useCallback(() => {
    const targets = (document.querySelectorAll(
      `.${TARGET_CLASS}`
    ) as unknown) as HTMLElement[] | null
    // Focus the target that is "last" in the DOM tree to allow sub components
    // to override the bypass block behavior of higher layouts
    if (targets?.length) setA11yFocus(targets[targets.length - 1])
  }, [])

  return (
    <Block
      data-cy={TEST_ID.BYPASS_BLOCK}
      onClick={() => skipToContent()}
      {...props}
    >
      Skip to main content
    </Block>
  )
}

const Block = styled(ButtonPrimary)`
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: -1px;
  z-index: 9999;

  &:focus {
    clip: auto;
    height: auto;
    overflow: visible;
    position: absolute;
    white-space: normal;
    width: auto;
    margin: auto;
  }
`
