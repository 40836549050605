export { onClientEntry } from './src/gatsby/gatsby-on-client-entry'
export { onInitialClientRender } from './src/gatsby/gatsby-on-initial-client-render'
export { onRouteUpdate } from './src/gatsby/gatsby-on-route-update'
export { wrapRootElementBrowser as wrapRootElement } from './src/gatsby/gatsby-wrap-root-element'
import { loadableReady } from '@loadable/component'
import React from 'react'
import { TrackHeapEventPropertiesWrapper } from '~/components/TrackHeapEventPropertiesWrapper/TrackHeapEventPropertiesWrapper'

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    loadableReady(() => {
      ReactDOM.render(element, container, callback)
    })
  }
}
export const wrapPageElement = ({ element }) => {
  return (
    <TrackHeapEventPropertiesWrapper>{element}</TrackHeapEventPropertiesWrapper>
  )
}
