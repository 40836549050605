import { useAuth0 } from '@auth0/auth0-react'
import { HeaderProps, NavItemLink } from '@butcherbox/freezer'
import { useLocation } from '@reach/router'
import React from 'react'
import {
  GIFT_BOXES_PATH_SHOP,
  GIFT_BOXES_PATH_STOREFRONT,
} from '~/data/constants'
import { CUSTOMER_SERVICE, UNAUTH } from '~/data/header'
import useOptimizelyExperiment from '~/hooks/useOptimizelyExperiment'
import useOptimizelyFeature from '~/hooks/useOptimizelyFeature'
import useSetCartOffer from '~/hooks/useSetCartOffer'
import useSwitchToSubDomain from '~/hooks/useSwitchToSubDomain'
import { TRY_BB_URL } from '~/routes/constants'
import { JOIN_586_VARIANTS } from '~/types/join-586-bypass-get-started'

const useUnauthenticatedNavState = (cta?: string) => {
  const { isAuthenticated } = useAuth0()
  const { pathname } = useLocation()

  /* Hook sets cookie for /get-started deal for join 586 experiment */
  const { cartDealUrl } = useSetCartOffer()
  const [shouldBypassGetStarted] = useOptimizelyExperiment(
    'join-586__bypassing-get-started'
  ) as [JOIN_586_VARIANTS, null, null]

  const [isGiftLinkEnabled] = useOptimizelyFeature('ecom_810_remix_otb_rollout')

  const { AUTH_URL } = useSwitchToSubDomain()

  return React.useMemo(() => {
    /* For finding the default CTA link + replacing it if user is bucketed */
    UNAUTH.cta.link.href =
      shouldBypassGetStarted &&
      shouldBypassGetStarted === JOIN_586_VARIANTS?.BYPASS_GET_STARTED
        ? cartDealUrl
        : TRY_BB_URL

    UNAUTH.nav[3]['link'].href = isGiftLinkEnabled
      ? GIFT_BOXES_PATH_SHOP
      : GIFT_BOXES_PATH_STOREFRONT

    // Default Navigation Fixture Data
    const navigation: HeaderProps['state'] = { ...UNAUTH }

    /**
     * For the corporate gifting page, we customize
     * the customer service phone number messaging and link
     */
    if (pathname.includes('corporate-gifting')) {
      navigation.customerService = CUSTOMER_SERVICE.corporateSales
    }

    /**
     * Append `My Account` link for Authenticated Members viewing UNAUTH pages
     */
    if (isAuthenticated) {
      navigation.nav = [
        ...UNAUTH.nav,
        {
          title: 'My Account',
          link: { href: `${AUTH_URL}/member`, isExternal: true },
          variant: 'primary',
          decoration: 'spicedCrimson',
          type: 'link',
        },
      ]
    } else {
      /**
       * Code pertaining to JOIN-586 Experiment
       */
      const getStartedIndex: number = UNAUTH.nav.findIndex(
        (navObj: NavItemLink) => navObj.title === 'Choose your Plan'
      )

      /*
      Finding object in the UNAUTH Nav and replacing it with this one.
      Exactly the same besides if a user is bucketed in 586
      */
      UNAUTH.nav[getStartedIndex] = {
        title: 'Choose your Plan',
        link: {
          href:
            shouldBypassGetStarted &&
            shouldBypassGetStarted === JOIN_586_VARIANTS?.BYPASS_GET_STARTED
              ? cartDealUrl
              : TRY_BB_URL,
        },
        variant: 'primary',
        type: 'link',
        renderFor: ['Mobile'],
      }

      /**
       * Append `Sign In` link for all other members.
       */
      navigation.nav = [
        ...UNAUTH.nav,
        {
          title: 'Sign In',
          link: { href: `${AUTH_URL}/login`, isExternal: true },
          variant: 'primary',
          decoration: 'spicedCrimson',
          type: 'link',
        },
      ]
    }

    // Update the Primary CTA text (Get Started)
    if (cta) {
      navigation.cta.title = cta
    }
    return navigation
  }, [cartDealUrl, cta, isAuthenticated, pathname, shouldBypassGetStarted])
}

export default useUnauthenticatedNavState
