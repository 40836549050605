import {
  Box,
  BoxProps,
  Grid,
  PseudoBox,
  PseudoBoxProps,
  VisuallyHidden,
} from '@chakra-ui/core'
import dayjs, { Dayjs } from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { rem } from 'design'
import { IconChevronLeft, IconChevronRight } from '@butcherbox/freezer'
import Tooltip from 'design/components/Tooltip/Tooltip'
import { Body } from 'design/components/Typography/Typography'
import { ToastContext } from 'design/contexts/Toast/Toast.context'
import React from 'react'
import { LiveAnnouncer, LiveMessage } from 'react-aria-live'
import { TEST_ID } from '~/constants/cypress'
import { isCalendarDayInvalid, YYYY_MM_DD } from '~/utils/dates'
import { Text } from '@butcherbox/freezer'
import { holidayWindowForDate } from '~/components/Calendar/holidays'
import { DateYMDString } from 'design/date-utils'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(localizedFormat)

const DAYS_IN_WEEK = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

type CalendarDayType =
  | 'active'
  | 'validCurrentMonth'
  | 'validNextMonth'
  | 'deliveryWindow'
  | 'default'
  | 'invalid'
  | 'custom'

interface CalendarCustomType<T extends CalendarDayType = CalendarDayType> {
  days: CalendarCustomDay[]
  type: T
}

export type CalendarCustomDay = {
  day: Dayjs
  tooltipText?: string
  dateOverrideType?: DateOverrideType
}

type ICalendarDay = {
  day: DayInMonth
  dayTypes: CalendarDayType[]
  invalid: boolean
  isConsecutive: boolean
  blackedOut: boolean
} & PseudoBoxProps

interface CalendarProps extends BoxProps {
  customTypes?: CalendarCustomType[]
  lastValidDate?: Dayjs
  selectedDate: string
  setSelectedDate: (date: string) => void
  formattedDeliveryWindow: string
}

export type DayInMonth = {
  date: string
  dayTypes: CalendarDayType[]
  label: string
  parsedDate: Dayjs
  focusable: boolean
  tooltipText?: string
  dateOverrideType?: DateOverrideType
}

export enum DateOverrideType {
  warning = 'Warning',
  blackOut = 'Blackout',
}

const COLUMN_GAP = 5
const MOBILE_COLUMN_GAP = 3

export default function Calendar({
  customTypes,
  lastValidDate = dayjs().add(6, 'month'),
  selectedDate = dayjs().format(),
  setSelectedDate,
  formattedDeliveryWindow,
  ...props
}: CalendarProps) {
  const showToast = React.useContext(ToastContext)
  const customDays = customTypes
    .filter((calendarType) => calendarType.type === 'custom')
    .flatMap(({ days }) => days)

  const invalidDates = customDays
    .filter((d) => d.dateOverrideType === DateOverrideType.blackOut)
    .map((date) => date.day)

  const [currentMonth, setCurrentMonth] = React.useState<string>(selectedDate)
  const [currentDay, setCurrentDay] = React.useState<string>(selectedDate)
  const [ariaAnnouncement, setAriaAnnouncement] = React.useState<string>(
    `The currently selected bill date is ${dayjs(selectedDate).format('L')}.`
  )

  React.useEffect(() => {
    setCurrentMonth(fromStartOfMonth(selectedDate))
  }, [selectedDate])

  React.useEffect(() => {
    const selectedDayButton: HTMLElement = document.querySelector(
      `[data-calendar-date='${currentDay}']`
    )
    if (selectedDayButton) {
      selectedDayButton.focus()
    }
  }, [currentDay])

  const moveToNextMonth = () => {
    setCurrentMonth(dayjs(currentMonth).add(1, 'month').format())
  }

  const moveToPreviousMonth = () => {
    setCurrentMonth(dayjs(currentMonth).subtract(1, 'month').format())
  }

  const calculateNextBillDay = (daysToAdd = 1) => {
    return dayjs(currentDay).add(daysToAdd, 'day').format()
  }

  const calculatePrevBillDay = (daysToSubtract = 1) => {
    return dayjs(currentDay).subtract(daysToSubtract, 'day').format()
  }

  const dateClicked = (date: string) => {
    const parsedDate = dayjs(date)

    if (parsedDate.isAfter(dayjs().add(6, 'month'), 'd')) {
      return showToast('warning', {
        children:
          'The bill date can only be set a maximum of six months in the future.',
      })
    }

    setSelectedDate(date)
    setCurrentDay(date)
    setCurrentMonth(fromStartOfMonth(parsedDate.format()))

    setAriaAnnouncement(
      `The currently selected bill date is ${parsedDate.format(
        'L'
      )} with a delivery window of ${formattedDeliveryWindow}.
      Press the save button to make this change to your next order.`
    )
  }

  const keyHandler = (event) => {
    let potentialDate = null
    if (event.keyCode === 37) {
      // left arrow
      potentialDate = calculatePrevBillDay(1)
    } else if (event.keyCode === 38) {
      // up arrow
      potentialDate = calculatePrevBillDay(7)
    } else if (event.keyCode === 39) {
      // right arrow
      potentialDate = calculateNextBillDay(1)
    } else if (event.keyCode === 40) {
      // down arrow
      potentialDate = calculateNextBillDay(7)
    } else {
      // skip any other key commands
      return false
    }

    const parsedDate = dayjs(potentialDate)

    // note: check accessibility of Toast
    if (parsedDate.isAfter(dayjs().add(6, 'month'), 'd')) {
      return showToast('warning', {
        children:
          'The bill date can only be set a maximum of six months in the future.',
      })
    }

    const dayInvalid = isCalendarDayInvalid(parsedDate, invalidDates)
    const dayBlackedOut = isDayBlackedOut(parsedDate, invalidDates)

    if (!dayInvalid && !dayBlackedOut) {
      setSelectedDate(potentialDate)
      setCurrentDay(potentialDate)
      setCurrentMonth(fromStartOfMonth(parsedDate.format()))

      setAriaAnnouncement(
        `The currently selected bill date is ${parsedDate.format(
          'L'
        )} with a delivery window of ${formattedDeliveryWindow}.
        Press the save button to make this change to your next order.`
      )
    }
  }

  const HolidayShippingMessage = () => (
    <Box m="0 auto" textAlign="center">
      {dayjs(currentMonth).format('M') === '11' && (
        <Text marginBottom={24} variant="Body1Regular">
          The last bill date to guarantee your box arrives before Thanksgiving
          is <strong>November 12</strong>
        </Text>
      )}
      {dayjs(currentMonth).format('M') === '12' && (
        <Text marginBottom={24} variant="Body1Regular">
          The last bill date to guarantee your box arrives before Christmas is{' '}
          <strong>December 17</strong>, and before New Year's Day is{' '}
          <strong>December 24</strong>
        </Text>
      )}
    </Box>
  )

  const month = createMonth(currentMonth, selectedDate, customTypes)

  return (
    <>
      <HolidayShippingMessage />
      <Box
        bg="white"
        borderColor="bb.silt"
        borderWidth={1}
        maxWidth={{ desktop: rem(376) }}
        pb={rem(32 - COLUMN_GAP)}
        pt={rem(33 - COLUMN_GAP)}
        px={{ base: rem(10 - COLUMN_GAP), tablet: rem(30 - COLUMN_GAP) }}
        {...props}
      >
        <LiveAnnouncer>
          <LiveMessage aria-live="polite" message={ariaAnnouncement} />
        </LiveAnnouncer>
        <Grid
          data-cy={TEST_ID.CALENDAR}
          gridGap={{ base: rem(MOBILE_COLUMN_GAP), tablet: rem(COLUMN_GAP) }}
          gridTemplateColumns={{
            mobile: `repeat(7, ${rem(35)})`,
            tablet: `repeat(7, ${rem(44)})`,
          }}
          gridTemplateRows={{
            mobile: `${rem(17 + 24 - COLUMN_GAP)} min-content repeat(6, ${rem(
              40
            )})`,
            tablet: `${rem(17 + 24 - COLUMN_GAP)} min-content repeat(6, ${rem(
              44
            )})`,
          }}
          justifyContent="center"
          title="Select a bill date"
        >
          <Box
            alignSelf="start"
            aria-label={`Move to previous month, ${dayjs(currentMonth)
              .subtract(1, 'month')
              .format('MMMM YYYY')}`}
            as="button"
            data-cy={TEST_ID.BUTTON_PREVIOUS_MONTH}
            data-testid="prevMonthButton"
            gridColumn={1}
            onClick={() => moveToPreviousMonth()}
            pt="1"
            px="4"
          >
            <IconChevronLeft
              customColor={{ base: 'spicedCrimson' }}
              size="text"
            />
          </Box>

          <Box
            alignSelf="start"
            gridColumn="2/7"
            py={rem(2)}
            textAlign="center"
          >
            <Text data-cy={TEST_ID.CALENDAR_CURRENT_MONTH} variant="H3Bold">
              {dayjs(currentMonth).format('MMMM YYYY')}
            </Text>
          </Box>

          <Box
            alignSelf="start"
            aria-label={`Move to next month, ${dayjs(currentMonth)
              .add(1, 'month')
              .format('MMMM YYYY')}`}
            as="button"
            data-cy={TEST_ID.BUTTON_NEXT_MONTH}
            gridColumn={7}
            onClick={() => moveToNextMonth()}
            pt="1"
            px="4"
          >
            <IconChevronRight
              customColor={{ base: 'spicedCrimson' }}
              size="text"
            />
          </Box>

          {DAYS_IN_WEEK.map((day, index) => (
            <Cell as="h4" key={day}>
              <Text textAlign="center" variant="Body2Bold">
                <VisuallyHidden as="span">
                  {dayjs().day(index).format('dddd')}
                </VisuallyHidden>
                <span aria-hidden="true">{day}</span>
              </Text>
            </Cell>
          ))}

          {month.map(
            (
              { date, label, dayTypes, parsedDate, focusable, tooltipText },
              idx,
              days
            ) => {
              const [dayType] = dayTypes
              const dayInvalid = isCalendarDayInvalid(
                parsedDate,
                invalidDates,
                lastValidDate
              )

              const dayBlackedOut = isDayBlackedOut(parsedDate, invalidDates)

              const holiday = holidayWindowForDate(dayjs(date))?.name

              const holidayShippingMessage =
                holiday &&
                `Most orders arrive within 5 days, but due to potential delays, we can’t guarantee delivery by ${holiday}.`

              // To hide tooltip, `content` must be null (not "" or `false`)
              const tooltipContent =
                tooltipText || holidayShippingMessage || null

              const dayIsClickable = !dayInvalid && !dayBlackedOut
              const isConsecutive =
                idx > 0 && days[idx - 1].dayTypes[0] === dayType

              const calendarContent = (
                <CalendarDay
                  aria-checked={dayType === 'active'}
                  aria-label={`${parsedDate.format('MMMM D')} bill date`}
                  as="button"
                  blackedOut={dayBlackedOut}
                  data-calendar-date={date}
                  data-chromatic="ignore"
                  data-cy-calendar-date={date}
                  data-cy-is-date-valid={!dayInvalid}
                  data-testid={parsedDate.format('MMMM D')}
                  day={days[idx]}
                  dayTypes={dayTypes}
                  height="100%"
                  invalid={dayInvalid}
                  isConsecutive={isConsecutive}
                  key={date}
                  onClick={dayIsClickable ? () => dateClicked(date) : () => {}}
                  onKeyUp={keyHandler}
                  role="radio"
                  tabIndex={focusable ? 0 : -1}
                >
                  <DayContent label={label} />
                </CalendarDay>
              )
              return tooltipContent ? (
                <Tooltip
                  content={tooltipContent}
                  data-what={dayjs().day(dayjs(date).day()).format('dddd')}
                  key={date}
                  position="top"
                >
                  {calendarContent}
                </Tooltip>
              ) : (
                calendarContent
              )
            }
          )}
        </Grid>
      </Box>
    </>
  )
}

const Cell = ({ children, ...props }: PseudoBoxProps) => (
  <PseudoBox
    alignItems="center"
    d="flex"
    justifyContent="center"
    position="relative"
    userSelect="none"
    {...props}
  >
    {children}
  </PseudoBox>
)

const CalendarDay = React.memo(
  ({
    dayTypes,
    invalid,
    isConsecutive,
    blackedOut,
    ...props
  }: ICalendarDay) => {
    const [dayType] = dayTypes
    let cellProps: PseudoBoxProps = {
      ...props,
    }

    // Clearly delineate styles for all possible states of calendar days
    switch (dayType) {
      case 'active':
        cellProps = {
          ...cellProps,
          _hover: { color: 'white' },
          bg: 'bb.spicedCrimson',
          color: 'white',
        }
        break
      case 'validNextMonth':
        cellProps = {
          ...cellProps,
          _hover: { bg: 'bb.crimson', color: 'white' },
          bg: 'white',
          color: 'bb.slate',
        }
        break
      case 'deliveryWindow':
        cellProps = {
          ...cellProps,
          _hover: { bg: 'bb.crimson', color: 'white' },
          bg: 'ui.inactive',
          color: 'bb.slate',
          pos: 'relative',
        }
        break
      // default, for 'validCurrentMonth' and 'default'.
      // this will also be applied for 'custom' and 'invalid'
      // but those will sometimes be overwritten below
      default:
        cellProps = {
          ...cellProps,
          _hover: { bg: 'bb.crimson', color: 'white' },
          bg: 'white',
          color: 'bb.slate',
        }
        break
    }

    if (invalid) {
      cellProps = {
        ...cellProps,
        _hover: { bg: 'bb.stone', color: 'white' },
        cursor: 'not-allowed',
        onClick: () => {},
        // we don't want tooltip *and* title text to show on hover
        title: !blackedOut ? 'This date is not currently available' : '',
      }

      if (dayType !== 'active') {
        cellProps.color = 'bb.stone'
      }
    }

    const { children, ...rest } = cellProps
    return (
      <Cell {...rest}>
        {children}
        {dayType === 'deliveryWindow' && isConsecutive && (
          <Box
            bg="ui.inactive"
            bottom={0}
            h="100%"
            pos="absolute"
            position="absolute"
            right="100%"
            top={0}
            width={rem(COLUMN_GAP)}
          />
        )}
      </Cell>
    )
  }
)

const getDayType = (
  calendarDate: Dayjs,
  currentMonth: string,
  selectedDate: Dayjs,
  customTypes: CalendarCustomType[] | undefined
): CalendarDayType[] => {
  if (selectedDate.isSame(calendarDate, 'd')) {
    return ['active']
  }

  const fmt = YYYY_MM_DD

  const customTypesForDay = customTypes
    ?.filter((type) =>
      type.days.map((d) => d.day.format(fmt)).includes(calendarDate.format(fmt))
    )
    .map((t) => t.type)

  if (customTypes) {
    return customTypesForDay as CalendarDayType[]
  }

  const afterCurrentDate = calendarDate.isAfter(dayjs())
  if (afterCurrentDate) {
    const inCurrentMonth = calendarDate.format('MM') === currentMonth
    if (inCurrentMonth) {
      return ['validCurrentMonth']
    } else {
      return ['validNextMonth']
    }
  }

  return ['default']
}

const isDayBlackedOut = (
  calendarDate: Dayjs,
  invalidDates: Dayjs[]
): boolean => {
  return (
    invalidDates.some((day) => day.isSame(calendarDate, 'd')) &&
    !calendarDate.isSame(dayjs(), 'd')
  )
}

const createMonth = (
  currentMonth: string,
  selectedDate: string,
  customTypes: CalendarCustomType[] | undefined
): DayInMonth[] => {
  const startDate = dayjs(currentMonth).startOf('week')

  // Fill 6 calendar rows
  const endDate = dayjs(startDate).add(41, 'd')

  const customDatesTable: Record<
    DateYMDString,
    CalendarCustomDay
  > = customTypes
    .flatMap((type) => type.days)
    .reduce(
      (obj, date) => ({ ...obj, [date.day.format(YYYY_MM_DD)]: date }),
      {} as Record<DateYMDString, CalendarCustomDay>
    )

  const days: DayInMonth[] = []
  let needFocusable = true

  for (
    let date = startDate;
    date.isSameOrBefore(endDate);
    date = date.add(1, 'day')
  ) {
    const customDay = customDatesTable[date.format(YYYY_MM_DD)]

    const newDay: DayInMonth = {
      date: date.format(),
      label: date.format('D'),
      parsedDate: date,
      focusable: false,
      tooltipText: customDay?.tooltipText,
      dateOverrideType: customDay?.dateOverrideType,
      dayTypes: getDayType(
        date,
        dayjs(currentMonth).format('MM'),
        dayjs(selectedDate),
        customTypes
      ),
    }

    // Make the first active day focusable, otherwise use the first valid, non-active day
    if (needFocusable) {
      const isCurrentDayFocusable =
        newDay.dayTypes[0] === 'active' ||
        newDay.dayTypes[0] === ('validCurrentMonth' || 'validNextMonth')
      if (isCurrentDayFocusable) {
        newDay.focusable = true
        needFocusable = false
      }
    }

    days.push(newDay)
  }

  return days
}

type IDayContent = {
  label: string
}

const DayContent: React.FC<IDayContent> = ({ label }) => (
  <Body textAlign="center">{label}</Body>
)

const fromStartOfMonth = (date: string) => dayjs(date).startOf('month').format()
