import React from 'react'

const defaultIntersectionObserverInit: IntersectionObserverInit = {
  root: null,
  rootMargin: '0px',
  threshold: [0.25, 0.5, 0.75],
}

/**
Note: the options passed in to this function are not included in the
dependency array of the effect used to set the intersection observer, 
so any changes made to them after the first render will not take effect
(i.e. they are expected to be static for the lifetime of the calling component). 
*/
export const useVisible = <T extends Element>(
  option: Partial<IntersectionObserverInit> = {}
): [React.MutableRefObject<T | null>, boolean] => {
  const targetRef = React.useRef<T | null>(null)
  const observerRef = React.useRef<IntersectionObserver | null>(null)
  const [visible, setVisible] = React.useState<boolean>(false)
  const observerCallback: IntersectionObserverCallback = React.useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        setVisible(entry.isIntersecting)
      })
    },
    []
  )

  React.useEffect(() => {
    if (observerRef.current) return
    if (!targetRef.current) return

    const target = targetRef.current

    observerRef.current = new IntersectionObserver(observerCallback, {
      ...defaultIntersectionObserverInit,
      ...option,
    })

    observerRef.current.observe(target)

    return () => observerRef.current.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerCallback])

  return [targetRef, visible]
}
