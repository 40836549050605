import { Box, BoxProps, VisuallyHidden } from '@chakra-ui/core'
import { useFormikContext } from 'formik'
import React, { FormHTMLAttributes } from 'react'
import { HEADER_HEIGHT } from '~/components/Header/Header'
import { smoothScrollTo } from '~/utils/scroll'

export type IForm = BoxProps &
  FormHTMLAttributes<any> & {
    /** descriptive title for the form, used in the <legend> field for screen readers */
    title: string
    /**
     * Supply a custom submit handler. If not supplied, this form must render in a Formik context.
     */
    onSubmit?: any
  }

/**
 * Form component that implements autoscrolling behavior and implements
 * the same behavior as the Formik Form component
 *
 * @todo Implement form reset behavior from the Formik <Form> component
 * @todo Ensure that all usages of this component render within a Formik context
 */
export default function Form({ children, onSubmit, title, ...props }: IForm) {
  const { handleSubmit: formikSubmit } = useFormikContext() || {}

  if (process.env.NODE_ENV !== 'production' && !onSubmit && !formikSubmit) {
    throw new Error(
      'Form is misconfigured, must either have an `onSubmit` or be rendered in a Formik context.'
    )
  }

  const formRef = React.useRef<HTMLFormElement>(null)
  const handleSubmit = React.useCallback(
    async (...args) => {
      if (onSubmit || formikSubmit) {
        await (onSubmit || formikSubmit)(...args)

        if (formRef.current) {
          // if there are any invalid inputs, make them visible
          const target:
            | HTMLInputElement
            | HTMLTextAreaElement
            | HTMLSelectElement
            | null = formRef.current.querySelector('[aria-invalid="true"]')

          if (target) {
            await smoothScrollTo(target, -(HEADER_HEIGHT + 20))
            target.focus()
          }
        }

        return
      }

      if (process.env.NODE_ENV !== 'production') {
        throw new Error(
          'Form is misconfigured, must either have an `onSubmit` or be rendered in a Formik context.'
        )
      }
    },
    [formikSubmit, onSubmit]
  )

  const FormBox = Box as React.FC<BoxProps & FormHTMLAttributes<any>>

  return (
    <FormBox
      as="form"
      data-what="form"
      {...props}
      noValidate
      onSubmit={handleSubmit}
      ref={formRef}
    >
      <fieldset style={{ display: 'contents' }}>
        <VisuallyHidden as="legend">{title}</VisuallyHidden>
        {children}
      </fieldset>
    </FormBox>
  )
}
