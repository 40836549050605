import colors from './colors'
import { sizes, space } from './sizes'
import typography from './typography'
import { rem } from './utils'

type Shadows = {
  default: string
  dropShadow: string
  shadow: string

  /** @deprecated */
  sm: string
  /** @deprecated */
  md: string
  /** @deprecated */
  lg: string
  /** @deprecated */
  xl: string
  /** @deprecated */
  '2xl': string
  /** @deprecated */
  outline: string
  /** @deprecated */
  outlinePrimary: string
  /** @deprecated */
  inner: string
  /** @deprecated */
  none: string
}

export type BreakpointAliases = {
  mobile?: string
  header_expanded?: string
  tablet?: string
  desktop?: string
}

type Breakpoints = string[] & BreakpointAliases

type StringOrNumber = string | number

type Radii = {
  none: string
  sm: string
  md: string
  lg: string
  full: string
}

type Borders = {
  none: StringOrNumber
  '1px': StringOrNumber
  '2px': StringOrNumber
  '4px': StringOrNumber
}

type Opacity = {
  '0': string
  '20%': string
  '40%': string
  '60%': string
  '80%': string
  '100%': string
}

const shadows: Shadows = {
  default: '0px 2px 6px rgba(0, 0, 0, 0.09)',
  dropShadow: '1px 2px 7px rgba(0, 0, 0, 0.15)',
  shadow: '0px 2px 7px rgba(0, 0, 0, 0.15)',

  // TODO: remove references to these shadows not specified in the pattern library

  sm: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
  md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  lg: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
  xl:
    '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
  '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  outline: '0 0 0 3px rgba(66, 153, 225, 0.6)',
  outlinePrimary: `0 0 0 2px ${colors.primaryAlpha[500]}`,
  inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
  none: 'none',
}

export const TABLET_MIN_WIDTH = 768
export const DESKTOP_MIN_WIDTH = 1280

const breakpoints: Breakpoints = [
  rem(480),
  rem(TABLET_MIN_WIDTH),
  rem(992),
  rem(DESKTOP_MIN_WIDTH),
  rem(1200),
  rem(1280),
]

// aliases
breakpoints.header_expanded = rem(1000)
breakpoints.tablet = breakpoints[1]
breakpoints.desktop = breakpoints[3]

const media: BreakpointAliases & { [key: string]: string } = {
  mobile: `@media (max-width: ${parseInt(breakpoints[1], 10) - 1}px)`,
  tablet: `@media (min-width: ${breakpoints[1]})`,
  desktop: `@media (min-width: ${breakpoints[3]})`,
  lt_desktop: `@media (max-width: ${parseInt(breakpoints[3], 10) - 1}px)`,
} as const

const sticky = 1100

// this is set in the GTM tag that adds window.zeSettings (Zendesk - Advanced Setup)
// anyone with GTM access can modify the value used in prod if needed
export const zenDeskZIndex = sticky + 1

const zIndices = {
  above_zendesk: zenDeskZIndex + 1,
  hide: -1,
  auto: 'auto',
  base: 0,
  header: 10,
  docked: 10,
  dropdown: 1000,
  sticky,
  banner: 1200,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  skipLink: 1600,
  toast: 1700,
  tooltip: 1800,
  zendesk: zenDeskZIndex,
} as const

const radii: Radii = {
  none: '0',
  sm: '0.125rem',
  md: '0.25rem',
  lg: '0.5rem',
  full: '9999px',
}

const opacity: Opacity = {
  '0': '0',
  '20%': '0.2',
  '40%': '0.4',
  '60%': '0.6',
  '80%': '0.8',
  '100%': '1',
}

const borders: Borders = {
  none: 0,
  '1px': '1px solid',
  '2px': '2px solid',
  '4px': '4px solid',
}

export default {
  ...typography,
  borders,
  breakpoints,
  colors,
  media,
  opacity,
  radii,
  shadows,
  sizes,
  space,
  zIndices,
}
