import { PseudoBoxProps } from '@chakra-ui/core'
import { LayoutContext } from 'design/contexts/layout'
import { RequireAtLeastOne } from 'design/helpers'
import React from 'react'
import { ITypography } from 'design/components/Typography/Typography'

export type ResponsiveTextProps = RequireAtLeastOne<{
  desktop: ITypography
  mobile: ITypography
  tablet: ITypography
}> &
  PseudoBoxProps

/**
 * @deprecated - Use Text from The Freezer
 */
export const ResponsiveText: React.FC<ResponsiveTextProps> = ({
  desktop,
  mobile,
  tablet,
  ...props
}) => {
  /**
   * TODO: Refactor this to use media queries rather than layout context in
   *       order to be renderable within the gatsby public page build process.
   */
  const { isDesktop, isTablet, isMobile } = React.useContext(LayoutContext)

  const Component = React.useMemo(() => {
    switch (true) {
      case isDesktop:
        return desktop || tablet || mobile
      case isTablet:
        return tablet || mobile || desktop
      case isMobile:
      default:
        return mobile || tablet || desktop
    }
  }, [desktop, isDesktop, isMobile, isTablet, mobile, tablet])

  return <Component data-what="responsive-text" {...props} />
}
