import theme from 'design'

const ZENDESK_EXCLUDED_PATHS = ['/']

export default {
  id: 'ze-snippet',
  url:
    'https://static.zdassets.com/ekr/snippet.js?key=4d069d11-7fa7-49fb-9b9a-ad428c2f3d61',
  delay: { mobile: 7000, default: 3500 },
  async: true,
  defer: true,
  executeCheck: function () {
    // Don't load zendesk on any the excluded paths
    if (
      location?.pathname &&
      ZENDESK_EXCLUDED_PATHS.includes(location.pathname)
    ) {
      return false
    }
    return process.env.NODE_ENV !== 'test'
  },
  pre: function (script) {
    window.zESettings = {
      webWidget: {
        chat: {
          connectOnPageLoad: false,
          departments: {
            enabled: [''],
            select: 'ButcherBox',
          },
        },
        zIndex: theme.zIndices.zendesk,
      },
    }
    return script
  },
}
