import dayjs, { Dayjs } from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { ShipZone } from '~/bb-api/schemata'
import { holidayWindowForDate } from '~/components/Calendar/holidays'

dayjs.extend(isBetween)

const EN_LOCALE_SUNDAY = 0
const EN_LOCALE_SATURDAY = 6
const DAYS_MAX = 4

/*
 * This can be used for a delayed shipping message for any holidays separate from the holiday.ts file (e.g 4th of July, Labor Day, etc.)
 * Since all dayjs dates are year locked we can/will update this as Fullfillment requests it as those holidays approach
 */
export const extendedDeliveryWindow = {
  billDateWindowStart: dayjs('2023-08-30'),
  billDateWindowEnd: dayjs('2023-09-06'),
  extendedRange: 7,
}

/**
 * This array is kept to maintain the logic implemented to account for
 * specific dates that are undeliverable
 * Format: YYYY-mm-dd
 */
const UNDELIVERABLE_DAYS = []

function isSaturday(day: Dayjs): boolean {
  return day.day() === EN_LOCALE_SATURDAY
}

function isSunday(day: Dayjs): boolean {
  return day.day() === EN_LOCALE_SUNDAY
}

/**
 * Create an estimated delivery window for the customer as an array of dayjs objects.
 * If a Sunday is in the delivery window, add an extra day, per https://nbox-341.atlassian.net/browse/WEB-2557.
 * If carrier doesn't deliver on Saturday, add an extra day.
 *
 * @param selectedDate Current date selected in calendar
 * @param shipZone Result of ship zone API for this customer
 */
export default function createDeliveryWindow(
  selectedDate: string,
  shipZone: Partial<ShipZone> = {}
): Dayjs[] {
  const { noSaturday } = shipZone
  const selectedDay = dayjs(selectedDate)

  function dayIsUndeliverable(day: Dayjs): boolean {
    return (
      (noSaturday && isSaturday(day)) ||
      isSunday(day) ||
      UNDELIVERABLE_DAYS.includes(day.format('YYYY-MM-DD'))
    )
  }

  const holidayWindow = holidayWindowForDate(selectedDay)

  const maxDeliveryWindow = selectedDay.isBetween(
    extendedDeliveryWindow.billDateWindowStart,
    extendedDeliveryWindow.billDateWindowEnd
  )
    ? extendedDeliveryWindow.extendedRange
    : DAYS_MAX

  const endDate = holidayWindow
    ? dayjs(holidayWindow.deliveryWindowEnd)
    : selectedDay.add(maxDeliveryWindow, 'day')

  let windowLength = endDate.diff(selectedDay, 'day')

  let day = selectedDay
  let i = 0

  const deliveryWindow: Dayjs[] = []
  while (deliveryWindow.length < windowLength) {
    // our loop condition should avoid an infinite loop, but we're going to perform
    // this explicit check on the iteration count just to be extra safe.
    if (i++ > 500) {
      throw new Error(
        'createDeliveryWindow function got caught in an infinite loop (500x)'
      )
    }

    day = dayjs(day).add(1, 'day')

    if (!holidayWindow && dayIsUndeliverable(day)) {
      windowLength++
    }

    deliveryWindow.push(day)
  }
  return deliveryWindow
}
