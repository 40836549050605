import { rem, styled } from 'design'
import React from 'react'
import { useBannerContent } from '~/hooks/useBannerContent'
import { H3 } from 'design/components/Typography/Typography'

export type IBannerContext = {
  content?: React.ReactNode
  setContent: (content?: React.ReactNode) => void
}

type IBannerOutlet = React.ComponentProps<typeof Banner>

const LINE_BREAK_R = /<br *\/?>/g

export const BannerContext = React.createContext<IBannerContext>(undefined)

export const BannerProvider = ({ children }) => {
  const [content, setContent] = React.useState<string>(undefined)

  return (
    <BannerContext.Provider
      value={{
        content,
        setContent,
      }}
    >
      {children}
    </BannerContext.Provider>
  )
}

export const BannerOutlet = (props: IBannerOutlet) => {
  const { content } = useBannerContent()

  const BannerNode = ((): React.ReactNode => {
    switch (true) {
      // Assume that strings might contain HTML from Wordpress
      case typeof content === 'string' && Boolean(content):
        return (
          <H3 as="p" data-what="banner-text">
            {process.env.IS_STORYBOOK ? (
              'Hello from Storybook!'
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: (content as string).replace(LINE_BREAK_R, ''),
                }}
              />
            )}
          </H3>
        )
      case Boolean(content):
        return content
      default:
        return null
    }
  })()

  return BannerNode ? (
    <>
      <Banner {...props} data-testid="banner" data-where="banner">
        {process.env.IS_STORYBOOK ? 'Hello from Storybook!' : BannerNode}
      </Banner>
    </>
  ) : null
}

export const Banner = styled.div<{ alert?: boolean }>`
  align-items: center;
  background-color: ${(p) =>
    p.alert ? p.theme.colors.bb.spicedCrimson : p.theme.colors.bb.slate};
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: Lato, sans-serif;
  font-size: ${rem(13)};
  line-height: 1.5em;
  justify-content: center;
  padding: ${rem(8)};
  text-align: center;

  p {
    color: inherit;
  }

  ${(p) => p.theme.media.tablet} {
    flex-direction: row;
    font-size: ${rem(16)};
  }
`
