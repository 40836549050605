import { Auth0ContextInterface } from '@auth0/auth0-react'
import qs from 'querystringify'
import { trackSignOut } from '~/analytics/events'

export const AUTH0_UID_KEY = 'https://butcherbox/jwt/uid'
export const AUTH0_ID_PREFIX = 'auth0|'

/**
 * This is totally a hack, but in some cases we need access to
 * the auth tokens outside of the React flow (like when using axios.)
 */

let auth0Client: Auth0ContextInterface

export const getClient = () => auth0Client
export const setAuth0Client = (client) => (auth0Client = client)

export const getAccessToken: any = (options) =>
  auth0Client.getAccessTokenSilently(options)

export const login: Auth0ContextInterface['loginWithRedirect'] = (
  options = {}
) => {
  if (!auth0Client) throw new Error('Auth0 client not available')

  const params = qs.parse(window.location.search) as {
    connection?: string
    target_uid?: string
  }

  options.connection = params.connection || 'Username-Password-Authentication'

  if (params.target_uid) {
    options.target_uid = params.target_uid
  }

  return auth0Client.loginWithRedirect(options)
}

export const logout: Auth0ContextInterface['logout'] = async (
  options = { returnTo: `${window.location.origin}/login` }
) => {
  if (!auth0Client) throw new Error('Auth0 client not available')

  // let the event fire before resetting analytics
  if (auth0Client.user) await trackSignOut(auth0Client.user?.email)

  // log out of segment
  window.analytics?.reset()

  return auth0Client.logout(options)
}

export const logoutToHome = () => {
  return logout({ returnTo: `${window.location.origin}?loggedout` })
}

export function getUserIdFromAuth0User(
  authUser: Auth0ContextInterface['user']
) {
  return authUser?.[AUTH0_UID_KEY] || authUser?.sub.replace(AUTH0_ID_PREFIX, '')
}
