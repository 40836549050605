import Cookies from 'js-cookie'
import React from 'react'
import {
  trackHeapEventProperties,
  trackHeapInitialPageView,
} from '~/analytics/events'

export function TrackHeapEventPropertiesWrapper({ children }) {
  React.useEffect(() => {
    trackHeapInitialPageView(!!Cookies.get('has_authenticated'))
    trackHeapEventProperties(!!Cookies.get('has_authenticated'))
  }, [])

  return <>{children}</>
}
