import { Box, rem, VisuallyHidden } from '@butcherbox/freezer'
import React from 'react'
import { UpcomingOrder } from '~/bb-api/schemata'
import { PanelBreak } from '~/components/Panel/PanelBreak/PanelBreak'
import { formatPriceFromCents } from '~/utils'
import TotalLineItem from './components/TotalLineItem'

const Totals = ({ order }: { order: UpcomingOrder }) => {
  const showCredits = !!order?.creditAmount
  const showDiscount = !!order?.discountAmount
  const totals = {
    subtotal: formatPriceFromCents(order?.subtotal),
    estimatedTaxes: formatPriceFromCents(order?.taxAmount),
    coupons: formatPriceFromCents(-Math.abs(order?.discountAmount)),
    credits: formatPriceFromCents(-Math.abs(order?.creditAmount)),
    total: formatPriceFromCents(order?.total),
  }

  return (
    <Box px={16}>
      <Box component="table" w="100%">
        <caption>
          <VisuallyHidden>Cart subtotal</VisuallyHidden>
        </caption>
        <TotalLineItem title="Subtotal" titleAddon={totals.subtotal} />
        <TotalLineItem
          title="Estimated Taxes"
          titleAddon={totals.estimatedTaxes}
        />
        <TotalLineItem title="Shipping" titleAddon="Free" />
        {showDiscount ? (
          <TotalLineItem title="Coupons" titleAddon={totals.coupons} />
        ) : (
          <></>
        )}
        {showCredits ? (
          <TotalLineItem title="Credits" titleAddon={totals.credits} />
        ) : (
          <></>
        )}
      </Box>

      <PanelBreak style={{ marginTop: rem(-4) }} />
      <Box component="table" w="100%">
        <TotalLineItem title="Total" titleAddon={totals.total} />
      </Box>
    </Box>
  )
}
export default Totals
