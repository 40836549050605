import { Box, Text, rem, TextLink } from '@butcherbox/freezer'
import { Link } from 'gatsby'
import React from 'react'

export const AddonsEmptyState = ({ onClose }: { onClose?: () => void }) => (
  <Box
    alignItems="center"
    border="spicedCrimson"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    px={32}
    py={20}
    textAlign="center"
    width="100%"
  >
    <div style={{ maxWidth: rem(650), marginBottom: rem(8) }}>
      <Text variant="Body2Regular">
        You don't have any add-ons in your box. Add-ons are a great way to stock
        up on essentials.
      </Text>
    </div>
    <TextLink
      component={Link}
      onClick={onClose}
      to="/account/addons"
      variant="Body2Regular"
    >
      View Available Add-ons
    </TextLink>
  </Box>
)

export const MemberDealsEmptyState = ({
  onClose,
}: {
  onClose?: () => void
}) => (
  <Box
    alignItems="center"
    border="spicedCrimson"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    px={32}
    py={20}
    textAlign="center"
    width="100%"
  >
    <div style={{ maxWidth: rem(650), marginBottom: rem(8) }}>
      <Text variant="Body2Regular">
        You don't have any deals in your upcoming order. Our deals change
        regularly, check often!
      </Text>
    </div>
    <TextLink
      component={Link}
      onClick={onClose}
      to="/account/deals"
      variant="Body2Regular"
    >
      View All Member Deals
    </TextLink>
  </Box>
)
