import { Box, Footer, FooterLegal } from '@butcherbox/freezer'
import React from 'react'
import { legalNavLinks, mainNavLinks, socialLinks } from '~/data'
import FooterNewsletterForm from './FooterNewsletterForm/FooterNewsletterForm'
import { Link } from 'gatsby'

export type DataAttributeMap = Record<string, string | number>

const buildDataAttributes = (data: DataAttributeMap = {}): DataAttributeMap => {
  const keys = Object.keys(data)
  const dataAttributes: DataAttributeMap = {}

  for (const key of keys) {
    dataAttributes[`data-${key}`] = data[key]
  }

  return dataAttributes
}

/**
 * This Footer is a reconciliation of the two previous components
 * for Unauth and Auth footers - the unauth footer allowed you to pass
 * a prop to disable publicity.
 * TODO: validate if we actually need to do this somewhere on the site,
 * and potentially reduce the scope of this interface.
 * https://nbox-341.atlassian.net/browse/WEB-3490
 */
export default function SharedFooter({
  isAuthenticated,
  disablePublicity,
  showLegalOnly,
}: {
  isAuthenticated: boolean
  disablePublicity: boolean
  showLegalOnly?: boolean
}) {
  /**
   * The newsletter form is only shown for
   * unauthenticated members.
   */
  const newsLetterForm = isAuthenticated
    ? undefined
    : () => <FooterNewsletterForm />

  /**
   * TODO: backtrace the need for this at contentful.
   * Migrate down to the freezer state prop once WEB-3490 is validated.
   */
  if (isAuthenticated) {
    disablePublicity = true
  }

  /**
   * Link rendering is handled by the consumer of freezer via
   * the render prop. This allows for customization by additional
   * box props for styling, or formatting data associated with the
   * link contents.
   */
  const link = ({ link, className, children }) => {
    if (link.isExternal || link.href.startsWith('https://')) {
      return (
        <Box
          className={className}
          component="a"
          display="inlineFlex"
          href={link.href}
          {...(link.data ? buildDataAttributes(link.data) : undefined)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {children}
        </Box>
      )
    } else {
      return (
        <Box
          className={className}
          display="inlineFlex"
          {...(link.data ? buildDataAttributes(link.data) : undefined)}
        >
          <Link to={link.href}>{children}</Link>
        </Box>
      )
    }
  }
  /**
   * Similar to Link rendering with less options.
   */
  const supportEmail = ({ className, children }) => {
    return (
      <Box
        className={className}
        component="a"
        href="mailto:support@butcherbox.com"
      >
        {children}
      </Box>
    )
  }

  return showLegalOnly ? (
    <Box
      component="footer"
      m={0}
      paddingBottom={{
        tablet: 0,
        mobile:
          // need more padding to account for protein type bar above next frame button
          window.location.pathname === '/plans-and-addons/customize' ? 60 : 40,
      }}
      paddingTop={8}
    >
      <FooterLegal
        links={legalNavLinks}
        renderLink={link}
        variant="compressed"
      />
    </Box>
  ) : (
    <Footer
      renderLink={link}
      renderNewsletterForm={newsLetterForm}
      renderSupportEmail={supportEmail}
      state={{
        mainNavLinks,
        legalNavLinks,
        socialLinks,
        showMediaBanner: !disablePublicity,
      }}
    />
  )
}
