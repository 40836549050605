import Tooltip from 'design/components/Tooltip/Tooltip'
import React from 'react'
import usePaymentProcessingMessage from '~/hooks/usePaymentProcessingMessage'

export const TooltipPaymentProcessing: React.FC<
  Partial<
    Omit<React.ComponentProps<typeof Tooltip>, 'position'> & {
      position?: 'top' | 'bottom'
      children:
        | React.ReactNode
        | (({
            isInteractionDisabled,
          }: {
            isInteractionDisabled: boolean
          }) => React.ReactNode)
    }
  > & { shouldShowTooltip?: boolean }
> = ({ children, position = 'bottom', shouldShowTooltip = true, ...props }) => {
  const { message, isLoading, isBlocked } = usePaymentProcessingMessage()
  return isBlocked && shouldShowTooltip ? (
    <Tooltip content={message} position={position} {...props}>
      {typeof children === 'function'
        ? children({ isInteractionDisabled: true })
        : children}
    </Tooltip>
  ) : (
    <>
      {typeof children === 'function'
        ? children({ isInteractionDisabled: isLoading })
        : children}
    </>
  )
}

export default TooltipPaymentProcessing
